import { FC, useState, useCallback, ChangeEvent, MouseEvent } from 'react'
import {
  Button,
  Menu,
  MenuItem,
  IconButton,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ShareIcon from '@material-ui/icons/Share'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState, updateDates } from '../redux'
import formatISO from 'date-fns/formatISO'

const useStyles = makeStyles((theme: any) => ({
  settingsContainer: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  button: {
    margin: theme.spacing(0, 1)
  },
  dateSelector: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    maxWidth: '100%'
  },
  date: {
    margin: theme.spacing(0, 2),
    '& ::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    '& .MuiInputBase-root, .MuiInputLabel-root': {
      color: 'white'
    },
    '& .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before':
      {
        borderColor: 'rgba(255, 255, 255, 0.7)'
      }
  },
  share: {
    color: 'white'
  }
}))

type DayRange = 'day' | 'week' | 'month'

interface ITableHeader {
  shareEnabled?: boolean
  hasData?: boolean
  onDownloadCsv?: () => void
}

const TableHeader: FC<ITableHeader> = (props) => {
  const classes = useStyles()
  const { shareEnabled, hasData, onDownloadCsv } = props
  const dispatch = useDispatch()
  const dateFilters = useSelector((state: ReduxState) => state.dateFilters)

  const [dayRange, setDayRange] = useState<DayRange>('day')
  const [dates, setDates] = useState({
    from: dateFilters.dates.from,
    to: dateFilters.dates.to
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleDateChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, name }
      } = e
      const formattedDate = new Date(value).getTime()
      setDates((oldDates) => ({ ...oldDates, [name]: formattedDate }))
    },
    []
  )

  const handleDateSave = useCallback(() => {
    dispatch(updateDates({ ...dates }))
  }, [dispatch, dates])

  const handleRangeChange = useCallback(
    (range) => () => {
      const getFromDate: { [key: string]: number } = {
        day: new Date().setDate(new Date().getDate() - 1),
        week: new Date().setDate(new Date().getDate() - 7),
        month: new Date().setMonth(new Date().getMonth() - 1)
      }
      const newDates = {
        from: getFromDate[range],
        to: new Date().getTime()
      }
      setDayRange(range)
      setDates(newDates)
    },
    [setDayRange, setDates]
  )

  const handleShareClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const { from, to } = dates
  const toDisplay = to ?? new Date()

  return (
    <div className={classes.settingsContainer}>
      <div className={classes.dateSelector}>
        <Button
          className={classes.button}
          onClick={handleRangeChange('day')}
          variant={dayRange === 'day' ? 'contained' : 'outlined'}
        >
          1D
        </Button>

        <Button
          className={classes.button}
          onClick={handleRangeChange('week')}
          variant={dayRange === 'week' ? 'contained' : 'outlined'}
        >
          1W
        </Button>

        <Button
          className={classes.button}
          onClick={handleRangeChange('month')}
          variant={dayRange === 'month' ? 'contained' : 'outlined'}
        >
          1M
        </Button>
        <TextField
          label="From"
          name="from"
          type="datetime-local"
          value={formatISO(from).slice(0, 16)}
          onChange={handleDateChange}
          classes={{ root: classes.date }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          label="To"
          name="to"
          type="datetime-local"
          value={formatISO(toDisplay).slice(0, 16)}
          onChange={handleDateChange}
          classes={{ root: classes.date }}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            max: formatISO(new Date()).slice(0, 16)
          }}
        />
        <Button
          className={classes.button}
          onClick={handleDateSave}
          variant={'contained'}
        >
          Update
        </Button>
      </div>

      {hasData && shareEnabled && (
        <IconButton
          size="small"
          onClick={handleShareClick}
          className={classes.share}
        >
          <ShareIcon />
        </IconButton>
      )}

      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem onClick={onDownloadCsv}>Export CSV</MenuItem>
        </Menu>
      )}
    </div>
  )
}

export default TableHeader
