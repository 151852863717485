import { FC, useMemo, useState } from 'react'
import markerSDK from '@marker.io/browser'

const destination = '618948c1be76ee21e844b4cf'

const MarkerIOWrapper: FC = () => {
  const [_, setWidget] = useState<any>()

  useMemo(async () => {
    const loadedWidget = await markerSDK.loadWidget({
      destination
    })
    setWidget(loadedWidget)
  }, [])

  return <span />
}

export default MarkerIOWrapper
