import useUserInfo from '../../redux/hooks/useUserInfo'
import { Button, makeStyles, Theme } from '@material-ui/core'
import { useState } from 'react'
import SetSensorDialog from './SetSensorDialog'

const useStyles = makeStyles((theme: Theme) => ({
  alertBtn: {
    marginBottom: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}))

interface Props {
  sensorID: string
}

export const SetOffsetButton = (props: Props) => {
  const classes = useStyles()
  const [showPingSensorDialog, setShowPingSensorDialog] = useState(false)
  const togglePingSensorDialog = () =>
    setShowPingSensorDialog(!showPingSensorDialog)
  const userInfo = useUserInfo()

  if (!userInfo.isSuperAdmin) {
    return null
  }

  return (
    <>
      <SetSensorDialog
        open={showPingSensorDialog}
        onClose={togglePingSensorDialog}
        sensorID={props.sensorID}
      />

      <Button
        variant="contained"
        className={classes.alertBtn}
        onClick={togglePingSensorDialog}
      >
        Set Offset
      </Button>
    </>
  )
}
