import { FC } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import MarkerIOWrapper from './MarkerIOWrapper'

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  content: {
    display: 'flex',
    flex: 2,
    height: '100%'
  }
}))

const Layout: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>

      <MarkerIOWrapper />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Layout
