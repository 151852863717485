import { FC, useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import format from 'date-fns/format'
import { GridColDef } from '@mui/x-data-grid'
import { Site, MaintenanceEvent, MaintenanceSchedule } from '../../API'
import TableView from '../TableView'

interface ScheduleDictionary { [scheduleID: string]: MaintenanceSchedule }

interface ILogsProps {
  site: Site
  schedules: MaintenanceSchedule[]
  events: (MaintenanceEvent | null)[]
  isLoading: boolean
  isError: boolean
}

const Logs: FC<ILogsProps> = (props) => {
  const { site, schedules, events, isLoading, isError } = props

  const keyedSchedules = useMemo(() => {
    return keyBy(schedules, 'id')
  }, [schedules])

  const columns = getColumns(keyedSchedules)

  return (
    <TableView
      showHeader={false}
      data={events}
      columns={columns}
      isError={isError}
      isLoading={isLoading}
      csvFileName={`${site.name}-maintence-events`}
      emptyMessage={`No maintenance events were found for ${site.name} and the provided dates.`}
    />
  )
}

const getColumns = (keyedSchedules: ScheduleDictionary): GridColDef[] => (
  [
    {
      field: 'maintenancescheduleID',
      headerName: 'Sched. Description',
      flex: 1,
      renderCell({ value }) {
        return keyedSchedules[value as string].description
      }
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 0.8
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1
    },
    {
      field: 'confirmedBy',
      headerName: 'Confirmed By',
      flex: 0.7
    },
    {
      field: 'uploadedBy',
      headerName: 'Uploaded By',
      flex: 0.7
    },
    {
      field: 'reportLink',
      headerName: 'Report',
      flex: 0.5,
      renderCell({ value }) {
        return <a style={{ color: 'white' }} href={value as string}>Download</a>
      }
    },
    {
      field: 'dateCompleted',
      headerName: 'Completed',
      flex: 0.7,
      renderCell({ value }) {
        return format(new Date(value as string), 'M/d/yyyy')
      }
    }
  ]
)

export default Logs
