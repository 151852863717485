import {
  FC,
  useState,
  useMemo,
  useCallback,
  ChangeEvent,
  FocusEvent
} from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button
} from '@material-ui/core'
import get from 'lodash/get'
import { Alert } from '@material-ui/lab'
import { Auth } from '@aws-amplify/auth'
import { useHistory } from 'react-router-dom'

interface IResetPassword {
  handleUserChange: (userData: any) => void
  parentClasses: any
  userData: any
}

const ResetPassword: FC<IResetPassword> = (props) => {
  const { handleUserChange, parentClasses, userData } = props

  const username = useMemo(() => get(userData, 'username', ''), [userData])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({
    code: false,
    email: false,
    password: false,
    confirmPassword: false
  })
  const history = useHistory()

  // Set email to username, otherwise hasErrors will always be true
  const formDefaults = useMemo(() => {
    return { code: '', email: username, password: '', confirmPassword: '' }
  }, [username])

  const [formData, setFormData] = useState<{ [key: string]: string }>(
    formDefaults
  )
  const { code, password, confirmPassword, email } = formData

  const handleGoBack = useCallback(() => {
    history.push('/signIn')
  }, [history])

  const handleSendConfirmationCode = useCallback(async () => {
    setError('')
    setIsLoading(true)
    try {
      await Auth.forgotPassword(email)
      handleUserChange({ username: email })
    } catch (e: any) {
      if (e.code === 'UserNotFoundException') {
        setError('No account found matching that email address.')
      } else {
        setError(e.message)
      }
    }
    setIsLoading(false)
  }, [handleUserChange, email])

  const handleResetPassword = useCallback(async () => {
    setError('')
    setIsLoading(true)
    try {
      await Auth.forgotPasswordSubmit(userData.username, code, password)
      history.push('/signIn')
    } catch (e: any) {
      setFormData(formDefaults)
      setError(e.message || e)
      setIsLoading(false)
    }
  }, [userData, code, password, history, formDefaults])

  const validatePassword = useCallback(
    (name: string, pwd: string) => {
      const valid =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[\w\d@$!%*?&]{8,}$/.test(
          pwd
        )
      return !valid || (name === 'confirmPassword' && pwd !== password)
    },
    [password]
  )

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const {
      target: { value, name }
    } = e
    setFormErrors((errors) => ({
      ...errors,
      [name]:
        !value || (name.includes('password') && validatePassword(name, value))
    }))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name }
    } = e
    setFormData((data) => ({ ...data, [name]: value }))
  }

  const hasErrors = Object.keys(formErrors).some(
    (key: string) => formErrors[key] || !formData[key]
  )
  const submitDisabled = isLoading || (username ? hasErrors : !email)

  return (
    <Card className={parentClasses.card}>
      <CardHeader
        className={parentClasses.header}
        title="Reset Your Password"
      />
      <CardContent>
        {error && <Alert severity="error">{error}</Alert>}

        {!username && (
          <TextField
            required
            fullWidth
            size="small"
            name="email"
            value={email}
            variant="filled"
            onBlur={handleBlur}
            disabled={isLoading}
            onChange={handleChange}
            label="Enter Email Address"
            error={formErrors.email}
            classes={{ root: parentClasses.textField }}
          />
        )}

        {username && (
          <>
            <TextField
              required
              fullWidth
              size="small"
              name="code"
              value={code}
              variant="filled"
              type="number"
              onBlur={handleBlur}
              disabled={isLoading}
              onChange={handleChange}
              label="Confirmation Code"
              error={formErrors.code}
              classes={{ root: parentClasses.textField }}
            />
            <TextField
              required
              fullWidth
              size="small"
              type="password"
              name="password"
              label="Password"
              value={password}
              variant="filled"
              onBlur={handleBlur}
              disabled={isLoading}
              onChange={handleChange}
              error={formErrors.password}
              classes={{ root: parentClasses.textField }}
              helperText="8 to 12 characters, with at least one uppercase, one lowercase, one number, and one special character"
            />
            <TextField
              required
              fullWidth
              size="small"
              type="password"
              variant="filled"
              onBlur={handleBlur}
              disabled={isLoading}
              name="confirmPassword"
              label="Confirm Password"
              value={confirmPassword}
              onChange={handleChange}
              error={formErrors.confirmPassword}
              classes={{ root: parentClasses.textField }}
            />
          </>
        )}
      </CardContent>
      <CardActions classes={{ root: parentClasses.actions }}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleGoBack}
          classes={{ root: parentClasses.button }}
        >
          Go back to sign in
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={username ? handleResetPassword : handleSendConfirmationCode}
          disabled={submitDisabled}
          classes={{ root: parentClasses.button }}
        >
          {username ? 'Reset Password' : 'Send Code'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default ResetPassword
