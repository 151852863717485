import { makeGraphQlQuery } from '../../graphql'
import { useMutation, useQueryClient } from 'react-query'
import { DeleteMaintenanceEventInput, Site } from '../../../API'
import { deleteMaintenanceSchedule } from '../../../graphql/mutations'

export const useDeleteMaintenanceSchedule = (site: Site) => {
  const queryClient = useQueryClient()

  const scheduleDeletion = useMutation(({ id, _version }: DeleteMaintenanceEventInput) => {
    return makeGraphQlQuery({
      query: deleteMaintenanceSchedule,
      variables: { input: { id, _version } }
    })
  }, {
    onSuccess: () => queryClient.invalidateQueries(['maintenanceSchedules', site.id])
  })

  return {
    deleteSchedule: scheduleDeletion.mutateAsync,
    resetDeletion: scheduleDeletion.reset,
    deleteScheduleIsSuccess: scheduleDeletion.isSuccess,
    deleteScheduleIsLoading: scheduleDeletion.isLoading,
    deleteScheduleIsError: scheduleDeletion.isError
  }
}
