/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelSensorFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  omniSensorId?: ModelStringInput | null,
  sensorType?: ModelSensorTypeInput | null,
  deviceID?: ModelIDInput | null,
  alertruleID?: ModelIDInput | null,
  hidden?: ModelBooleanInput | null,
  sortOrder?: ModelIntInput | null,
  energyEquation?: ModelStringInput | null,
  fullLoadKw?: ModelFloatInput | null,
  min?: ModelFloatInput | null,
  max?: ModelFloatInput | null,
  avg?: ModelFloatInput | null,
  avgRecent?: ModelFloatInput | null,
  and?: Array< ModelSensorFilterInput | null > | null,
  or?: Array< ModelSensorFilterInput | null > | null,
  not?: ModelSensorFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSensorTypeInput = {
  eq?: SensorType | null,
  ne?: SensorType | null,
};

export enum SensorType {
  THERMOMETER = "THERMOMETER",
}


export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelSensorConnection = {
  __typename: "ModelSensorConnection",
  items:  Array<Sensor | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Sensor = {
  __typename: "Sensor",
  id: string,
  name?: string | null,
  omniSensorId?: string | null,
  sensorType?: SensorType | null,
  deviceID?: string | null,
  alertruleID?: string | null,
  hidden?: boolean | null,
  sortOrder?: number | null,
  energyEquation?: string | null,
  fullLoadKw?: number | null,
  min?: number | null,
  max?: number | null,
  avg?: number | null,
  avgRecent?: number | null,
  lastReading?: SensorReading | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  SensorIssues?: ModelSensorIssueConnection | null,
  SensorReadings?: ModelSensorReadingConnection | null,
  sensorgroups?: ModelSensorGroupSensorConnection | null,
};

export type SensorReading = {
  __typename: "SensorReading",
  id: string,
  readTime?: number | null,
  readValue?: string | null,
  sensorID?: string | null,
  pingType?: OmniDevicePingType | null,
  oldSensorID?: string | null,
  newSensorID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export enum OmniDevicePingType {
  UNKNOWN = "UNKNOWN",
  SCHEDULED = "SCHEDULED",
  AD_HOC = "AD_HOC",
  SHADOW = "SHADOW",
}


export type ModelSensorIssueConnection = {
  __typename: "ModelSensorIssueConnection",
  items:  Array<SensorIssue | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SensorIssue = {
  __typename: "SensorIssue",
  id: string,
  state?: SensorIssueStatus | null,
  startTime?: number | null,
  lastUpdateTime?: number | null,
  endTime?: number | null,
  notes?: string | null,
  sensorID?: string | null,
  userAckID?: string | null,
  ackTime?: number | null,
  ackEvent?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  SensorIssueContactAttempts?: ModelSensorIssueContactAttemptConnection | null,
};

export enum SensorIssueStatus {
  ONGOING = "ONGOING",
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
  CLOSED = "CLOSED",
  INSPEC = "INSPEC",
  CALIBRATION = "CALIBRATION",
  UNKNOWN = "UNKNOWN",
}


export type ModelSensorIssueContactAttemptConnection = {
  __typename: "ModelSensorIssueContactAttemptConnection",
  items:  Array<SensorIssueContactAttempt | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SensorIssueContactAttempt = {
  __typename: "SensorIssueContactAttempt",
  id: string,
  sensorIssueID?: string | null,
  serviceContactID?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  attemptNumber?: number | null,
  attemptTime?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type ModelSensorReadingConnection = {
  __typename: "ModelSensorReadingConnection",
  items:  Array<SensorReading | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSensorGroupSensorConnection = {
  __typename: "ModelSensorGroupSensorConnection",
  items:  Array<SensorGroupSensor | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SensorGroupSensor = {
  __typename: "SensorGroupSensor",
  id: string,
  sensorgroupID: string,
  sensorID: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  sensorgroup: SensorGroup,
  sensor: Sensor,
};

export type SensorGroup = {
  __typename: "SensorGroup",
  id: string,
  name?: string | null,
  siteID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  Sensors?: ModelSensorGroupSensorConnection | null,
};

export type MutateUserInput = {
  username: string,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  emailAlerts?: boolean | null,
  smsAlerts?: boolean | null,
  accountContactPreference?: UserAccountContactPreference | null,
  isAdmin?: boolean | null,
  clientID?: string | null,
  siteID?: Array< string | null > | null,
  password?: string | null,
  groups?: Array< string | null > | null,
  firstName?: string | null,
  lastName?: string | null,
  siteIDs?: string | null,
};

export enum UserAccountContactPreference {
  DEFAULT = "DEFAULT",
  DO_NOT_DISTURB = "DO_NOT_DISTURB",
}


export type PingSensorInput = {
  id?: string | null,
  value?: string | null,
};

export type PingSensorResult = {
  __typename: "PingSensorResult",
  statusCode?: number | null,
  sensorID?: string | null,
  value?: string | null,
  message?: string | null,
};

export type MergeSensorInput = {
  oldSensorId?: string | null,
  newSensorId?: string | null,
};

export type MergeSensorResult = {
  __typename: "MergeSensorResult",
  statusCode?: number | null,
  message?: string | null,
};

export type GenerateReportInput = {
  date?: string | null,
  reporttemplateID?: string | null,
  generateTestReport?: boolean | null,
};

export type GenerateReportOutput = {
  __typename: "GenerateReportOutput",
  statusCode?: number | null,
  message?: string | null,
};

export type CreateSensorGroupInput = {
  id?: string | null,
  name?: string | null,
  siteID?: string | null,
  _version?: number | null,
};

export type ModelSensorGroupConditionInput = {
  name?: ModelStringInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelSensorGroupConditionInput | null > | null,
  or?: Array< ModelSensorGroupConditionInput | null > | null,
  not?: ModelSensorGroupConditionInput | null,
};

export type UpdateSensorGroupInput = {
  id: string,
  name?: string | null,
  siteID?: string | null,
  _version?: number | null,
};

export type DeleteSensorGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateReportTemplateInput = {
  id?: string | null,
  sensorgroupID?: string | null,
  name?: string | null,
  times?: Array< string | null > | null,
  isActive?: boolean | null,
  siteID?: string | null,
  _version?: number | null,
};

export type ModelReportTemplateConditionInput = {
  sensorgroupID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  times?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelReportTemplateConditionInput | null > | null,
  or?: Array< ModelReportTemplateConditionInput | null > | null,
  not?: ModelReportTemplateConditionInput | null,
};

export type ReportTemplate = {
  __typename: "ReportTemplate",
  id: string,
  sensorgroupID?: string | null,
  name?: string | null,
  times?: Array< string | null > | null,
  isActive?: boolean | null,
  siteID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  reports?: ModelReportConnection | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Report = {
  __typename: "Report",
  id: string,
  reporttemplateID?: string | null,
  name?: string | null,
  date?: string | null,
  data?: string | null,
  approver?: string | null,
  approvedAt?: number | null,
  approvalComment?: string | null,
  userID?: string | null,
  siteID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  approverUser?: User | null,
};

export type User = {
  __typename: "User",
  id: string,
  Username: string,
  Name?: string | null,
  Email?: string | null,
  Phone?: string | null,
  EmailAlerts?: boolean | null,
  SmsAlerts?: boolean | null,
  IsAdmin?: boolean | null,
  ClientID?: string | null,
  SiteID?: Array< string | null > | null,
  AccountContactPreference?: UserAccountContactPreference | null,
  UserStatus?: UserStatus | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  UserContactPreferences?: ModelUserContactPreferencesConnection | null,
};

export enum UserStatus {
  UNCONFIRMED = "UNCONFIRMED",
  CONFIRMED = "CONFIRMED",
  ARCHIVED = "ARCHIVED",
  COMPROMISED = "COMPROMISED",
  UNKNOWN = "UNKNOWN",
  RESET_REQUIRED = "RESET_REQUIRED",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
}


export type ModelUserContactPreferencesConnection = {
  __typename: "ModelUserContactPreferencesConnection",
  items:  Array<UserContactPreferences | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UserContactPreferences = {
  __typename: "UserContactPreferences",
  id: string,
  userID: string,
  siteID: string,
  canSendEmail?: boolean | null,
  canSendText?: boolean | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReportTemplateInput = {
  id: string,
  sensorgroupID?: string | null,
  name?: string | null,
  times?: Array< string | null > | null,
  isActive?: boolean | null,
  siteID?: string | null,
  _version?: number | null,
};

export type DeleteReportTemplateInput = {
  id: string,
  _version?: number | null,
};

export type CreateReportInput = {
  id?: string | null,
  reporttemplateID?: string | null,
  name?: string | null,
  date?: string | null,
  data?: string | null,
  approver?: string | null,
  approvedAt?: number | null,
  approvalComment?: string | null,
  userID?: string | null,
  siteID?: string | null,
  _version?: number | null,
};

export type ModelReportConditionInput = {
  reporttemplateID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  date?: ModelStringInput | null,
  data?: ModelStringInput | null,
  approver?: ModelStringInput | null,
  approvedAt?: ModelIntInput | null,
  approvalComment?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
};

export type UpdateReportInput = {
  id: string,
  reporttemplateID?: string | null,
  name?: string | null,
  date?: string | null,
  data?: string | null,
  approver?: string | null,
  approvedAt?: number | null,
  approvalComment?: string | null,
  userID?: string | null,
  siteID?: string | null,
  _version?: number | null,
};

export type DeleteReportInput = {
  id: string,
  _version?: number | null,
};

export type CreateServiceContactInput = {
  id?: string | null,
  name?: string | null,
  serviceDescription?: string | null,
  phone?: string | null,
  website?: string | null,
  email?: string | null,
  siteID?: string | null,
  _version?: number | null,
};

export type ModelServiceContactConditionInput = {
  name?: ModelStringInput | null,
  serviceDescription?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  website?: ModelStringInput | null,
  email?: ModelStringInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelServiceContactConditionInput | null > | null,
  or?: Array< ModelServiceContactConditionInput | null > | null,
  not?: ModelServiceContactConditionInput | null,
};

export type ServiceContact = {
  __typename: "ServiceContact",
  id: string,
  name?: string | null,
  serviceDescription?: string | null,
  phone?: string | null,
  website?: string | null,
  email?: string | null,
  siteID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateServiceContactInput = {
  id: string,
  name?: string | null,
  serviceDescription?: string | null,
  phone?: string | null,
  website?: string | null,
  email?: string | null,
  siteID?: string | null,
  _version?: number | null,
};

export type DeleteServiceContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateMaintenanceScheduleInput = {
  id?: string | null,
  description?: string | null,
  frequencyInDays?: number | null,
  siteID?: string | null,
  _version?: number | null,
};

export type ModelMaintenanceScheduleConditionInput = {
  description?: ModelStringInput | null,
  frequencyInDays?: ModelIntInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelMaintenanceScheduleConditionInput | null > | null,
  or?: Array< ModelMaintenanceScheduleConditionInput | null > | null,
  not?: ModelMaintenanceScheduleConditionInput | null,
};

export type MaintenanceSchedule = {
  __typename: "MaintenanceSchedule",
  id: string,
  description?: string | null,
  frequencyInDays?: number | null,
  siteID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  MaintenanceEvents?: ModelMaintenanceEventConnection | null,
};

export type ModelMaintenanceEventConnection = {
  __typename: "ModelMaintenanceEventConnection",
  items:  Array<MaintenanceEvent | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MaintenanceEvent = {
  __typename: "MaintenanceEvent",
  id: string,
  confirmedBy?: string | null,
  reportLink?: string | null,
  notes?: string | null,
  dateCompleted?: number | null,
  maintenancescheduleID?: string | null,
  uploadedBy?: string | null,
  company?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMaintenanceScheduleInput = {
  id: string,
  description?: string | null,
  frequencyInDays?: number | null,
  siteID?: string | null,
  _version?: number | null,
};

export type DeleteMaintenanceScheduleInput = {
  id: string,
  _version?: number | null,
};

export type CreateMaintenanceEventInput = {
  id?: string | null,
  confirmedBy?: string | null,
  reportLink?: string | null,
  notes?: string | null,
  dateCompleted?: number | null,
  maintenancescheduleID?: string | null,
  uploadedBy?: string | null,
  company?: string | null,
  _version?: number | null,
};

export type ModelMaintenanceEventConditionInput = {
  confirmedBy?: ModelStringInput | null,
  reportLink?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  dateCompleted?: ModelIntInput | null,
  maintenancescheduleID?: ModelIDInput | null,
  uploadedBy?: ModelStringInput | null,
  company?: ModelStringInput | null,
  and?: Array< ModelMaintenanceEventConditionInput | null > | null,
  or?: Array< ModelMaintenanceEventConditionInput | null > | null,
  not?: ModelMaintenanceEventConditionInput | null,
};

export type UpdateMaintenanceEventInput = {
  id: string,
  confirmedBy?: string | null,
  reportLink?: string | null,
  notes?: string | null,
  dateCompleted?: number | null,
  maintenancescheduleID?: string | null,
  uploadedBy?: string | null,
  company?: string | null,
  _version?: number | null,
};

export type DeleteMaintenanceEventInput = {
  id: string,
  _version?: number | null,
};

export type CreateSensorIssueInput = {
  id?: string | null,
  state?: SensorIssueStatus | null,
  startTime?: number | null,
  lastUpdateTime?: number | null,
  endTime?: number | null,
  notes?: string | null,
  sensorID?: string | null,
  userAckID?: string | null,
  ackTime?: number | null,
  ackEvent?: string | null,
  _version?: number | null,
};

export type ModelSensorIssueConditionInput = {
  state?: ModelSensorIssueStatusInput | null,
  startTime?: ModelIntInput | null,
  lastUpdateTime?: ModelIntInput | null,
  endTime?: ModelIntInput | null,
  notes?: ModelStringInput | null,
  sensorID?: ModelIDInput | null,
  userAckID?: ModelStringInput | null,
  ackTime?: ModelIntInput | null,
  ackEvent?: ModelStringInput | null,
  and?: Array< ModelSensorIssueConditionInput | null > | null,
  or?: Array< ModelSensorIssueConditionInput | null > | null,
  not?: ModelSensorIssueConditionInput | null,
};

export type ModelSensorIssueStatusInput = {
  eq?: SensorIssueStatus | null,
  ne?: SensorIssueStatus | null,
};

export type UpdateSensorIssueInput = {
  id: string,
  state?: SensorIssueStatus | null,
  startTime?: number | null,
  lastUpdateTime?: number | null,
  endTime?: number | null,
  notes?: string | null,
  sensorID?: string | null,
  userAckID?: string | null,
  ackTime?: number | null,
  ackEvent?: string | null,
  _version?: number | null,
};

export type DeleteSensorIssueInput = {
  id: string,
  _version?: number | null,
};

export type CreateSensorIssueSummaryInput = {
  id?: string | null,
  siteID: string,
  date?: string | null,
  _version?: number | null,
};

export type ModelSensorIssueSummaryConditionInput = {
  siteID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelSensorIssueSummaryConditionInput | null > | null,
  or?: Array< ModelSensorIssueSummaryConditionInput | null > | null,
  not?: ModelSensorIssueSummaryConditionInput | null,
};

export type SensorIssueSummary = {
  __typename: "SensorIssueSummary",
  id: string,
  siteID: string,
  date?: string | null,
  issues?:  Array<SensorIssue | null > | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSensorIssueSummaryInput = {
  id: string,
  siteID?: string | null,
  date?: string | null,
  _version?: number | null,
};

export type DeleteSensorIssueSummaryInput = {
  id: string,
  _version?: number | null,
};

export type CreateSensorIssueContactAttemptInput = {
  id?: string | null,
  sensorIssueID?: string | null,
  serviceContactID?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  attemptNumber?: number | null,
  attemptTime?: number | null,
  _version?: number | null,
};

export type ModelSensorIssueContactAttemptConditionInput = {
  sensorIssueID?: ModelIDInput | null,
  serviceContactID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  attemptNumber?: ModelIntInput | null,
  attemptTime?: ModelIntInput | null,
  and?: Array< ModelSensorIssueContactAttemptConditionInput | null > | null,
  or?: Array< ModelSensorIssueContactAttemptConditionInput | null > | null,
  not?: ModelSensorIssueContactAttemptConditionInput | null,
};

export type UpdateSensorIssueContactAttemptInput = {
  id: string,
  sensorIssueID?: string | null,
  serviceContactID?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  attemptNumber?: number | null,
  attemptTime?: number | null,
  _version?: number | null,
};

export type DeleteSensorIssueContactAttemptInput = {
  id: string,
  _version?: number | null,
};

export type CreateAlertRuleInput = {
  id?: string | null,
  name?: string | null,
  minValue?: number | null,
  maxValue?: number | null,
  alertDelay?: number | null,
  alertAttempts?: number | null,
  threshold?: number | null,
  snoozeTime?: number | null,
  siteID?: string | null,
  protocol?: string | null,
  alertTextTemplate?: string | null,
  contactUsers?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelAlertRuleConditionInput = {
  name?: ModelStringInput | null,
  minValue?: ModelFloatInput | null,
  maxValue?: ModelFloatInput | null,
  alertDelay?: ModelIntInput | null,
  alertAttempts?: ModelIntInput | null,
  threshold?: ModelFloatInput | null,
  snoozeTime?: ModelIntInput | null,
  protocol?: ModelStringInput | null,
  alertTextTemplate?: ModelStringInput | null,
  contactUsers?: ModelStringInput | null,
  and?: Array< ModelAlertRuleConditionInput | null > | null,
  or?: Array< ModelAlertRuleConditionInput | null > | null,
  not?: ModelAlertRuleConditionInput | null,
};

export type AlertRule = {
  __typename: "AlertRule",
  id: string,
  name?: string | null,
  minValue?: number | null,
  maxValue?: number | null,
  alertDelay?: number | null,
  alertAttempts?: number | null,
  threshold?: number | null,
  snoozeTime?: number | null,
  siteID?: string | null,
  protocol?: string | null,
  alertTextTemplate?: string | null,
  contactUsers?: Array< string | null > | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  Sensors?: ModelSensorConnection | null,
};

export type UpdateAlertRuleInput = {
  id: string,
  name?: string | null,
  minValue?: number | null,
  maxValue?: number | null,
  alertDelay?: number | null,
  alertAttempts?: number | null,
  threshold?: number | null,
  snoozeTime?: number | null,
  siteID?: string | null,
  protocol?: string | null,
  alertTextTemplate?: string | null,
  contactUsers?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteAlertRuleInput = {
  id: string,
  _version?: number | null,
};

export type CreateDeviceInput = {
  id?: string | null,
  name?: string | null,
  omnideviceID?: string | null,
  _version?: number | null,
};

export type ModelDeviceConditionInput = {
  name?: ModelStringInput | null,
  omnideviceID?: ModelIDInput | null,
  and?: Array< ModelDeviceConditionInput | null > | null,
  or?: Array< ModelDeviceConditionInput | null > | null,
  not?: ModelDeviceConditionInput | null,
};

export type Device = {
  __typename: "Device",
  id: string,
  name?: string | null,
  omnideviceID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  Sensors?: ModelSensorConnection | null,
};

export type UpdateDeviceInput = {
  id: string,
  name?: string | null,
  omnideviceID?: string | null,
  _version?: number | null,
};

export type DeleteDeviceInput = {
  id: string,
  _version?: number | null,
};

export type CreateOmniDeviceStatusInput = {
  id?: string | null,
  omnideviceID?: string | null,
  timestamp?: string | null,
  listDevicesResult?: string | null,
  _version?: number | null,
};

export type ModelOmniDeviceStatusConditionInput = {
  omnideviceID?: ModelIDInput | null,
  timestamp?: ModelStringInput | null,
  listDevicesResult?: ModelStringInput | null,
  and?: Array< ModelOmniDeviceStatusConditionInput | null > | null,
  or?: Array< ModelOmniDeviceStatusConditionInput | null > | null,
  not?: ModelOmniDeviceStatusConditionInput | null,
};

export type OmniDeviceStatus = {
  __typename: "OmniDeviceStatus",
  id: string,
  omnideviceID?: string | null,
  timestamp?: string | null,
  listDevicesResult?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOmniDeviceStatusInput = {
  id: string,
  omnideviceID?: string | null,
  timestamp?: string | null,
  listDevicesResult?: string | null,
  _version?: number | null,
};

export type DeleteOmniDeviceStatusInput = {
  id: string,
  _version?: number | null,
};

export type CreateClientInput = {
  id?: string | null,
  name?: string | null,
  address?: string | null,
  email?: string | null,
  website?: string | null,
  phone?: string | null,
  _version?: number | null,
};

export type ModelClientConditionInput = {
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  website?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
};

export type Client = {
  __typename: "Client",
  id: string,
  name?: string | null,
  address?: string | null,
  email?: string | null,
  website?: string | null,
  phone?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  Site?: ModelSiteConnection | null,
};

export type ModelSiteConnection = {
  __typename: "ModelSiteConnection",
  items:  Array<Site | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Site = {
  __typename: "Site",
  id: string,
  name?: string | null,
  timezone?: string | null,
  sensorsUpdatedAt?: number | null,
  lastOfflineNotification?: number | null,
  clientID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  SensorGroups?: ModelSensorGroupConnection | null,
  ReportTemplates?: ModelReportTemplateConnection | null,
  ServiceContacts?: ModelServiceContactConnection | null,
  MaintenanceSchedules?: ModelMaintenanceScheduleConnection | null,
  AlertRules?: ModelAlertRuleConnection | null,
  Devices?: ModelOmniDeviceConnection | null,
  UserContactPreferences?: ModelUserContactPreferencesConnection | null,
};

export type ModelSensorGroupConnection = {
  __typename: "ModelSensorGroupConnection",
  items:  Array<SensorGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelReportTemplateConnection = {
  __typename: "ModelReportTemplateConnection",
  items:  Array<ReportTemplate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelServiceContactConnection = {
  __typename: "ModelServiceContactConnection",
  items:  Array<ServiceContact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMaintenanceScheduleConnection = {
  __typename: "ModelMaintenanceScheduleConnection",
  items:  Array<MaintenanceSchedule | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAlertRuleConnection = {
  __typename: "ModelAlertRuleConnection",
  items:  Array<AlertRule | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOmniDeviceConnection = {
  __typename: "ModelOmniDeviceConnection",
  items:  Array<OmniDevice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OmniDevice = {
  __typename: "OmniDevice",
  id: string,
  deviceName?: string | null,
  note?: string | null,
  connectionType?: ConnectionType | null,
  ipAddress?: string | null,
  hostname?: string | null,
  port?: number | null,
  username?: string | null,
  password?: string | null,
  devicegroupID?: string | null,
  siteID?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
  Devices?: ModelDeviceConnection | null,
  OmniDeviceStatuses?: ModelOmniDeviceStatusConnection | null,
};

export enum ConnectionType {
  UNKNOWN = "UNKNOWN",
  LEGACY = "LEGACY",
  VCL = "VCL",
}


export type ModelDeviceConnection = {
  __typename: "ModelDeviceConnection",
  items:  Array<Device | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOmniDeviceStatusConnection = {
  __typename: "ModelOmniDeviceStatusConnection",
  items:  Array<OmniDeviceStatus | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateClientInput = {
  id: string,
  name?: string | null,
  address?: string | null,
  email?: string | null,
  website?: string | null,
  phone?: string | null,
  _version?: number | null,
};

export type DeleteClientInput = {
  id: string,
  _version?: number | null,
};

export type CreateSensorReadingInput = {
  id?: string | null,
  readTime?: number | null,
  readValue?: string | null,
  sensorID?: string | null,
  pingType?: OmniDevicePingType | null,
  oldSensorID?: string | null,
  newSensorID?: string | null,
  _version?: number | null,
};

export type ModelSensorReadingConditionInput = {
  readTime?: ModelIntInput | null,
  readValue?: ModelStringInput | null,
  sensorID?: ModelIDInput | null,
  pingType?: ModelOmniDevicePingTypeInput | null,
  oldSensorID?: ModelStringInput | null,
  newSensorID?: ModelStringInput | null,
  and?: Array< ModelSensorReadingConditionInput | null > | null,
  or?: Array< ModelSensorReadingConditionInput | null > | null,
  not?: ModelSensorReadingConditionInput | null,
};

export type ModelOmniDevicePingTypeInput = {
  eq?: OmniDevicePingType | null,
  ne?: OmniDevicePingType | null,
};

export type UpdateSensorReadingInput = {
  id: string,
  readTime?: number | null,
  readValue?: string | null,
  sensorID?: string | null,
  pingType?: OmniDevicePingType | null,
  oldSensorID?: string | null,
  newSensorID?: string | null,
  _version?: number | null,
};

export type DeleteSensorReadingInput = {
  id: string,
  _version?: number | null,
};

export type CreateSensorInput = {
  id?: string | null,
  name?: string | null,
  omniSensorId?: string | null,
  sensorType?: SensorType | null,
  deviceID?: string | null,
  alertruleID?: string | null,
  hidden?: boolean | null,
  sortOrder?: number | null,
  energyEquation?: string | null,
  fullLoadKw?: number | null,
  min?: number | null,
  max?: number | null,
  avg?: number | null,
  avgRecent?: number | null,
  _version?: number | null,
};

export type ModelSensorConditionInput = {
  name?: ModelStringInput | null,
  omniSensorId?: ModelStringInput | null,
  sensorType?: ModelSensorTypeInput | null,
  deviceID?: ModelIDInput | null,
  alertruleID?: ModelIDInput | null,
  hidden?: ModelBooleanInput | null,
  sortOrder?: ModelIntInput | null,
  energyEquation?: ModelStringInput | null,
  fullLoadKw?: ModelFloatInput | null,
  min?: ModelFloatInput | null,
  max?: ModelFloatInput | null,
  avg?: ModelFloatInput | null,
  avgRecent?: ModelFloatInput | null,
  and?: Array< ModelSensorConditionInput | null > | null,
  or?: Array< ModelSensorConditionInput | null > | null,
  not?: ModelSensorConditionInput | null,
};

export type UpdateSensorInput = {
  id: string,
  name?: string | null,
  omniSensorId?: string | null,
  sensorType?: SensorType | null,
  deviceID?: string | null,
  alertruleID?: string | null,
  hidden?: boolean | null,
  sortOrder?: number | null,
  energyEquation?: string | null,
  fullLoadKw?: number | null,
  min?: number | null,
  max?: number | null,
  avg?: number | null,
  avgRecent?: number | null,
  _version?: number | null,
};

export type DeleteSensorInput = {
  id: string,
  _version?: number | null,
};

export type CreateSiteInput = {
  id?: string | null,
  name?: string | null,
  timezone?: string | null,
  sensorsUpdatedAt?: number | null,
  lastOfflineNotification?: number | null,
  clientID?: string | null,
  _version?: number | null,
};

export type ModelSiteConditionInput = {
  name?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  sensorsUpdatedAt?: ModelIntInput | null,
  lastOfflineNotification?: ModelIntInput | null,
  clientID?: ModelIDInput | null,
  and?: Array< ModelSiteConditionInput | null > | null,
  or?: Array< ModelSiteConditionInput | null > | null,
  not?: ModelSiteConditionInput | null,
};

export type UpdateSiteInput = {
  id: string,
  name?: string | null,
  timezone?: string | null,
  sensorsUpdatedAt?: number | null,
  lastOfflineNotification?: number | null,
  clientID?: string | null,
  _version?: number | null,
};

export type DeleteSiteInput = {
  id: string,
  _version?: number | null,
};

export type CreateOmniDeviceInput = {
  id?: string | null,
  deviceName?: string | null,
  note?: string | null,
  connectionType?: ConnectionType | null,
  ipAddress?: string | null,
  hostname?: string | null,
  port?: number | null,
  username?: string | null,
  password?: string | null,
  devicegroupID?: string | null,
  siteID?: string | null,
  _version?: number | null,
};

export type ModelOmniDeviceConditionInput = {
  deviceName?: ModelStringInput | null,
  note?: ModelStringInput | null,
  connectionType?: ModelConnectionTypeInput | null,
  ipAddress?: ModelStringInput | null,
  hostname?: ModelStringInput | null,
  port?: ModelIntInput | null,
  username?: ModelStringInput | null,
  password?: ModelStringInput | null,
  devicegroupID?: ModelIDInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelOmniDeviceConditionInput | null > | null,
  or?: Array< ModelOmniDeviceConditionInput | null > | null,
  not?: ModelOmniDeviceConditionInput | null,
};

export type ModelConnectionTypeInput = {
  eq?: ConnectionType | null,
  ne?: ConnectionType | null,
};

export type UpdateOmniDeviceInput = {
  id: string,
  deviceName?: string | null,
  note?: string | null,
  connectionType?: ConnectionType | null,
  ipAddress?: string | null,
  hostname?: string | null,
  port?: number | null,
  username?: string | null,
  password?: string | null,
  devicegroupID?: string | null,
  siteID?: string | null,
  _version?: number | null,
};

export type DeleteOmniDeviceInput = {
  id: string,
  _version?: number | null,
};

export type PingOmniDeviceInput = {
  id?: string | null,
  ipAddress?: string | null,
  port?: number | null,
  pingType?: OmniDevicePingType | null,
};

export type PingOmniDeviceResult = {
  __typename: "PingOmniDeviceResult",
  statusCode?: number | null,
  omnideviceID?: string | null,
  message?: string | null,
  listDevicesResult?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  Username: string,
  Name?: string | null,
  Email?: string | null,
  Phone?: string | null,
  EmailAlerts?: boolean | null,
  SmsAlerts?: boolean | null,
  IsAdmin?: boolean | null,
  ClientID?: string | null,
  SiteID?: Array< string | null > | null,
  AccountContactPreference?: UserAccountContactPreference | null,
  UserStatus?: UserStatus | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  Username?: ModelStringInput | null,
  Name?: ModelStringInput | null,
  Email?: ModelStringInput | null,
  Phone?: ModelStringInput | null,
  EmailAlerts?: ModelBooleanInput | null,
  SmsAlerts?: ModelBooleanInput | null,
  IsAdmin?: ModelBooleanInput | null,
  AccountContactPreference?: ModelUserAccountContactPreferenceInput | null,
  UserStatus?: ModelUserStatusInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserAccountContactPreferenceInput = {
  eq?: UserAccountContactPreference | null,
  ne?: UserAccountContactPreference | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type UpdateUserInput = {
  id: string,
  Username?: string | null,
  Name?: string | null,
  Email?: string | null,
  Phone?: string | null,
  EmailAlerts?: boolean | null,
  SmsAlerts?: boolean | null,
  IsAdmin?: boolean | null,
  ClientID?: string | null,
  SiteID?: Array< string | null > | null,
  AccountContactPreference?: UserAccountContactPreference | null,
  UserStatus?: UserStatus | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserContactPreferencesInput = {
  id?: string | null,
  userID: string,
  siteID: string,
  canSendEmail?: boolean | null,
  canSendText?: boolean | null,
  _version?: number | null,
};

export type ModelUserContactPreferencesConditionInput = {
  siteID?: ModelIDInput | null,
  canSendEmail?: ModelBooleanInput | null,
  canSendText?: ModelBooleanInput | null,
  and?: Array< ModelUserContactPreferencesConditionInput | null > | null,
  or?: Array< ModelUserContactPreferencesConditionInput | null > | null,
  not?: ModelUserContactPreferencesConditionInput | null,
};

export type UpdateUserContactPreferencesInput = {
  id: string,
  userID?: string | null,
  siteID?: string | null,
  canSendEmail?: boolean | null,
  canSendText?: boolean | null,
  _version?: number | null,
};

export type DeleteUserContactPreferencesInput = {
  id: string,
  _version?: number | null,
};

export type CreateSensorGroupSensorInput = {
  id?: string | null,
  sensorgroupID: string,
  sensorID: string,
  _version?: number | null,
};

export type ModelSensorGroupSensorConditionInput = {
  sensorgroupID?: ModelIDInput | null,
  sensorID?: ModelIDInput | null,
  and?: Array< ModelSensorGroupSensorConditionInput | null > | null,
  or?: Array< ModelSensorGroupSensorConditionInput | null > | null,
  not?: ModelSensorGroupSensorConditionInput | null,
};

export type UpdateSensorGroupSensorInput = {
  id: string,
  sensorgroupID?: string | null,
  sensorID?: string | null,
  _version?: number | null,
};

export type DeleteSensorGroupSensorInput = {
  id: string,
  _version?: number | null,
};

export type ModelSensorGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelSensorGroupFilterInput | null > | null,
  or?: Array< ModelSensorGroupFilterInput | null > | null,
  not?: ModelSensorGroupFilterInput | null,
};

export type ModelReportTemplateFilterInput = {
  id?: ModelIDInput | null,
  sensorgroupID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  times?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelReportTemplateFilterInput | null > | null,
  or?: Array< ModelReportTemplateFilterInput | null > | null,
  not?: ModelReportTemplateFilterInput | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  reporttemplateID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  date?: ModelStringInput | null,
  data?: ModelStringInput | null,
  approver?: ModelStringInput | null,
  approvedAt?: ModelIntInput | null,
  approvalComment?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelServiceContactFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  serviceDescription?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  website?: ModelStringInput | null,
  email?: ModelStringInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelServiceContactFilterInput | null > | null,
  or?: Array< ModelServiceContactFilterInput | null > | null,
  not?: ModelServiceContactFilterInput | null,
};

export type ModelMaintenanceScheduleFilterInput = {
  id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  frequencyInDays?: ModelIntInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelMaintenanceScheduleFilterInput | null > | null,
  or?: Array< ModelMaintenanceScheduleFilterInput | null > | null,
  not?: ModelMaintenanceScheduleFilterInput | null,
};

export type ModelMaintenanceEventFilterInput = {
  id?: ModelIDInput | null,
  confirmedBy?: ModelStringInput | null,
  reportLink?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  dateCompleted?: ModelIntInput | null,
  maintenancescheduleID?: ModelIDInput | null,
  uploadedBy?: ModelStringInput | null,
  company?: ModelStringInput | null,
  and?: Array< ModelMaintenanceEventFilterInput | null > | null,
  or?: Array< ModelMaintenanceEventFilterInput | null > | null,
  not?: ModelMaintenanceEventFilterInput | null,
};

export type ModelSensorIssueFilterInput = {
  id?: ModelIDInput | null,
  state?: ModelSensorIssueStatusInput | null,
  startTime?: ModelIntInput | null,
  lastUpdateTime?: ModelIntInput | null,
  endTime?: ModelIntInput | null,
  notes?: ModelStringInput | null,
  sensorID?: ModelIDInput | null,
  userAckID?: ModelStringInput | null,
  ackTime?: ModelIntInput | null,
  ackEvent?: ModelStringInput | null,
  and?: Array< ModelSensorIssueFilterInput | null > | null,
  or?: Array< ModelSensorIssueFilterInput | null > | null,
  not?: ModelSensorIssueFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelSensorIssueSummaryFilterInput = {
  id?: ModelIDInput | null,
  siteID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelSensorIssueSummaryFilterInput | null > | null,
  or?: Array< ModelSensorIssueSummaryFilterInput | null > | null,
  not?: ModelSensorIssueSummaryFilterInput | null,
};

export type ModelSensorIssueSummaryConnection = {
  __typename: "ModelSensorIssueSummaryConnection",
  items:  Array<SensorIssueSummary | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSensorIssueContactAttemptFilterInput = {
  id?: ModelIDInput | null,
  sensorIssueID?: ModelIDInput | null,
  serviceContactID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  attemptNumber?: ModelIntInput | null,
  attemptTime?: ModelIntInput | null,
  and?: Array< ModelSensorIssueContactAttemptFilterInput | null > | null,
  or?: Array< ModelSensorIssueContactAttemptFilterInput | null > | null,
  not?: ModelSensorIssueContactAttemptFilterInput | null,
};

export type ModelAlertRuleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  minValue?: ModelFloatInput | null,
  maxValue?: ModelFloatInput | null,
  alertDelay?: ModelIntInput | null,
  alertAttempts?: ModelIntInput | null,
  threshold?: ModelFloatInput | null,
  snoozeTime?: ModelIntInput | null,
  siteID?: ModelIDInput | null,
  protocol?: ModelStringInput | null,
  alertTextTemplate?: ModelStringInput | null,
  contactUsers?: ModelStringInput | null,
  and?: Array< ModelAlertRuleFilterInput | null > | null,
  or?: Array< ModelAlertRuleFilterInput | null > | null,
  not?: ModelAlertRuleFilterInput | null,
};

export type ModelDeviceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  omnideviceID?: ModelIDInput | null,
  and?: Array< ModelDeviceFilterInput | null > | null,
  or?: Array< ModelDeviceFilterInput | null > | null,
  not?: ModelDeviceFilterInput | null,
};

export type ModelOmniDeviceStatusFilterInput = {
  id?: ModelIDInput | null,
  omnideviceID?: ModelIDInput | null,
  timestamp?: ModelStringInput | null,
  listDevicesResult?: ModelStringInput | null,
  and?: Array< ModelOmniDeviceStatusFilterInput | null > | null,
  or?: Array< ModelOmniDeviceStatusFilterInput | null > | null,
  not?: ModelOmniDeviceStatusFilterInput | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  website?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSensorReadingFilterInput = {
  id?: ModelIDInput | null,
  readTime?: ModelIntInput | null,
  readValue?: ModelStringInput | null,
  sensorID?: ModelIDInput | null,
  pingType?: ModelOmniDevicePingTypeInput | null,
  oldSensorID?: ModelStringInput | null,
  newSensorID?: ModelStringInput | null,
  and?: Array< ModelSensorReadingFilterInput | null > | null,
  or?: Array< ModelSensorReadingFilterInput | null > | null,
  not?: ModelSensorReadingFilterInput | null,
};

export type ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyConditionInput = {
  eq?: ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyInput | null,
  le?: ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyInput | null,
  lt?: ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyInput | null,
  ge?: ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyInput | null,
  gt?: ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyInput | null,
  between?: Array< ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyInput | null > | null,
  beginsWith?: ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyInput | null,
};

export type ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyInput = {
  pingType?: OmniDevicePingType | null,
  readTime?: number | null,
};

export type ModelSiteFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  sensorsUpdatedAt?: ModelIntInput | null,
  lastOfflineNotification?: ModelIntInput | null,
  clientID?: ModelIDInput | null,
  and?: Array< ModelSiteFilterInput | null > | null,
  or?: Array< ModelSiteFilterInput | null > | null,
  not?: ModelSiteFilterInput | null,
};

export type ModelOmniDeviceFilterInput = {
  id?: ModelIDInput | null,
  deviceName?: ModelStringInput | null,
  note?: ModelStringInput | null,
  connectionType?: ModelConnectionTypeInput | null,
  ipAddress?: ModelStringInput | null,
  hostname?: ModelStringInput | null,
  port?: ModelIntInput | null,
  username?: ModelStringInput | null,
  password?: ModelStringInput | null,
  devicegroupID?: ModelIDInput | null,
  siteID?: ModelIDInput | null,
  and?: Array< ModelOmniDeviceFilterInput | null > | null,
  or?: Array< ModelOmniDeviceFilterInput | null > | null,
  not?: ModelOmniDeviceFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  Username?: ModelStringInput | null,
  Name?: ModelStringInput | null,
  Email?: ModelStringInput | null,
  Phone?: ModelStringInput | null,
  EmailAlerts?: ModelBooleanInput | null,
  SmsAlerts?: ModelBooleanInput | null,
  IsAdmin?: ModelBooleanInput | null,
  ClientID?: ModelStringInput | null,
  SiteID?: ModelStringInput | null,
  AccountContactPreference?: ModelUserAccountContactPreferenceInput | null,
  UserStatus?: ModelUserStatusInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserContactPreferencesFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  siteID?: ModelIDInput | null,
  canSendEmail?: ModelBooleanInput | null,
  canSendText?: ModelBooleanInput | null,
  and?: Array< ModelUserContactPreferencesFilterInput | null > | null,
  or?: Array< ModelUserContactPreferencesFilterInput | null > | null,
  not?: ModelUserContactPreferencesFilterInput | null,
};

export type ModelSensorGroupSensorFilterInput = {
  id?: ModelIDInput | null,
  sensorgroupID?: ModelIDInput | null,
  sensorID?: ModelIDInput | null,
  and?: Array< ModelSensorGroupSensorFilterInput | null > | null,
  or?: Array< ModelSensorGroupSensorFilterInput | null > | null,
  not?: ModelSensorGroupSensorFilterInput | null,
};

export type ListSensorsCustomQueryVariables = {
  deviceID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSensorsCustomQuery = {
  sensorByDeviceID?:  {
    __typename: "ModelSensorConnection",
    items:  Array< {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      sensorgroups?:  {
        __typename: "ModelSensorGroupSensorConnection",
        items:  Array< {
          __typename: "SensorGroupSensor",
          id: string,
          sensorgroupID: string,
          sensorgroup:  {
            __typename: "SensorGroup",
            name?: string | null,
            id: string,
          },
          _version: number,
          _deleted?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      lastReading?:  {
        __typename: "SensorReading",
        id: string,
        readTime?: number | null,
        readValue?: string | null,
        sensorID?: string | null,
        pingType?: OmniDevicePingType | null,
        oldSensorID?: string | null,
        newSensorID?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AdminCreateUserMutationVariables = {
  input?: MutateUserInput | null,
};

export type AdminCreateUserMutation = {
  adminCreateUser?: string | null,
};

export type AdminUpdateUserMutationVariables = {
  input?: MutateUserInput | null,
};

export type AdminUpdateUserMutation = {
  adminUpdateUser?: string | null,
};

export type AdminDeleteUserMutationVariables = {
  input?: MutateUserInput | null,
};

export type AdminDeleteUserMutation = {
  adminDeleteUser?: string | null,
};

export type ResetUserPasswordMutationVariables = {
  input?: MutateUserInput | null,
};

export type ResetUserPasswordMutation = {
  resetUserPassword?: string | null,
};

export type PingSensorMutationVariables = {
  input?: PingSensorInput | null,
};

export type PingSensorMutation = {
  pingSensor?:  {
    __typename: "PingSensorResult",
    statusCode?: number | null,
    sensorID?: string | null,
    value?: string | null,
    message?: string | null,
  } | null,
};

export type MergeSensorsMutationVariables = {
  input?: MergeSensorInput | null,
};

export type MergeSensorsMutation = {
  mergeSensors?:  {
    __typename: "MergeSensorResult",
    statusCode?: number | null,
    message?: string | null,
  } | null,
};

export type GenerateReportMutationVariables = {
  input?: GenerateReportInput | null,
};

export type GenerateReportMutation = {
  generateReport?:  {
    __typename: "GenerateReportOutput",
    statusCode?: number | null,
    message?: string | null,
  } | null,
};

export type CreateSensorGroupMutationVariables = {
  input: CreateSensorGroupInput,
  condition?: ModelSensorGroupConditionInput | null,
};

export type CreateSensorGroupMutation = {
  createSensorGroup?:  {
    __typename: "SensorGroup",
    id: string,
    name?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateSensorGroupMutationVariables = {
  input: UpdateSensorGroupInput,
  condition?: ModelSensorGroupConditionInput | null,
};

export type UpdateSensorGroupMutation = {
  updateSensorGroup?:  {
    __typename: "SensorGroup",
    id: string,
    name?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteSensorGroupMutationVariables = {
  input: DeleteSensorGroupInput,
  condition?: ModelSensorGroupConditionInput | null,
};

export type DeleteSensorGroupMutation = {
  deleteSensorGroup?:  {
    __typename: "SensorGroup",
    id: string,
    name?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateReportTemplateMutationVariables = {
  input: CreateReportTemplateInput,
  condition?: ModelReportTemplateConditionInput | null,
};

export type CreateReportTemplateMutation = {
  createReportTemplate?:  {
    __typename: "ReportTemplate",
    id: string,
    sensorgroupID?: string | null,
    name?: string | null,
    times?: Array< string | null > | null,
    isActive?: boolean | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    reports?:  {
      __typename: "ModelReportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateReportTemplateMutationVariables = {
  input: UpdateReportTemplateInput,
  condition?: ModelReportTemplateConditionInput | null,
};

export type UpdateReportTemplateMutation = {
  updateReportTemplate?:  {
    __typename: "ReportTemplate",
    id: string,
    sensorgroupID?: string | null,
    name?: string | null,
    times?: Array< string | null > | null,
    isActive?: boolean | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    reports?:  {
      __typename: "ModelReportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteReportTemplateMutationVariables = {
  input: DeleteReportTemplateInput,
  condition?: ModelReportTemplateConditionInput | null,
};

export type DeleteReportTemplateMutation = {
  deleteReportTemplate?:  {
    __typename: "ReportTemplate",
    id: string,
    sensorgroupID?: string | null,
    name?: string | null,
    times?: Array< string | null > | null,
    isActive?: boolean | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    reports?:  {
      __typename: "ModelReportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    reporttemplateID?: string | null,
    name?: string | null,
    date?: string | null,
    data?: string | null,
    approver?: string | null,
    approvedAt?: number | null,
    approvalComment?: string | null,
    userID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    approverUser?:  {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    reporttemplateID?: string | null,
    name?: string | null,
    date?: string | null,
    data?: string | null,
    approver?: string | null,
    approvedAt?: number | null,
    approvalComment?: string | null,
    userID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    approverUser?:  {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    reporttemplateID?: string | null,
    name?: string | null,
    date?: string | null,
    data?: string | null,
    approver?: string | null,
    approvedAt?: number | null,
    approvalComment?: string | null,
    userID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    approverUser?:  {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateServiceContactMutationVariables = {
  input: CreateServiceContactInput,
  condition?: ModelServiceContactConditionInput | null,
};

export type CreateServiceContactMutation = {
  createServiceContact?:  {
    __typename: "ServiceContact",
    id: string,
    name?: string | null,
    serviceDescription?: string | null,
    phone?: string | null,
    website?: string | null,
    email?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateServiceContactMutationVariables = {
  input: UpdateServiceContactInput,
  condition?: ModelServiceContactConditionInput | null,
};

export type UpdateServiceContactMutation = {
  updateServiceContact?:  {
    __typename: "ServiceContact",
    id: string,
    name?: string | null,
    serviceDescription?: string | null,
    phone?: string | null,
    website?: string | null,
    email?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteServiceContactMutationVariables = {
  input: DeleteServiceContactInput,
  condition?: ModelServiceContactConditionInput | null,
};

export type DeleteServiceContactMutation = {
  deleteServiceContact?:  {
    __typename: "ServiceContact",
    id: string,
    name?: string | null,
    serviceDescription?: string | null,
    phone?: string | null,
    website?: string | null,
    email?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMaintenanceScheduleMutationVariables = {
  input: CreateMaintenanceScheduleInput,
  condition?: ModelMaintenanceScheduleConditionInput | null,
};

export type CreateMaintenanceScheduleMutation = {
  createMaintenanceSchedule?:  {
    __typename: "MaintenanceSchedule",
    id: string,
    description?: string | null,
    frequencyInDays?: number | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    MaintenanceEvents?:  {
      __typename: "ModelMaintenanceEventConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateMaintenanceScheduleMutationVariables = {
  input: UpdateMaintenanceScheduleInput,
  condition?: ModelMaintenanceScheduleConditionInput | null,
};

export type UpdateMaintenanceScheduleMutation = {
  updateMaintenanceSchedule?:  {
    __typename: "MaintenanceSchedule",
    id: string,
    description?: string | null,
    frequencyInDays?: number | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    MaintenanceEvents?:  {
      __typename: "ModelMaintenanceEventConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteMaintenanceScheduleMutationVariables = {
  input: DeleteMaintenanceScheduleInput,
  condition?: ModelMaintenanceScheduleConditionInput | null,
};

export type DeleteMaintenanceScheduleMutation = {
  deleteMaintenanceSchedule?:  {
    __typename: "MaintenanceSchedule",
    id: string,
    description?: string | null,
    frequencyInDays?: number | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    MaintenanceEvents?:  {
      __typename: "ModelMaintenanceEventConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateMaintenanceEventMutationVariables = {
  input: CreateMaintenanceEventInput,
  condition?: ModelMaintenanceEventConditionInput | null,
};

export type CreateMaintenanceEventMutation = {
  createMaintenanceEvent?:  {
    __typename: "MaintenanceEvent",
    id: string,
    confirmedBy?: string | null,
    reportLink?: string | null,
    notes?: string | null,
    dateCompleted?: number | null,
    maintenancescheduleID?: string | null,
    uploadedBy?: string | null,
    company?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMaintenanceEventMutationVariables = {
  input: UpdateMaintenanceEventInput,
  condition?: ModelMaintenanceEventConditionInput | null,
};

export type UpdateMaintenanceEventMutation = {
  updateMaintenanceEvent?:  {
    __typename: "MaintenanceEvent",
    id: string,
    confirmedBy?: string | null,
    reportLink?: string | null,
    notes?: string | null,
    dateCompleted?: number | null,
    maintenancescheduleID?: string | null,
    uploadedBy?: string | null,
    company?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMaintenanceEventMutationVariables = {
  input: DeleteMaintenanceEventInput,
  condition?: ModelMaintenanceEventConditionInput | null,
};

export type DeleteMaintenanceEventMutation = {
  deleteMaintenanceEvent?:  {
    __typename: "MaintenanceEvent",
    id: string,
    confirmedBy?: string | null,
    reportLink?: string | null,
    notes?: string | null,
    dateCompleted?: number | null,
    maintenancescheduleID?: string | null,
    uploadedBy?: string | null,
    company?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSensorIssueMutationVariables = {
  input: CreateSensorIssueInput,
  condition?: ModelSensorIssueConditionInput | null,
};

export type CreateSensorIssueMutation = {
  createSensorIssue?:  {
    __typename: "SensorIssue",
    id: string,
    state?: SensorIssueStatus | null,
    startTime?: number | null,
    lastUpdateTime?: number | null,
    endTime?: number | null,
    notes?: string | null,
    sensorID?: string | null,
    userAckID?: string | null,
    ackTime?: number | null,
    ackEvent?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssueContactAttempts?:  {
      __typename: "ModelSensorIssueContactAttemptConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateSensorIssueMutationVariables = {
  input: UpdateSensorIssueInput,
  condition?: ModelSensorIssueConditionInput | null,
};

export type UpdateSensorIssueMutation = {
  updateSensorIssue?:  {
    __typename: "SensorIssue",
    id: string,
    state?: SensorIssueStatus | null,
    startTime?: number | null,
    lastUpdateTime?: number | null,
    endTime?: number | null,
    notes?: string | null,
    sensorID?: string | null,
    userAckID?: string | null,
    ackTime?: number | null,
    ackEvent?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssueContactAttempts?:  {
      __typename: "ModelSensorIssueContactAttemptConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteSensorIssueMutationVariables = {
  input: DeleteSensorIssueInput,
  condition?: ModelSensorIssueConditionInput | null,
};

export type DeleteSensorIssueMutation = {
  deleteSensorIssue?:  {
    __typename: "SensorIssue",
    id: string,
    state?: SensorIssueStatus | null,
    startTime?: number | null,
    lastUpdateTime?: number | null,
    endTime?: number | null,
    notes?: string | null,
    sensorID?: string | null,
    userAckID?: string | null,
    ackTime?: number | null,
    ackEvent?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssueContactAttempts?:  {
      __typename: "ModelSensorIssueContactAttemptConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateSensorIssueSummaryMutationVariables = {
  input: CreateSensorIssueSummaryInput,
  condition?: ModelSensorIssueSummaryConditionInput | null,
};

export type CreateSensorIssueSummaryMutation = {
  createSensorIssueSummary?:  {
    __typename: "SensorIssueSummary",
    id: string,
    siteID: string,
    date?: string | null,
    issues?:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSensorIssueSummaryMutationVariables = {
  input: UpdateSensorIssueSummaryInput,
  condition?: ModelSensorIssueSummaryConditionInput | null,
};

export type UpdateSensorIssueSummaryMutation = {
  updateSensorIssueSummary?:  {
    __typename: "SensorIssueSummary",
    id: string,
    siteID: string,
    date?: string | null,
    issues?:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSensorIssueSummaryMutationVariables = {
  input: DeleteSensorIssueSummaryInput,
  condition?: ModelSensorIssueSummaryConditionInput | null,
};

export type DeleteSensorIssueSummaryMutation = {
  deleteSensorIssueSummary?:  {
    __typename: "SensorIssueSummary",
    id: string,
    siteID: string,
    date?: string | null,
    issues?:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSensorIssueContactAttemptMutationVariables = {
  input: CreateSensorIssueContactAttemptInput,
  condition?: ModelSensorIssueContactAttemptConditionInput | null,
};

export type CreateSensorIssueContactAttemptMutation = {
  createSensorIssueContactAttempt?:  {
    __typename: "SensorIssueContactAttempt",
    id: string,
    sensorIssueID?: string | null,
    serviceContactID?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    attemptNumber?: number | null,
    attemptTime?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSensorIssueContactAttemptMutationVariables = {
  input: UpdateSensorIssueContactAttemptInput,
  condition?: ModelSensorIssueContactAttemptConditionInput | null,
};

export type UpdateSensorIssueContactAttemptMutation = {
  updateSensorIssueContactAttempt?:  {
    __typename: "SensorIssueContactAttempt",
    id: string,
    sensorIssueID?: string | null,
    serviceContactID?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    attemptNumber?: number | null,
    attemptTime?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSensorIssueContactAttemptMutationVariables = {
  input: DeleteSensorIssueContactAttemptInput,
  condition?: ModelSensorIssueContactAttemptConditionInput | null,
};

export type DeleteSensorIssueContactAttemptMutation = {
  deleteSensorIssueContactAttempt?:  {
    __typename: "SensorIssueContactAttempt",
    id: string,
    sensorIssueID?: string | null,
    serviceContactID?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    attemptNumber?: number | null,
    attemptTime?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAlertRuleMutationVariables = {
  input: CreateAlertRuleInput,
  condition?: ModelAlertRuleConditionInput | null,
};

export type CreateAlertRuleMutation = {
  createAlertRule?:  {
    __typename: "AlertRule",
    id: string,
    name?: string | null,
    minValue?: number | null,
    maxValue?: number | null,
    alertDelay?: number | null,
    alertAttempts?: number | null,
    threshold?: number | null,
    snoozeTime?: number | null,
    siteID?: string | null,
    protocol?: string | null,
    alertTextTemplate?: string | null,
    contactUsers?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateAlertRuleMutationVariables = {
  input: UpdateAlertRuleInput,
  condition?: ModelAlertRuleConditionInput | null,
};

export type UpdateAlertRuleMutation = {
  updateAlertRule?:  {
    __typename: "AlertRule",
    id: string,
    name?: string | null,
    minValue?: number | null,
    maxValue?: number | null,
    alertDelay?: number | null,
    alertAttempts?: number | null,
    threshold?: number | null,
    snoozeTime?: number | null,
    siteID?: string | null,
    protocol?: string | null,
    alertTextTemplate?: string | null,
    contactUsers?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteAlertRuleMutationVariables = {
  input: DeleteAlertRuleInput,
  condition?: ModelAlertRuleConditionInput | null,
};

export type DeleteAlertRuleMutation = {
  deleteAlertRule?:  {
    __typename: "AlertRule",
    id: string,
    name?: string | null,
    minValue?: number | null,
    maxValue?: number | null,
    alertDelay?: number | null,
    alertAttempts?: number | null,
    threshold?: number | null,
    snoozeTime?: number | null,
    siteID?: string | null,
    protocol?: string | null,
    alertTextTemplate?: string | null,
    contactUsers?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateDeviceMutationVariables = {
  input: CreateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type CreateDeviceMutation = {
  createDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    omnideviceID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  input: UpdateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type UpdateDeviceMutation = {
  updateDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    omnideviceID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteDeviceMutationVariables = {
  input: DeleteDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type DeleteDeviceMutation = {
  deleteDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    omnideviceID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateOmniDeviceStatusMutationVariables = {
  input: CreateOmniDeviceStatusInput,
  condition?: ModelOmniDeviceStatusConditionInput | null,
};

export type CreateOmniDeviceStatusMutation = {
  createOmniDeviceStatus?:  {
    __typename: "OmniDeviceStatus",
    id: string,
    omnideviceID?: string | null,
    timestamp?: string | null,
    listDevicesResult?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOmniDeviceStatusMutationVariables = {
  input: UpdateOmniDeviceStatusInput,
  condition?: ModelOmniDeviceStatusConditionInput | null,
};

export type UpdateOmniDeviceStatusMutation = {
  updateOmniDeviceStatus?:  {
    __typename: "OmniDeviceStatus",
    id: string,
    omnideviceID?: string | null,
    timestamp?: string | null,
    listDevicesResult?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOmniDeviceStatusMutationVariables = {
  input: DeleteOmniDeviceStatusInput,
  condition?: ModelOmniDeviceStatusConditionInput | null,
};

export type DeleteOmniDeviceStatusMutation = {
  deleteOmniDeviceStatus?:  {
    __typename: "OmniDeviceStatus",
    id: string,
    omnideviceID?: string | null,
    timestamp?: string | null,
    listDevicesResult?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    email?: string | null,
    website?: string | null,
    phone?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Site?:  {
      __typename: "ModelSiteConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    email?: string | null,
    website?: string | null,
    phone?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Site?:  {
      __typename: "ModelSiteConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    email?: string | null,
    website?: string | null,
    phone?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Site?:  {
      __typename: "ModelSiteConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateSensorReadingMutationVariables = {
  input: CreateSensorReadingInput,
  condition?: ModelSensorReadingConditionInput | null,
};

export type CreateSensorReadingMutation = {
  createSensorReading?:  {
    __typename: "SensorReading",
    id: string,
    readTime?: number | null,
    readValue?: string | null,
    sensorID?: string | null,
    pingType?: OmniDevicePingType | null,
    oldSensorID?: string | null,
    newSensorID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSensorReadingMutationVariables = {
  input: UpdateSensorReadingInput,
  condition?: ModelSensorReadingConditionInput | null,
};

export type UpdateSensorReadingMutation = {
  updateSensorReading?:  {
    __typename: "SensorReading",
    id: string,
    readTime?: number | null,
    readValue?: string | null,
    sensorID?: string | null,
    pingType?: OmniDevicePingType | null,
    oldSensorID?: string | null,
    newSensorID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSensorReadingMutationVariables = {
  input: DeleteSensorReadingInput,
  condition?: ModelSensorReadingConditionInput | null,
};

export type DeleteSensorReadingMutation = {
  deleteSensorReading?:  {
    __typename: "SensorReading",
    id: string,
    readTime?: number | null,
    readValue?: string | null,
    sensorID?: string | null,
    pingType?: OmniDevicePingType | null,
    oldSensorID?: string | null,
    newSensorID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSensorMutationVariables = {
  input: CreateSensorInput,
  condition?: ModelSensorConditionInput | null,
};

export type CreateSensorMutation = {
  createSensor?:  {
    __typename: "Sensor",
    id: string,
    name?: string | null,
    omniSensorId?: string | null,
    sensorType?: SensorType | null,
    deviceID?: string | null,
    alertruleID?: string | null,
    hidden?: boolean | null,
    sortOrder?: number | null,
    energyEquation?: string | null,
    fullLoadKw?: number | null,
    min?: number | null,
    max?: number | null,
    avg?: number | null,
    avgRecent?: number | null,
    lastReading?:  {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssues?:  {
      __typename: "ModelSensorIssueConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SensorReadings?:  {
      __typename: "ModelSensorReadingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sensorgroups?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateSensorMutationVariables = {
  input: UpdateSensorInput,
  condition?: ModelSensorConditionInput | null,
};

export type UpdateSensorMutation = {
  updateSensor?:  {
    __typename: "Sensor",
    id: string,
    name?: string | null,
    omniSensorId?: string | null,
    sensorType?: SensorType | null,
    deviceID?: string | null,
    alertruleID?: string | null,
    hidden?: boolean | null,
    sortOrder?: number | null,
    energyEquation?: string | null,
    fullLoadKw?: number | null,
    min?: number | null,
    max?: number | null,
    avg?: number | null,
    avgRecent?: number | null,
    lastReading?:  {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssues?:  {
      __typename: "ModelSensorIssueConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SensorReadings?:  {
      __typename: "ModelSensorReadingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sensorgroups?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteSensorMutationVariables = {
  input: DeleteSensorInput,
  condition?: ModelSensorConditionInput | null,
};

export type DeleteSensorMutation = {
  deleteSensor?:  {
    __typename: "Sensor",
    id: string,
    name?: string | null,
    omniSensorId?: string | null,
    sensorType?: SensorType | null,
    deviceID?: string | null,
    alertruleID?: string | null,
    hidden?: boolean | null,
    sortOrder?: number | null,
    energyEquation?: string | null,
    fullLoadKw?: number | null,
    min?: number | null,
    max?: number | null,
    avg?: number | null,
    avgRecent?: number | null,
    lastReading?:  {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssues?:  {
      __typename: "ModelSensorIssueConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SensorReadings?:  {
      __typename: "ModelSensorReadingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sensorgroups?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateSiteMutationVariables = {
  input: CreateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type CreateSiteMutation = {
  createSite?:  {
    __typename: "Site",
    id: string,
    name?: string | null,
    timezone?: string | null,
    sensorsUpdatedAt?: number | null,
    lastOfflineNotification?: number | null,
    clientID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorGroups?:  {
      __typename: "ModelSensorGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ReportTemplates?:  {
      __typename: "ModelReportTemplateConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ServiceContacts?:  {
      __typename: "ModelServiceContactConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    MaintenanceSchedules?:  {
      __typename: "ModelMaintenanceScheduleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    AlertRules?:  {
      __typename: "ModelAlertRuleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Devices?:  {
      __typename: "ModelOmniDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateSiteMutationVariables = {
  input: UpdateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type UpdateSiteMutation = {
  updateSite?:  {
    __typename: "Site",
    id: string,
    name?: string | null,
    timezone?: string | null,
    sensorsUpdatedAt?: number | null,
    lastOfflineNotification?: number | null,
    clientID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorGroups?:  {
      __typename: "ModelSensorGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ReportTemplates?:  {
      __typename: "ModelReportTemplateConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ServiceContacts?:  {
      __typename: "ModelServiceContactConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    MaintenanceSchedules?:  {
      __typename: "ModelMaintenanceScheduleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    AlertRules?:  {
      __typename: "ModelAlertRuleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Devices?:  {
      __typename: "ModelOmniDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteSiteMutationVariables = {
  input: DeleteSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type DeleteSiteMutation = {
  deleteSite?:  {
    __typename: "Site",
    id: string,
    name?: string | null,
    timezone?: string | null,
    sensorsUpdatedAt?: number | null,
    lastOfflineNotification?: number | null,
    clientID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorGroups?:  {
      __typename: "ModelSensorGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ReportTemplates?:  {
      __typename: "ModelReportTemplateConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ServiceContacts?:  {
      __typename: "ModelServiceContactConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    MaintenanceSchedules?:  {
      __typename: "ModelMaintenanceScheduleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    AlertRules?:  {
      __typename: "ModelAlertRuleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Devices?:  {
      __typename: "ModelOmniDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateOmniDeviceMutationVariables = {
  input: CreateOmniDeviceInput,
  condition?: ModelOmniDeviceConditionInput | null,
};

export type CreateOmniDeviceMutation = {
  createOmniDevice?:  {
    __typename: "OmniDevice",
    id: string,
    deviceName?: string | null,
    note?: string | null,
    connectionType?: ConnectionType | null,
    ipAddress?: string | null,
    hostname?: string | null,
    port?: number | null,
    username?: string | null,
    password?: string | null,
    devicegroupID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OmniDeviceStatuses?:  {
      __typename: "ModelOmniDeviceStatusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateOmniDeviceMutationVariables = {
  input: UpdateOmniDeviceInput,
  condition?: ModelOmniDeviceConditionInput | null,
};

export type UpdateOmniDeviceMutation = {
  updateOmniDevice?:  {
    __typename: "OmniDevice",
    id: string,
    deviceName?: string | null,
    note?: string | null,
    connectionType?: ConnectionType | null,
    ipAddress?: string | null,
    hostname?: string | null,
    port?: number | null,
    username?: string | null,
    password?: string | null,
    devicegroupID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OmniDeviceStatuses?:  {
      __typename: "ModelOmniDeviceStatusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteOmniDeviceMutationVariables = {
  input: DeleteOmniDeviceInput,
  condition?: ModelOmniDeviceConditionInput | null,
};

export type DeleteOmniDeviceMutation = {
  deleteOmniDevice?:  {
    __typename: "OmniDevice",
    id: string,
    deviceName?: string | null,
    note?: string | null,
    connectionType?: ConnectionType | null,
    ipAddress?: string | null,
    hostname?: string | null,
    port?: number | null,
    username?: string | null,
    password?: string | null,
    devicegroupID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OmniDeviceStatuses?:  {
      __typename: "ModelOmniDeviceStatusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type PingOmniDeviceMutationVariables = {
  input?: PingOmniDeviceInput | null,
};

export type PingOmniDeviceMutation = {
  pingOmniDevice?:  {
    __typename: "PingOmniDeviceResult",
    statusCode?: number | null,
    omnideviceID?: string | null,
    message?: string | null,
    listDevicesResult?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    Username: string,
    Name?: string | null,
    Email?: string | null,
    Phone?: string | null,
    EmailAlerts?: boolean | null,
    SmsAlerts?: boolean | null,
    IsAdmin?: boolean | null,
    ClientID?: string | null,
    SiteID?: Array< string | null > | null,
    AccountContactPreference?: UserAccountContactPreference | null,
    UserStatus?: UserStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    Username: string,
    Name?: string | null,
    Email?: string | null,
    Phone?: string | null,
    EmailAlerts?: boolean | null,
    SmsAlerts?: boolean | null,
    IsAdmin?: boolean | null,
    ClientID?: string | null,
    SiteID?: Array< string | null > | null,
    AccountContactPreference?: UserAccountContactPreference | null,
    UserStatus?: UserStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    Username: string,
    Name?: string | null,
    Email?: string | null,
    Phone?: string | null,
    EmailAlerts?: boolean | null,
    SmsAlerts?: boolean | null,
    IsAdmin?: boolean | null,
    ClientID?: string | null,
    SiteID?: Array< string | null > | null,
    AccountContactPreference?: UserAccountContactPreference | null,
    UserStatus?: UserStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CreateUserContactPreferencesMutationVariables = {
  input: CreateUserContactPreferencesInput,
  condition?: ModelUserContactPreferencesConditionInput | null,
};

export type CreateUserContactPreferencesMutation = {
  createUserContactPreferences?:  {
    __typename: "UserContactPreferences",
    id: string,
    userID: string,
    siteID: string,
    canSendEmail?: boolean | null,
    canSendText?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserContactPreferencesMutationVariables = {
  input: UpdateUserContactPreferencesInput,
  condition?: ModelUserContactPreferencesConditionInput | null,
};

export type UpdateUserContactPreferencesMutation = {
  updateUserContactPreferences?:  {
    __typename: "UserContactPreferences",
    id: string,
    userID: string,
    siteID: string,
    canSendEmail?: boolean | null,
    canSendText?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserContactPreferencesMutationVariables = {
  input: DeleteUserContactPreferencesInput,
  condition?: ModelUserContactPreferencesConditionInput | null,
};

export type DeleteUserContactPreferencesMutation = {
  deleteUserContactPreferences?:  {
    __typename: "UserContactPreferences",
    id: string,
    userID: string,
    siteID: string,
    canSendEmail?: boolean | null,
    canSendText?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSensorGroupSensorMutationVariables = {
  input: CreateSensorGroupSensorInput,
  condition?: ModelSensorGroupSensorConditionInput | null,
};

export type CreateSensorGroupSensorMutation = {
  createSensorGroupSensor?:  {
    __typename: "SensorGroupSensor",
    id: string,
    sensorgroupID: string,
    sensorID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    sensorgroup:  {
      __typename: "SensorGroup",
      id: string,
      name?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
    sensor:  {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type UpdateSensorGroupSensorMutationVariables = {
  input: UpdateSensorGroupSensorInput,
  condition?: ModelSensorGroupSensorConditionInput | null,
};

export type UpdateSensorGroupSensorMutation = {
  updateSensorGroupSensor?:  {
    __typename: "SensorGroupSensor",
    id: string,
    sensorgroupID: string,
    sensorID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    sensorgroup:  {
      __typename: "SensorGroup",
      id: string,
      name?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
    sensor:  {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type DeleteSensorGroupSensorMutationVariables = {
  input: DeleteSensorGroupSensorInput,
  condition?: ModelSensorGroupSensorConditionInput | null,
};

export type DeleteSensorGroupSensorMutation = {
  deleteSensorGroupSensor?:  {
    __typename: "SensorGroupSensor",
    id: string,
    sensorgroupID: string,
    sensorID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    sensorgroup:  {
      __typename: "SensorGroup",
      id: string,
      name?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
    sensor:  {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type GetSampleAlertMessageRenderQueryVariables = {
  alertRuleID?: string | null,
};

export type GetSampleAlertMessageRenderQuery = {
  getSampleAlertMessageRender?: string | null,
};

export type GetSensorGroupQueryVariables = {
  id: string,
};

export type GetSensorGroupQuery = {
  getSensorGroup?:  {
    __typename: "SensorGroup",
    id: string,
    name?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListSensorGroupsQueryVariables = {
  filter?: ModelSensorGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSensorGroupsQuery = {
  listSensorGroups?:  {
    __typename: "ModelSensorGroupConnection",
    items:  Array< {
      __typename: "SensorGroup",
      id: string,
      name?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSensorGroupsQueryVariables = {
  filter?: ModelSensorGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSensorGroupsQuery = {
  syncSensorGroups?:  {
    __typename: "ModelSensorGroupConnection",
    items:  Array< {
      __typename: "SensorGroup",
      id: string,
      name?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReportTemplateQueryVariables = {
  id: string,
};

export type GetReportTemplateQuery = {
  getReportTemplate?:  {
    __typename: "ReportTemplate",
    id: string,
    sensorgroupID?: string | null,
    name?: string | null,
    times?: Array< string | null > | null,
    isActive?: boolean | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    reports?:  {
      __typename: "ModelReportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListReportTemplatesQueryVariables = {
  filter?: ModelReportTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportTemplatesQuery = {
  listReportTemplates?:  {
    __typename: "ModelReportTemplateConnection",
    items:  Array< {
      __typename: "ReportTemplate",
      id: string,
      sensorgroupID?: string | null,
      name?: string | null,
      times?: Array< string | null > | null,
      isActive?: boolean | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReportTemplateBySensorGroupQueryVariables = {
  sensorgroupID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportTemplateBySensorGroupQuery = {
  reportTemplateBySensorGroup?:  {
    __typename: "ModelReportTemplateConnection",
    items:  Array< {
      __typename: "ReportTemplate",
      id: string,
      sensorgroupID?: string | null,
      name?: string | null,
      times?: Array< string | null > | null,
      isActive?: boolean | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReportTemplatesQueryVariables = {
  filter?: ModelReportTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReportTemplatesQuery = {
  syncReportTemplates?:  {
    __typename: "ModelReportTemplateConnection",
    items:  Array< {
      __typename: "ReportTemplate",
      id: string,
      sensorgroupID?: string | null,
      name?: string | null,
      times?: Array< string | null > | null,
      isActive?: boolean | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    reporttemplateID?: string | null,
    name?: string | null,
    date?: string | null,
    data?: string | null,
    approver?: string | null,
    approvedAt?: number | null,
    approvalComment?: string | null,
    userID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    approverUser?:  {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      reporttemplateID?: string | null,
      name?: string | null,
      date?: string | null,
      data?: string | null,
      approver?: string | null,
      approvedAt?: number | null,
      approvalComment?: string | null,
      userID?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReportByReportTemplateQueryVariables = {
  reporttemplateID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportByReportTemplateQuery = {
  reportByReportTemplate?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      reporttemplateID?: string | null,
      name?: string | null,
      date?: string | null,
      data?: string | null,
      approver?: string | null,
      approvedAt?: number | null,
      approvalComment?: string | null,
      userID?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReportByReportTemplateAndDateQueryVariables = {
  reporttemplateID?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportByReportTemplateAndDateQuery = {
  reportByReportTemplateAndDate?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      reporttemplateID?: string | null,
      name?: string | null,
      date?: string | null,
      data?: string | null,
      approver?: string | null,
      approvedAt?: number | null,
      approvalComment?: string | null,
      userID?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReportByDateQueryVariables = {
  date?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportByDateQuery = {
  reportByDate?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      reporttemplateID?: string | null,
      name?: string | null,
      date?: string | null,
      data?: string | null,
      approver?: string | null,
      approvedAt?: number | null,
      approvalComment?: string | null,
      userID?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReportsQuery = {
  syncReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      reporttemplateID?: string | null,
      name?: string | null,
      date?: string | null,
      data?: string | null,
      approver?: string | null,
      approvedAt?: number | null,
      approvalComment?: string | null,
      userID?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetServiceContactQueryVariables = {
  id: string,
};

export type GetServiceContactQuery = {
  getServiceContact?:  {
    __typename: "ServiceContact",
    id: string,
    name?: string | null,
    serviceDescription?: string | null,
    phone?: string | null,
    website?: string | null,
    email?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListServiceContactsQueryVariables = {
  filter?: ModelServiceContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServiceContactsQuery = {
  listServiceContacts?:  {
    __typename: "ModelServiceContactConnection",
    items:  Array< {
      __typename: "ServiceContact",
      id: string,
      name?: string | null,
      serviceDescription?: string | null,
      phone?: string | null,
      website?: string | null,
      email?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncServiceContactsQueryVariables = {
  filter?: ModelServiceContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncServiceContactsQuery = {
  syncServiceContacts?:  {
    __typename: "ModelServiceContactConnection",
    items:  Array< {
      __typename: "ServiceContact",
      id: string,
      name?: string | null,
      serviceDescription?: string | null,
      phone?: string | null,
      website?: string | null,
      email?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMaintenanceScheduleQueryVariables = {
  id: string,
};

export type GetMaintenanceScheduleQuery = {
  getMaintenanceSchedule?:  {
    __typename: "MaintenanceSchedule",
    id: string,
    description?: string | null,
    frequencyInDays?: number | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    MaintenanceEvents?:  {
      __typename: "ModelMaintenanceEventConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListMaintenanceSchedulesQueryVariables = {
  filter?: ModelMaintenanceScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMaintenanceSchedulesQuery = {
  listMaintenanceSchedules?:  {
    __typename: "ModelMaintenanceScheduleConnection",
    items:  Array< {
      __typename: "MaintenanceSchedule",
      id: string,
      description?: string | null,
      frequencyInDays?: number | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMaintenanceSchedulesQueryVariables = {
  filter?: ModelMaintenanceScheduleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMaintenanceSchedulesQuery = {
  syncMaintenanceSchedules?:  {
    __typename: "ModelMaintenanceScheduleConnection",
    items:  Array< {
      __typename: "MaintenanceSchedule",
      id: string,
      description?: string | null,
      frequencyInDays?: number | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMaintenanceEventQueryVariables = {
  id: string,
};

export type GetMaintenanceEventQuery = {
  getMaintenanceEvent?:  {
    __typename: "MaintenanceEvent",
    id: string,
    confirmedBy?: string | null,
    reportLink?: string | null,
    notes?: string | null,
    dateCompleted?: number | null,
    maintenancescheduleID?: string | null,
    uploadedBy?: string | null,
    company?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMaintenanceEventsQueryVariables = {
  filter?: ModelMaintenanceEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMaintenanceEventsQuery = {
  listMaintenanceEvents?:  {
    __typename: "ModelMaintenanceEventConnection",
    items:  Array< {
      __typename: "MaintenanceEvent",
      id: string,
      confirmedBy?: string | null,
      reportLink?: string | null,
      notes?: string | null,
      dateCompleted?: number | null,
      maintenancescheduleID?: string | null,
      uploadedBy?: string | null,
      company?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMaintenanceEventsQueryVariables = {
  filter?: ModelMaintenanceEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMaintenanceEventsQuery = {
  syncMaintenanceEvents?:  {
    __typename: "ModelMaintenanceEventConnection",
    items:  Array< {
      __typename: "MaintenanceEvent",
      id: string,
      confirmedBy?: string | null,
      reportLink?: string | null,
      notes?: string | null,
      dateCompleted?: number | null,
      maintenancescheduleID?: string | null,
      uploadedBy?: string | null,
      company?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSensorIssueQueryVariables = {
  id: string,
};

export type GetSensorIssueQuery = {
  getSensorIssue?:  {
    __typename: "SensorIssue",
    id: string,
    state?: SensorIssueStatus | null,
    startTime?: number | null,
    lastUpdateTime?: number | null,
    endTime?: number | null,
    notes?: string | null,
    sensorID?: string | null,
    userAckID?: string | null,
    ackTime?: number | null,
    ackEvent?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssueContactAttempts?:  {
      __typename: "ModelSensorIssueContactAttemptConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListSensorIssuesQueryVariables = {
  filter?: ModelSensorIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSensorIssuesQuery = {
  listSensorIssues?:  {
    __typename: "ModelSensorIssueConnection",
    items:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SensorIssueBySensorQueryVariables = {
  sensorID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SensorIssueBySensorQuery = {
  sensorIssueBySensor?:  {
    __typename: "ModelSensorIssueConnection",
    items:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SensorIssueByStateQueryVariables = {
  sensorID?: string | null,
  state?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SensorIssueByStateQuery = {
  sensorIssueByState?:  {
    __typename: "ModelSensorIssueConnection",
    items:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SensorIssueBySensorAndTimeQueryVariables = {
  sensorID?: string | null,
  lastUpdateTime?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SensorIssueBySensorAndTimeQuery = {
  SensorIssueBySensorAndTime?:  {
    __typename: "ModelSensorIssueConnection",
    items:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSensorIssuesQueryVariables = {
  filter?: ModelSensorIssueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSensorIssuesQuery = {
  syncSensorIssues?:  {
    __typename: "ModelSensorIssueConnection",
    items:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSensorIssueSummaryQueryVariables = {
  id: string,
};

export type GetSensorIssueSummaryQuery = {
  getSensorIssueSummary?:  {
    __typename: "SensorIssueSummary",
    id: string,
    siteID: string,
    date?: string | null,
    issues?:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSensorIssueSummariesQueryVariables = {
  filter?: ModelSensorIssueSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSensorIssueSummariesQuery = {
  listSensorIssueSummaries?:  {
    __typename: "ModelSensorIssueSummaryConnection",
    items:  Array< {
      __typename: "SensorIssueSummary",
      id: string,
      siteID: string,
      date?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSensorIssueSummariesQueryVariables = {
  filter?: ModelSensorIssueSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSensorIssueSummariesQuery = {
  syncSensorIssueSummaries?:  {
    __typename: "ModelSensorIssueSummaryConnection",
    items:  Array< {
      __typename: "SensorIssueSummary",
      id: string,
      siteID: string,
      date?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSensorIssueContactAttemptQueryVariables = {
  id: string,
};

export type GetSensorIssueContactAttemptQuery = {
  getSensorIssueContactAttempt?:  {
    __typename: "SensorIssueContactAttempt",
    id: string,
    sensorIssueID?: string | null,
    serviceContactID?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    attemptNumber?: number | null,
    attemptTime?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSensorIssueContactAttemptsQueryVariables = {
  filter?: ModelSensorIssueContactAttemptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSensorIssueContactAttemptsQuery = {
  listSensorIssueContactAttempts?:  {
    __typename: "ModelSensorIssueContactAttemptConnection",
    items:  Array< {
      __typename: "SensorIssueContactAttempt",
      id: string,
      sensorIssueID?: string | null,
      serviceContactID?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      attemptNumber?: number | null,
      attemptTime?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SensorIssueContactAttemptByPhoneQueryVariables = {
  phoneNumber?: string | null,
  attemptTime?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorIssueContactAttemptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SensorIssueContactAttemptByPhoneQuery = {
  sensorIssueContactAttemptByPhone?:  {
    __typename: "ModelSensorIssueContactAttemptConnection",
    items:  Array< {
      __typename: "SensorIssueContactAttempt",
      id: string,
      sensorIssueID?: string | null,
      serviceContactID?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      attemptNumber?: number | null,
      attemptTime?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSensorIssueContactAttemptsQueryVariables = {
  filter?: ModelSensorIssueContactAttemptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSensorIssueContactAttemptsQuery = {
  syncSensorIssueContactAttempts?:  {
    __typename: "ModelSensorIssueContactAttemptConnection",
    items:  Array< {
      __typename: "SensorIssueContactAttempt",
      id: string,
      sensorIssueID?: string | null,
      serviceContactID?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      attemptNumber?: number | null,
      attemptTime?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAlertRuleQueryVariables = {
  id: string,
};

export type GetAlertRuleQuery = {
  getAlertRule?:  {
    __typename: "AlertRule",
    id: string,
    name?: string | null,
    minValue?: number | null,
    maxValue?: number | null,
    alertDelay?: number | null,
    alertAttempts?: number | null,
    threshold?: number | null,
    snoozeTime?: number | null,
    siteID?: string | null,
    protocol?: string | null,
    alertTextTemplate?: string | null,
    contactUsers?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListAlertRulesQueryVariables = {
  filter?: ModelAlertRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAlertRulesQuery = {
  listAlertRules?:  {
    __typename: "ModelAlertRuleConnection",
    items:  Array< {
      __typename: "AlertRule",
      id: string,
      name?: string | null,
      minValue?: number | null,
      maxValue?: number | null,
      alertDelay?: number | null,
      alertAttempts?: number | null,
      threshold?: number | null,
      snoozeTime?: number | null,
      siteID?: string | null,
      protocol?: string | null,
      alertTextTemplate?: string | null,
      contactUsers?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAlertRulesQueryVariables = {
  filter?: ModelAlertRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAlertRulesQuery = {
  syncAlertRules?:  {
    __typename: "ModelAlertRuleConnection",
    items:  Array< {
      __typename: "AlertRule",
      id: string,
      name?: string | null,
      minValue?: number | null,
      maxValue?: number | null,
      alertDelay?: number | null,
      alertAttempts?: number | null,
      threshold?: number | null,
      snoozeTime?: number | null,
      siteID?: string | null,
      protocol?: string | null,
      alertTextTemplate?: string | null,
      contactUsers?: Array< string | null > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDeviceQueryVariables = {
  id: string,
};

export type GetDeviceQuery = {
  getDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    omnideviceID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListDevicesQueryVariables = {
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDevicesQuery = {
  listDevices?:  {
    __typename: "ModelDeviceConnection",
    items:  Array< {
      __typename: "Device",
      id: string,
      name?: string | null,
      omnideviceID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DeviceByOmniDeviceQueryVariables = {
  omnideviceID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeviceByOmniDeviceQuery = {
  deviceByOmniDevice?:  {
    __typename: "ModelDeviceConnection",
    items:  Array< {
      __typename: "Device",
      id: string,
      name?: string | null,
      omnideviceID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDevicesQueryVariables = {
  filter?: ModelDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDevicesQuery = {
  syncDevices?:  {
    __typename: "ModelDeviceConnection",
    items:  Array< {
      __typename: "Device",
      id: string,
      name?: string | null,
      omnideviceID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOmniDeviceStatusQueryVariables = {
  id: string,
};

export type GetOmniDeviceStatusQuery = {
  getOmniDeviceStatus?:  {
    __typename: "OmniDeviceStatus",
    id: string,
    omnideviceID?: string | null,
    timestamp?: string | null,
    listDevicesResult?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOmniDeviceStatusesQueryVariables = {
  filter?: ModelOmniDeviceStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOmniDeviceStatusesQuery = {
  listOmniDeviceStatuses?:  {
    __typename: "ModelOmniDeviceStatusConnection",
    items:  Array< {
      __typename: "OmniDeviceStatus",
      id: string,
      omnideviceID?: string | null,
      timestamp?: string | null,
      listDevicesResult?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOmniDeviceStatusesQueryVariables = {
  filter?: ModelOmniDeviceStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOmniDeviceStatusesQuery = {
  syncOmniDeviceStatuses?:  {
    __typename: "ModelOmniDeviceStatusConnection",
    items:  Array< {
      __typename: "OmniDeviceStatus",
      id: string,
      omnideviceID?: string | null,
      timestamp?: string | null,
      listDevicesResult?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    email?: string | null,
    website?: string | null,
    phone?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Site?:  {
      __typename: "ModelSiteConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      email?: string | null,
      website?: string | null,
      phone?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClientsQuery = {
  syncClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      name?: string | null,
      address?: string | null,
      email?: string | null,
      website?: string | null,
      phone?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSensorReadingQueryVariables = {
  id: string,
};

export type GetSensorReadingQuery = {
  getSensorReading?:  {
    __typename: "SensorReading",
    id: string,
    readTime?: number | null,
    readValue?: string | null,
    sensorID?: string | null,
    pingType?: OmniDevicePingType | null,
    oldSensorID?: string | null,
    newSensorID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSensorReadingsQueryVariables = {
  filter?: ModelSensorReadingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSensorReadingsQuery = {
  listSensorReadings?:  {
    __typename: "ModelSensorReadingConnection",
    items:  Array< {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BySensorAndTimeQueryVariables = {
  sensorID?: string | null,
  readTime?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorReadingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySensorAndTimeQuery = {
  bySensorAndTime?:  {
    __typename: "ModelSensorReadingConnection",
    items:  Array< {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BySensorPingTypeAndTimeQueryVariables = {
  sensorID?: string | null,
  pingTypeReadTime?: ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorReadingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BySensorPingTypeAndTimeQuery = {
  bySensorPingTypeAndTime?:  {
    __typename: "ModelSensorReadingConnection",
    items:  Array< {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSensorReadingsQueryVariables = {
  filter?: ModelSensorReadingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSensorReadingsQuery = {
  syncSensorReadings?:  {
    __typename: "ModelSensorReadingConnection",
    items:  Array< {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSensorQueryVariables = {
  id: string,
};

export type GetSensorQuery = {
  getSensor?:  {
    __typename: "Sensor",
    id: string,
    name?: string | null,
    omniSensorId?: string | null,
    sensorType?: SensorType | null,
    deviceID?: string | null,
    alertruleID?: string | null,
    hidden?: boolean | null,
    sortOrder?: number | null,
    energyEquation?: string | null,
    fullLoadKw?: number | null,
    min?: number | null,
    max?: number | null,
    avg?: number | null,
    avgRecent?: number | null,
    lastReading?:  {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssues?:  {
      __typename: "ModelSensorIssueConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SensorReadings?:  {
      __typename: "ModelSensorReadingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sensorgroups?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListSensorsQueryVariables = {
  filter?: ModelSensorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSensorsQuery = {
  listSensors?:  {
    __typename: "ModelSensorConnection",
    items:  Array< {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SensorByDeviceIDQueryVariables = {
  deviceID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SensorByDeviceIDQuery = {
  sensorByDeviceID?:  {
    __typename: "ModelSensorConnection",
    items:  Array< {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SensorByAlertRuleQueryVariables = {
  alertruleID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSensorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SensorByAlertRuleQuery = {
  sensorByAlertRule?:  {
    __typename: "ModelSensorConnection",
    items:  Array< {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSensorsQueryVariables = {
  filter?: ModelSensorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSensorsQuery = {
  syncSensors?:  {
    __typename: "ModelSensorConnection",
    items:  Array< {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSiteQueryVariables = {
  id: string,
};

export type GetSiteQuery = {
  getSite?:  {
    __typename: "Site",
    id: string,
    name?: string | null,
    timezone?: string | null,
    sensorsUpdatedAt?: number | null,
    lastOfflineNotification?: number | null,
    clientID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorGroups?:  {
      __typename: "ModelSensorGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ReportTemplates?:  {
      __typename: "ModelReportTemplateConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ServiceContacts?:  {
      __typename: "ModelServiceContactConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    MaintenanceSchedules?:  {
      __typename: "ModelMaintenanceScheduleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    AlertRules?:  {
      __typename: "ModelAlertRuleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Devices?:  {
      __typename: "ModelOmniDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListSitesQueryVariables = {
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSitesQuery = {
  listSites?:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      id: string,
      name?: string | null,
      timezone?: string | null,
      sensorsUpdatedAt?: number | null,
      lastOfflineNotification?: number | null,
      clientID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SiteByClientIDQueryVariables = {
  clientID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SiteByClientIDQuery = {
  siteByClientID?:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      id: string,
      name?: string | null,
      timezone?: string | null,
      sensorsUpdatedAt?: number | null,
      lastOfflineNotification?: number | null,
      clientID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSitesQueryVariables = {
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSitesQuery = {
  syncSites?:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      id: string,
      name?: string | null,
      timezone?: string | null,
      sensorsUpdatedAt?: number | null,
      lastOfflineNotification?: number | null,
      clientID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOmniDeviceQueryVariables = {
  id: string,
};

export type GetOmniDeviceQuery = {
  getOmniDevice?:  {
    __typename: "OmniDevice",
    id: string,
    deviceName?: string | null,
    note?: string | null,
    connectionType?: ConnectionType | null,
    ipAddress?: string | null,
    hostname?: string | null,
    port?: number | null,
    username?: string | null,
    password?: string | null,
    devicegroupID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OmniDeviceStatuses?:  {
      __typename: "ModelOmniDeviceStatusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListOmniDevicesQueryVariables = {
  filter?: ModelOmniDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOmniDevicesQuery = {
  listOmniDevices?:  {
    __typename: "ModelOmniDeviceConnection",
    items:  Array< {
      __typename: "OmniDevice",
      id: string,
      deviceName?: string | null,
      note?: string | null,
      connectionType?: ConnectionType | null,
      ipAddress?: string | null,
      hostname?: string | null,
      port?: number | null,
      username?: string | null,
      password?: string | null,
      devicegroupID?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OmniDeviceBySiteIDQueryVariables = {
  siteID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOmniDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OmniDeviceBySiteIDQuery = {
  omniDeviceBySiteID?:  {
    __typename: "ModelOmniDeviceConnection",
    items:  Array< {
      __typename: "OmniDevice",
      id: string,
      deviceName?: string | null,
      note?: string | null,
      connectionType?: ConnectionType | null,
      ipAddress?: string | null,
      hostname?: string | null,
      port?: number | null,
      username?: string | null,
      password?: string | null,
      devicegroupID?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOmniDevicesQueryVariables = {
  filter?: ModelOmniDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOmniDevicesQuery = {
  syncOmniDevices?:  {
    __typename: "ModelOmniDeviceConnection",
    items:  Array< {
      __typename: "OmniDevice",
      id: string,
      deviceName?: string | null,
      note?: string | null,
      connectionType?: ConnectionType | null,
      ipAddress?: string | null,
      hostname?: string | null,
      port?: number | null,
      username?: string | null,
      password?: string | null,
      devicegroupID?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    Username: string,
    Name?: string | null,
    Email?: string | null,
    Phone?: string | null,
    EmailAlerts?: boolean | null,
    SmsAlerts?: boolean | null,
    IsAdmin?: boolean | null,
    ClientID?: string | null,
    SiteID?: Array< string | null > | null,
    AccountContactPreference?: UserAccountContactPreference | null,
    UserStatus?: UserStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserContactPreferencesQueryVariables = {
  id: string,
};

export type GetUserContactPreferencesQuery = {
  getUserContactPreferences?:  {
    __typename: "UserContactPreferences",
    id: string,
    userID: string,
    siteID: string,
    canSendEmail?: boolean | null,
    canSendText?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserContactPreferencesQueryVariables = {
  filter?: ModelUserContactPreferencesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserContactPreferencesQuery = {
  listUserContactPreferences?:  {
    __typename: "ModelUserContactPreferencesConnection",
    items:  Array< {
      __typename: "UserContactPreferences",
      id: string,
      userID: string,
      siteID: string,
      canSendEmail?: boolean | null,
      canSendText?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserContactPreferencesQueryVariables = {
  filter?: ModelUserContactPreferencesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserContactPreferencesQuery = {
  syncUserContactPreferences?:  {
    __typename: "ModelUserContactPreferencesConnection",
    items:  Array< {
      __typename: "UserContactPreferences",
      id: string,
      userID: string,
      siteID: string,
      canSendEmail?: boolean | null,
      canSendText?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSensorGroupSensorsQueryVariables = {
  filter?: ModelSensorGroupSensorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSensorGroupSensorsQuery = {
  syncSensorGroupSensors?:  {
    __typename: "ModelSensorGroupSensorConnection",
    items:  Array< {
      __typename: "SensorGroupSensor",
      id: string,
      sensorgroupID: string,
      sensorID: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateSensorGroupSubscriptionVariables = {
};

export type OnCreateSensorGroupSubscription = {
  onCreateSensorGroup?:  {
    __typename: "SensorGroup",
    id: string,
    name?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateSensorGroupSubscriptionVariables = {
};

export type OnUpdateSensorGroupSubscription = {
  onUpdateSensorGroup?:  {
    __typename: "SensorGroup",
    id: string,
    name?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteSensorGroupSubscriptionVariables = {
};

export type OnDeleteSensorGroupSubscription = {
  onDeleteSensorGroup?:  {
    __typename: "SensorGroup",
    id: string,
    name?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateReportTemplateSubscriptionVariables = {
};

export type OnCreateReportTemplateSubscription = {
  onCreateReportTemplate?:  {
    __typename: "ReportTemplate",
    id: string,
    sensorgroupID?: string | null,
    name?: string | null,
    times?: Array< string | null > | null,
    isActive?: boolean | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    reports?:  {
      __typename: "ModelReportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateReportTemplateSubscriptionVariables = {
};

export type OnUpdateReportTemplateSubscription = {
  onUpdateReportTemplate?:  {
    __typename: "ReportTemplate",
    id: string,
    sensorgroupID?: string | null,
    name?: string | null,
    times?: Array< string | null > | null,
    isActive?: boolean | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    reports?:  {
      __typename: "ModelReportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteReportTemplateSubscriptionVariables = {
};

export type OnDeleteReportTemplateSubscription = {
  onDeleteReportTemplate?:  {
    __typename: "ReportTemplate",
    id: string,
    sensorgroupID?: string | null,
    name?: string | null,
    times?: Array< string | null > | null,
    isActive?: boolean | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    reports?:  {
      __typename: "ModelReportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateReportSubscriptionVariables = {
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    id: string,
    reporttemplateID?: string | null,
    name?: string | null,
    date?: string | null,
    data?: string | null,
    approver?: string | null,
    approvedAt?: number | null,
    approvalComment?: string | null,
    userID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    approverUser?:  {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateReportSubscriptionVariables = {
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    id: string,
    reporttemplateID?: string | null,
    name?: string | null,
    date?: string | null,
    data?: string | null,
    approver?: string | null,
    approvedAt?: number | null,
    approvalComment?: string | null,
    userID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    approverUser?:  {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteReportSubscriptionVariables = {
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    id: string,
    reporttemplateID?: string | null,
    name?: string | null,
    date?: string | null,
    data?: string | null,
    approver?: string | null,
    approvedAt?: number | null,
    approvalComment?: string | null,
    userID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    approverUser?:  {
      __typename: "User",
      id: string,
      Username: string,
      Name?: string | null,
      Email?: string | null,
      Phone?: string | null,
      EmailAlerts?: boolean | null,
      SmsAlerts?: boolean | null,
      IsAdmin?: boolean | null,
      ClientID?: string | null,
      SiteID?: Array< string | null > | null,
      AccountContactPreference?: UserAccountContactPreference | null,
      UserStatus?: UserStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateServiceContactSubscriptionVariables = {
};

export type OnCreateServiceContactSubscription = {
  onCreateServiceContact?:  {
    __typename: "ServiceContact",
    id: string,
    name?: string | null,
    serviceDescription?: string | null,
    phone?: string | null,
    website?: string | null,
    email?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateServiceContactSubscriptionVariables = {
};

export type OnUpdateServiceContactSubscription = {
  onUpdateServiceContact?:  {
    __typename: "ServiceContact",
    id: string,
    name?: string | null,
    serviceDescription?: string | null,
    phone?: string | null,
    website?: string | null,
    email?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteServiceContactSubscriptionVariables = {
};

export type OnDeleteServiceContactSubscription = {
  onDeleteServiceContact?:  {
    __typename: "ServiceContact",
    id: string,
    name?: string | null,
    serviceDescription?: string | null,
    phone?: string | null,
    website?: string | null,
    email?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMaintenanceScheduleSubscriptionVariables = {
};

export type OnCreateMaintenanceScheduleSubscription = {
  onCreateMaintenanceSchedule?:  {
    __typename: "MaintenanceSchedule",
    id: string,
    description?: string | null,
    frequencyInDays?: number | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    MaintenanceEvents?:  {
      __typename: "ModelMaintenanceEventConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateMaintenanceScheduleSubscriptionVariables = {
};

export type OnUpdateMaintenanceScheduleSubscription = {
  onUpdateMaintenanceSchedule?:  {
    __typename: "MaintenanceSchedule",
    id: string,
    description?: string | null,
    frequencyInDays?: number | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    MaintenanceEvents?:  {
      __typename: "ModelMaintenanceEventConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteMaintenanceScheduleSubscriptionVariables = {
};

export type OnDeleteMaintenanceScheduleSubscription = {
  onDeleteMaintenanceSchedule?:  {
    __typename: "MaintenanceSchedule",
    id: string,
    description?: string | null,
    frequencyInDays?: number | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    MaintenanceEvents?:  {
      __typename: "ModelMaintenanceEventConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateMaintenanceEventSubscriptionVariables = {
};

export type OnCreateMaintenanceEventSubscription = {
  onCreateMaintenanceEvent?:  {
    __typename: "MaintenanceEvent",
    id: string,
    confirmedBy?: string | null,
    reportLink?: string | null,
    notes?: string | null,
    dateCompleted?: number | null,
    maintenancescheduleID?: string | null,
    uploadedBy?: string | null,
    company?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMaintenanceEventSubscriptionVariables = {
};

export type OnUpdateMaintenanceEventSubscription = {
  onUpdateMaintenanceEvent?:  {
    __typename: "MaintenanceEvent",
    id: string,
    confirmedBy?: string | null,
    reportLink?: string | null,
    notes?: string | null,
    dateCompleted?: number | null,
    maintenancescheduleID?: string | null,
    uploadedBy?: string | null,
    company?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMaintenanceEventSubscriptionVariables = {
};

export type OnDeleteMaintenanceEventSubscription = {
  onDeleteMaintenanceEvent?:  {
    __typename: "MaintenanceEvent",
    id: string,
    confirmedBy?: string | null,
    reportLink?: string | null,
    notes?: string | null,
    dateCompleted?: number | null,
    maintenancescheduleID?: string | null,
    uploadedBy?: string | null,
    company?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSensorIssueSubscriptionVariables = {
};

export type OnCreateSensorIssueSubscription = {
  onCreateSensorIssue?:  {
    __typename: "SensorIssue",
    id: string,
    state?: SensorIssueStatus | null,
    startTime?: number | null,
    lastUpdateTime?: number | null,
    endTime?: number | null,
    notes?: string | null,
    sensorID?: string | null,
    userAckID?: string | null,
    ackTime?: number | null,
    ackEvent?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssueContactAttempts?:  {
      __typename: "ModelSensorIssueContactAttemptConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateSensorIssueSubscriptionVariables = {
};

export type OnUpdateSensorIssueSubscription = {
  onUpdateSensorIssue?:  {
    __typename: "SensorIssue",
    id: string,
    state?: SensorIssueStatus | null,
    startTime?: number | null,
    lastUpdateTime?: number | null,
    endTime?: number | null,
    notes?: string | null,
    sensorID?: string | null,
    userAckID?: string | null,
    ackTime?: number | null,
    ackEvent?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssueContactAttempts?:  {
      __typename: "ModelSensorIssueContactAttemptConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteSensorIssueSubscriptionVariables = {
};

export type OnDeleteSensorIssueSubscription = {
  onDeleteSensorIssue?:  {
    __typename: "SensorIssue",
    id: string,
    state?: SensorIssueStatus | null,
    startTime?: number | null,
    lastUpdateTime?: number | null,
    endTime?: number | null,
    notes?: string | null,
    sensorID?: string | null,
    userAckID?: string | null,
    ackTime?: number | null,
    ackEvent?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssueContactAttempts?:  {
      __typename: "ModelSensorIssueContactAttemptConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateSensorIssueSummarySubscriptionVariables = {
};

export type OnCreateSensorIssueSummarySubscription = {
  onCreateSensorIssueSummary?:  {
    __typename: "SensorIssueSummary",
    id: string,
    siteID: string,
    date?: string | null,
    issues?:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSensorIssueSummarySubscriptionVariables = {
};

export type OnUpdateSensorIssueSummarySubscription = {
  onUpdateSensorIssueSummary?:  {
    __typename: "SensorIssueSummary",
    id: string,
    siteID: string,
    date?: string | null,
    issues?:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSensorIssueSummarySubscriptionVariables = {
};

export type OnDeleteSensorIssueSummarySubscription = {
  onDeleteSensorIssueSummary?:  {
    __typename: "SensorIssueSummary",
    id: string,
    siteID: string,
    date?: string | null,
    issues?:  Array< {
      __typename: "SensorIssue",
      id: string,
      state?: SensorIssueStatus | null,
      startTime?: number | null,
      lastUpdateTime?: number | null,
      endTime?: number | null,
      notes?: string | null,
      sensorID?: string | null,
      userAckID?: string | null,
      ackTime?: number | null,
      ackEvent?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSensorIssueContactAttemptSubscriptionVariables = {
};

export type OnCreateSensorIssueContactAttemptSubscription = {
  onCreateSensorIssueContactAttempt?:  {
    __typename: "SensorIssueContactAttempt",
    id: string,
    sensorIssueID?: string | null,
    serviceContactID?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    attemptNumber?: number | null,
    attemptTime?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSensorIssueContactAttemptSubscriptionVariables = {
};

export type OnUpdateSensorIssueContactAttemptSubscription = {
  onUpdateSensorIssueContactAttempt?:  {
    __typename: "SensorIssueContactAttempt",
    id: string,
    sensorIssueID?: string | null,
    serviceContactID?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    attemptNumber?: number | null,
    attemptTime?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSensorIssueContactAttemptSubscriptionVariables = {
};

export type OnDeleteSensorIssueContactAttemptSubscription = {
  onDeleteSensorIssueContactAttempt?:  {
    __typename: "SensorIssueContactAttempt",
    id: string,
    sensorIssueID?: string | null,
    serviceContactID?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    attemptNumber?: number | null,
    attemptTime?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAlertRuleSubscriptionVariables = {
};

export type OnCreateAlertRuleSubscription = {
  onCreateAlertRule?:  {
    __typename: "AlertRule",
    id: string,
    name?: string | null,
    minValue?: number | null,
    maxValue?: number | null,
    alertDelay?: number | null,
    alertAttempts?: number | null,
    threshold?: number | null,
    snoozeTime?: number | null,
    siteID?: string | null,
    protocol?: string | null,
    alertTextTemplate?: string | null,
    contactUsers?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateAlertRuleSubscriptionVariables = {
};

export type OnUpdateAlertRuleSubscription = {
  onUpdateAlertRule?:  {
    __typename: "AlertRule",
    id: string,
    name?: string | null,
    minValue?: number | null,
    maxValue?: number | null,
    alertDelay?: number | null,
    alertAttempts?: number | null,
    threshold?: number | null,
    snoozeTime?: number | null,
    siteID?: string | null,
    protocol?: string | null,
    alertTextTemplate?: string | null,
    contactUsers?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteAlertRuleSubscriptionVariables = {
};

export type OnDeleteAlertRuleSubscription = {
  onDeleteAlertRule?:  {
    __typename: "AlertRule",
    id: string,
    name?: string | null,
    minValue?: number | null,
    maxValue?: number | null,
    alertDelay?: number | null,
    alertAttempts?: number | null,
    threshold?: number | null,
    snoozeTime?: number | null,
    siteID?: string | null,
    protocol?: string | null,
    alertTextTemplate?: string | null,
    contactUsers?: Array< string | null > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateDeviceSubscriptionVariables = {
};

export type OnCreateDeviceSubscription = {
  onCreateDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    omnideviceID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateDeviceSubscriptionVariables = {
};

export type OnUpdateDeviceSubscription = {
  onUpdateDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    omnideviceID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteDeviceSubscriptionVariables = {
};

export type OnDeleteDeviceSubscription = {
  onDeleteDevice?:  {
    __typename: "Device",
    id: string,
    name?: string | null,
    omnideviceID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Sensors?:  {
      __typename: "ModelSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateOmniDeviceStatusSubscriptionVariables = {
};

export type OnCreateOmniDeviceStatusSubscription = {
  onCreateOmniDeviceStatus?:  {
    __typename: "OmniDeviceStatus",
    id: string,
    omnideviceID?: string | null,
    timestamp?: string | null,
    listDevicesResult?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOmniDeviceStatusSubscriptionVariables = {
};

export type OnUpdateOmniDeviceStatusSubscription = {
  onUpdateOmniDeviceStatus?:  {
    __typename: "OmniDeviceStatus",
    id: string,
    omnideviceID?: string | null,
    timestamp?: string | null,
    listDevicesResult?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOmniDeviceStatusSubscriptionVariables = {
};

export type OnDeleteOmniDeviceStatusSubscription = {
  onDeleteOmniDeviceStatus?:  {
    __typename: "OmniDeviceStatus",
    id: string,
    omnideviceID?: string | null,
    timestamp?: string | null,
    listDevicesResult?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClientSubscriptionVariables = {
};

export type OnCreateClientSubscription = {
  onCreateClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    email?: string | null,
    website?: string | null,
    phone?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Site?:  {
      __typename: "ModelSiteConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateClientSubscriptionVariables = {
};

export type OnUpdateClientSubscription = {
  onUpdateClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    email?: string | null,
    website?: string | null,
    phone?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Site?:  {
      __typename: "ModelSiteConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteClientSubscriptionVariables = {
};

export type OnDeleteClientSubscription = {
  onDeleteClient?:  {
    __typename: "Client",
    id: string,
    name?: string | null,
    address?: string | null,
    email?: string | null,
    website?: string | null,
    phone?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Site?:  {
      __typename: "ModelSiteConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateSensorReadingSubscriptionVariables = {
};

export type OnCreateSensorReadingSubscription = {
  onCreateSensorReading?:  {
    __typename: "SensorReading",
    id: string,
    readTime?: number | null,
    readValue?: string | null,
    sensorID?: string | null,
    pingType?: OmniDevicePingType | null,
    oldSensorID?: string | null,
    newSensorID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSensorReadingSubscriptionVariables = {
};

export type OnUpdateSensorReadingSubscription = {
  onUpdateSensorReading?:  {
    __typename: "SensorReading",
    id: string,
    readTime?: number | null,
    readValue?: string | null,
    sensorID?: string | null,
    pingType?: OmniDevicePingType | null,
    oldSensorID?: string | null,
    newSensorID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSensorReadingSubscriptionVariables = {
};

export type OnDeleteSensorReadingSubscription = {
  onDeleteSensorReading?:  {
    __typename: "SensorReading",
    id: string,
    readTime?: number | null,
    readValue?: string | null,
    sensorID?: string | null,
    pingType?: OmniDevicePingType | null,
    oldSensorID?: string | null,
    newSensorID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSensorSubscriptionVariables = {
};

export type OnCreateSensorSubscription = {
  onCreateSensor?:  {
    __typename: "Sensor",
    id: string,
    name?: string | null,
    omniSensorId?: string | null,
    sensorType?: SensorType | null,
    deviceID?: string | null,
    alertruleID?: string | null,
    hidden?: boolean | null,
    sortOrder?: number | null,
    energyEquation?: string | null,
    fullLoadKw?: number | null,
    min?: number | null,
    max?: number | null,
    avg?: number | null,
    avgRecent?: number | null,
    lastReading?:  {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssues?:  {
      __typename: "ModelSensorIssueConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SensorReadings?:  {
      __typename: "ModelSensorReadingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sensorgroups?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateSensorSubscriptionVariables = {
};

export type OnUpdateSensorSubscription = {
  onUpdateSensor?:  {
    __typename: "Sensor",
    id: string,
    name?: string | null,
    omniSensorId?: string | null,
    sensorType?: SensorType | null,
    deviceID?: string | null,
    alertruleID?: string | null,
    hidden?: boolean | null,
    sortOrder?: number | null,
    energyEquation?: string | null,
    fullLoadKw?: number | null,
    min?: number | null,
    max?: number | null,
    avg?: number | null,
    avgRecent?: number | null,
    lastReading?:  {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssues?:  {
      __typename: "ModelSensorIssueConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SensorReadings?:  {
      __typename: "ModelSensorReadingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sensorgroups?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteSensorSubscriptionVariables = {
};

export type OnDeleteSensorSubscription = {
  onDeleteSensor?:  {
    __typename: "Sensor",
    id: string,
    name?: string | null,
    omniSensorId?: string | null,
    sensorType?: SensorType | null,
    deviceID?: string | null,
    alertruleID?: string | null,
    hidden?: boolean | null,
    sortOrder?: number | null,
    energyEquation?: string | null,
    fullLoadKw?: number | null,
    min?: number | null,
    max?: number | null,
    avg?: number | null,
    avgRecent?: number | null,
    lastReading?:  {
      __typename: "SensorReading",
      id: string,
      readTime?: number | null,
      readValue?: string | null,
      sensorID?: string | null,
      pingType?: OmniDevicePingType | null,
      oldSensorID?: string | null,
      newSensorID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorIssues?:  {
      __typename: "ModelSensorIssueConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SensorReadings?:  {
      __typename: "ModelSensorReadingConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sensorgroups?:  {
      __typename: "ModelSensorGroupSensorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateSiteSubscriptionVariables = {
};

export type OnCreateSiteSubscription = {
  onCreateSite?:  {
    __typename: "Site",
    id: string,
    name?: string | null,
    timezone?: string | null,
    sensorsUpdatedAt?: number | null,
    lastOfflineNotification?: number | null,
    clientID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorGroups?:  {
      __typename: "ModelSensorGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ReportTemplates?:  {
      __typename: "ModelReportTemplateConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ServiceContacts?:  {
      __typename: "ModelServiceContactConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    MaintenanceSchedules?:  {
      __typename: "ModelMaintenanceScheduleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    AlertRules?:  {
      __typename: "ModelAlertRuleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Devices?:  {
      __typename: "ModelOmniDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateSiteSubscriptionVariables = {
};

export type OnUpdateSiteSubscription = {
  onUpdateSite?:  {
    __typename: "Site",
    id: string,
    name?: string | null,
    timezone?: string | null,
    sensorsUpdatedAt?: number | null,
    lastOfflineNotification?: number | null,
    clientID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorGroups?:  {
      __typename: "ModelSensorGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ReportTemplates?:  {
      __typename: "ModelReportTemplateConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ServiceContacts?:  {
      __typename: "ModelServiceContactConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    MaintenanceSchedules?:  {
      __typename: "ModelMaintenanceScheduleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    AlertRules?:  {
      __typename: "ModelAlertRuleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Devices?:  {
      __typename: "ModelOmniDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteSiteSubscriptionVariables = {
};

export type OnDeleteSiteSubscription = {
  onDeleteSite?:  {
    __typename: "Site",
    id: string,
    name?: string | null,
    timezone?: string | null,
    sensorsUpdatedAt?: number | null,
    lastOfflineNotification?: number | null,
    clientID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    SensorGroups?:  {
      __typename: "ModelSensorGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ReportTemplates?:  {
      __typename: "ModelReportTemplateConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ServiceContacts?:  {
      __typename: "ModelServiceContactConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    MaintenanceSchedules?:  {
      __typename: "ModelMaintenanceScheduleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    AlertRules?:  {
      __typename: "ModelAlertRuleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Devices?:  {
      __typename: "ModelOmniDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateOmniDeviceSubscriptionVariables = {
};

export type OnCreateOmniDeviceSubscription = {
  onCreateOmniDevice?:  {
    __typename: "OmniDevice",
    id: string,
    deviceName?: string | null,
    note?: string | null,
    connectionType?: ConnectionType | null,
    ipAddress?: string | null,
    hostname?: string | null,
    port?: number | null,
    username?: string | null,
    password?: string | null,
    devicegroupID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OmniDeviceStatuses?:  {
      __typename: "ModelOmniDeviceStatusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateOmniDeviceSubscriptionVariables = {
};

export type OnUpdateOmniDeviceSubscription = {
  onUpdateOmniDevice?:  {
    __typename: "OmniDevice",
    id: string,
    deviceName?: string | null,
    note?: string | null,
    connectionType?: ConnectionType | null,
    ipAddress?: string | null,
    hostname?: string | null,
    port?: number | null,
    username?: string | null,
    password?: string | null,
    devicegroupID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OmniDeviceStatuses?:  {
      __typename: "ModelOmniDeviceStatusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteOmniDeviceSubscriptionVariables = {
};

export type OnDeleteOmniDeviceSubscription = {
  onDeleteOmniDevice?:  {
    __typename: "OmniDevice",
    id: string,
    deviceName?: string | null,
    note?: string | null,
    connectionType?: ConnectionType | null,
    ipAddress?: string | null,
    hostname?: string | null,
    port?: number | null,
    username?: string | null,
    password?: string | null,
    devicegroupID?: string | null,
    siteID?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    Devices?:  {
      __typename: "ModelDeviceConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OmniDeviceStatuses?:  {
      __typename: "ModelOmniDeviceStatusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    Username: string,
    Name?: string | null,
    Email?: string | null,
    Phone?: string | null,
    EmailAlerts?: boolean | null,
    SmsAlerts?: boolean | null,
    IsAdmin?: boolean | null,
    ClientID?: string | null,
    SiteID?: Array< string | null > | null,
    AccountContactPreference?: UserAccountContactPreference | null,
    UserStatus?: UserStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    Username: string,
    Name?: string | null,
    Email?: string | null,
    Phone?: string | null,
    EmailAlerts?: boolean | null,
    SmsAlerts?: boolean | null,
    IsAdmin?: boolean | null,
    ClientID?: string | null,
    SiteID?: Array< string | null > | null,
    AccountContactPreference?: UserAccountContactPreference | null,
    UserStatus?: UserStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    Username: string,
    Name?: string | null,
    Email?: string | null,
    Phone?: string | null,
    EmailAlerts?: boolean | null,
    SmsAlerts?: boolean | null,
    IsAdmin?: boolean | null,
    ClientID?: string | null,
    SiteID?: Array< string | null > | null,
    AccountContactPreference?: UserAccountContactPreference | null,
    UserStatus?: UserStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    UserContactPreferences?:  {
      __typename: "ModelUserContactPreferencesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type OnCreateUserContactPreferencesSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateUserContactPreferencesSubscription = {
  onCreateUserContactPreferences?:  {
    __typename: "UserContactPreferences",
    id: string,
    userID: string,
    siteID: string,
    canSendEmail?: boolean | null,
    canSendText?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserContactPreferencesSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateUserContactPreferencesSubscription = {
  onUpdateUserContactPreferences?:  {
    __typename: "UserContactPreferences",
    id: string,
    userID: string,
    siteID: string,
    canSendEmail?: boolean | null,
    canSendText?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserContactPreferencesSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteUserContactPreferencesSubscription = {
  onDeleteUserContactPreferences?:  {
    __typename: "UserContactPreferences",
    id: string,
    userID: string,
    siteID: string,
    canSendEmail?: boolean | null,
    canSendText?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSensorGroupSensorSubscriptionVariables = {
};

export type OnCreateSensorGroupSensorSubscription = {
  onCreateSensorGroupSensor?:  {
    __typename: "SensorGroupSensor",
    id: string,
    sensorgroupID: string,
    sensorID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    sensorgroup:  {
      __typename: "SensorGroup",
      id: string,
      name?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
    sensor:  {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnUpdateSensorGroupSensorSubscriptionVariables = {
};

export type OnUpdateSensorGroupSensorSubscription = {
  onUpdateSensorGroupSensor?:  {
    __typename: "SensorGroupSensor",
    id: string,
    sensorgroupID: string,
    sensorID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    sensorgroup:  {
      __typename: "SensorGroup",
      id: string,
      name?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
    sensor:  {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnDeleteSensorGroupSensorSubscriptionVariables = {
};

export type OnDeleteSensorGroupSensorSubscription = {
  onDeleteSensorGroupSensor?:  {
    __typename: "SensorGroupSensor",
    id: string,
    sensorgroupID: string,
    sensorID: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    sensorgroup:  {
      __typename: "SensorGroup",
      id: string,
      name?: string | null,
      siteID?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
    sensor:  {
      __typename: "Sensor",
      id: string,
      name?: string | null,
      omniSensorId?: string | null,
      sensorType?: SensorType | null,
      deviceID?: string | null,
      alertruleID?: string | null,
      hidden?: boolean | null,
      sortOrder?: number | null,
      energyEquation?: string | null,
      fullLoadKw?: number | null,
      min?: number | null,
      max?: number | null,
      avg?: number | null,
      avgRecent?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};
