import { makeStyles } from '@material-ui/styles'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../redux'
import { useGlobalData } from '../../utils/hooks/global-data'

const useStyles = makeStyles((theme: any) => {
  return {
    nav: {
      listStyle: 'none',
      padding: 0
    },

    item: {

    },

    itemTitle: {
      display: 'block',
      color: theme.palette.primary.light,
      fontSize: 16,
      fontWeight: 'bold',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
      cursor: 'pointer',
      textDecoration: 'none',

      '&:hover': {
        color: theme.palette.primary.contrastText
      }
    },

    subNav: {
      listStyle: 'none',
      padding: 0
    },

    subItem: {
      display: 'block',
      color: theme.palette.primary.light,
      fontSize: 16,
      padding: '5px',
      paddingLeft: theme.spacing(3),
      cursor: 'pointer',
      textDecoration: 'none',

      '&:hover': {
        color: theme.palette.primary.contrastText
      }
    },

    subItemTitle: {
    },

    active: {
      cursor: 'default',
      background: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText
    },

    matching: {
      color: theme.palette.primary.contrastText
    }
  }
})

type SubItemProps = {
  path: string,
  children: React.ReactNode
}

function SubItem({ path, children }: SubItemProps) {
  const classes = useStyles()
  const { pathname } = useLocation()
  const [, , globalDataToSearchParams] = useGlobalData()
  const isActive = pathname === path

  return (
    <li>
      <Link
        to={`${path}?${globalDataToSearchParams()}`}
        className={`${classes.subItem} ${isActive ? classes.active : ''}`}
      >
        <>{children}</>
      </Link>
    </li>
  )
}

type ItemProps = {
  title: string,
  path: string,
  children?: React.ReactNode
}

function Item({ title, path, children }: ItemProps) {
  const classes = useStyles()
  const { pathname } = useLocation()
  const [, , globalDataToSearchParams] = useGlobalData()
  const basePath = pathname.match(/^\/\w+/)?.[0]
  const isActive = pathname === path
  const isMatching = basePath === path

  return (
    <li className={classes.item}>
      <Link
        to={`${path}?${globalDataToSearchParams()}`}
        className={`
          ${classes.itemTitle}
          ${isActive ? classes.active : ''}
          ${isMatching ? classes.matching : ''}
        `}
      >
        <>{title}</>
      </Link>
      <ul className={classes.subNav}>
        {children}
      </ul>
    </li>
  )
}

function Navigation() {
  const classes = useStyles()
  const { isAdmin, isSuperAdmin } = useSelector((state: ReduxState) => state.userInfo)

  return (
    <ul className={classes.nav}>
      <Item title="Dashboard" path="/dashboard">
        <SubItem path="/dashboard/sensors">Sensors</SubItem>
        <SubItem path="/dashboard/issues">Issues</SubItem>
        {isSuperAdmin && (
          <SubItem path="/dashboard/omniDevices">Omni Devices</SubItem>
        )}
      </Item>

      {isAdmin && <Item title="Users" path="/users" />}
      <Item title="Alert Rules" path="/alertRules" />
      <Item title="Maintenance" path="/maintenance">
        <SubItem path="/maintenance/schedule">Schedule</SubItem>
        <SubItem path="/maintenance/logs">Logs</SubItem>
        <SubItem path="/maintenance/contacts">Service Contacts</SubItem>
        <SubItem path="/maintenance/energy-usage">Energy Usage</SubItem>
      </Item>
      <Item title="Reports" path="/reports" />
      {isAdmin && (
        <Item title="Manage" path="/manage">
          <SubItem path="/manage/clients">Clients</SubItem>
          <SubItem path="/manage/sites">Sites</SubItem>
          <SubItem path="/manage/sensorGroups">Sensor Groups</SubItem>
          <SubItem path="/manage/exportData">Export Data</SubItem>
        </Item>
      )}
    </ul>
  )
}

export { Navigation }
