import { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Client, Site } from '../../API'
import { useFetchSites } from '../../utils/hooks'
import { useListClients } from '../../utils/hooks/clients'
import { siteForm } from '../../utils/FormData'
import ActionHeader from '../../components/ActionHeader'
import { AutoCompleteComp, ChangeFunc } from '../../components/AutoCompleteComp'
import { FormFields } from './FormFields'
import { useCreateSite } from './useCreateSite'
import { useUpdateSite } from './useUpdateSite'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%'
  },
  content: {
    padding: theme.spacing(2)
  },
  selectContainer: {
    marginBottom: theme.spacing(2)
  }
}))

function SiteManager() {
  const classes = useStyles()
  const [selectedClient, setSelectedClient] = useState<string | null>(null)
  const [selectedSite, setSelectedSite] = useState<string | null>(null)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  const clientsQuery = useListClients()
  const { sites, keyedSites } = useFetchSites(selectedClient)
  const createMutation = useCreateSite()
  const updateMutation = useUpdateSite()
  const clients = (clientsQuery.data?.data?.listClients?.items ??
    []) as Client[]
  const client = clients.find((client) => client.id === selectedClient) || null
  const site = selectedSite ? keyedSites[selectedSite] : null

  const getRenderedOption = useCallback(
    (test: string, prefix: string) => (option: any) =>
      (
        <div>
          {option[prefix] === test ? <em>{option[prefix]}</em> : option[prefix]}
        </div>
      ),
    []
  )

  const getOptionSelected = useCallback(
    (option: any, value: any) => option?.id === value?.id,
    []
  )

  const handleClientChange: ChangeFunc<Client> = (_, value) => {
    const { id } = value || { id: null, name: null }

    setSelectedClient(id)
    setSelectedSite(null)
  }

  const handleSiteChange: ChangeFunc<Site> = (_, value) => {
    const { id } = value || { id: null, name: null }

    setSelectedSite(id)
  }

  const handleSave = async (data: Record<string, any>) => {
    const mutation = data.id ? updateMutation : createMutation
    mutation.mutateAsync({
      // @ts-ignore
      input: {
        ...data,
        clientID: selectedClient
      }
    }).then(() => {
      setShowSuccess(true)
    })
  }

  return (
    <div className={classes.container}>
      <ActionHeader name="Site Manager" />

      <div className={classes.content}>
        <div className={classes.selectContainer}>
          <AutoCompleteComp
            disableClearable={false}
            value={client}
            options={clients}
            getOptionLabel={(option: Client) => option.name || ''}
            getOptionSelected={getOptionSelected}
            handleChange={handleClientChange}
            label="Client"
            renderOption={getRenderedOption('New Client', 'name')}
            checkID={selectedClient}
          />
        </div>
        {selectedClient && (
          <div className={classes.selectContainer}>
            <AutoCompleteComp
              disableClearable={false}
              value={site}
              options={sites}
              getOptionLabel={(option: Site) => option.name || ''}
              getOptionSelected={getOptionSelected}
              handleChange={handleSiteChange}
              label="Site"
              renderOption={getRenderedOption('New Site', 'name')}
              checkID={selectedSite}
            />
          </div>
        )}
        {selectedClient &&
          <FormFields
            key={selectedSite}
            title="Site"
            isLoading={updateMutation.isLoading || createMutation.isLoading}
            isError={false}
            fields={siteForm}
            data={site}
            onSave={handleSave}
          />}
      </div>
      {showSuccess && (
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Success
        </Alert>
      )}
    </div>
  )
}

export { SiteManager }
