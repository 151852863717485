import ReactDOM from 'react-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { Auth } from '@aws-amplify/auth'
import { Authenticator } from 'aws-amplify-react'
import LogRocket from 'logrocket'
// Local components
import App from './App'
import rootReducer from './redux'
import awsconfig from './aws-exports.js'
// Styling components
import './index.css'
import Theme from './providers/Theme'
import CssBaseline from '@material-ui/core/CssBaseline'

LogRocket.init('cetmbb/crw-monitoring-app')

Auth.configure(awsconfig)

const store = createStore(rootReducer, (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__())

ReactDOM.render(
  <Theme>
    <CssBaseline />
    <Provider store={store}>
      <Authenticator hideDefault>
        <App />
      </Authenticator>
    </Provider>
  </Theme>,
  document.getElementById('root')
)
