export enum AppDataTypes {
  CLIENT_ID = 'update_clientID',
  SITE_ID = 'update_siteID',
  GROUP_ID = 'update_sensorgroupID',
  OMNI_ID = 'update_omniDeviceID',
  DEVICE_ID = 'update_deviceID',
  SENSOR_ID = 'update_sensorID',
  HIDDEN_SENSORS = 'update_hidden_sensors_shown',
}

interface Data {
  clientID: string
  siteID: string
  sensorgroupID: string
  omnideviceID: string
  deviceID: string
  sensorID: string
  hiddenSensorsShown: boolean
}

export interface IAppData extends Partial<Data> {
  type: AppDataTypes
}
