import { useQuery } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  ReportTemplate as ApiReportTemplate,
  GetReportTemplateQueryVariables as Variables,
  GetReportTemplateQuery as Response
} from '../../API'
import { getReportTemplate as query } from '../../graphql/queries'

const REPORT_TEMPLATE_KEY = 'report-template'

export type TReportTemplate = Omit<ApiReportTemplate, 'reports'>

function useFetchTemplate(variables: Variables) {
  return useQuery([REPORT_TEMPLATE_KEY, variables], () => {
    return api.request<Response>({ query, variables })
  })
}

export { REPORT_TEMPLATE_KEY, useFetchTemplate }
