import 'chartjs-plugin-crosshair'
import { Line } from 'react-chartjs-2'
import { Typography } from '@material-ui/core'
import { Sensor } from '../API'
import { useGraph, useFetchSensorReadings } from '../utils/hooks'
import Loading from './Loading'
import AlertComp from './AlertComp'

interface Props {
  sensor: Sensor
}

const SingleDeviceGraph = ({ sensor }: Props) => {
  const alertruleID = sensor.alertruleID || ''

  const {
    readingsData,
    isLoading: readingLoading,
    isError: readingError
  } = useFetchSensorReadings(sensor.id)

  const { lineData, options } = useGraph(readingsData, alertruleID)

  const renderGraph = () => {
    if (readingLoading) {
      return <Loading />
    }
    if (readingError) {
      return <AlertComp severity="error" message="A problem occured retrieving the graph data" />
    }
    // @ts-ignore Something about dependency types is broken here
    return <Line id={`data-chart-${sensor.id}`} data={lineData} options={options} height={350} />
  }

  return (
    <div>
      <Typography variant="h6">{sensor.name}</Typography>
      <div>
        {renderGraph()}
      </div>
    </div>
  )
}

export default SingleDeviceGraph
