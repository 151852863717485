import { useQueryClient, useMutation } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  UpdateReportTemplateMutationVariables as Variables,
  UpdateReportTemplateMutation as Response
} from '../../API'
import { updateReportTemplate as query } from '../../graphql/mutations'
import { REPORT_TEMPLATE_LIST_KEY } from './useFetchTemplateList'

function useUpdateTemplate() {
  const queryClient = useQueryClient()

  return useMutation((variables: Variables) => {
    return api.request<Response>({ query, variables })
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(REPORT_TEMPLATE_LIST_KEY)
    }
  })
}

export { useUpdateTemplate }
