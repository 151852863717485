import { keyBy } from 'lodash'
import { useQuery } from 'react-query'
import { ListSitesQuery, Site } from '../../../API'
import { listSites } from '../../../graphql/queries'
import isEmpty from 'lodash/isEmpty'
import { api } from '../../gql-client'

export const SITES_KEY = 'sites'

export const useFetchSites = (
  clientID: string | null | undefined,
  sitesArray: (string | null)[] = []
) => {
  const sitesRes = useQuery([SITES_KEY, clientID, sitesArray], () => {
    const sitesFilter = sitesArray.map((siteId) => ({ id: { eq: siteId } }))
    const variables = clientID
      ? {
          filter: {
            ...(isEmpty(sitesFilter) ? {} : { or: sitesFilter }),
            clientID: { eq: clientID }
          }
        }
      : {}
    return api.request<ListSitesQuery>({
      query: listSites,
      variables
    })
  })

  const sites = (sitesRes.data?.data?.listSites?.items as Site[]) ?? []
  return {
    sites: sites,
    keyedSites: keyBy(sites, 'id'),
    isLoading: sitesRes.isLoading,
    isError: sitesRes.isError
  }
}
