import { useQuery } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  ListReportsQueryVariables as Variables,
  ListReportsQuery as Response
} from '../../API'
import { listReports as query } from '../../graphql/queries'

const REPORT_LIST_KEY = 'report-list'

function useFetchReportList(variables: Variables = {}) {
  return useQuery([REPORT_LIST_KEY], () => {
    return api.request<Response>({ query, variables })
  })
}

export { REPORT_LIST_KEY, useFetchReportList }
