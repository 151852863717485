import { FC } from 'react'
import cx from 'classnames'
import isObject from 'lodash/isObject'
import { useDropzone } from 'react-dropzone'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import AttachFileIcon from '@material-ui/icons/AttachFile'

const useStyles = makeStyles((theme: Theme) => ({
  formControlRoot: {
    marginTop: theme.spacing(2)
  },
  formLabel: {
    fontSize: 12
  },
  container: {
    border: '1px solid white',
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  subContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center'
  },
  cursor: {
    cursor: 'pointer'
  },
  dragActive: {
    opacity: 0.7
  },
  text: {
    padding: theme.spacing(0, 1.5),
    color: theme.palette.common.black
  },
  errorText: {
    fontWeight: 500,
    fontSize: 15,
    display: 'flex',
    justifyContent: 'center'
  }
}))

interface IFile {
  file: File
  errors?: {
    code: string
    message: string
  }[]
}

interface IFileUploadProps {
  label: string
  name: string
  value: File | string
  required: boolean
  disabled: boolean
  maxSize?: number
  accept: string
  error?: any
  handleChange: (name: string, obj: File | string) => void
  handleError: (message: string) => void
}

const FileUpload: FC<IFileUploadProps> = (props) => {
  const classes = useStyles()
  const {
    label,
    name,
    value,
    required,
    disabled,
    maxSize = 1000 * 1000 * 20, // 20mb
    accept,
    error,
    handleChange,
    handleError
  } = props

  const handleDeleteFile = () => handleChange(name, '')

  const handleDrop = (acceptedFiles: File[], fileRejections: IFile[]) => {
    const error = fileRejections[0]?.errors?.[0]
    if (error) {
      if (error.code === 'file-too-large' || error.code === 'file-invalid-type') {
        handleError(error.message)
        return
      }
    }

    const file = acceptedFiles[0]
    handleChange(name, file)
    handleError('')
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxSize,
    onDrop: handleDrop
  })

  const dragActiveStyle = { [classes.dragActive]: isDragActive }

  const renderInput = () => {
    if (isObject(value)) {
      return (
        <div className={classes.subContainer}>
          <AttachFileIcon />
          <Typography className={classes.text}>{value.name}</Typography>
          <Tooltip title="Remove" arrow>
            <IconButton disabled={disabled} size="small" onClick={handleDeleteFile}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
    }
    return (
      <div
        {...getRootProps()}
        className={cx(classes.subContainer, classes.cursor)}
      >
        <Typography className={classes.text}>Drag a file or click to select a file.</Typography>
        <input {...getInputProps()} />
      </div>
    )
  }

  return (
    <FormControl
      fullWidth
      error={!!error}
      required={required}
      classes={{ root: classes.formControlRoot }}
    >
      <div className={cx(classes.container, dragActiveStyle)}>
        <FormLabel className={classes.formLabel}>{label}</FormLabel>
        {renderInput()}
      </div>
      {!disabled && error && <FormHelperText className={classes.errorText}>{error}</FormHelperText>}
    </FormControl>
  )
}

export default FileUpload
