import { useMutation, useQueryClient } from 'react-query'
import { PingOmniDeviceInput, PingOmniDeviceMutation } from '../../API'
import { pingOmniDevice } from '../../graphql/mutations'
import { api } from '../gql-client'
import { SENSORS_KEY } from './devices'

export const usePingOmniDevice = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (input: PingOmniDeviceInput) => {
      console.log('Pinging Omni Device:')
      console.log(input)
      const res = await api.request<PingOmniDeviceMutation>({
        query: pingOmniDevice,
        variables: {
          input
        }
      })
      return res.data?.pingOmniDevice
    },
    { onSuccess: () => queryClient.invalidateQueries([SENSORS_KEY]) }
  )
}
