import { useQuery } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  ListReportTemplatesQueryVariables as Variables,
  ListReportTemplatesQuery as Response
} from '../../API'
import { listReportTemplates as query } from '../../graphql/queries'

const REPORT_TEMPLATE_LIST_KEY = 'report-template-list'

function useFetchTemplateList(variables: Variables = {}) {
  return useQuery([REPORT_TEMPLATE_LIST_KEY], () => {
    return api.request<Response>({ query, variables })
  })
}

export { REPORT_TEMPLATE_LIST_KEY, useFetchTemplateList }
