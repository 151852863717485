import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
// Local Utils
import { User } from '../API'
import { useFetchCurrentUser } from '../utils/hooks'
import ActionHeader from '../components/ActionHeader'
import Loading from '../components/Loading'
import AlertComp from '../components/AlertComp'
import { UserOveriew } from '../components/Users'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    backgroundColor: theme.palette.primary.main
  }
}))

const Account = () => {
  const classes = useStyles()
  const currentUserQuery = useFetchCurrentUser()

  const me = useMemo(
    () => (currentUserQuery.data || {}) as User,
    [currentUserQuery]
  )
  const isLoading = currentUserQuery.isLoading
  const isError = currentUserQuery.isError

  const checkSubName = (user: User) => {
    if (!user?.IsAdmin) {
      return ''
    }
    if (user?.SiteID) {
      return 'Site Admin'
    }
    if (user?.ClientID) {
      return 'Client Admin'
    }
    return 'Super Admin'
  }

  const hasUserData = useMemo(() => !isEmpty(me), [me])

  const renderBody = () => {
    if (isLoading) {
      return <Loading />
    }

    if (isError) {
      return (
        <AlertComp
          severity="error"
          message="There was a problem fetch the users. If this persits, please contact support."
        />
      )
    }

    if (!hasUserData) {
      return (
        <AlertComp
          severity="warning"
          message="No users were found. Create a user, select a different user, or if you believe this was a mistake, please contact support."
        />
      )
    }

    return (
      <>
        <ActionHeader
          checkbox
          name={me?.Name || ''}
          subname={checkSubName(me as any)}
          checked={false}
        />
        <UserOveriew user={me as any} displayUserContactPreferencesControl />
      </>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.body}>{renderBody()}</div>
    </div>
  )
}

export default Account
