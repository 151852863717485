import { useQueries, useQuery, useQueryClient } from 'react-query'
import { sensorIssueBySensor } from '../../../graphql/queries'
import {
  Sensor,
  SensorIssue,
  ModelSensorIssueFilterInput,
  SensorIssueBySensorQuery,
  SensorIssueBySensorQueryVariables,
  SensorIssueStatus
} from '../../../API'
import { api } from '../../gql-client'

const SENSOR_ISSUES_KEY = 'sensorIssues'

const getKey = (variables: SensorIssueBySensorQueryVariables) => {
  return [SENSOR_ISSUES_KEY, variables]
}

export const ONGOING_ISSUES_FILTER: ModelSensorIssueFilterInput = {
  state: { eq: SensorIssueStatus.ONGOING }
}

/**
 * Fetches sensor issues for a single sensor.
 */
export const useFetchSensorIssuesBySensor = (
  variables: SensorIssueBySensorQueryVariables
) => {
  const queryClient = useQueryClient()
  const sensorID = variables.sensorID
  const sensorIssuesRes = useQuery(getKey(variables), () => {
    if (!sensorID) {
      return
    }
    return api.request<SensorIssueBySensorQuery>({
      query: sensorIssueBySensor,
      variables
    })
  })

  const issuesData = (sensorIssuesRes?.data?.data?.sensorIssueBySensor?.items ??
    []) as SensorIssue[]

  return {
    issuesData,
    isLoading: sensorIssuesRes.isLoading,
    isError: sensorIssuesRes.isError,
    dataUpdatedAt: sensorIssuesRes.dataUpdatedAt,
    invalidateSensorIssues: () =>
      queryClient.invalidateQueries(getKey(variables))
  }
}

/**
 * To cover a site (list of sensors), just map over sensors and combine results
 * Clients should sort the results themselves
 */
export const useFetchSensorIssues = (
  sensors: Sensor[],
  filter: ModelSensorIssueFilterInput = {}
) => {
  const queryClient = useQueryClient()
  const queries = useQueries(
    sensors.map((sensor) => {
      const variables = {
        sensorID: sensor.id,
        filter
      }
      return {
        queryKey: getKey(variables),
        queryFn: () => {
          if (!variables.sensorID) {
            return
          }
          return api.request<SensorIssueBySensorQuery>({
            query: sensorIssueBySensor,
            variables
          })
        }
      }
    })
  )

  const issuesData = queries.reduce((prev, next) => {
    const issuesData = (next?.data?.data?.sensorIssueBySensor?.items ??
      []) as SensorIssue[]
    return prev.concat(issuesData)
  }, [] as SensorIssue[])
  const isLoading = queries.reduce(
    (prev, next) => prev && !!next.isLoading,
    true
  )
  const isError = queries.reduce((prev, next) => prev || !!next.isError, false)

  return {
    issuesData,
    isLoading,
    isError,
    dataUpdatedAt: queries[0]?.dataUpdatedAt,
    invalidateSensorIssues: () => {
      sensors.forEach((sensor) => {
        const variables = {
          sensorID: sensor.id,
          filter
        }
        queryClient.invalidateQueries(getKey(variables))
      })
    }
  }
}
