import isEmpty from 'lodash/isEmpty'
import { useQuery } from 'react-query'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import {
  ListMaintenanceEventsQuery,
  MaintenanceEvent,
  MaintenanceSchedule
} from '../../../API'
import { listMaintenanceEvents } from '../../../graphql/queries'
import { makeGraphQlQuery } from '../../graphql'
import { getPresignedURL } from '../../getPresignedURL'

export const useFetchMaintenanceEvents = (schedules: MaintenanceSchedule[]) => {
  const eventsRes = useQuery<unknown, unknown, MaintenanceEvent[]>(
    ['maintenanceEvents', schedules],
    async () => {
      if (isEmpty(schedules)) {
        return
      }
      const maintenanceEventsRes = (await makeGraphQlQuery({
        query: listMaintenanceEvents,
        variables: {
          filter: {
            or: schedules.map(({ id }) => ({
              maintenancescheduleID: { eq: id }
            }))
          }
        }
      })) as GraphQLResult<ListMaintenanceEventsQuery>
      const filteredEvents = (
        (maintenanceEventsRes?.data?.listMaintenanceEvents?.items ??
          []) as MaintenanceEvent[]
      ).filter((event) => !!event)
      return Promise.all(filteredEvents.map(getPresignedURL))
    }
  )

  const events = eventsRes.data || []
  return {
    events,
    keyedEvents: events.reduce((m, event) => {
      // Create Dictionary with the most recent Event for each maintenance schedule
      const maintenancescheduleID = event?.maintenancescheduleID as string
      if (!m[maintenancescheduleID]) {
        // Set initial event for a maintenance schedule
        return {
          ...m,
          [maintenancescheduleID]: event as MaintenanceEvent
        }
      } else if (
        m[maintenancescheduleID].createdAt < (event?.createdAt as string)
      ) {
        // Update the most recent event for the maintenance schedule
        return {
          ...m,
          [maintenancescheduleID]: event as MaintenanceEvent
        }
      } else {
        // Most recent schedule already set
        return m
      }
    }, {} as { [eventId: string]: MaintenanceEvent }),
    isLoading: eventsRes.isLoading,
    isError: eventsRes.isError
  }
}
