import 'chartjs-plugin-crosshair'
import { useMemo } from 'react'
import { ChartOptions } from 'chart.js'
import { Sensor, SensorReading } from '../../../API'
import { getStandardRange } from '../../dataRange'
import { useFetchAlertRule } from '../alertRules'
import { LTTB, TupleDataPoint } from 'downsample'
import { getChartLabel } from './useGetChartConfigs'
import { parseISO } from 'date-fns'
import { useGetSiteByID } from '../sites'
import { useSite } from '../appData'

const sortByTime = (lhs: SensorReading, rhs: SensorReading) => {
  const firstRead = lhs.updatedAt
  const secondRead = rhs.updatedAt
  return firstRead < secondRead ? -1 : firstRead > secondRead ? 1 : 0
}

const sensorReadingToDataPoint = (reading: SensorReading): TupleDataPoint => {
  const readParsed = JSON.parse(reading.readValue as string)
  let val = readParsed.Value

  if ([true, false].includes(val)) {
    val = val ? 1 : 0 // Format booleans into numbers
  }
  return [parseISO(reading.updatedAt), parseFloat(val)]
}

const WINDOW_SIZE = 6
function simpleMovingAverage(data: TupleDataPoint[], window = WINDOW_SIZE) {
  if (!data || data.length < window) {
    return []
  }
  let index = 0
  const length = data.length + 1
  const simpleMovingAverages = []
  while (++index < length) {
    const minIndex = Math.max(index - window, 0)
    const windowSlice = data.slice(minIndex, index)
    const sum = windowSlice.reduce((prev, curr) => prev + curr[1], 0)
    simpleMovingAverages.push(sum / windowSlice.length)
  }
  return simpleMovingAverages
}

export const useGraph = (
  sensorReadings: SensorReading[],
  sensorAlertID: string,
  sensor?: Sensor,
  options?: {
    showMovingAverage: boolean
  }
) => {
  const { alertRule } = useFetchAlertRule(sensorAlertID)
  const siteID = useSite()
  const { siteData } = useGetSiteByID(siteID)
  const title =
    siteData && sensor ? `${siteData.name} - ${sensor.name}` : undefined

  const data = useMemo(
    () => sensorReadings.sort(sortByTime).map(sensorReadingToDataPoint),
    [sensorReadings]
  )

  const downsample = LTTB(data, 500) as TupleDataPoint[]

  const X = downsample.map((p) => p[0])
  const Y = downsample.map((p) => p[1])
  const Y1 = simpleMovingAverage(downsample)

  const label = getChartLabel(sensorReadings[0])
  const labels = X

  const thresholdDataPoints = useMemo(() => {
    if (!sensorAlertID || !alertRule) {
      return []
    }
    return [
      {
        label: 'Threshold Min',
        data: new Array(Y.length).fill(alertRule.minValue),
        borderColor: 'rgba(255, 0, 0, 0.7)',
        fill: false,
        pointRadius: 0
      },
      {
        label: 'Threshold Max',
        data: new Array(Y.length).fill(alertRule.maxValue),
        borderColor: 'rgba(255, 0, 0, 0.7)',
        fill: false,
        pointRadius: 0
      }
    ]
  }, [alertRule, sensorAlertID, Y])

  const movingAverage = options?.showMovingAverage
    ? [
        {
          label: '30 Minute Average',
          data: Y1,
          backgroundColor: 'transparent',
          borderColor: 'rgba(104, 195, 163, 1)',
          pointRadius: 0
        }
      ]
    : []

  const lineData = {
    labels,
    datasets: [
      {
        label,
        data: Y,
        fill: true,
        backgroundColor: 'rgba(62, 158, 233, 0.4)',
        borderColor: 'rgba(62, 158, 233, 1)'
      },
      ...movingAverage,
      ...thresholdDataPoints
    ]
  }

  return {
    lineData,
    options: getOptions(Y, title)
  }
}

const getOptions = (chartData: number[], title?: string): ChartOptions => {
  const [suggestedMin, suggestedMax] = getStandardRange(chartData)
  return {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          position: 'bottom',
          type: 'time',
          ticks: {
            fontColor: '#fff',
            autoSkip: true,
            autoSkipPadding: 50,
            maxRotation: 0
          },
          time: {
            displayFormats: {
              hour: 'HH:mm',
              minute: 'HH:mm',
              second: 'HH:mm:ss'
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin,
            suggestedMax,
            fontColor: '#fff'
          }
        }
      ]
    },
    tooltips: {
      mode: 'x-axis',
      intersect: false
    },
    plugins: {
      crosshair: {
        line: {
          color: '#F66',
          width: 1
        },
        sync: {
          enabled: true
        }
      }
    },
    legend: {
      display: true,
      labels: {
        fontColor: '#fff',
        fontSize: 14
      }
    },
    title: {
      text: title ?? undefined,
      display: !!title,
      fontColor: 'white',
      fontSize: 30
    }
  }
}
