/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSampleAlertMessageRender = /* GraphQL */ `query GetSampleAlertMessageRender($alertRuleID: String) {
  getSampleAlertMessageRender(alertRuleID: $alertRuleID)
}
` as GeneratedQuery<
  APITypes.GetSampleAlertMessageRenderQueryVariables,
  APITypes.GetSampleAlertMessageRenderQuery
>;
export const getSensorGroup = /* GraphQL */ `query GetSensorGroup($id: ID!) {
  getSensorGroup(id: $id) {
    id
    name
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSensorGroupQueryVariables,
  APITypes.GetSensorGroupQuery
>;
export const listSensorGroups = /* GraphQL */ `query ListSensorGroups(
  $filter: ModelSensorGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensorGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorGroupsQueryVariables,
  APITypes.ListSensorGroupsQuery
>;
export const syncSensorGroups = /* GraphQL */ `query SyncSensorGroups(
  $filter: ModelSensorGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSensorGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSensorGroupsQueryVariables,
  APITypes.SyncSensorGroupsQuery
>;
export const getReportTemplate = /* GraphQL */ `query GetReportTemplate($id: ID!) {
  getReportTemplate(id: $id) {
    id
    sensorgroupID
    name
    times
    isActive
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    reports {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportTemplateQueryVariables,
  APITypes.GetReportTemplateQuery
>;
export const listReportTemplates = /* GraphQL */ `query ListReportTemplates(
  $filter: ModelReportTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listReportTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sensorgroupID
      name
      times
      isActive
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportTemplatesQueryVariables,
  APITypes.ListReportTemplatesQuery
>;
export const reportTemplateBySensorGroup = /* GraphQL */ `query ReportTemplateBySensorGroup(
  $sensorgroupID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelReportTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  reportTemplateBySensorGroup(
    sensorgroupID: $sensorgroupID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sensorgroupID
      name
      times
      isActive
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportTemplateBySensorGroupQueryVariables,
  APITypes.ReportTemplateBySensorGroupQuery
>;
export const syncReportTemplates = /* GraphQL */ `query SyncReportTemplates(
  $filter: ModelReportTemplateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReportTemplates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sensorgroupID
      name
      times
      isActive
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncReportTemplatesQueryVariables,
  APITypes.SyncReportTemplatesQuery
>;
export const getReport = /* GraphQL */ `query GetReport($id: ID!) {
  getReport(id: $id) {
    id
    reporttemplateID
    name
    date
    data
    approver
    approvedAt
    approvalComment
    userID
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    approverUser {
      id
      Username
      Name
      Email
      Phone
      EmailAlerts
      SmsAlerts
      IsAdmin
      ClientID
      SiteID
      AccountContactPreference
      UserStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReportQueryVariables, APITypes.GetReportQuery>;
export const listReports = /* GraphQL */ `query ListReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reporttemplateID
      name
      date
      data
      approver
      approvedAt
      approvalComment
      userID
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const reportByReportTemplate = /* GraphQL */ `query ReportByReportTemplate(
  $reporttemplateID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  reportByReportTemplate(
    reporttemplateID: $reporttemplateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reporttemplateID
      name
      date
      data
      approver
      approvedAt
      approvalComment
      userID
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportByReportTemplateQueryVariables,
  APITypes.ReportByReportTemplateQuery
>;
export const reportByReportTemplateAndDate = /* GraphQL */ `query ReportByReportTemplateAndDate(
  $reporttemplateID: ID
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  reportByReportTemplateAndDate(
    reporttemplateID: $reporttemplateID
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reporttemplateID
      name
      date
      data
      approver
      approvedAt
      approvalComment
      userID
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportByReportTemplateAndDateQueryVariables,
  APITypes.ReportByReportTemplateAndDateQuery
>;
export const reportByDate = /* GraphQL */ `query ReportByDate(
  $date: AWSDate
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  reportByDate(
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      reporttemplateID
      name
      date
      data
      approver
      approvedAt
      approvalComment
      userID
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportByDateQueryVariables,
  APITypes.ReportByDateQuery
>;
export const syncReports = /* GraphQL */ `query SyncReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReports(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      reporttemplateID
      name
      date
      data
      approver
      approvedAt
      approvalComment
      userID
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncReportsQueryVariables,
  APITypes.SyncReportsQuery
>;
export const getServiceContact = /* GraphQL */ `query GetServiceContact($id: ID!) {
  getServiceContact(id: $id) {
    id
    name
    serviceDescription
    phone
    website
    email
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceContactQueryVariables,
  APITypes.GetServiceContactQuery
>;
export const listServiceContacts = /* GraphQL */ `query ListServiceContacts(
  $filter: ModelServiceContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listServiceContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      serviceDescription
      phone
      website
      email
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServiceContactsQueryVariables,
  APITypes.ListServiceContactsQuery
>;
export const syncServiceContacts = /* GraphQL */ `query SyncServiceContacts(
  $filter: ModelServiceContactFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncServiceContacts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      serviceDescription
      phone
      website
      email
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncServiceContactsQueryVariables,
  APITypes.SyncServiceContactsQuery
>;
export const getMaintenanceSchedule = /* GraphQL */ `query GetMaintenanceSchedule($id: ID!) {
  getMaintenanceSchedule(id: $id) {
    id
    description
    frequencyInDays
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    MaintenanceEvents {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMaintenanceScheduleQueryVariables,
  APITypes.GetMaintenanceScheduleQuery
>;
export const listMaintenanceSchedules = /* GraphQL */ `query ListMaintenanceSchedules(
  $filter: ModelMaintenanceScheduleFilterInput
  $limit: Int
  $nextToken: String
) {
  listMaintenanceSchedules(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      description
      frequencyInDays
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMaintenanceSchedulesQueryVariables,
  APITypes.ListMaintenanceSchedulesQuery
>;
export const syncMaintenanceSchedules = /* GraphQL */ `query SyncMaintenanceSchedules(
  $filter: ModelMaintenanceScheduleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMaintenanceSchedules(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      description
      frequencyInDays
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMaintenanceSchedulesQueryVariables,
  APITypes.SyncMaintenanceSchedulesQuery
>;
export const getMaintenanceEvent = /* GraphQL */ `query GetMaintenanceEvent($id: ID!) {
  getMaintenanceEvent(id: $id) {
    id
    confirmedBy
    reportLink
    notes
    dateCompleted
    maintenancescheduleID
    uploadedBy
    company
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMaintenanceEventQueryVariables,
  APITypes.GetMaintenanceEventQuery
>;
export const listMaintenanceEvents = /* GraphQL */ `query ListMaintenanceEvents(
  $filter: ModelMaintenanceEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listMaintenanceEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      confirmedBy
      reportLink
      notes
      dateCompleted
      maintenancescheduleID
      uploadedBy
      company
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMaintenanceEventsQueryVariables,
  APITypes.ListMaintenanceEventsQuery
>;
export const syncMaintenanceEvents = /* GraphQL */ `query SyncMaintenanceEvents(
  $filter: ModelMaintenanceEventFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMaintenanceEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      confirmedBy
      reportLink
      notes
      dateCompleted
      maintenancescheduleID
      uploadedBy
      company
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMaintenanceEventsQueryVariables,
  APITypes.SyncMaintenanceEventsQuery
>;
export const getSensorIssue = /* GraphQL */ `query GetSensorIssue($id: ID!) {
  getSensorIssue(id: $id) {
    id
    state
    startTime
    lastUpdateTime
    endTime
    notes
    sensorID
    userAckID
    ackTime
    ackEvent
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorIssueContactAttempts {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSensorIssueQueryVariables,
  APITypes.GetSensorIssueQuery
>;
export const listSensorIssues = /* GraphQL */ `query ListSensorIssues(
  $filter: ModelSensorIssueFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensorIssues(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorIssuesQueryVariables,
  APITypes.ListSensorIssuesQuery
>;
export const sensorIssueBySensor = /* GraphQL */ `query SensorIssueBySensor(
  $sensorID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelSensorIssueFilterInput
  $limit: Int
  $nextToken: String
) {
  sensorIssueBySensor(
    sensorID: $sensorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SensorIssueBySensorQueryVariables,
  APITypes.SensorIssueBySensorQuery
>;
export const sensorIssueByState = /* GraphQL */ `query SensorIssueByState(
  $sensorID: ID
  $state: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSensorIssueFilterInput
  $limit: Int
  $nextToken: String
) {
  sensorIssueByState(
    sensorID: $sensorID
    state: $state
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SensorIssueByStateQueryVariables,
  APITypes.SensorIssueByStateQuery
>;
export const SensorIssueBySensorAndTime = /* GraphQL */ `query SensorIssueBySensorAndTime(
  $sensorID: ID
  $lastUpdateTime: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSensorIssueFilterInput
  $limit: Int
  $nextToken: String
) {
  SensorIssueBySensorAndTime(
    sensorID: $sensorID
    lastUpdateTime: $lastUpdateTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SensorIssueBySensorAndTimeQueryVariables,
  APITypes.SensorIssueBySensorAndTimeQuery
>;
export const syncSensorIssues = /* GraphQL */ `query SyncSensorIssues(
  $filter: ModelSensorIssueFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSensorIssues(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSensorIssuesQueryVariables,
  APITypes.SyncSensorIssuesQuery
>;
export const getSensorIssueSummary = /* GraphQL */ `query GetSensorIssueSummary($id: ID!) {
  getSensorIssueSummary(id: $id) {
    id
    siteID
    date
    issues {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSensorIssueSummaryQueryVariables,
  APITypes.GetSensorIssueSummaryQuery
>;
export const listSensorIssueSummaries = /* GraphQL */ `query ListSensorIssueSummaries(
  $filter: ModelSensorIssueSummaryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensorIssueSummaries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      siteID
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorIssueSummariesQueryVariables,
  APITypes.ListSensorIssueSummariesQuery
>;
export const syncSensorIssueSummaries = /* GraphQL */ `query SyncSensorIssueSummaries(
  $filter: ModelSensorIssueSummaryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSensorIssueSummaries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      siteID
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSensorIssueSummariesQueryVariables,
  APITypes.SyncSensorIssueSummariesQuery
>;
export const getSensorIssueContactAttempt = /* GraphQL */ `query GetSensorIssueContactAttempt($id: ID!) {
  getSensorIssueContactAttempt(id: $id) {
    id
    sensorIssueID
    serviceContactID
    email
    phoneNumber
    attemptNumber
    attemptTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSensorIssueContactAttemptQueryVariables,
  APITypes.GetSensorIssueContactAttemptQuery
>;
export const listSensorIssueContactAttempts = /* GraphQL */ `query ListSensorIssueContactAttempts(
  $filter: ModelSensorIssueContactAttemptFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensorIssueContactAttempts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sensorIssueID
      serviceContactID
      email
      phoneNumber
      attemptNumber
      attemptTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorIssueContactAttemptsQueryVariables,
  APITypes.ListSensorIssueContactAttemptsQuery
>;
export const sensorIssueContactAttemptByPhone = /* GraphQL */ `query SensorIssueContactAttemptByPhone(
  $phoneNumber: AWSPhone
  $attemptTime: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSensorIssueContactAttemptFilterInput
  $limit: Int
  $nextToken: String
) {
  sensorIssueContactAttemptByPhone(
    phoneNumber: $phoneNumber
    attemptTime: $attemptTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sensorIssueID
      serviceContactID
      email
      phoneNumber
      attemptNumber
      attemptTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SensorIssueContactAttemptByPhoneQueryVariables,
  APITypes.SensorIssueContactAttemptByPhoneQuery
>;
export const syncSensorIssueContactAttempts = /* GraphQL */ `query SyncSensorIssueContactAttempts(
  $filter: ModelSensorIssueContactAttemptFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSensorIssueContactAttempts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sensorIssueID
      serviceContactID
      email
      phoneNumber
      attemptNumber
      attemptTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSensorIssueContactAttemptsQueryVariables,
  APITypes.SyncSensorIssueContactAttemptsQuery
>;
export const getAlertRule = /* GraphQL */ `query GetAlertRule($id: ID!) {
  getAlertRule(id: $id) {
    id
    name
    minValue
    maxValue
    alertDelay
    alertAttempts
    threshold
    snoozeTime
    siteID
    protocol
    alertTextTemplate
    contactUsers
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAlertRuleQueryVariables,
  APITypes.GetAlertRuleQuery
>;
export const listAlertRules = /* GraphQL */ `query ListAlertRules(
  $filter: ModelAlertRuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlertRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      minValue
      maxValue
      alertDelay
      alertAttempts
      threshold
      snoozeTime
      siteID
      protocol
      alertTextTemplate
      contactUsers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlertRulesQueryVariables,
  APITypes.ListAlertRulesQuery
>;
export const syncAlertRules = /* GraphQL */ `query SyncAlertRules(
  $filter: ModelAlertRuleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAlertRules(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      minValue
      maxValue
      alertDelay
      alertAttempts
      threshold
      snoozeTime
      siteID
      protocol
      alertTextTemplate
      contactUsers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAlertRulesQueryVariables,
  APITypes.SyncAlertRulesQuery
>;
export const getDevice = /* GraphQL */ `query GetDevice($id: ID!) {
  getDevice(id: $id) {
    id
    name
    omnideviceID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDeviceQueryVariables, APITypes.GetDeviceQuery>;
export const listDevices = /* GraphQL */ `query ListDevices(
  $filter: ModelDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      omnideviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDevicesQueryVariables,
  APITypes.ListDevicesQuery
>;
export const deviceByOmniDevice = /* GraphQL */ `query DeviceByOmniDevice(
  $omnideviceID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  deviceByOmniDevice(
    omnideviceID: $omnideviceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      omnideviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DeviceByOmniDeviceQueryVariables,
  APITypes.DeviceByOmniDeviceQuery
>;
export const syncDevices = /* GraphQL */ `query SyncDevices(
  $filter: ModelDeviceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDevices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      omnideviceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDevicesQueryVariables,
  APITypes.SyncDevicesQuery
>;
export const getOmniDeviceStatus = /* GraphQL */ `query GetOmniDeviceStatus($id: ID!) {
  getOmniDeviceStatus(id: $id) {
    id
    omnideviceID
    timestamp
    listDevicesResult
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOmniDeviceStatusQueryVariables,
  APITypes.GetOmniDeviceStatusQuery
>;
export const listOmniDeviceStatuses = /* GraphQL */ `query ListOmniDeviceStatuses(
  $filter: ModelOmniDeviceStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listOmniDeviceStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      omnideviceID
      timestamp
      listDevicesResult
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOmniDeviceStatusesQueryVariables,
  APITypes.ListOmniDeviceStatusesQuery
>;
export const syncOmniDeviceStatuses = /* GraphQL */ `query SyncOmniDeviceStatuses(
  $filter: ModelOmniDeviceStatusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOmniDeviceStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      omnideviceID
      timestamp
      listDevicesResult
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOmniDeviceStatusesQueryVariables,
  APITypes.SyncOmniDeviceStatusesQuery
>;
export const getClient = /* GraphQL */ `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    name
    address
    email
    website
    phone
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Site {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientQueryVariables, APITypes.GetClientQuery>;
export const listClients = /* GraphQL */ `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      address
      email
      website
      phone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientsQueryVariables,
  APITypes.ListClientsQuery
>;
export const syncClients = /* GraphQL */ `query SyncClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      address
      email
      website
      phone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncClientsQueryVariables,
  APITypes.SyncClientsQuery
>;
export const getSensorReading = /* GraphQL */ `query GetSensorReading($id: ID!) {
  getSensorReading(id: $id) {
    id
    readTime
    readValue
    sensorID
    pingType
    oldSensorID
    newSensorID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSensorReadingQueryVariables,
  APITypes.GetSensorReadingQuery
>;
export const listSensorReadings = /* GraphQL */ `query ListSensorReadings(
  $filter: ModelSensorReadingFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensorReadings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      readTime
      readValue
      sensorID
      pingType
      oldSensorID
      newSensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorReadingsQueryVariables,
  APITypes.ListSensorReadingsQuery
>;
export const bySensorAndTime = /* GraphQL */ `query BySensorAndTime(
  $sensorID: ID
  $readTime: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSensorReadingFilterInput
  $limit: Int
  $nextToken: String
) {
  bySensorAndTime(
    sensorID: $sensorID
    readTime: $readTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      readTime
      readValue
      sensorID
      pingType
      oldSensorID
      newSensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BySensorAndTimeQueryVariables,
  APITypes.BySensorAndTimeQuery
>;
export const bySensorPingTypeAndTime = /* GraphQL */ `query BySensorPingTypeAndTime(
  $sensorID: ID
  $pingTypeReadTime: ModelSensorReadingBySensorPingTypeAndTimeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSensorReadingFilterInput
  $limit: Int
  $nextToken: String
) {
  bySensorPingTypeAndTime(
    sensorID: $sensorID
    pingTypeReadTime: $pingTypeReadTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      readTime
      readValue
      sensorID
      pingType
      oldSensorID
      newSensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BySensorPingTypeAndTimeQueryVariables,
  APITypes.BySensorPingTypeAndTimeQuery
>;
export const syncSensorReadings = /* GraphQL */ `query SyncSensorReadings(
  $filter: ModelSensorReadingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSensorReadings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      readTime
      readValue
      sensorID
      pingType
      oldSensorID
      newSensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSensorReadingsQueryVariables,
  APITypes.SyncSensorReadingsQuery
>;
export const getSensor = /* GraphQL */ `query GetSensor($id: ID!) {
  getSensor(id: $id) {
    id
    name
    omniSensorId
    sensorType
    deviceID
    alertruleID
    hidden
    sortOrder
    energyEquation
    fullLoadKw
    min
    max
    avg
    avgRecent
    lastReading {
      id
      readTime
      readValue
      sensorID
      pingType
      oldSensorID
      newSensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorIssues {
      nextToken
      startedAt
      __typename
    }
    SensorReadings {
      nextToken
      startedAt
      __typename
    }
    sensorgroups {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSensorQueryVariables, APITypes.GetSensorQuery>;
export const listSensors = /* GraphQL */ `query ListSensors(
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      omniSensorId
      sensorType
      deviceID
      alertruleID
      hidden
      sortOrder
      energyEquation
      fullLoadKw
      min
      max
      avg
      avgRecent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorsQueryVariables,
  APITypes.ListSensorsQuery
>;
export const sensorByDeviceID = /* GraphQL */ `query SensorByDeviceID(
  $deviceID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  sensorByDeviceID(
    deviceID: $deviceID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      omniSensorId
      sensorType
      deviceID
      alertruleID
      hidden
      sortOrder
      energyEquation
      fullLoadKw
      min
      max
      avg
      avgRecent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SensorByDeviceIDQueryVariables,
  APITypes.SensorByDeviceIDQuery
>;
export const sensorByAlertRule = /* GraphQL */ `query SensorByAlertRule(
  $alertruleID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  sensorByAlertRule(
    alertruleID: $alertruleID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      omniSensorId
      sensorType
      deviceID
      alertruleID
      hidden
      sortOrder
      energyEquation
      fullLoadKw
      min
      max
      avg
      avgRecent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SensorByAlertRuleQueryVariables,
  APITypes.SensorByAlertRuleQuery
>;
export const syncSensors = /* GraphQL */ `query SyncSensors(
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSensors(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      omniSensorId
      sensorType
      deviceID
      alertruleID
      hidden
      sortOrder
      energyEquation
      fullLoadKw
      min
      max
      avg
      avgRecent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSensorsQueryVariables,
  APITypes.SyncSensorsQuery
>;
export const getSite = /* GraphQL */ `query GetSite($id: ID!) {
  getSite(id: $id) {
    id
    name
    timezone
    sensorsUpdatedAt
    lastOfflineNotification
    clientID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorGroups {
      nextToken
      startedAt
      __typename
    }
    ReportTemplates {
      nextToken
      startedAt
      __typename
    }
    ServiceContacts {
      nextToken
      startedAt
      __typename
    }
    MaintenanceSchedules {
      nextToken
      startedAt
      __typename
    }
    AlertRules {
      nextToken
      startedAt
      __typename
    }
    Devices {
      nextToken
      startedAt
      __typename
    }
    UserContactPreferences {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSiteQueryVariables, APITypes.GetSiteQuery>;
export const listSites = /* GraphQL */ `query ListSites(
  $filter: ModelSiteFilterInput
  $limit: Int
  $nextToken: String
) {
  listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      timezone
      sensorsUpdatedAt
      lastOfflineNotification
      clientID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSitesQueryVariables, APITypes.ListSitesQuery>;
export const siteByClientID = /* GraphQL */ `query SiteByClientID(
  $clientID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelSiteFilterInput
  $limit: Int
  $nextToken: String
) {
  siteByClientID(
    clientID: $clientID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      timezone
      sensorsUpdatedAt
      lastOfflineNotification
      clientID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SiteByClientIDQueryVariables,
  APITypes.SiteByClientIDQuery
>;
export const syncSites = /* GraphQL */ `query SyncSites(
  $filter: ModelSiteFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSites(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      timezone
      sensorsUpdatedAt
      lastOfflineNotification
      clientID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncSitesQueryVariables, APITypes.SyncSitesQuery>;
export const getOmniDevice = /* GraphQL */ `query GetOmniDevice($id: ID!) {
  getOmniDevice(id: $id) {
    id
    deviceName
    note
    connectionType
    ipAddress
    hostname
    port
    username
    password
    devicegroupID
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Devices {
      nextToken
      startedAt
      __typename
    }
    OmniDeviceStatuses {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOmniDeviceQueryVariables,
  APITypes.GetOmniDeviceQuery
>;
export const listOmniDevices = /* GraphQL */ `query ListOmniDevices(
  $filter: ModelOmniDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  listOmniDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deviceName
      note
      connectionType
      ipAddress
      hostname
      port
      username
      password
      devicegroupID
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOmniDevicesQueryVariables,
  APITypes.ListOmniDevicesQuery
>;
export const omniDeviceBySiteID = /* GraphQL */ `query OmniDeviceBySiteID(
  $siteID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelOmniDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  omniDeviceBySiteID(
    siteID: $siteID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deviceName
      note
      connectionType
      ipAddress
      hostname
      port
      username
      password
      devicegroupID
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OmniDeviceBySiteIDQueryVariables,
  APITypes.OmniDeviceBySiteIDQuery
>;
export const syncOmniDevices = /* GraphQL */ `query SyncOmniDevices(
  $filter: ModelOmniDeviceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOmniDevices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      deviceName
      note
      connectionType
      ipAddress
      hostname
      port
      username
      password
      devicegroupID
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOmniDevicesQueryVariables,
  APITypes.SyncOmniDevicesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    Username
    Name
    Email
    Phone
    EmailAlerts
    SmsAlerts
    IsAdmin
    ClientID
    SiteID
    AccountContactPreference
    UserStatus
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    UserContactPreferences {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Username
      Name
      Email
      Phone
      EmailAlerts
      SmsAlerts
      IsAdmin
      ClientID
      SiteID
      AccountContactPreference
      UserStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      Username
      Name
      Email
      Phone
      EmailAlerts
      SmsAlerts
      IsAdmin
      ClientID
      SiteID
      AccountContactPreference
      UserStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getUserContactPreferences = /* GraphQL */ `query GetUserContactPreferences($id: ID!) {
  getUserContactPreferences(id: $id) {
    id
    userID
    siteID
    canSendEmail
    canSendText
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserContactPreferencesQueryVariables,
  APITypes.GetUserContactPreferencesQuery
>;
export const listUserContactPreferences = /* GraphQL */ `query ListUserContactPreferences(
  $filter: ModelUserContactPreferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserContactPreferences(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      siteID
      canSendEmail
      canSendText
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserContactPreferencesQueryVariables,
  APITypes.ListUserContactPreferencesQuery
>;
export const syncUserContactPreferences = /* GraphQL */ `query SyncUserContactPreferences(
  $filter: ModelUserContactPreferencesFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserContactPreferences(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      siteID
      canSendEmail
      canSendText
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserContactPreferencesQueryVariables,
  APITypes.SyncUserContactPreferencesQuery
>;
export const syncSensorGroupSensors = /* GraphQL */ `query SyncSensorGroupSensors(
  $filter: ModelSensorGroupSensorFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSensorGroupSensors(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sensorgroupID
      sensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSensorGroupSensorsQueryVariables,
  APITypes.SyncSensorGroupSensorsQuery
>;
