import get from 'lodash/get'

const parseSession = (session: any) => {
  if (!session) return {}

  return get(session, 'signInUserSession.idToken.payload', {})
}

export const updateUserInfo = (user: any) => {
  const {
    name,
    sub: userId,
    'cognito:groups': groups,
    'cognito:username': username,
    given_name: givenName,
    phone_number: phoneNumber
  } = parseSession(user)
  return {
    type: 'update_user_info',
    userId,
    groups,
    phoneNumber,
    username: name || username || givenName
  }
}
