import { FC, useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import format from 'date-fns/format'
import { Storage } from 'aws-amplify'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
// Local components
import Loading from '../Loading'
import AlertComp from '../AlertComp'
import Form from '../NewForm'
import DeleteDialog from '../DeleteDialog'
import {
  maintenanceScheduleForm,
  tableHeaders,
  maintenanceEventForm
} from './constants'
import {
  Site,
  MaintenanceEvent,
  MaintenanceSchedule,
  CreateMaintenanceEventInput,
  CreateMaintenanceScheduleInput
} from '../../API'
import {
  useMutateMaintenanceSchedule,
  useMutateMaintenanceEvent,
  useDeleteMaintenanceSchedule
} from '../../utils/hooks'
import {
  createMaintenanceEvent,
  createMaintenanceSchedule,
  updateMaintenanceSchedule
} from '../../graphql/mutations'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../redux'

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    display: 'flex',
    height: '100%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3, 3)
  },
  button: {
    color: 'white'
  },
  container: {
    width: 'inherit',
    margin: theme.spacing(0, 3),
    backgroundColor: theme.palette.primary.main,
    border: '1px solid rgba(255, 255, 255, 0.6)'
  },
  table: {
    '& .MuiTableCell-root': {
      color: 'white',
      fontWeight: 500,
      PointerEvent: 'none'
    },
    '& .MuiTableCell-head': {
      fontWeight: 700,
      fontSize: 15
    }
  },
  textCell: {
    maxWidth: 270
  },
  downloadLink: {
    color: 'white',
    fontSize: '16px',
    marginRight: theme.spacing(1)
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 13
  },
  tooltipArrow: {
    color: theme.palette.common.white
  }
}))

interface CreateMaintenance extends CreateMaintenanceEventInput {
  file: File
}

interface IFormSettings {
  formOpen: boolean
  formData: MaintenanceSchedule | {}
}

interface IScheduleProps {
  site: Site
  schedules: MaintenanceSchedule[]
  keyedEvents: { [eventId: string]: MaintenanceEvent }
  isLoading: boolean
  isError: boolean
}

const Schedule: FC<IScheduleProps> = (props) => {
  const classes = useStyles()
  const { site, schedules, keyedEvents, isLoading, isError } = props
  const username = useSelector((state: ReduxState) => state.userInfo.username)

  const [eventFormSettings, setEventFormSettings] = useState({
    eventFormOpen: false,
    maintenancescheduleID: ''
  })
  const [formSettings, setFormSettings] = useState<IFormSettings>({
    formOpen: false,
    formData: {}
  })
  const [deleteSettings, setDeleteSettings] = useState({
    deleteOpen: false,
    scheduleIDToDelete: '',
    scheduleVersion: 0
  })
  const [storageSettings, setStorageSettings] = useState({
    loading: false,
    errorSaving: ''
  })
  const { formOpen, formData } = formSettings
  const { eventFormOpen, maintenancescheduleID } = eventFormSettings
  const { deleteOpen, scheduleIDToDelete, scheduleVersion } = deleteSettings
  const { loading: storageLoading, errorSaving } = storageSettings

  const {
    mutateSchedule,
    resetMutation,
    isLoading: isScheduleMutateLoading,
    isError: isScheduleMutateError
  } = useMutateMaintenanceSchedule(site)

  const {
    createEventMutate,
    resetEventMutation,
    isLoading: isEventMutateLoading,
    isError: isEventMutateError
  } = useMutateMaintenanceEvent(schedules)

  const {
    deleteSchedule,
    resetDeletion,
    deleteScheduleIsSuccess,
    deleteScheduleIsLoading,
    deleteScheduleIsError
  } = useDeleteMaintenanceSchedule(site)

  const handleToggleScheduleForm = useCallback(
    (formData: MaintenanceSchedule | {} = {}) =>
      () => {
        setFormSettings((settings) => ({
          formOpen: !settings.formOpen,
          formData
        }))
        resetMutation()
      },
    [resetMutation]
  )

  const handleToggleEventForm = useCallback(
    (id: string = '') =>
      () => {
        setEventFormSettings((settings) => ({
          eventFormOpen: !settings.eventFormOpen,
          maintenancescheduleID: id
        }))
        resetEventMutation()
      },
    [resetEventMutation]
  )

  const handleToggleDelete = useCallback(
    (scheduleIDToDelete: string = '', scheduleVersion: number = 0) =>
      () => {
        setDeleteSettings((settings) => ({
          deleteOpen: !settings.deleteOpen,
          scheduleIDToDelete,
          scheduleVersion
        }))
        resetDeletion()
      },
    [resetDeletion]
  )

  const handleSaveSchedule = useCallback(
    async (data: CreateMaintenanceScheduleInput) => {
      try {
        let mutateData
        if (!isEmpty(formData)) {
          mutateData = {
            query: updateMaintenanceSchedule,
            variables: { input: data }
          }
        } else {
          mutateData = {
            query: createMaintenanceSchedule,
            variables: {
              input: {
                ...data,
                siteID: site.id
              }
            }
          }
        }
        await mutateSchedule(mutateData)
        handleToggleScheduleForm()()
      } catch (e) {}
    },
    [mutateSchedule, handleToggleScheduleForm, site, formData]
  )

  const handleCreateEvent = useCallback(
    async (data: CreateMaintenance) => {
      try {
        setStorageSettings({
          loading: true,
          errorSaving: ''
        })
        const result = await Storage.put(data.file.name, data.file)
        setStorageSettings({
          loading: false,
          errorSaving: ''
        })
        const input = {
          confirmedBy: data.confirmedBy,
          notes: data.notes,
          reportLink: (result as { key: string }).key, // Set reportLink to key that was returned from storage.put request
          dateCompleted: new Date(
            data?.dateCompleted as string | number
          ).getTime(),
          maintenancescheduleID,
          uploadedBy: username,
          company: data.company
        }
        await createEventMutate({
          query: createMaintenanceEvent,
          variables: { input }
        })
        handleToggleEventForm()()
      } catch (e) {
        setStorageSettings({
          loading: false,
          errorSaving:
            'There was an issue saving your file. If this persists, please contact support.'
        })
      }
    },
    [createEventMutate, handleToggleEventForm, maintenancescheduleID, username]
  )

  const handleDeleteSchedule = useCallback(() => {
    deleteSchedule({
      id: scheduleIDToDelete,
      _version: scheduleVersion
    })
  }, [deleteSchedule, scheduleIDToDelete, scheduleVersion])

  const formatValue = (eventKey: string, schedule: MaintenanceSchedule) => {
    const event = keyedEvents[schedule.id]
    const value = event?.[eventKey as keyof MaintenanceEvent]

    if (eventKey === 'dateCompleted') {
      return value ? format(new Date(value as number), 'M/d/yyyy') : 'N/A'
    }
    if (eventKey === 'nextDue') {
      const dateCompleted = event?.dateCompleted
      if (!dateCompleted) {
        return 'N/A'
      }
      return format(
        new Date(
          new Date().setDate(
            new Date(dateCompleted).getDate() +
              Number(schedule?.frequencyInDays || 0)
          )
        ),
        'M/d/yyyy'
      )
    }
    if (eventKey === 'reportLink') {
      return (
        <div>
          {value && (
            <a className={classes.downloadLink} href={value as string} download>
              Download
            </a>
          )}
          <Tooltip
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
            title="Upload a maintenance report"
            placement="top-start"
            arrow
          >
            <Button onClick={handleToggleEventForm(schedule.id)}>Upload</Button>
          </Tooltip>
        </div>
      )
    }
    return value || 'N/A'
  }

  const renderTableHeaders = () =>
    tableHeaders.map(({ label }) => (
      <TableCell key={`maintenance-head-${label}`}>{label}</TableCell>
    ))

  const renderTableRows = () =>
    schedules.map((schedule) => (
      <TableRow key={`schedule-${schedule.id}`}>
        {tableHeaders.slice(0, 2).map(({ value }) => (
          <TableCell key={`schedule-${schedule.id}-${value}`}>
            {schedule[value as keyof typeof schedule]}
          </TableCell>
        ))}
        {tableHeaders.slice(2, -1).map(({ value }) => (
          <TableCell
            key={`schedule-${schedule.id}-${value}`}
            className={classes.textCell}
          >
            {formatValue(value, schedule)}
          </TableCell>
        ))}
        <TableCell>
          <IconButton
            size="small"
            color="inherit"
            onClick={handleToggleScheduleForm(schedule)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            onClick={handleToggleDelete(schedule.id, schedule._version)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ))

  const checkToRenderForm = () => {
    if (!formOpen && !eventFormOpen) return

    const props = {
      ...(formOpen ? { data: formData } : {}),
      title:
        formOpen && !isEmpty(formData)
          ? 'Edit Schedule'
          : `Create ${formOpen ? 'Schedule' : 'Event Log'}`,
      fields: formOpen ? maintenanceScheduleForm : maintenanceEventForm,
      handleClose: formOpen
        ? handleToggleScheduleForm()
        : handleToggleEventForm(),
      onSave: formOpen ? handleSaveSchedule : handleCreateEvent,
      isLoading: formOpen
        ? isScheduleMutateLoading
        : isEventMutateLoading || storageLoading,
      isError: formOpen
        ? isScheduleMutateError
        : isEventMutateError || !!errorSaving,
      errorMsg: formOpen ? '' : errorSaving,
      numberFields: formOpen ? ['frequencyInDays'] : []
    }

    return <Form {...props} />
  }

  const renderDelete = () => {
    if (!deleteOpen) return

    return (
      <DeleteDialog
        title="Delete Maintenance Schedule"
        text="Are you sure you want to delete this maintenance schedule?"
        successText="The maintenance schedule has been successfully deleted."
        errorText="There was a problem deleting this maintenance schedule. If this persits, please contact support."
        onClose={handleToggleDelete()}
        onConfirm={handleDeleteSchedule}
        isSuccess={deleteScheduleIsSuccess}
        isLoading={deleteScheduleIsLoading}
        isError={deleteScheduleIsError}
      />
    )
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    )
  }

  if (isError) {
    return (
      <AlertComp
        severity="error"
        message={`There was a problem fetching the maintenance schedule for ${site.name}. If this persists, please contact support.`}
      />
    )
  }

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleToggleScheduleForm()}
          classes={{ root: classes.button }}
        >
          Add Schedule
        </Button>
      </div>
      <TableContainer component={Paper} classes={{ root: classes.container }}>
        <Table classes={{ root: classes.table }}>
          <TableHead>
            <TableRow>{renderTableHeaders()}</TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>

      {checkToRenderForm()}
      {renderDelete()}
    </div>
  )
}

export default Schedule
