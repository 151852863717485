import { combineReducers } from 'redux'
import {
  userInfo,
  appData,
  dateFilters,
  IUserInfo,
  IAppData,
  IDateFilters
} from './reducers'
export * from './actions'
export * from './reducers'

export default combineReducers({
  userInfo,
  appData,
  dateFilters
})

export interface ReduxState {
  userInfo: IUserInfo,
  appData: IAppData,
  dateFilters: IDateFilters
}
