import { useQuery } from 'react-query'
import { getSite } from '../../../graphql/queries'
import { GetSiteQuery, Site } from '../../../API'
import { api } from '../../gql-client'

export const useGetSiteByID = (siteID?: string | null) => {
  const siteDataRes = useQuery(
    ['sites', siteID],
    async () => {
      const res = await api.request<GetSiteQuery>({
        query: getSite,
        variables: { id: siteID }
      })
      return res.data?.getSite
    },
    {
      enabled: !!siteID
    }
  )
  const siteData = (siteDataRes.data || {}) as Site
  const clientID = siteData.clientID ?? ''

  return {
    isLoading: siteDataRes.isLoading,
    clientID,
    siteData
  }
}
