import { useQueries, UseQueryResult } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  GetSensorQueryVariables as Variables,
  GetSensorQuery as Response,
  Sensor
} from '../../API'
import { getSensor as query } from '../../graphql/queries'

const SENSOR_LIST_KEY = 'sensor-list'

const reduceQueries = (
  queries: UseQueryResult[],
  property: keyof UseQueryResult
) => queries.reduce((prev, next) => prev || !!next[property], false)

function useFetchSensorList(sensorIds: string[]) {
  const queries = useQueries(
    sensorIds.map((sensorId) => {
      const variables: Variables = {
        id: sensorId
      }
      return {
        queryKey: [SENSOR_LIST_KEY, variables],
        queryFn: () => {
          return api.request<Response>({
            query,
            variables
          })
        }
      }
    })
  )

  const sensors = queries.reduce((prev, curr) => {
    const sensor = curr?.data?.data?.getSensor as Sensor
    if (sensor) {
      prev.push(sensor)
    }
    return prev
  }, [] as Sensor[])

  return {
    sensors,
    isLoading: reduceQueries(queries, 'isLoading'),
    isError: reduceQueries(queries, 'isError'),
    isFetching: reduceQueries(queries, 'isFetching')
  }
}

export { SENSOR_LIST_KEY, useFetchSensorList }
