import { useQueries, UseQueryResult } from 'react-query'
import { deviceByOmniDevice as query } from '../../../graphql/queries'
import {
  DeviceByOmniDeviceQueryVariables as VariablesType,
  DeviceByOmniDeviceQuery as QueryType,
  OmniDevice,
  Device
} from '../../../API'
import { api } from '../../gql-client'

const DEVICES_KEY = 'devices'

const getKey = (variables: VariablesType) => {
  return [DEVICES_KEY, variables]
}

const reduceQueries = (
  queries: UseQueryResult[],
  property: keyof UseQueryResult
) => queries.reduce((prev, next) => prev || !!next[property], false)

function useListDevices(omniDevices: OmniDevice[]) {
  const queries = useQueries(
    omniDevices.map((omniDevice) => {
      const variables = {
        omnideviceID: omniDevice.id
      }
      return {
        queryKey: getKey(variables),
        queryFn: () => {
          if (!variables.omnideviceID) {
            return
          }
          return api.request<QueryType>({
            query,
            variables
          })
        }
      }
    })
  )

  const devices = queries.reduce((prev, next) => {
    const devices = (next?.data?.data?.deviceByOmniDevice?.items ??
      []) as Device[]
    return prev.concat(devices)
  }, [] as Device[])

  return {
    devices,
    isLoading: reduceQueries(queries, 'isLoading'),
    isError: reduceQueries(queries, 'isError'),
    isFetching: reduceQueries(queries, 'isFetching')
  }
}

export { DEVICES_KEY, useListDevices }
