import { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Client } from '../../API'
import { useListClients } from '../../utils/hooks/clients'
import { clientForm } from '../../utils/FormData'
import ActionHeader from '../../components/ActionHeader'
import { AutoCompleteComp, ChangeFunc } from '../../components/AutoCompleteComp'
import { FormFields } from './FormFields'
import { useCreateClient } from './useCreateClient'
import { useUpdateClient } from './useUpateClient'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%'
  },
  content: {
    padding: theme.spacing(2)
  },
  selectContainer: {
    marginBottom: theme.spacing(2)
  }
}))

function ClientManager() {
  const classes = useStyles()
  const [selectedClient, setSelectedClient] = useState<string | null>(null)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const clientsQuery = useListClients()
  const createMutation = useCreateClient()
  const updateMutation = useUpdateClient()
  const clients = (clientsQuery.data?.data?.listClients?.items ??
    []) as Client[]
  const client = clients.find((client) => client.id === selectedClient) || null

  const getRenderedOption = useCallback(
    (test: string, prefix: string) => (option: any) =>
      (
        <div>
          {option[prefix] === test ? <em>{option[prefix]}</em> : option[prefix]}
        </div>
      ),
    []
  )

  const getOptionSelected = useCallback(
    (option: any, value: any) => option?.id === value?.id,
    []
  )

  const handleClientChange: ChangeFunc<Client> = (_, value) => {
    const { id } = value || { id: null, name: null }

    setSelectedClient(id)
  }

  const handleSave = async (data: Record<string, any>) => {
    const mutation = data.id ? updateMutation : createMutation

    try {
      await mutation.mutateAsync({
        // @ts-ignore
        input: data
      })
      setShowSuccess(true)
    } catch (res: any) {
      if (res.errors && res.errors.length) {
        setError(res.errors[0]?.message || 'Error creating client')
      }
    }
  }

  return (
    <div className={classes.container}>
      <ActionHeader name="Client Manager" />

      <div className={classes.content}>
        <div className={classes.selectContainer}>
          <AutoCompleteComp
            disableClearable={false}
            value={client}
            options={clients}
            getOptionLabel={(option: Client) => option.name || ''}
            getOptionSelected={getOptionSelected}
            handleChange={handleClientChange}
            label="Client"
            renderOption={getRenderedOption('New Client', 'name')}
            checkID={selectedClient}
          />
        </div>
        <FormFields
          key={selectedClient}
          title="Client"
          isLoading={updateMutation.isLoading || createMutation.isLoading}
          isError={false}
          fields={clientForm}
          data={client}
          onSave={handleSave}
        />
      </div>
      {showSuccess && (
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Success
        </Alert>
      )}
      {error && (
        <Alert onClose={() => setError(undefined)} severity="error">
          {error}
        </Alert>
      )}
    </div>
  )
}

export { ClientManager }
