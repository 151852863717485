import { FC, useState, useCallback } from 'react'
import { useHistory, Prompt } from 'react-router-dom'
import papa from 'papaparse'
import { Button } from '@material-ui/core'
import { DataGrid, GridRowData, GridValueGetterParams, GridEditRowsModel } from '@mui/x-data-grid'
import { makeStyles } from '@material-ui/styles'
import format from 'date-fns/format'
import { Sensor, Device, SensorReading, SensorIssue } from '../../API'
import { isTruthy } from '../../utils/is-truthy'
import { useSite } from '../../utils/hooks/appData'
import { useGlobalData } from '../../utils/hooks/global-data'
import { SensorDataFetcher } from './SensorDataFetcher'
import { UpdateSensorGroupDialog } from './UpdateSensorGroupDialog'
import { UpdateAlertRuleDialog } from './UpdateAlertRuleDialog'
import { useFetchAlertRules, useMutateSensor, useMutateSensorGroupJoin } from '../../utils/hooks'

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1.5
  },
  {
    field: 'sensorGroups',
    headerName: 'Sensor Groups',
    flex: 1.5,
    valueGetter: (params: GridValueGetterParams) => {
      return (params.value as Array<{ id: string, name: string }>)
        .map((v) => v.name).join(', ')
    }
  },
  {
    field: 'alertRule',
    headerName: 'Alert Rule',
    flex: 1.5,
    valueGetter: (params: GridValueGetterParams) => {
      return (params.value as { id: string, name: string } | null)?.name || ''
    }
  },
  {
    field: 'lastReading',
    headerName: 'Last Reading',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      const { Value = '', Unit = '' } = JSON.parse(params.value as string || '{}')
      const value = typeof Value === 'boolean'
        ? `${Value ? 'On' : 'Off'}`
        : `${Value} ${Unit || ''}`

      return value.trim() || '-'
    }
  },
  {
    field: 'min',
    headerName: 'Min',
    flex: 1
  },
  {
    field: 'max',
    headerName: 'Max',
    flex: 1
  },
  {
    field: 'avg',
    headerName: 'Average',
    flex: 1
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return format(new Date(params.value as string ?? new Date()), 'h:mm aa M/d/yyyy')
    }
  },
  {
    field: 'sortOrder',
    headerName: 'Order',
    flex: 1,
    editable: true
  }
]

const useStyles = makeStyles((theme: any) => ({
  deviceContainer: {
    padding: theme.spacing(2)
  },
  table: {
    background: '#ffffff'
  },
  hasOngoingIssues: {
    background: theme.palette.error.main
  },
  tableControlBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& > *': {
      marginLeft: theme.spacing(2)
    }
  }
}))

interface ISensorsProps {
  sensorsData: Sensor[]
  keyedDevices: { [ deviceID: string]: Device }
}

export const SensorsTableView: FC<ISensorsProps> = (props) => {
  const classes = useStyles()
  const { sensorsData, keyedDevices } = props
  const history = useHistory()
  const siteId = useSite()
  const [, , globalDataToSearchParams] = useGlobalData()
  const { mutateSensor } = useMutateSensor()
  const { mutateSensorGroupJoin, deleteSensorGroupJoin } = useMutateSensorGroupJoin()
  const { keyedAlertRules } = useFetchAlertRules(siteId)
  const [isSavingSensors, setIsSavingSensors] = useState(false)
  const [sensorEdits, setSensorEdits] = useState<Record<string, Partial<{
    sensorGroups: Array<{
      name: string,
      id: string,
    }>,
    alertruleID?: string | null
  }>>>({})
  const [
    isUpdateSensorGroupDialogOpen,
    setIsUpdateSensorGroupDialogOpen
  ] = useState(false)
  const [
    isUpdateAlertRuleDialogOpen,
    setIsUpdateAlertRuleDialogOpen
  ] = useState(false)
  const [focusedRow, setFocusedRow] = useState<any>(null)
  const [editRowsModel, setEditRowsModel] = useState<GridEditRowsModel>({})
  const [fetchedSensorData, setFetchedSensorData] = useState<Record<string, {
    readings: {
      min: number,
      max: number,
      avg: number,
      lastReading: SensorReading
    },
    issues: Array<SensorIssue>
  }>>({})
  const rows = sensorsData.map((sensor) => {
    const fetchedData = fetchedSensorData[sensor.id]
    const device = keyedDevices[sensor?.deviceID as string]

    if (!fetchedData || !device) {
      return null
    }

    const {
      sensorGroups: sensorGroupEdit,
      alertruleID: alertRuleIdEdit = sensor.alertruleID,
      ...sensorEdit
    } = sensorEdits[sensor.id] || {}
    return {
      id: sensor.id,
      name: sensor.name,
      deviceName: device.name,
      deviceId: device.id,
      omniDeviceId: device.omnideviceID,
      alertRule: alertRuleIdEdit ? keyedAlertRules[alertRuleIdEdit] : null,
      sensorGroups: sensorGroupEdit || sensor.sensorgroups?.items
        .filter((g) => !g?._deleted)
        .map((g) => ({
          id: g?.sensorgroup.id,
          name: g?.sensorgroup.name
        })),
      min: fetchedData.readings?.min,
      max: fetchedData.readings?.max,
      avg: fetchedData.readings?.avg,
      lastReading: fetchedData.readings?.lastReading?.readValue,
      updatedAt: fetchedData.readings?.lastReading?.updatedAt,
      sortOrder: sensor.sortOrder ?? 1,
      issueCount: fetchedData.issues.length,
      _version: sensor._version,
      ...sensorEdit
    }
  }).filter(isTruthy)

  const handleOnLoad = useCallback((sensorId, readings, issues) => {
    setFetchedSensorData((prev) => ({
      ...prev,
      [sensorId]: {
        readings,
        issues
      }
    }))
  }, [])

  const handleOpenUpdateSensorGroupDialog = (sensorId: string) => {
    const row = rows.find((r) => r.id === sensorId)
    if (!row) {
      return
    }

    setFocusedRow(row)
    setIsUpdateSensorGroupDialogOpen(true)
  }

  const handleCloseUpdateSensorGroupDialog = () => {
    setFocusedRow(null)
    setIsUpdateSensorGroupDialogOpen(false)
  }

  const handleOpenUpdateAlertRuleDialog = (sensorId: string) => {
    const row = rows.find((r) => r.id === sensorId)
    if (!row) {
      return
    }

    setFocusedRow(row)
    setIsUpdateAlertRuleDialogOpen(true)
  }

  const handleCloseUpdateAlertRuleDialog = () => {
    setFocusedRow(null)
    setIsUpdateAlertRuleDialogOpen(false)
  }

  const handleSensorChange = (id: string, edit: Record<string, any>) => {
    setSensorEdits((curr) => ({
      ...curr,
      [id]: {
        ...curr[id],
        ...edit
      }
    }))
  }

  const handleUpdateSensorGroups = (sensorId: string, sensorGroups: any[]) => {
    const sensor = sensorsData.find((s) => s.id === sensorId)
    if (!sensor) {
      return
    }

    handleSensorChange(sensorId, {
      sensorGroups,
      _version: sensor._version
    })
    handleCloseUpdateSensorGroupDialog()
  }

  const handleUpdateAlertRule = (sensorId: string, alertRuleId: string | null) => {
    const sensor = sensorsData.find((s) => s.id === sensorId)
    if (!sensor) {
      return
    }

    handleSensorChange(sensorId, {
      alertruleID: alertRuleId,
      _version: sensor._version
    })
    handleCloseUpdateAlertRuleDialog()
  }

  const handleEditRowsModelChange = useCallback(
    (newModel: GridEditRowsModel) => {
      const updatedModel = { ...newModel }
      Object.keys(updatedModel).forEach((id) => {
        if (updatedModel[id].sortOrder) {
          const parsedValue = parseInt(`${updatedModel[id].sortOrder.value || ''}`)
          const isValid = !Number.isNaN(parsedValue)
          updatedModel[id].sortOrder = {
            ...updatedModel[id].sortOrder,
            value: parsedValue,
            error: !isValid
          }
        }
      })
      setEditRowsModel(updatedModel)
    },
    []
  )

  const handleCellEditCommit = (commit: any) => {
    handleSensorChange(commit.id, {
      [commit.field]: commit.value,
      _version: rows.find((r) => r.id === commit.id)?._version
    })
  }

  const handleDeviceView = (row: GridRowData) => {
    history.push(`/dashboard/device/${row.deviceId}?${globalDataToSearchParams({}, {
      omniDevice: row.omniDeviceId,
      device: row.deviceId,
      sensor: row.id
    })}`)
  }

  const handleDownloadCsv = () => {
    const csv = papa.unparse(rows)
    const blob = new Blob([csv])
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = 'Sensors Download.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const handleSaveSensors = async () => {
    setIsSavingSensors(true)
    await Promise.all(Object.keys(sensorEdits).map((id) => {
      const sensor = sensorsData.find((s) => s.id === id)
      if (!sensor) {
        return Promise.resolve()
      }

      const { sensorGroups: sensorGroupEdits, ...edits } = sensorEdits[id]
      const sensorGroups = sensor?.sensorgroups?.items.filter((sg) => !sg?._deleted) || []
      // Don't attempt to remove any groups if there were no edits.
      const relationshipsToRemove = sensorGroupEdits
        ? sensorGroups.filter((relationship) => {
          return !sensorGroupEdits?.find((sg) => {
            return relationship?.sensorgroup.id === sg.id
          })
        }).filter(isTruthy)
        : []
      const sensorGroupsToAdd = sensorGroupEdits?.filter((sg) => {
        return !sensorGroups.find((relationship) => {
          return relationship?.sensorgroup.id === sg.id
        })
      }).map((sg) => sg.id) || []

      return Promise.all([
        mutateSensor({
          id,
          ...edits
        }),
        ...sensorGroupsToAdd.map((groupId) =>
          mutateSensorGroupJoin({
            sensorID: sensor.id,
            sensorgroupID: groupId
          })
        ),
        ...relationshipsToRemove.map((relationship) =>
          deleteSensorGroupJoin({
            id: relationship.id,
            _version: relationship._version
          })
        )
      ])
    }))

    setSensorEdits({})
    setIsSavingSensors(false)
  }

  return (
    <div className={classes.deviceContainer}>
      <Prompt
        when={Object.keys(sensorEdits).length > 0}
        message={() => 'You have unsaved changes. Are you sure you want to leave?'}
      />
      {sensorsData.map((sensor) => (
        <SensorDataFetcher
          key={sensor.id}
          sensor={sensor}
          onLoad={handleOnLoad}
        />
      ))}
      {isUpdateSensorGroupDialogOpen && focusedRow &&
        <UpdateSensorGroupDialog
          siteId={siteId}
          sensorId={focusedRow.id}
          sensorGroups={focusedRow.sensorGroups}
          handleClose={handleCloseUpdateSensorGroupDialog}
          onSelect={handleUpdateSensorGroups}
        />}
      {isUpdateAlertRuleDialogOpen && focusedRow &&
        <UpdateAlertRuleDialog
          siteId={siteId}
          sensorId={focusedRow.id}
          alertRuleId={focusedRow.alertRule?.id}
          handleClose={handleCloseUpdateAlertRuleDialog}
          onSelect={handleUpdateAlertRule}
        />}
      <div className={classes.tableControlBar}>
        <Button
          color="secondary"
          variant="contained"
          disabled={isSavingSensors}
          onClick={handleSaveSensors}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleDownloadCsv}
        >
          Download CSV
        </Button>
      </div>
      <DataGrid
        autoHeight
        className={classes.table}
        rows={rows}
        columns={columns}
        pageSize={20}
        editRowsModel={editRowsModel}
        getRowClassName={({ row }) => {
          return row.issueCount > 0 ? classes.hasOngoingIssues : ''
        }}
        onEditRowsModelChange={handleEditRowsModelChange}
        onCellEditCommit={handleCellEditCommit}
        onCellClick={(params) => {
          if (params.field === 'sensorGroups') {
            handleOpenUpdateSensorGroupDialog(params.row.id)
          } else if (params.field === 'alertRule') {
            handleOpenUpdateAlertRuleDialog(params.row.id)
          } else if (!params.isEditable) {
            handleDeviceView(params.row)
          }
        }}
      />
    </div>
  )
}
