import { useQuery } from 'react-query'
import { api } from '../../../utils/gql-client'
import {
  GetSensorGroupQuery as Response
} from '../../../API'
import { getSensorGroup as query } from '../../../graphql/queries'

const SENSOR_GROUP_KEY = 'sensor-group'

function useFetchSensorGroup(id: string | null = null) {
  return useQuery([SENSOR_GROUP_KEY, id], () => {
    if (!id) { return }

    return api.request<Response>({ query, variables: { id } })
  })
}

export { SENSOR_GROUP_KEY, useFetchSensorGroup }
