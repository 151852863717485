import { makeStyles } from '@material-ui/styles'
import ActionHeader from '../../components/ActionHeader'
import { GlobalDataSelection } from '../../components/GlobalDataSelection'
import {
  getSensorReadings,
  useFetchDevices,
  useFetchSites
} from '../../utils/hooks'
import papa from 'papaparse'

import {
  BySensorPingTypeAndTimeQueryVariables,
  OmniDevicePingType
} from '../../API'
import { Button, Card, Grid, TextField, Typography } from '@material-ui/core'
import { ChangeEvent, useCallback, useState } from 'react'
import formatISO from 'date-fns/formatISO'
import JSZip from 'jszip'
import { useClient, useSite } from '../../utils/hooks/appData'

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    paddingBottom: '20px',
    overflowY: 'auto'
  },
  selectContainer: {
    marginBottom: theme.spacing(2)
  },
  date: {
    margin: theme.spacing(0, 2),
    '& ::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    '& .MuiInputBase-root, .MuiInputLabel-root': {
      color: 'white'
    },
    '& .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before':
      {
        borderColor: 'rgba(255, 255, 255, 0.7)'
      }
  },
  sensorsContainer: {
    display: 'flex'
  },
  sensorListContainer: {
    margin: 50
  }
}))

function ExportDataManager() {
  const classes = useStyles()
  const { sensorsData } = useFetchDevices()
  const siteId = useSite()
  const clientId = useClient()
  const { keyedSites } = useFetchSites(clientId)
  const siteName = siteId && keyedSites[siteId]?.name
  console.log(siteName)
  const pingType: OmniDevicePingType = OmniDevicePingType.SCHEDULED
  const [month, setMonth] = useState(new Date())

  function getStartOfNextMonth(currentMonth: Date) {
    let year = currentMonth.getFullYear()
    let month = currentMonth.getMonth() + 1
    if (month > 11) {
      year += 1
      month = 0
    }

    return new Date(year, month, 1, 1, 0, 0, 0)
  }

  function getStartOfThisMonth(currentMonth: Date) {
    let year = currentMonth.getFullYear()
    let month = currentMonth.getMonth()
    if (month > 11) {
      year += 1
      month = 0
    }

    return new Date(year, month, 1, 1, 0, 0, 0)
  }
  const getDataForSensor = (sensorID: string) => {
    const between = [
      {
        pingType,
        readTime: Math.floor(getStartOfThisMonth(month).getTime() / 1000)
      },
      {
        pingType,
        readTime: Math.floor(new Date().getTime() / 1000)
      }
    ]

    const variables: BySensorPingTypeAndTimeQueryVariables = {
      sensorID,
      pingTypeReadTime: { between },
      limit: 1000000
    }
    return getSensorReadings(variables)
  }
  const getDataForAllSensors = async () => {
    const csvs = await Promise.all(
      sensorsData.map(async (sensor) => {
        const gqlResult = await getDataForSensor(sensor.id)
        const data: any = gqlResult?.data?.bySensorPingTypeAndTime?.items ?? []

        for (const row of data) {
          row['Site Name'] = siteName
          row['Read Value'] = JSON.parse(row.readValue).Value
          row['Date Time'] = formatDate(new Date(row.readTime * 1000))
        }

        return getCsvFromData(data)
      })
    )
    const zipContent = await createZipArchive(csvs)
    downloadZip(zipContent)
  }

  const getCsvFromData = (rows: any[]) => {
    const csv = papa.unparse(rows)
    return csv
  }

  async function createZipArchive(csvFiles: string[]) {
    const zip = new JSZip()
    function fileformatDate(date: Date) {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      const monthIndex = date.getMonth()
      const year = date.getFullYear()

      return `${monthNames[monthIndex]}${year}`
    }
    // Add each CSV file to the zip
    csvFiles.forEach((csvFile, index) => {
      zip.file(
        `SensorReadings-${sensorsData[index].name}-${fileformatDate(
          month
        )}to${fileformatDate(new Date())}.csv`,
        csvFile
      )
    })

    // Generate the zip file
    const content = await zip.generateAsync({ type: 'blob' })
    return content
  }

  function downloadZip(zipContent: Blob): void {
    const url = URL.createObjectURL(zipContent)
    const a = document.createElement('a')
    a.href = url
    a.download = 'SensorReadings.zip'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const handleDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = e
    console.log(value)
    if (value) {
      const newMonth = new Date(value)
      setMonth(getStartOfNextMonth(newMonth))
    } else {
      setMonth(new Date())
    }
  }, [])

  return (
    <>
      <div className={classes.container}>
        <ActionHeader name="Export Data Manager" />
        <GlobalDataSelection selectors={['sensorGroup']} />

        {renderSensorList()}

        <TextField
          label="From"
          name="from"
          type="month"
          value={formatISO(month).slice(0, 7)}
          onChange={handleDateChange}
          classes={{ root: classes.date }}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            inputProps: {
              max: formatISO(new Date()).slice(0, 7)
            }
          }}
        />

        <Button
          variant="contained"
          // disabled={isLoading}
          color="secondary"
          // classes={{ root: classes.button }}
          onClick={getDataForAllSensors}
        >
          Download Zip
        </Button>
      </div>
    </>
  )

  function renderSensorList() {
    const limitedSensorData = sensorsData.slice(0, 24)

    return (
      <>
        <div className={classes.sensorListContainer}>
          <Typography variant="h5">Sensors</Typography>
          {sensorsData && (
            <Typography variant="subtitle1">
              Showing {limitedSensorData.length} sensors out of{' '}
              {sensorsData.length}{' '}
            </Typography>
          )}

          <div className={classes.sensorsContainer}>
            <Grid container>
              {sensorsData &&
                sensorsData.map((sensor) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={sensor.name}>
                    <Card style={{ width: 200, margin: 3, padding: 3 }}>
                      {sensor.name}
                    </Card>
                  </Grid>
                ))}
            </Grid>
            {!sensorsData && (
              <Typography variant="subtitle1"> No Sensors Selected </Typography>
            )}
          </div>
        </div>
      </>
    )
  }
}

const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return formattedDate
}

export { ExportDataManager }
