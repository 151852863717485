import { FC, useMemo } from 'react'
import { Dictionary } from 'lodash'
import { format } from 'date-fns'
import { GridColDef } from '@mui/x-data-grid'
// Local Components
import TableView from '../TableView'
import { useFetchSensorIssues } from '../../utils/hooks'
import { useDates } from '../../utils'
import { Sensor, Site } from '../../API'

interface IIssues {
  site: Site | null
  sensors: Sensor[]
  keyedSensors: Dictionary<Sensor>
}

export const Issues: FC<IIssues> = (props) => {
  const { site, sensors, keyedSensors } = props
  const { from, to } = useDates()
  const { issuesData, isLoading, isError } = useFetchSensorIssues(
    sensors,
    {
      lastUpdateTime: { between: [from, to] }
    }
  )

  const columns = useMemo(() => renderColumns(keyedSensors), [keyedSensors])
  const data = issuesData.map((issue) => ({
    ...issue,
    sensorName: issue.sensorID ? keyedSensors[issue.sensorID].name : '',
    lastUpdateTime: issue.lastUpdateTime
      ? format(new Date((issue.lastUpdateTime) * 1000), 'M/dd/yyyy @ hh:mma')
      : ''
  }))

  return (
    <TableView
      data={data}
      isLoading={isLoading}
      isError={isError}
      columns={columns}
      emptyMessage="No data found for the selected dates"
      csvFileName={`${site?.name}-issues`}
    />
  )
}

const renderColumns = (keyedSensors: Dictionary<Sensor>): GridColDef[] => ([
  {
    field: 'state',
    headerName: 'State',
    flex: 0.8
  },
  {
    field: 'sensorName',
    headerName: 'Sensor',
    flex: 1
  },
  {
    field: 'notes',
    headerName: 'Notes',
    flex: 1
  },
  {
    field: 'lastUpdateTime',
    headerName: 'Last Updated',
    flex: 1
  }
])
