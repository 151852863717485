import { FC, useState } from 'react'
import { useFetchDevices } from '../../utils/hooks/useFetchDevices'
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid'
import { makeStyles } from '@material-ui/styles'
import { Site } from '../../API'
import { TextDialog } from './TextDialog'
import { useMutateSensor } from '../../utils/hooks'
import { NumberDialog } from './NumberDialog'
import { useQueryClient } from 'react-query'

interface EnergyUsageProps {
  site: Site | null
}

const useStyles = makeStyles((theme: any) => ({
  table: {
    background: '#ffffff'
  }
}))

const EnergyUsage: FC<EnergyUsageProps> = ({ site }) => {
  const {
    keyedSensors,
    sensorsData
  } = useFetchDevices({})

  const classes = useStyles()
  const [showEquationDialog, setShowEquationDialog] = useState<Boolean>(false)
  const [showFullLoadDialog, setShowFullLoadDialog] = useState<Boolean>(false)
  const [focusedRow, setFocusedRow] = useState<any>(null)
  const { mutateSensor } = useMutateSensor()
  const queryClient = useQueryClient()

  const updateSensorEquation = (
    id: string,
    _version: number,
    energyEquation: string
  ) => {
    return mutateSensor({ id, _version, energyEquation })
  }

  const updateSensorFullLoad = (
    id: string,
    _version: number,
    fullLoadKw: number
  ) => {
    return mutateSensor({ id, _version, fullLoadKw })
  }
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'recentAverage',
      headerName: 'Recent Average',
      flex: 1
    },
    {
      field: 'energyEquation',
      headerName: 'Equation',
      flex: 1
    },
    {
      field: 'fullLoadKw',
      headerName: 'Full Load kW',
      flex: 1
    },
    {
      field: 'last24HrUsage',
      headerName: 'Last 24 Hour Usage',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        const decimal = params.row.recentAverage * 24 * params.row.fullLoadKw
        if (params.row.energyEquation === 'decimal') {
          return Number(decimal.toFixed(2))
        }
        if (params.row.energyEquation === 'percent') {
          return Number((decimal / 100).toFixed(2))
        }
        return 'NA'
      }
    }
  ]

  const rows = sensorsData.map((sensor) => {
    return {
      id: sensor.id,
      name: sensor.name,
      recentAverage: sensor.avgRecent?.toFixed(3) ?? sensor.avg,
      energyEquation: sensor.energyEquation,
      fullLoadKw: sensor.fullLoadKw
    }
  })
  console.log(rows)

  const handleEquationSelect = (sensorId: string, equationType: string) => {
    updateSensorEquation(
      sensorId,
      keyedSensors[sensorId]._version,
      equationType
    ).then(() => {
      queryClient.invalidateQueries('all-sensors')
    })
  }

  const handleFullLoadSelect = (sensorId: string, fullLoad: number) => {
    updateSensorFullLoad(
      sensorId,
      keyedSensors[sensorId]._version,
      fullLoad
    ).then(() => {
      queryClient.invalidateQueries('all-sensors')
    })
  }

  const setSensorRow = (sensorId: string) => {
    const row = rows.find((r) => r.id === sensorId)
    if (!row) {
      return
    }

    setFocusedRow(row)
  }

  return (
    <>
      {showEquationDialog && (
        <TextDialog
          sensorId={focusedRow.id}
          handleClose={() => setShowEquationDialog(false)}
          onSelect={handleEquationSelect}
        />
      )}
      {showFullLoadDialog && (
        <NumberDialog
          sensorId={focusedRow.id}
          handleClose={() => setShowFullLoadDialog(false)}
          onSelect={handleFullLoadSelect}
        />
      )}
      <DataGrid
        autoHeight
        className={classes.table}
        rows={rows}
        columns={columns}
        pageSize={20}
        onCellClick={(params) => {
          if (params.field === 'energyEquation') {
            setSensorRow(params.row.id)
            setShowEquationDialog(true)
          } else if (params.field === 'fullLoadKw') {
            setSensorRow(params.row.id)
            setShowFullLoadDialog(true)
          }
        }}
      />
    </>
  )
}

export default EnergyUsage
