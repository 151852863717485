import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const GLOBAL_URL_KEYS = {
  client: 'gClient',
  site: 'gSite',
  device: 'gDevice',
  sensorGroup: 'gSensorGroup',
  sensor: 'gSensor',
  omniDevice: 'gOmniDevice'
} as const

type GlobalData = {
  client: string | null,
  site: string | null,
  device: string | null,
  sensorGroup: string | null,
  sensor: string | null,
  omniDevice: string | null
}

function useGlobalData() {
  const history = useHistory()
  const location = useLocation()

  const data: GlobalData = React.useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return Object.entries(GLOBAL_URL_KEYS).reduce((acc, [key, value]) => {
      acc[key as keyof GlobalData] = searchParams.get(value)
      return acc
    }, {} as GlobalData)
  }, [location.search])

  const toSearchParams = React.useCallback((initParams: Record<string, string> = {}, nextData: Partial<GlobalData> = {}) => {
    const searchParams = new URLSearchParams(initParams)
    Object.entries({ ...data, ...nextData }).forEach(([key, value]) => {
      if (value) {
        searchParams.set(GLOBAL_URL_KEYS[key as keyof GlobalData], value)
      }
    })

    return searchParams.toString()
  }, [data])

  const setData = React.useCallback((nextData: Partial<GlobalData>) => {
    const nextParams = new URLSearchParams(location.search)
    Object.entries(nextData).forEach(([key, value]) => {
      nextParams.set(GLOBAL_URL_KEYS[key as keyof GlobalData], value || '')
    })
    history.push({ search: nextParams.toString() })
  }, [history, location])

  return [data, setData, toSearchParams] as const
}

export { useGlobalData }
