import { ChangeEvent, FC, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { isTruthy } from '../../utils/is-truthy'
import { convert24Hourto12Hour } from '../../utils/format-time'
import { useSite, useSensorGroup } from '../../utils/hooks/appData'
import { useGlobalData } from '../../utils/hooks/global-data'
import ActionHeader from '../../components/ActionHeader'
import NavBar from '../../components/Navbar'
import { GlobalDataSelection } from '../../components/GlobalDataSelection'
import { CreateReportTemplateDialog } from './CreateReportTemplateDialog'
import { useFetchTemplateList } from './useFetchTemplateList'
import { useFetchSensorGroup } from '../../utils/hooks/sensorGroups/useFetchSensorGroup'
import { useUpdateTemplate } from './useUpdateTemplate'

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.primary.main
  },
  nav: {
    flex: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  },
  toolbarControls: {
    marginLeft: 'auto'
  },
  tableContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    paddingTop: 0
  },
  reportLink: {
    color: 'inherit'
  }
}))

type ReportTemplateRowProps = {
  template: Partial<{
    id: string | null,
    name: string | null,
    siteID: string | null,
    sensorgroupID: string | null,
    times: Array<string | null> | null,
    isActive: boolean | null,
    _version: number | null
  }>
}

const ReportTemplateRow: FC<ReportTemplateRowProps> = ({ template }) => {
  const classes = useStyles()
  const [, , globalDataToSearchParams] = useGlobalData()
  const { data: sensorGroup } = useFetchSensorGroup(template.sensorgroupID)
  const updateTemplate = useUpdateTemplate()

  const handleUpdateActiveStatus = (e: ChangeEvent<HTMLInputElement>) => {
    if (!template?.id) { return }

    updateTemplate.mutate({
      input: {
        id: template.id,
        isActive: e.target.checked,
        _version: template._version
      }
    })
  }

  return (
    <TableRow key={template.id}>
      <TableCell component="th" scope="row">
        <Link
          className={classes.reportLink}
          to={`/reports/${template.id}?${globalDataToSearchParams()}`}
        >
          {template.name}
        </Link>
      </TableCell>
      <TableCell>{sensorGroup?.data?.getSensorGroup?.name}</TableCell>
      <TableCell>{convert24Hourto12Hour(template?.times?.[0])}</TableCell>
      <TableCell>{convert24Hourto12Hour(template?.times?.[1])}</TableCell>
      <TableCell>
        <Switch
          checked={!!template.isActive}
          onChange={handleUpdateActiveStatus}
          color="primary"
          disabled={updateTemplate.isLoading}
        />
      </TableCell>
    </TableRow>
  )
}

const ReportList: FC = () => {
  const classes = useStyles()
  const siteId = useSite()
  const sensorGroupId = useSensorGroup()
  const { data: reportTemplatesRes } = useFetchTemplateList()
  const [isShowInactive, setIsShowInactive] = useState(false)
  const [isCreateTemplateDialogOpen, setIsCreateTemplateDialogOpen] = useState(false)
  const reportTemplates = reportTemplatesRes?.data?.listReportTemplates?.items || []
  const filteredReportTemplates = reportTemplates.filter(isTruthy).filter((t) => {
    if (sensorGroupId) {
      return t.sensorgroupID === sensorGroupId
    } else if (siteId) {
      return t.siteID === siteId
    } else {
      return true
    }
  }).filter((t) => t.isActive || (isShowInactive && !t.isActive))

  const handleShowInactive = (e: ChangeEvent<HTMLInputElement>) => {
    setIsShowInactive(e.target.checked)
  }

  const handleOpenCreateTemplateDialog = () => {
    setIsCreateTemplateDialogOpen(true)
  }

  const handleCloseCreateTemplateDialog = () => {
    setIsCreateTemplateDialogOpen(false)
  }

  const handleSaveTemplate = () => {
    handleCloseCreateTemplateDialog()
  }

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <NavBar />
      </div>
      <div className={classes.content}>
        <ActionHeader name="Report Templates" />

        <GlobalDataSelection selectors={['sensorGroup']} />

        <div className={classes.toolbar}>
          <div className={classes.toolbarControls}>
            <FormControlLabel
              label="Show Inactive"
              control={
                <Switch
                  checked={isShowInactive}
                  onChange={handleShowInactive}
                />
              }
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOpenCreateTemplateDialog}
            >
              Create Template
            </Button>
          </div>
        </div>
        {isCreateTemplateDialogOpen &&
          <CreateReportTemplateDialog
            onCreate={handleSaveTemplate}
            onClose={handleCloseCreateTemplateDialog}
          />}
        <div className={classes.tableContainer}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Sensor Group</TableCell>
                  <TableCell>Time A</TableCell>
                  <TableCell>Time B</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredReportTemplates.map((template) => (
                  <ReportTemplateRow key={template.id} template={template} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  )
}

export { ReportList }
