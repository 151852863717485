import cx from 'classnames'
import { Typography } from '@material-ui/core'
import { SensorReading } from '../../API'

interface Props {
  lastReading: SensorReading
  classes: any
  min: number
  max: number
  avg: number
}

const SensorContent = (props: Props) => {
  const { lastReading, min, max, avg, classes } = props
  const reading = JSON.parse(lastReading?.readValue as string)
  const { Unit, WatchName, Value } = reading

  const isOnOffVal = typeof Value === 'boolean'
  const valueToDisplay = isOnOffVal ? `${Value ? 'On' : 'Off'}` : `${Value} ${Unit || ''}`

  const renderDetailsFor = (dataPoint: number, text: string) => {
    let dataPointToRender: string | number = dataPoint
    if (isOnOffVal) {
      dataPointToRender = dataPoint ? 'On' : 'Off'
    }
    return (
      <div className={classes.sensorReading}>
        <Typography classes={{ root: classes.title }}>{text}</Typography>
        <Typography className={cx(classes.sensorValue, classes.smallValue)}>
          {dataPointToRender}
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.sensorReading}>
      <Typography classes={{ root: classes.title }}>Last Reading</Typography>
      <Typography className={classes.sensorValue}>
        {valueToDisplay}
      </Typography>
      {!Unit && WatchName && (
        <Typography className={cx(classes.sensorValue, classes.smallValue)}>{WatchName}</Typography>
      )}

      <Typography className={classes.subTitle}>24hr Details</Typography>
      <div className={classes.lastDayDetails}>
        {renderDetailsFor(min, 'Min')}
        {renderDetailsFor(max, 'Max')}
        {renderDetailsFor(avg, 'Average')}
      </div>
    </div>
  )
}

export default SensorContent
