/* eslint-disable camelcase */
import { FC, useState, useCallback, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import { Alert, Autocomplete, AutocompleteGetTagProps } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import MuiPhoneNumber from 'material-ui-phone-number'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { isEmpty } from 'lodash'
import { Client, MutateUserInput, User } from '../../API'
import { ReduxState } from '../../redux'
import { useFetchSites } from '../../utils/hooks'
import { useListClients } from '../../utils/hooks/clients'

const useStyles = makeStyles((theme: any) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '45vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  contentContainer: {
    marginTop: theme.spacing(2)
  },
  textField: {
    background: 'white',
    margin: theme.spacing(1)
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 800
  },
  fieldContainer: {
    display: 'flex'
  },
  select: {
    width: '100%',
    color: 'white',
    margin: theme.spacing(1, 1, 3, 1),
    paddingRight: 16,
    '& .MuiInput-root': {
      minHeight: 50
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  },
  client: {
    paddingRight: 0
  },
  chip: {
    background: 'white',
    margin: theme.spacing(1, 0.5)
  },
  error: {
    '& .MuiInput-underline:after': {
      borderColor: 'red'
    }
  }
}))

interface Props {
  isEdit: boolean
  data: User
  handleClose: () => void
  onSave: (data: MutateUserInput) => void
  isLoading: boolean
  isError: boolean
}

interface FormEntry<T = string> {
  value: T | null | undefined
  hasError: boolean
}

interface FormData {
  username: FormEntry
  email: FormEntry
  given_name: FormEntry
  family_name: FormEntry
  phone_number: FormEntry
  permission: FormEntry<PermissionLevel>
  client: FormEntry
  sites: FormEntry<string[]>
  isAdmin: Omit<FormEntry<boolean>, 'hasError'>
  email_alerts: Omit<FormEntry<boolean>, 'hasError'>
  sms_alerts: Omit<FormEntry<boolean>, 'hasError'>
}

const SUPER_ADMIN = 'superAdmin'
const CLIENT_ADMIN = 'clientAdmin'
const CLIENT_USER = 'clientUser'
const SITE_ADMIN = 'siteAdmin'
const SITE_USER = 'siteUser'
const NONE = 'none'
const PERMISSION_HIERARCHY = [
  SUPER_ADMIN,
  CLIENT_ADMIN,
  CLIENT_USER,
  SITE_ADMIN,
  SITE_USER,
  NONE
]

type PermissionLevel =
  | typeof SUPER_ADMIN
  | typeof CLIENT_ADMIN
  | typeof CLIENT_USER
  | typeof SITE_ADMIN
  | typeof SITE_USER
  | typeof NONE

function canCreateRole(target: PermissionLevel, source: string[]) {
  if (source.includes(SUPER_ADMIN)) {
    return true
  }

  const targetIdx = PERMISSION_HIERARCHY.findIndex((p) => p === target)
  const sourceIdx = Math.min(...source
    .map((perm) => PERMISSION_HIERARCHY.findIndex((p) => p === perm))
  )

  return sourceIdx < targetIdx
}

// The only users that will see this page are admins
const getPermission = (user?: User) => {
  if (user?.SiteID) {
    return SITE_USER
  }
  if (user?.ClientID) {
    return CLIENT_USER
  }
  if (user?.IsAdmin) {
    return SUPER_ADMIN
  }
  return NONE
}

// TODO(nwestman): consider encapsulating formData, this is some unweildy state management
const userToFormData = (user: User, isEdit: boolean): FormData => {
  const username = user?.Username
  const email = user?.Email
  const given_name = user?.Name?.split(' ')[0]
  const family_name = user?.Name?.split(' ')[1]
  const phone_number = user?.Phone
  const permission = getPermission(user)
  const client = user?.ClientID
  const sites = user?.SiteID as any as string[]
  const isAdmin = user?.IsAdmin
  const sms_alerts = user?.SmsAlerts
  const email_alerts = user?.EmailAlerts

  const shouldRenderClients =
    permission === SITE_USER || permission === CLIENT_USER
  const shouldRenderSites = permission === SITE_USER

  return {
    username: { value: username || '', hasError: isEdit && username === '' },
    email: { value: email, hasError: email === '' },
    given_name: { value: given_name, hasError: given_name === '' },
    family_name: { value: family_name, hasError: family_name === '' },
    phone_number: {
      value: phone_number,
      hasError: isEdit && phone_number === ''
    },
    isAdmin: { value: isAdmin },
    sms_alerts: { value: sms_alerts === true },
    email_alerts: { value: email_alerts !== false },
    permission: { value: permission, hasError: !permission },
    client: { value: client, hasError: shouldRenderClients && !client },
    sites: { value: sites, hasError: shouldRenderSites && isEmpty(sites) }
  }
}

export const UserForm: FC<Props> = (props) => {
  const classes = useStyles()

  const { isEdit, handleClose, onSave, isLoading, isError, data } = props
  const userInfo = useSelector((state: ReduxState) => state.userInfo)
  const [formData, setFormData] = useState(userToFormData(data, isEdit))

  const { given_name, family_name, email, phone_number, permission } = formData

  const updateFormData = useCallback((key: keyof FormData, value: any) => {
    // Update
    formData[key]!.value = value

    const username = formData.username.value
    const email = formData.email.value
    const given_name = formData.given_name.value
    const family_name = formData.family_name.value
    const phone_number = formData.phone_number.value
    const permission = formData.permission.value
    const client = formData.client.value
    const sites = formData.sites.value
    const isAdmin = formData.isAdmin.value
    const sms_alerts = formData.sms_alerts.value
    const email_alerts = formData.email_alerts.value

    const shouldRenderClients =
      permission === SITE_USER || permission === CLIENT_USER
    const shouldRenderSites = permission === SITE_USER

    setFormData({
      username: { value: username || '', hasError: isEdit && username === '' },
      email: { value: email, hasError: email === '' },
      given_name: { value: given_name, hasError: given_name === '' },
      family_name: { value: family_name, hasError: family_name === '' },
      phone_number: {
        value: phone_number,
        hasError: isEdit && phone_number === ''
      },
      isAdmin: { value: isAdmin },
      sms_alerts: { value: !!sms_alerts },
      email_alerts: { value: !!email_alerts },
      permission: { value: permission, hasError: !permission },
      client: { value: client, hasError: shouldRenderClients && !client },
      sites: { value: sites, hasError: shouldRenderSites && isEmpty(sites) }
    })
  }, [formData, isEdit])

  const handlePhoneChange = useCallback(
    (value) => {
      updateFormData('phone_number', value.replace(/[()-\s]/g, ''))
    },
    [updateFormData]
  )

  const handleChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) => {
      const {
        target: { name = '', value }
      } = e
      updateFormData(name as keyof FormData, value)
    },
    [updateFormData]
  )

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateFormData('isAdmin', checked)
    },
    [updateFormData]
  )

  const handleTextAlertsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateFormData('sms_alerts', checked)
    },
    [updateFormData]
  )

  const handleEmailAlertsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateFormData('email_alerts', checked)
    },
    [updateFormData]
  )

  const handleSave = useCallback(() => {
    const userData: MutateUserInput = {
      name: `${formData.given_name?.value} ${formData.family_name?.value}`,
      firstName: formData.given_name?.value,
      lastName: formData.family_name?.value,
      email: formData.email?.value?.toLocaleLowerCase(),
      ...(isEdit ? { phone: formData.phone_number?.value } : {}),
      username: isEdit ? data.Username : formData.email?.value?.toLocaleLowerCase() || '',
      isAdmin: formData.permission?.value === SUPER_ADMIN || formData.isAdmin?.value,
      emailAlerts: formData.email_alerts.value,
      smsAlerts: formData.sms_alerts.value,
      clientID: formData.client?.value,
      siteID: formData.sites?.value
    }
    onSave(userData)
  }, [onSave, formData, isEdit, data])

  const submitIsDisabled =
    isLoading || Object.values(formData).some((value) => value.hasError)

  const shouldRenderClients = formData.permission.value === CLIENT_USER ||
    formData.permission.value === SITE_USER

  const clientsQuery = useListClients()
  const clients = (clientsQuery.data?.data?.listClients?.items ?? []) as Client[]

  const renderClient = (client: Client) => {
    return <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
  }

  const renderClients = () => {
    if (!shouldRenderClients) {
      return null
    }
    return (
      <Select
        name="client"
        onChange={handleChange}
        value={formData.client.value || ''}
      >
        {clients.map(renderClient)}
      </Select>
    )
  }

  const shouldRenderSites = formData.permission.value === SITE_ADMIN ||
    formData.permission.value === SITE_USER

  const { keyedSites } = useFetchSites(formData.client.value)

  const onSitesChange = useCallback((e, value: string[]) => {
    const filteredValues = value.filter((site) => !!keyedSites[site])
    updateFormData('sites', filteredValues)
  }, [updateFormData, keyedSites])

  const renderTags = (
    tagValue: string[],
    getTagProps: AutocompleteGetTagProps
  ) => {
    return tagValue.map((option: string, index: number) => {
      const site = keyedSites[option]
      if (!site) {
        return null
      }
      return <Chip key={site.id} label={site.name} {...getTagProps({ index })} />
    })
  }

  const renderSites = () => {
    if (!shouldRenderSites) {
      return null
    }
    return (
      <Autocomplete
        multiple
        id="sites"
        value={formData.sites.value ?? []}
        onChange={onSitesChange}
        options={Object.keys(keyedSites)}
        getOptionLabel={(option) => keyedSites[option]?.name || ''}
        renderTags={renderTags}
        renderInput={(params) => (
          <TextField {...params} label="Sites" placeholder="Sites" />
        )}
      />
    )
  }

  const renderIsAdminCheckbox = () => {
    if (!shouldRenderClients && !shouldRenderSites) {
      return null
    }

    if (formData.permission.value === CLIENT_USER &&
        !canCreateRole(CLIENT_ADMIN, userInfo.groups)
    ) {
      return null
    }

    if (formData.permission.value === SITE_USER &&
      !canCreateRole(SITE_ADMIN, userInfo.groups)
    ) {
      return null
    }

    return (
      <FormControlLabel
        control={<Checkbox checked={!!formData.isAdmin.value} onChange={handleCheckboxChange} />}
        label="Has Admin privileges"
      />
    )
  }

  const renderContactPreferences = () => {
    return (
      <>
        <div className={classes.fieldContainer}>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Checkbox checked={!!formData.email_alerts.value} name={'email_alerts'} onChange={handleEmailAlertsChange} />
              }
              label={
                <div>
                  Email Alerts
                </div>
              }
            />
          </FormControl>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Checkbox checked={!!formData.sms_alerts.value} name={'sms_alerts'} onChange={handleTextAlertsChange} />
              }
              label={
                <div>
                  Text Alerts
                </div>
              }
            />
          </FormControl>
        </div>
      </>
    )
  }

  const renderPermissions = () => {
    return (
      <>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="gender"
            name="permission"
            onChange={handleChange}
            value={permission.value || ''}
          >
            {canCreateRole(SUPER_ADMIN, userInfo.groups) &&
              <FormControlLabel
                value={SUPER_ADMIN}
                control={<Radio />}
                label="Super Admin"
              />}
            {canCreateRole(CLIENT_USER, userInfo.groups) &&
              <FormControlLabel
                value={CLIENT_USER}
                control={<Radio />}
                label="Client User"
              />}
            {canCreateRole(SITE_USER, userInfo.groups) &&
              <FormControlLabel
                value={SITE_USER}
                control={<Radio />}
                label="Site User"
              />}
          </RadioGroup>
          {renderIsAdminCheckbox()}
          {renderClients()}
          {renderSites()}
        </FormControl>
      </>
    )
  }

  return (
    <Dialog open onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>
          {isEdit ? 'Edit User' : 'New User'}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.contentContainer}>
        {isError && (
          <Alert severity="error">
            There was a problem saving this user. If this persists, please
            contact support.
          </Alert>
        )}

        <Typography variant="h6">Contact Information</Typography>

        <div className={classes.fieldContainer}>
          <TextField
            required
            fullWidth
            size="small"
            variant="filled"
            name="given_name"
            label="First Name"
            id="user-firstName"
            disabled={isLoading}
            onChange={handleChange}
            value={given_name.value || ''}
            classes={{ root: classes.textField }}
            error={given_name?.hasError}
          />
          <TextField
            required
            fullWidth
            size="small"
            variant="filled"
            name="family_name"
            label="Last Name"
            id="user-lastName"
            disabled={isLoading}
            onChange={handleChange}
            value={family_name.value || ''}
            classes={{ root: classes.textField }}
            error={family_name?.hasError}
          />
        </div>
        <div className={classes.fieldContainer}>
          <TextField
            required
            fullWidth
            size="small"
            type="email"
            variant="filled"
            name="email"
            label="Email"
            id="user-email"
            disabled={isLoading || isEdit}
            onChange={handleChange}
            value={email.value || ''}
            classes={{ root: classes.textField }}
            error={email?.hasError}
          />
          {isEdit && (
            <MuiPhoneNumber
              fullWidth
              required
              size="small"
              name="phone"
              variant="filled"
              defaultCountry="us"
              label="Phone Number"
              disabled={isLoading}
              onChange={handlePhoneChange}
              value={phone_number.value || ''}
              className={classes.textField}
              error={phone_number?.hasError}
            />
          )}
        </div>

        <Typography variant="h6">Permissions</Typography>
        {renderPermissions()}

        <Typography variant="h6">Alert Preferences</Typography>
        {renderContactPreferences()}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          disabled={submitIsDisabled}
          onClick={handleSave}
          classes={{ root: classes.save }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
