import { IFormData } from './sharedInterfaces'

export const omniDeviceForm: IFormData[] = [
  {
    name: 'deviceName',
    text: 'Device Name',
    required: true
  },
  {
    name: 'ipAddress',
    text: 'Hostname',
    required: true
  },
  {
    name: 'port',
    text: 'Port',
    type: 'number',
    required: true
  }
]

export const deviceForm = [
  {
    name: 'name',
    text: 'Name',
    required: true
  }
]

export const sensorGroupForm = [
  {
    name: 'name',
    text: 'Name',
    required: true
  }
]

export const siteForm: IFormData[] = [
  {
    name: 'name',
    text: 'Name',
    required: true
  },
  {
    name: 'timezone',
    text: 'Time Zone',
    required: true,
    type: 'timezone'
  }
]

export const clientForm = [
  {
    name: 'name',
    text: 'Name',
    required: true
  },
  {
    name: 'address',
    text: 'Address'
  },
  {
    name: 'email',
    text: 'Email',
    required: true
  },
  {
    name: 'website',
    text: 'Website',
    helperText: 'Must begin with http:// or https://',
    test(value: string) {
      if (!value) return false
      return !/^http(s)?:\/\//.test(value)
    }
  },
  {
    name: 'phone',
    text: 'Phone',
    required: true
  }
]
