import { useQueryClient, useMutation } from 'react-query'
import { Auth } from '@aws-amplify/auth'
import { updateUserContactPreferences } from '../../../graphql/mutations'
import { CURRENT_USER_KEY, USER_LIST_KEY } from '.'
import {
  UpdateUserContactPreferencesMutationVariables as Variables,
  UpdateUserContactPreferencesMutation as Response
} from '../../../API'
import { api } from '../../gql-client'
import { useDispatch } from 'react-redux'
import { updateUserInfo } from '../../../redux'

export const useUpdateUserContactPreferences = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    (variables: Variables) => {
      return api.request<Response>({
        query: updateUserContactPreferences,
        variables
      })
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(USER_LIST_KEY)
        queryClient.invalidateQueries(CURRENT_USER_KEY)

        const newUser = await Auth.currentAuthenticatedUser({
          bypassCache: true
        })
        dispatch(updateUserInfo(newUser))
      }
    }
  )
}
