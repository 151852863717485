import { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import { useQueryClient } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
// Local Utils
import { MutateUserInput, User } from '../../API'
import {
  useMutateUser,
  useCreateUser,
  useDeleteUser,
  useFetchCurrentUser,
  useFetchUserList,
  USER_LIST_KEY,
  useResetPassword
} from '../../utils/hooks'
import Navbar from '../../components/Navbar'
import ActionHeader from '../../components/ActionHeader'
import Loading from '../../components/Loading'
import AlertComp from '../../components/AlertComp'
import { UserOveriew, UserForm } from '../../components/Users'
import DeleteDialog from '../../components/DeleteDialog'
import { ReduxState } from '../../redux'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import { userResetStatus } from '../../components/Users/constants'
import { UserSelection } from './UserSelection'
import { UserAccountContactPreference } from '../../models'
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%'
  },
  nav: {
    flex: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main
  },
  selectionContainer: {
    padding: theme.spacing(1.5)
  },
  resetBtn: {
    marginTop: theme.spacing(1.5),
    color: 'white',
    fontSize: 15
  },
  deleteBtn: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#ba000d'
    }
  }
}))

interface ResetUserPasswordButtonProps {
  user: User
}

const ResetUserPasswordButton = (props: ResetUserPasswordButtonProps) => {
  const classes = useStyles()
  const { user } = props
  const { resetPassword, isLoading, isError, resetMutation } =
    useResetPassword()

  useEffect(() => {
    resetMutation()
  }, [user, resetMutation])

  const handleResetClick = () => {
    resetPassword({ username: user.Username })
  }

  const resetDisabled = useMemo(
    () =>
      isLoading ||
      isError ||
      (!!user.UserStatus && user.UserStatus === 'RESET_REQUIRED'),
    [isLoading, isError, user]
  )

  return (
    <div>
      <Typography variant="h5">Reset User's Password</Typography>
      {isError && (
        <AlertComp
          severity="error"
          message="There was a problem reseting the user's password. Please try again, and if this persists, please contact support"
        />
      )}
      {user.UserStatus === userResetStatus && (
        <AlertComp
          severity="warning"
          message="The user will be forced to reset their password next time they log in"
        />
      )}
      <Button
        disabled={resetDisabled}
        variant="contained"
        color="secondary"
        onClick={handleResetClick}
        className={classes.resetBtn}
      >
        Send Reset Password Link
      </Button>
    </div>
  )
}

interface DeleteUserButtonProps {
  user: User
  handleDeleteDialog?: () => void
}

const DeleteUserButton = (props: DeleteUserButtonProps) => {
  const classes = useStyles()
  const { handleDeleteDialog, user } = props
  const { userId } = useSelector((state: ReduxState) => state.userInfo)

  if (userId === user.Username || !handleDeleteDialog) {
    return null
  }
  return (
    <div>
      <Typography variant="h5">Delete User</Typography>
      <Button
        variant="contained"
        onClick={handleDeleteDialog}
        className={cx(classes.resetBtn, classes.deleteBtn)}
      >
        Delete User
      </Button>
    </div>
  )
}

const Users = () => {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()

  const { username } = useParams<{ username: string }>()
  const [formSettings, setFormSettings] = useState({
    formOpen: false,
    isEdit: false
  })
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { formOpen, isEdit } = formSettings

  const {
    mutateAsync: mutateUser,
    isLoading: mutateLoading,
    isError: mutateError
  } = useMutateUser()

  const {
    mutateAsync: createUser,
    isLoading: createLoading,
    isError: createError
  } = useCreateUser()

  const {
    deleteUser,
    resetDeletion,
    isSuccess: deleteSuccessful,
    isLoading: deleteLoading,
    isError: deleteError
  } = useDeleteUser()

  const currentUserQuery = useFetchCurrentUser()
  const userListQuery = useFetchUserList()

  const me = useMemo(
    () => (currentUserQuery.data || {}) as User,
    [currentUserQuery]
  )
  const userData = useMemo(
    () => (userListQuery.data ?? []) as User[],
    [userListQuery]
  )
  const isLoading = currentUserQuery.isLoading || userListQuery.isLoading
  const isError = currentUserQuery.isError || userListQuery.isError

  const keyedUsers = useMemo(() => keyBy(userData, 'Username'), [userData])

  const [selectedUser, setSelectedUser] = useState(me)

  useEffect(() => {
    if (username) {
      setSelectedUser(keyedUsers[username])
    } else {
      setSelectedUser(me)
    }
  }, [username, keyedUsers, me])

  const handleUserSelect = useCallback(
    (username) => {
      setSelectedUser(keyedUsers[username])
    },
    [keyedUsers]
  )

  const handleDNDChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked }
    } = e
    const updatedContactPreference = checked ? UserAccountContactPreference.DO_NOT_DISTURB : UserAccountContactPreference.DEFAULT

    const input: MutateUserInput = {
      accountContactPreference: updatedContactPreference,
      name: me.Name,
      email: me.Email,
      phone: me.Phone,
      isAdmin: me.IsAdmin,
      emailAlerts: me.EmailAlerts,
      smsAlerts: me.SmsAlerts,
      clientID: me.ClientID,
      siteID: me.SiteID,
      username: me.Username
    }

    console.info(input)
    mutateUser(input)
  }, [mutateUser, me])

  const handleToggleForm = useCallback((isEdit: boolean = false) => {
    setFormSettings((settings) => ({
      formOpen: !settings.formOpen,
      isEdit
    }))
  }, [])

  const handleDeleteDialog = useCallback(() => {
    resetDeletion()
    setDeleteOpen((current) => !current)
  }, [resetDeletion])

  const handleDeleteUser = useCallback(() => {
    deleteUser(selectedUser.Username)
  }, [deleteUser, selectedUser])

  const handleSuccessfulDelete = useCallback(() => {
    handleDeleteDialog()
    history.push(`/users/${me.Username}`)
    queryClient.invalidateQueries([USER_LIST_KEY])
  }, [queryClient, me, handleDeleteDialog, history])

  const handleUserSave = useCallback(
    async (data: MutateUserInput) => {
      if (isEdit) {
        await mutateUser(data)
      } else {
        await createUser(data)
      }
      handleToggleForm()
    },
    [createUser, mutateUser, isEdit, handleToggleForm]
  )

  const checkSubName = (user: User) => {
    if (!user?.IsAdmin) {
      return ''
    }
    if (user?.SiteID) {
      return 'Site Admin'
    }
    if (user?.ClientID) {
      return 'Client Admin'
    }
    return 'Super Admin'
  }

  const hasUserData = useMemo(
    () => !isEmpty(userData) && !isEmpty(selectedUser),
    [userData, selectedUser]
  )

  const renderBody = () => {
    if (isLoading) {
      return <Loading />
    }

    if (isError) {
      return (
        <AlertComp
          severity="error"
          message="There was a problem fetch the users. If this persits, please contact support."
        />
      )
    }

    if (!hasUserData) {
      return (
        <AlertComp
          severity="warning"
          message="No users were found. Create a user, select a different user, or if you believe this was a mistake, please contact support."
        />
      )
    }

    console.info('SELECTED USER', selectedUser)
    return (
      <>
        <ActionHeader
          checkbox
          name={selectedUser?.Name || ''}
          subname={checkSubName(selectedUser as any)}
          checkDisabled={isLoading}
          checked={selectedUser.AccountContactPreference === UserAccountContactPreference.DO_NOT_DISTURB}
          handleChange={handleDNDChange}
          handleOpenEdit={handleToggleForm}
        />

        <div className={classes.selectionContainer}>
          <UserSelection
            data={userData as any}
            onSelect={handleUserSelect}
            selectedOption={selectedUser}
            openForm={handleToggleForm}
          />
        </div>

        <UserOveriew user={selectedUser as any}>
          <ResetUserPasswordButton user={selectedUser} />
          <DeleteUserButton
            user={selectedUser}
            handleDeleteDialog={handleDeleteDialog}
          />
        </UserOveriew>
      </>
    )
  }

  const renderDialog = () => {
    if (formOpen) {
      return (
        <UserForm
          isEdit={isEdit}
          handleClose={handleToggleForm}
          data={isEdit ? selectedUser : ({} as User)}
          onSave={handleUserSave}
          isLoading={mutateLoading || createLoading}
          isError={mutateError || createError}
        />
      )
    }
    if (deleteOpen) {
      return (
        <DeleteDialog
          text={`Are you sure you want to delete "${selectedUser.Name}"? This is a permanent action and can't be undone.`}
          title={`Delete User — ${selectedUser.Name}`}
          successText="User has been successfully deleted"
          errorText="There was a problem deleting this user"
          isSuccess={deleteSuccessful}
          isLoading={deleteLoading}
          isError={deleteError}
          onClose={handleSuccessfulDelete}
          onConfirm={handleDeleteUser}
        />
      )
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <Navbar />
      </div>

      <div className={classes.content}>
        {renderBody()}
      </div>

      {renderDialog()}
    </div>
  )
}

export { Users }
