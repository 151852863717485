import { FC } from 'react'
import { makeStyles } from '@material-ui/styles'
import Logo from '../../assets/crw-logo2.png'
import { Navigation } from './Navigation'
import { UserMenu } from './UserMenu'

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 180,
    height: '100%',
    background: theme.palette.primary.dark,
    overflowY: 'auto'
  },
  logo: {
    width: '100%',
    padding: theme.spacing(1)
  },
  footer: {
    marginTop: 'auto'
  }
}))

const NavBar: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <img src={Logo} alt="crw-logo" className={classes.logo} />

      <Navigation />

      <div className={classes.footer}>
        <UserMenu />
      </div>
    </div>
  )
}

export default NavBar
