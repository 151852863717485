import { makeGraphQlQuery } from '../../graphql'
import keyBy from 'lodash/keyBy'
import { useQuery } from 'react-query'
import { listServiceContacts } from '../../../graphql/queries'
import { Site, ServiceContact, ListServiceContactsQuery } from '../../../API'

export const useFetchServiceContacts = (site: Site) => {
  const serviceContactRes = useQuery<
    unknown,
    unknown,
    { data?: ListServiceContactsQuery}
  >(['serviceContacts', site], () => {
    if (!site.id) {
      return null
    }
    return makeGraphQlQuery({
      query: listServiceContacts,
      variables: {
        filter: { siteID: { eq: site.id } }
      }
    })
  })

  const serviceContacts = (serviceContactRes.data?.data?.listServiceContacts?.items ?? [])
    .filter((contact) => !!contact) as ServiceContact[]

  return {
    serviceContacts,
    keyedContacts: keyBy(serviceContacts, 'id'),
    isLoading: serviceContactRes.isLoading,
    isError: serviceContactRes.isError
  }
}
