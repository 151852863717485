import { useState, useCallback, useMemo } from 'react'
import { keyBy } from 'lodash'
import cx from 'classnames'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import formatISO from 'date-fns/formatISO'
import { makeStyles } from '@material-ui/styles'
import MuiPhoneNumber from 'material-ui-phone-number'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import FileUpload from './FileUpload'
import { IFormData } from '../utils/sharedInterfaces'
import { aryIannaTimeZones } from './Timezone/timezones'

const useStyles = makeStyles((theme: any) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw',
    overflowY: 'visible'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  textField: {
    background: 'white',
    marginTop: theme.spacing(4)
  },
  helperText: {
    margin: 0,
    paddingLeft: 14,
    color: 'white',
    backgroundColor: theme.palette.primary.light
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 800
  },
  accepted: {
    backgroundColor: 'green'
  },
  error: {
    backgroundColor: 'red'
  },
  removed: {
    backgroundColor: theme.palette.secondary.dark
  },
  snackBarMessage: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

interface ISnackBar {
  status: 'accepted' | 'error' | 'removed' | ''
  message: string
}

interface Props {
  title: string
  fields: IFormData[]
  data?: any
  handleClose: () => void
  onSave: (data: any) => void
  isLoading: boolean
  isError: boolean
  errorMsg?: string
  numberFields?: string[]
}

const NewForm = ({
  title,
  fields,
  handleClose,
  onSave,
  isLoading,
  isError,
  data = {},
  errorMsg,
  numberFields = []
}: Props) => {
  const classes = useStyles()
  const [formData, setFormData] = useState(
    fields.reduce<any>(
      (m, field) => ({ ...m, [field.name]: data[field.name] }),
      {}
    )
  )

  const keyedFields = useMemo(() => keyBy(fields, 'name'), [fields])

  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBar>({
    status: '',
    message: ''
  })

  const handleFileChange = useCallback((name: string, file: File | string) => {
    setFormData((d: any) => ({ ...d, [name]: file }))
    setSnackBarSettings({
      status: file ? 'accepted' : 'removed',
      message: file
        ? `${(file as File).name} successfully added`
        : 'File removed'
    })
  }, [])

  const handleFileError = useCallback((message: string) => {
    setErrorMessage(message)
    if (message) {
      setSnackBarSettings({ status: 'error', message })
    }
  }, [])

  const handlePhoneChange = useCallback((value) => {
    setFormData((d: any) => ({ ...d, phone: value }))
  }, [])

  const handleTimeZoneChange = useCallback((e: any) => {
    const value = e.target.innerText
    setFormData((d: any) => ({ ...d, timezone: value }))
  }, [])

  const handleChange = useCallback(
    (e: any) => {
      const {
        target: { name, value }
      } = e
      let val = value
      if (numberFields.includes(name)) {
        val = Number(val)
      }
      setFormData((d: any) => ({ ...d, [name]: val }))
    },
    [numberFields]
  )

  const determineFieldError = (field: IFormData) => {
    if (!field.required) {
      return false
    }
    const fieldFormValue = formData[field.name]
    return fieldFormValue === '' || field.test?.(fieldFormValue)
  }

  const handleSave = useCallback(
    () =>
      onSave({
        ...formData,
        ...(data.id
          ? {
              id: data.id,
              _version: data._version // Required for amplify to mutate document correctly
            }
          : {})
      }),
    [onSave, formData, data]
  )

  const renderInputFields = () =>
    fields.map((field: IFormData) => {
      if (field.name === 'phone') {
        return (
          <MuiPhoneNumber
            size="small"
            className={classes.textField}
            key={`${title}-form-phone`}
            id={`${title}-form-phone`}
            name="phone"
            label="Phone Number"
            value={formData.phone}
            onChange={handlePhoneChange}
            defaultCountry="us"
            fullWidth
            variant="filled"
            error={!!(field.required && formData[field.name] === '')}
            disabled={isLoading}
            required={Boolean(field.required)}
          />
        )
      }
      if (field.type === 'file') {
        return (
          <FileUpload
            key={`${title}-form-${field.name}`}
            label={field.text}
            name={field.name}
            value={formData[field.name] || ''}
            disabled={isLoading}
            required={field.required ?? false}
            maxSize={1000 * 1000 * 10}
            accept=".doc,.docx,.pdf"
            error={errorMessage}
            handleChange={handleFileChange}
            handleError={handleFileError}
          />
        )
      }
      if (field.type === 'timezone') {
        return (
          <Autocomplete
            key={`${title}-form-${field.name}`}
            classes={{ root: classes.textField }}
            disablePortal
            fullWidth
            value={formData[field.name] ?? field.defaultValue ?? ''}
            onChange={handleTimeZoneChange}
            id="combo-box-demo"
            options={aryIannaTimeZones}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="small"
                key={`${title}-form-${field.name}`}
                id={`${title}-form-${field.name}`}
                label={`${field.text}`}
                name={field.name}
                value={formData[field.name] ?? field.defaultValue ?? ''}
                fullWidth
                variant="filled"
                error={determineFieldError(field)}
                helperText={field.helperText}
                disabled={isLoading}
                required={Boolean(field.required)}
                FormHelperTextProps={{
                  className: classes.helperText
                }}
              />
            )}
          />
        )
      }
      const isDateType = field.type === 'date'
      return (
        <TextField
          size="small"
          type={isDateType ? 'datetime-local' : undefined}
          classes={{ root: classes.textField }}
          key={`${title}-form-${field.name}`}
          id={`${title}-form-${field.name}`}
          label={`${field.text}`}
          name={field.name}
          value={formData[field.name] ?? field.defaultValue ?? ''}
          onChange={handleChange}
          fullWidth
          variant="filled"
          error={determineFieldError(field)}
          helperText={field.helperText}
          disabled={isLoading}
          required={Boolean(field.required)}
          InputLabelProps={isDateType ? { shrink: true } : {}}
          inputProps={{
            max: isDateType ? formatISO(new Date()).slice(0, 19) : undefined
          }}
          FormHelperTextProps={{
            className: classes.helperText
          }}
        />
      )
    })

  const submitIsDisabled =
    isLoading ||
    Object.keys(formData).some((field) => {
      const value = formData[field]
      return typeof value === 'number'
        ? value < 0
        : !value || keyedFields[field].test?.(value)
    })

  return (
    <Dialog open onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {isError && (
          <Alert severity="error">
            {!errorMsg
              ? 'There was a problem saving your info. If this persists, please contact support.'
              : errorMsg}
          </Alert>
        )}
        {renderInputFields()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          disabled={submitIsDisabled}
          onClick={handleSave}
          classes={{ root: classes.save }}
        >
          Save
        </Button>
        <Snackbar
          open={!!snackBarSettings.message}
          message={snackBarSettings.message}
          autoHideDuration={3000}
          onClose={() => setSnackBarSettings({ status: '', message: '' })}
          ContentProps={{
            className: cx(
              classes.snackBarMessage,
              snackBarSettings.status ? classes[snackBarSettings.status] : ''
            )
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default NewForm
