import { saveAs } from 'file-saver'
import { useState, MouseEvent } from 'react'
import { Menu, MenuItem, Theme, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ShareIcon from '@material-ui/icons/Share'
import { format, formatISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useDates } from '../../utils'
import { useDownloadCsv } from '../../utils/hooks/useDownloadCsv'

const useStyles = makeStyles((theme: Theme) => ({
  share: {
    marginBottom: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    },
    minWidth: '100px'
  }
}))

interface Props {
  data: any[]
  sensorID: string
  sensorName: string
  timezone: string | null
}

const DeviceGraphShareButton = ({ data, sensorID, sensorName, timezone }: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { from, to } = useDates()
  const downloadCsv = useDownloadCsv()

  const handleShareClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleExportGraph = () => {
    const dataChart = document.getElementById('data-chart') as HTMLCanvasElement
    dataChart.toBlob(function (blob) {
      saveAs(blob as Blob, `${sensorID}-graph.jpeg`)
    }, 'image/jpeg')
  }

  const handleDownloadCsv = () => {
    const formattedFrom = format(new Date(from * 1000), 'yyyy-MM-dd')
    const formattedTo = format(new Date(to * 1000), 'yyyy-MM-dd')
    const csvData = data.map((d) => {
      const { Value, Unit } = JSON.parse(d.readValue)
      const zonedTime = timezone ? utcToZonedTime(d.updatedAt, timezone) : d.updatedAt

      return {
        'Read Value': Value,
        'Read Unit': Unit,
        [`Updated At (${timezone || 'local'})`]: formatISO(zonedTime),
        'Updated At (UTC)': d.updatedAt
      }
    })

    downloadCsv(csvData, `${sensorName} ${formattedFrom} to ${formattedTo}`)
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={handleShareClick}
        className={classes.share}
      >
        Share <ShareIcon />
      </Button>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem onClick={handleExportGraph}>Export Graph</MenuItem>
          <MenuItem onClick={handleDownloadCsv}>Export CSV</MenuItem>
        </Menu>
      )}
    </>
  )
}

export default DeviceGraphShareButton
