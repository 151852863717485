import { Dispatch, FC, SetStateAction, useState, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { ClassNameMap } from '@material-ui/styles'
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  SelectProps
} from '@material-ui/core'
import { Sensor } from '../../API'
import { Dictionary } from 'lodash'
import { CheckboxOption } from '../../components/CheckboxOption'

interface CompareSelectProps {
  sensorsCompared: string[]
  availableSensors: Sensor[]
  keyedSensors: Dictionary<Sensor>
  setCompare: Dispatch<SetStateAction<string[]>>
  isLoading: boolean
  classes: ClassNameMap
}

export const CompareSelect: FC<CompareSelectProps> = (props) => {
  const { sensorsCompared, availableSensors, setCompare, keyedSensors, isLoading, classes } = props
  const [selectedItems, setSelectedItems] = useState(sensorsCompared)
  const sortedSensors = [...availableSensors].sort((a, b) => (a.name || '').localeCompare(b.name || ''))

  const handleSelectChange: SelectProps['onChange'] = (e) => {
    const { target: { value } } = e
    setSelectedItems(value as string[])
  }

  const handleCompare = () => setCompare(selectedItems)

  const renderOption = useCallback((sensor: Sensor) => (
    <CheckboxOption
      key={sensor.id}
      isIncluded={selectedItems.includes(sensor.id)}
      maxSelected={selectedItems.length === 6}
      value={sensor.id}
      text={sensor.name}
      classes={classes}
    />
  ), [classes, selectedItems])

  const renderValue = useCallback((sensors) => {
    return isEmpty(sensors) ? 'Select sensors...' : sensors.map((id: string) => keyedSensors[id]?.name).join(', ')
  }, [keyedSensors])

  return (
    <div className={classes.selectContainer}>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.inputLabel} id="compare-select">Select Up to 6 Sensors...</InputLabel>
        <Select
          multiple
          labelId="compare-select"
          placeholder="Select sensors..."
          onChange={handleSelectChange}
          value={selectedItems}
          disabled={isLoading}
          renderValue={renderValue}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'menu',
            classes: { paper: classes.menuPaper }
          }}
        >
          {sortedSensors.map(renderOption)}
        </Select>
      </FormControl>
      <Button className={classes.white} variant="contained" color="secondary" onClick={handleCompare}>
        Compare
      </Button>
    </div>
  )
}
