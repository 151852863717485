import { useQueryClient, useMutation } from 'react-query'
import { MutateUserInput } from '../../../API'
import { adminCreateUser } from '../../../graphql/mutations'
import { makeGraphQlQuery } from '../../graphql'
import { USER_LIST_KEY } from '.'

export const useCreateUser = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (createData: MutateUserInput) => {
      return makeGraphQlQuery({
        query: adminCreateUser,
        variables: { input: createData }
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries([USER_LIST_KEY])
    }
  )
}
