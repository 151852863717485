import { useMutation, useQueryClient } from 'react-query'
import { ALERT_RULE_KEY, ALERT_RULE_LIST_KEY } from '.'
import { AlertRule } from '../../../API'
import { deleteAlertRule } from '../../../graphql/mutations'
import { api } from '../../../utils/gql-client'

export const useDeleteAlertRule = (alertRule: AlertRule) => {
  const queryClient = useQueryClient()
  return useMutation(
    () =>
      api.request({
        query: deleteAlertRule,
        variables: { input: { id: alertRule.id, _version: alertRule._version } }
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ALERT_RULE_LIST_KEY, alertRule.siteID])
        queryClient.invalidateQueries([ALERT_RULE_KEY, alertRule.id])
      }
    }
  )
}
