import { FC, useState, useCallback, ChangeEvent, FocusEvent } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Typography,
  Button
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Auth } from '@aws-amplify/auth'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: any) => ({
  reset: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

interface ILogin {
  handleUserChange: (userData: any) => void
  parentClasses: any
}

const SignIn: FC<ILogin> = (props) => {
  const { handleUserChange, parentClasses } = props
  const classes = useStyles()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [formErrors, setFormErrors] = useState<{[key: string]: boolean}>({
    username: false,
    password: false
  })
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  })
  const { username, password } = credentials

  const handleShowReset = () => {
    history.push('/forgotPassword')
  }

  const handleSignIn = useCallback(async () => {
    setError('')
    setIsLoading(true)
    try {
      const userData = await Auth.signIn(username, password)
      if (userData.challengeName === 'SMS_MFA') {
        handleUserChange(userData)
        history.push('/confirmSignIn')
      } else if (userData.challengeName === 'NEW_PASSWORD_REQUIRED') {
        userData.email = username
        handleUserChange(userData)
        history.push('/signUp')
      } else {
        throw new Error('There was an issue logging in. If this persists, please contact support')
      }
    } catch (e: any) {
      setCredentials({ username: '', password: '' })
      if (e.code === 'PasswordResetRequiredException') {
        await Auth.forgotPassword(username)
        handleUserChange({ username })
        history.push('/forgotPassword')
      } else {
        setError(e.message || e)
        setIsLoading(false)
      }
    }
  }, [username, password, history, handleUserChange])

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const {
      target: { value, name }
    } = e
    setFormErrors((errors) => ({
      ...errors,
      [name]: !value || (name === 'password' && value.length < 8)
    }))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name }
    } = e
    setCredentials((creds) => ({ ...creds, [name]: value }))
  }

  const hasErrors = Object.keys(formErrors).some((key: string) => formErrors[key])
  const submitDisabled = isLoading || hasErrors || !username || !password

  return (
    <Card className={parentClasses.card}>
      <CardHeader className={parentClasses.header} title="Sign into your account" />
      <CardContent>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        <TextField
          required
          fullWidth
          type="email"
          size="small"
          name="username"
          label="Username"
          value={username}
          variant="filled"
          onBlur={handleBlur}
          disabled={isLoading}
          onChange={handleChange}
          error={formErrors.username}
          classes={{ root: parentClasses.textField }}
        />
        <TextField
          required
          fullWidth
          type="password"
          size="small"
          name="password"
          label="Password"
          value={password}
          variant="filled"
          disabled={isLoading}
          onChange={handleChange}
          error={formErrors.password}
          classes={{ root: parentClasses.textField }}
        />
        <Typography>Forgot your password? <span onClick={handleShowReset} className={classes.reset}>Reset Password</span></Typography>

      </CardContent>
      <CardActions classes={{ root: parentClasses.actions }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSignIn}
          disabled={submitDisabled}
          classes={{ root: parentClasses.button }}
        >
          Sign In
        </Button>
      </CardActions>
    </Card>
  )
}

export default SignIn
