import { useQueryClient, useMutation } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  CreateSensorGroupMutationVariables as Variables,
  CreateSensorGroupMutation as Response
} from '../../API'
import { createSensorGroup as query } from '../../graphql/mutations'
import { SENSOR_GROUPS_KEY } from '../../utils/hooks'

function useCreateSensorGroup() {
  const queryClient = useQueryClient()

  return useMutation((variables: Variables) => {
    return api.request<Response>({ query, variables })
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(SENSOR_GROUPS_KEY)
    }
  })
}

export { useCreateSensorGroup }
