import { FC } from 'react'
import { Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/Add'
// Local Components
import { AlertRule } from '../../API'
import { AutoCompleteComp, ChangeFunc } from '../../components/AutoCompleteComp'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex'
  },
  search: {
    minWidth: '300px'
  },
  createBtn: {
    textAlign: 'center',
    padding: theme.spacing(1.5)
  }
}))

interface Props {
  data: AlertRule[]
  selectedOption?: AlertRule | null
  onSelect?: (id: string) => void
  openForm?: (isEdit?: boolean) => void
}

const AlertSelection: FC<Props> = (props) => {
  const {
    data,
    onSelect,
    selectedOption,
    openForm
  } = props
  const classes = useStyles()

  const handleSearchChange: ChangeFunc<{ id: string }> = (_, option) => {
    if (!option) {
      return
    }
    const { id } = option
    onSelect?.(id)
  }

  const getRuleSelected = (option: object, value: object) => {
    const { id: valueId } = value as AlertRule
    const { id: optionId } = option as AlertRule
    if (!valueId) { return true }
    return valueId === optionId
  }

  const handleAddClick = () => openForm?.()

  return (
    <div className={classes.container}>
      <div className={classes.search}>
        <AutoCompleteComp
          value={selectedOption || {}}
          options={data}
          getOptionLabel={(options) => options.name || ''}
          getOptionSelected={getRuleSelected}
          handleChange={handleSearchChange}
          label="Alert Rule"
        />
      </div>
      <div className={classes.createBtn}>
        <Button
          size="small"
          color="secondary"
          endIcon={<AddIcon />}
          onClick={handleAddClick}
        >
          Create Alert Rule
        </Button>
      </div>
    </div>
  )
}

export { AlertSelection }
