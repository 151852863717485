interface UserPayload {
  type: string,
  userId: string,
  groups: string[],
  sites?: string[], // TODO(nwestman): Remove this
  phoneNumber: string,
  username: string
}

export interface IUserInfo extends Omit<UserPayload, 'type'> {
  isSuperAdmin: boolean
  isAdmin: boolean
}

export const userInfo = (state = {
  userId: '',
  groups: [],
  phoneNumber: '',
  username: '',
  isSuperAdmin: false,
  isAdmin: false
}, action: UserPayload): IUserInfo => {
  switch (action.type) {
    case 'update_user_info':
      return {
        userId: action.userId,
        groups: action.groups,
        phoneNumber: action.phoneNumber,
        username: action.username,
        isSuperAdmin: action.groups?.includes('superAdmin'),
        isAdmin: action.groups?.some((group) => group.includes('Admin'))
      }
    default:
      return state
  }
}
