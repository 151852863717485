import { useQueryClient, useMutation } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  UpdateSensorGroupMutationVariables as Variables,
  UpdateSensorGroupMutation as Response
} from '../../API'
import { updateSensorGroup as query } from '../../graphql/mutations'
import { SENSOR_GROUPS_KEY } from '../../utils/hooks'

function useUpdateSensorGroup() {
  const queryClient = useQueryClient()

  return useMutation((variables: Variables) => {
    return api.request<Response>({ query, variables })
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(SENSOR_GROUPS_KEY)
    }
  })
}

export { useUpdateSensorGroup }
