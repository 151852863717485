/* eslint-disable camelcase */
import { FC, useState, useCallback, ChangeEvent, FocusEvent } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import MuiPhoneNumber from 'material-ui-phone-number'
import { Auth } from '@aws-amplify/auth'
import { useHistory } from 'react-router-dom'

const formDataDefault = {
  given_name: '',
  family_name: '',
  password: '',
  confirmPassword: '',
  phone_number: '',
  checked: true
}

type FormKey = keyof typeof formDataDefault
type FormErrors = { [key in FormKey]: boolean }

interface ISignUp {
  handleUserChange: (userData: any) => void
  parentClasses: any
  userData: any
}

const SignUp: FC<ISignUp> = (props) => {
  const { handleUserChange, parentClasses, userData } = props
  const history = useHistory()

  const {
    challengeParam: { userAttributes }
  } = userData

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [formErrors, setFormErrors] = useState<FormErrors>({
    given_name: false,
    family_name: false,
    password: false,
    confirmPassword: false,
    phone_number: false,
    checked: false
  })
  const [formData, setFormData] = useState<typeof formDataDefault>({
    given_name: userAttributes.given_name || '',
    family_name: userAttributes.family_name || '',
    phone_number: '',
    password: '',
    confirmPassword: '',
    checked: true
  })
  const {
    given_name,
    family_name,
    password,
    confirmPassword,
    phone_number,
    checked
  } = formData

  const handleSignUp = useCallback(async () => {
    setError('')
    setIsLoading(true)
    try {
      const signUpRes = await Auth.completeNewPassword(
        userData,
        formData.password,
        {
          given_name: formData.given_name,
          family_name: formData.family_name,
          name: `${formData.given_name} ${formData.family_name}`,
          email: userData.email,
          phone_number: formData.phone_number.replace(/[\s()-]/g, '')
        }
      )
      if (signUpRes.challengeName === 'SMS_MFA') {
        history.push('/confirmSignIn')
        handleUserChange(signUpRes)
      } else {
        throw new Error(
          'There was an issue signing up. If this persists, please contact support'
        )
      }
    } catch (e: any) {
      setFormData(formDataDefault)
      setError(e.message || e)
      setIsLoading(false)
    }
  }, [formData, userData, history, handleUserChange])

  const validatePassword = useCallback(
    (name: string, pwd: string) => {
      const valid =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[\w\d@$!%*?&]{8,}$/.test(
          pwd
        )
      return !valid || (name === 'confirmPassword' && pwd !== password)
    },
    [password]
  )

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const {
      target: { value, name }
    } = e
    setFormErrors((errors) => ({
      ...errors,
      [name]:
        !value ||
        (name.toLowerCase().includes('password') &&
          validatePassword(name, value))
    }))
  }

  const handlePhoneChange = useCallback((value) => {
    setFormData((d: any) => ({ ...d, phone_number: value }))
  }, [])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name }
    } = e
    setFormData((data) => ({ ...data, [name]: value }))
  }, [])

  const handleCheckboxChange = useCallback(
    (unused: React.ChangeEvent<{}>, checked: boolean) => {
      setFormData((data) => ({ ...data, checked }))
      setFormErrors((errors) => ({ ...errors, checked: !checked }))
    },
    []
  )

  const hasErrors = Object.entries(formErrors).some(
    ([key, value]) => value || !formData[key as FormKey]
  )
  const submitDisabled = isLoading || hasErrors

  return (
    <Card className={parentClasses.card}>
      <CardHeader className={parentClasses.header} title="Sign Up" />
      <CardContent>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          required
          fullWidth
          size="small"
          variant="filled"
          name="given_name"
          label="First name"
          value={given_name}
          onBlur={handleBlur}
          disabled={isLoading}
          onChange={handleChange}
          error={formErrors.given_name}
          classes={{ root: parentClasses.textField }}
        />
        <TextField
          required
          fullWidth
          size="small"
          variant="filled"
          label="Last name"
          name="family_name"
          value={family_name}
          onBlur={handleBlur}
          disabled={isLoading}
          onChange={handleChange}
          error={formErrors.family_name}
          classes={{ root: parentClasses.textField }}
        />
        <TextField
          required
          fullWidth
          size="small"
          type="password"
          name="password"
          label="Password"
          value={password}
          variant="filled"
          onBlur={handleBlur}
          disabled={isLoading}
          onChange={handleChange}
          error={formErrors.password}
          classes={{ root: parentClasses.textField }}
          helperText="8 or more characters, with at least one uppercase, one lowercase, one number, and one special character"
        />
        <TextField
          required
          fullWidth
          size="small"
          type="password"
          variant="filled"
          onBlur={handleBlur}
          disabled={isLoading}
          name="confirmPassword"
          label="Confirm Password"
          value={confirmPassword}
          onChange={handleChange}
          error={formErrors.confirmPassword}
          classes={{ root: parentClasses.textField }}
        />
        <MuiPhoneNumber
          required
          fullWidth
          size="small"
          variant="filled"
          name="phone_number"
          label="Phone Number"
          defaultCountry="us"
          onBlur={handleBlur}
          disabled={isLoading}
          onChange={handlePhoneChange}
          value={phone_number}
          error={formErrors.phone_number}
          className={parentClasses.textField}
        />
        <FormControl required error={formErrors.checked} component="fieldset">
          <FormControlLabel
            control={<Checkbox required defaultChecked />}
            label="by checking this box, I agree to receive SMS for MFA."
            value={checked}
            onChange={handleCheckboxChange}
          />
          <FormHelperText>You must enable multi-factor authentication to use this application.</FormHelperText>
        </FormControl>
      </CardContent>
      <CardActions classes={{ root: parentClasses.actions }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSignUp}
          disabled={submitDisabled}
          classes={{ root: parentClasses.button }}
        >
          Sign Up
        </Button>
      </CardActions>
    </Card>
  )
}

export default SignUp
