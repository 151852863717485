import { useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useHistory, useParams } from 'react-router-dom'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
// Local components
import Navbar from '../../components/Navbar'
import Loading from '../../components/Loading'
import AlertComp from '../../components/AlertComp'
import ActionHeader from '../../components/ActionHeader'
import { AlertRulesOverview } from '../../components/Alerts/AlertRulesOverview'
import AlertForm from '../../components/Alerts/AlertForm'
import { useFetchAlertRules } from '../../utils/hooks'
import { useFetchSensorsByAlertRule } from '../../utils/hooks/sensors/useFetchSensorsByAlertRule'
import { useGlobalData } from '../../utils/hooks/global-data'
import { AlertSelection } from './AlertSelection'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%'
  },
  nav: {
    flex: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main
  },
  selectionContainer: {
    padding: theme.spacing(1.5)
  }
}))

const Alerts = () => {
  const classes = useStyles()
  const { alertRuleID } = useParams<{ alertRuleID: string }>()
  const history = useHistory()
  const [{ site: siteID }, , globalDataToSearchParams] = useGlobalData()
  const [formOpen, setFormOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { alertRules, keyedAlertRules, isLoading, isError } =
    useFetchAlertRules(siteID)

  const selectedAlertRule = keyedAlertRules[alertRuleID] || alertRules[0] || null
  const hasAlertRules = !isEmpty(alertRules) && !isEmpty(selectedAlertRule)

  const res = useFetchSensorsByAlertRule({
    alertruleID: selectedAlertRule?.id,
    filter: { hidden: { ne: true } }
  }, { enabled: !!selectedAlertRule?.id })

  const alertRuleSensors = res.data?.items.map((sensor) => sensor?.id) as string []

  const handleToggleForm = useCallback(
    (isEdit: boolean = false) => {
      setIsEdit(isEdit)
      setFormOpen(!formOpen)
    },
    [formOpen]
  )

  const handleCloseForm = useCallback(() => {
    setFormOpen(false)
  }, [])

  const handleRuleSelect = (id: string) => {
    history.push(`/alertRules/${id}?${globalDataToSearchParams()}`)
  }

  const renderBody = (children: React.ReactNode) => {
    if (isLoading) {
      return <Loading />
    }

    if (isError) {
      return (
        <>
          <AlertComp
            severity="error"
            message="There was a problem fetching the alert rules. If this persits, please contact support."
          />
          {children}
        </>
      )
    }

    if (!selectedAlertRule || !hasAlertRules) {
      return (
        <>
          <AlertComp
            severity="warning"
            message="No alert rule configurations were found. Create an alert rule, select a rule, or if you believe this was a mistake, please contact support."
          />
          {children}
        </>
      )
    }
    return (
      <>
        <ActionHeader
          sensorDropDown
          name={selectedAlertRule.name || ''}
          handleOpenEdit={handleToggleForm}
          alertRuleSensors={alertRuleSensors}
          alertruleID={selectedAlertRule?.id}
        />

        {children}

        <AlertRulesOverview alertRule={selectedAlertRule} />
      </>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <Navbar />
      </div>

      <div className={classes.content}>
        {renderBody(
          <div className={classes.selectionContainer}>
            <AlertSelection
              data={alertRules as any}
              onSelect={handleRuleSelect}
              selectedOption={selectedAlertRule}
              openForm={handleToggleForm}
            />
          </div>
        )}
      </div>

      {formOpen && (
        <AlertForm
          open
          isEdit={isEdit}
          selectedAlertRule={selectedAlertRule}
          siteID={siteID}
          onClose={handleCloseForm}
        />
      )}
    </div>
  )
}

export { Alerts }
