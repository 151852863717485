import { FC } from 'react'
import { GlobalDataSelection } from '../../components/GlobalDataSelection'
import { Site } from '../../API'
import SubHeader from '../SubHeader'
import DashboardView from './DashboardView'
import { useParams } from 'react-router-dom'

interface IOverviewProps {
  site: Site | null
}

export const Overview: FC<IOverviewProps> = (props) => {
  const { site } = props
  const { tab = 'sensors' } = useParams<{ tab: string }>()

  return (
    <div>
      <SubHeader siteData={site} />

      <GlobalDataSelection selectors={['sensorGroup']} />

      <DashboardView site={site} tab={tab} />
    </div>
  )
}
