import isEmpty from 'lodash/isEmpty'
import { Button, Grid, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { OmniDevice, Site } from '../../../API'
import AlertComp from '../../AlertComp'
import { OmniDeviceCard } from './OmniDeviceCard'
import MutateOmniDeviceDialog from './MutateOmniDeviceDialog'
import { useState } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3, 3)
  },
  button: {
    color: 'white'
  }
}))

interface Props {
  site: Site
  omniDevices: OmniDevice[]
  isError: boolean
}

export const OmniDevicesTab = (props: Props) => {
  const classes = useStyles()
  const { omniDevices, isError, site } = props
  const [showDialog, setShowDialog] = useState(false)
  const toggleDialog = () => setShowDialog(!showDialog)

  const renderBody = () => {
    if (isEmpty(omniDevices)) {
      return (
        <AlertComp
          severity="warning"
          message={`No Omni Devices assigned to the site ${site.name}`}
        />
      )
    }
    if (isError) {
      return (
        <AlertComp
          severity="error"
          message="There was an issue fetching the sites devices."
        />
      )
    }
    return (
      <Grid container spacing={3} justifyContent="center">
        {omniDevices.map((omni) => {
          return <OmniDeviceCard id={omni.id} key={omni.id} />
        })}
      </Grid>
    )
  }

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button
          color="secondary"
          variant="contained"
          classes={{ root: classes.button }}
          onClick={toggleDialog}
        >
          Add Omni Device
        </Button>
        <MutateOmniDeviceDialog
          site={site}
          open={showDialog}
          onClose={toggleDialog}
        />
      </div>
      {renderBody()}
    </div>
  )
}
