import { FC, ChangeEvent, useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Theme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import SettingsIcon from '@material-ui/icons/Settings'
import { ListSensorIssuesQuery, Sensor, SensorIssueStatus, UpdateSensorIssueInput } from '../../API'
import {
  useFetchDevices,
  useMutateSensor,
  useMutateSensorIssue
} from '../../utils/hooks'
import { useQueryClient } from 'react-query'
import { api } from '../../utils/gql-client'
import { listSensorIssues } from '../../graphql/queries'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  formControl: {
    margin: theme.spacing(0, 1),
    width: 300,
    '& .MuiInputLabel-root': {
      color: 'white'
    }
  },
  menuPaper: {
    maxHeight: 300,
    overflow: 'scroll',
    backgroundColor: theme.palette.primary.dark,
    '& .MuiListItem-root.Mui-disabled': {
      opacity: 1,
      color: 'white'
    }
  },
  checkbox: {
    color: 'white'
  },
  snackBarMessage: {
    display: 'flex',
    justifyContent: 'center'
  },
  success: {
    backgroundColor: 'green'
  },
  error: {
    backgroundColor: 'red'
  },
  settingsIcon: {
    color: 'white',
    transform: 'scale(1.5)',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(0.5)
  }
}))

interface ISnackBar {
  status: 'success' | 'error' | ''
  message: string
}

interface ISensorDropDownProps {
  existingSensors: string[]
  alertruleID: string
}

export const SensorDropDown: FC<ISensorDropDownProps> = (props) => {
  const { existingSensors, alertruleID } = props
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [selectedSensors, setSelectedSensors] = useState(existingSensors)
  const [saveIsLoading, setSaveIsLoading] = useState(false)
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBar>({
    status: '',
    message: ''
  })

  useEffect(() => {
    setSelectedSensors(existingSensors)
  }, [existingSensors])

  const {
    sensorsData,
    keyedSensors,
    isLoading: sensorsIsLoading
  } = useFetchDevices()

  const { mutateSensor } = useMutateSensor()

  const { mutateAsync: closeSensorIssue } = useMutateSensorIssue()

  const updateSensor = (
    id: string,
    alertruleID: string | null,
    _version: number
  ) => {
    return mutateSensor({ id, alertruleID, _version })
  }

  const closeSensorIssuesWithSensorID = (sensorId: string) => {
    const variables = {
      filter: {
        sensorID: { eq: sensorId }
      },
      limit: 1000
    }
    return api.request<ListSensorIssuesQuery>({
      query: listSensorIssues,
      variables
    }).then(res => {
      let sensorIssueIds = res?.data?.listSensorIssues?.items.map(sensorIssue => sensorIssue?.id ?? '')
      console.log(res?.data?.listSensorIssues)
      if (!sensorIssueIds) return []
      sensorIssueIds = sensorIssueIds.filter((ele) => ele !== '')

      return Promise.all([
        ...sensorIssueIds.map((sensorIssueId) => {
          const input : UpdateSensorIssueInput = {
            id: sensorIssueId,
            state: SensorIssueStatus.CLOSED,
            _version: res?.data?.listSensorIssues?.items.find((item) => item?.id === sensorIssueId)?._version
          }
          return closeSensorIssue(input)
        }
        )
      ])
    })
  }

  const handleSave = async () => {
    setSaveIsLoading(true)
    try {
      const sensorsToAdd = selectedSensors.filter(
        (sensorID) => !existingSensors.includes(sensorID)
      )
      const sensorsToRemove = existingSensors.filter(
        (sensorID) => !selectedSensors.includes(sensorID)
      )
      await Promise.all([
        ...sensorsToAdd.map((sensorID) =>
          updateSensor(sensorID, alertruleID, keyedSensors[sensorID]._version)
        ),
        ...sensorsToRemove.map((sensorID) =>
          updateSensor(sensorID, null, keyedSensors[sensorID]._version)
        ),
        ...sensorsToRemove.map((sensorID) =>
          closeSensorIssuesWithSensorID(sensorID)
        )
      ])
      queryClient.invalidateQueries('sensorIssues')

      queryClient.invalidateQueries('sensors')

      setSnackBarSettings({
        status: 'success',
        message: 'Alert Rule has successfully been set on selected devices'
      })
    } catch (e) {
      setSelectedSensors(existingSensors)
      setSnackBarSettings({
        status: 'error',
        message: 'There was a problem saving this alert rule to some sensors.'
      })
    }
    setSaveIsLoading(false)
  }

  const handleSelectChange = (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const {
      target: { value }
    } = e
    setSelectedSensors(value as string[])
  }

  const renderSensorOption = useCallback(
    (sensor: Sensor) => {
      const sensorIsDisabled =
        !!sensor.alertruleID && alertruleID !== sensor.alertruleID
      return (
        <MenuItem
          key={sensor.id}
          value={sensor.id || ''}
          disabled={sensorIsDisabled}
        >
          <ListItemIcon>
            <Checkbox
              checked={selectedSensors.includes(sensor.id)}
              className={classes.checkbox}
            />
          </ListItemIcon>
          <ListItemText primary={sensor.name} />
        </MenuItem>
      )
    },
    [classes, selectedSensors, alertruleID]
  )

  const renderSelectedValues = (selected: unknown) => {
    const selectedLength = (selected as string[]).length || 'No'
    return `${selectedLength} Sensor${
      selectedLength === 1 ? ' uses' : 's use'
    } this alert`
  }

  const saveIsDisabled =
    (selectedSensors.length === existingSensors.length &&
      selectedSensors.every((sensorID) =>
        existingSensors.includes(sensorID)
      )) ||
    saveIsLoading

  return (
    <div className={classes.container}>
      <SettingsIcon className={classes.settingsIcon} />
      <FormControl className={classes.formControl}>
        <Select
          multiple
          value={selectedSensors}
          onChange={handleSelectChange}
          renderValue={renderSelectedValues}
          displayEmpty
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'menu',
            classes: { paper: classes.menuPaper }
          }}
          inputProps={{
            endadornment: sensorsIsLoading
              ? (
                <CircularProgress size={20} color="inherit" />
                )
              : null
          }}
        >
          {isEmpty(sensorsData) && (
            <MenuItem value="" disabled>
              No Sensors attached to this site
            </MenuItem>
          )}
          {sensorsData.map(renderSensorOption)}
        </Select>
      </FormControl>

      <Button
        disabled={saveIsDisabled}
        variant="contained"
        color="secondary"
        onClick={handleSave}
      >
        {saveIsLoading ? <CircularProgress size={20} color="inherit" /> : null}{' '}
        Save
      </Button>
      <Snackbar
        open={!!snackBarSettings.message}
        message={snackBarSettings.message}
        autoHideDuration={3000}
        onClose={() => setSnackBarSettings({ status: '', message: '' })}
        ContentProps={{
          className: cx(
            classes.snackBarMessage,
            snackBarSettings.status ? classes[snackBarSettings.status] : ''
          )
        }}
      />
    </div>
  )
}
