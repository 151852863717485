import { ChangeEvent } from 'react'
import {
  Breadcrumbs,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Site, OmniDevice, Sensor, Device } from '../API'
import { SelectChangeEvent } from '../utils/sharedInterfaces'
import { useGlobalData } from '../utils/hooks/global-data'
import { isEmpty } from 'lodash'
import { ReduxState } from '../redux'

const useStyles = makeStyles((theme: any) => ({
  header: {
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: theme.spacing(10),
    paddingRight: theme.spacing(5)
  },
  tabs: {
    height: 'inherit'
  },
  title: {
    marginLeft: theme.spacing(2),
    color: 'white'
  },
  sensorSelect: {
    display: 'flex',
    alignItems: 'baseline'
  },
  selectLabel: {
    marginRight: theme.spacing(2)
  },
  link: {
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

interface ITab {
  label?: string
  value?: string
}

interface Props {
  tabs?: ITab[]
  activeTab?: string
  handleTabChange?: (e: ChangeEvent<{}>, value: string) => void
  siteData: Site | null
  omniDeviceData?: OmniDevice | null
  deviceData?: Device | null
  sensors?: Sensor[]
  sensorValue?: string
  onSensorChange?: (e: ChangeEvent<SelectChangeEvent>) => void
}

const SubHeader = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const [, , globalDataToSearchParams] = useGlobalData()
  const isSuperAdmin = useSelector(
    (state: ReduxState) => state.userInfo.isSuperAdmin
  )
  const {
    tabs,
    activeTab,
    handleTabChange,
    siteData,
    omniDeviceData,
    deviceData,
    sensors,
    sensorValue,
    onSensorChange
  } = props
  const sortedSensors = [...(sensors || [])].sort((a, b) => (a.name || '').localeCompare(b.name || ''))

  const handleSiteClick = () => {
    history.push(`/dashboard?${globalDataToSearchParams()}`)
  }

  const renderDeviceCrumb = () => {
    if (!deviceData) {
      return null
    }
    return <Typography>{deviceData.name}</Typography>
  }

  return (
    <div className={classes.header}>
      <Breadcrumbs className={classes.title}>
        <Typography
          variant="h6"
          className={omniDeviceData ? classes.link : ''}
          onClick={() => omniDeviceData && handleSiteClick()}
        >
          {siteData ? siteData.name : 'All Sites'}
        </Typography>
        <Typography>
          {omniDeviceData
            ? `${omniDeviceData.deviceName}${
                isSuperAdmin
                  ? ` (${omniDeviceData.hostname ?? omniDeviceData.ipAddress})`
                  : ''
              }`
            : 'All Devices'}
        </Typography>
        {renderDeviceCrumb()}
      </Breadcrumbs>
      {tabs && (
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          classes={{ root: classes.tabs }}
        >
          {tabs.map((tab, i) => (
            <Tab
              key={`subHeaderTab-${i}`}
              disableRipple
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      )}
      {sensors && !isEmpty(sensors) && onSensorChange && (
        <div className={classes.sensorSelect}>
          <Typography variant="h6" className={classes.selectLabel}>
            Sensor:{' '}
          </Typography>
          <Select autoWidth value={sensorValue} onChange={onSensorChange}>
            {sortedSensors.map(({ id, name }) => (
              <MenuItem key={`sensor-${id}`} value={id}>
                {name || id}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}

export default SubHeader
