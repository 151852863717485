import { FC, ChangeEvent } from 'react'
import { Checkbox, FormControlLabel, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import NotificationsOnIcon from '@material-ui/icons/Notifications'
import EditIcon from '@material-ui/icons/Edit'
import { SensorDropDown } from './Alerts/SensorDropDown'

const useStyles = makeStyles((theme: any) => ({
  header: {
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: theme.spacing(10),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  text: {
    display: 'flex',
    justifyContent: 'center'
  },
  subname: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: 14
  },
  editIcon: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: 'white'
    }
  },
  action: {
    borderLeft: '0.1em solid black',
    borderColor: 'rgba(255, 255, 255, 0.4)'
  },
  checkbox: {
    paddingLeft: theme.spacing(3),
    color: 'rgba(255, 255, 255, 0.4)'
  },
  checkboxLabel: {
    display: 'flex',
    justifyContent: 'center',
    color: 'rgba(255, 255, 255, 0.4)',
    '& :nth-child(1)': {
      marginRight: theme.spacing(0.5)
    }
  }
}))

interface IActionHeader {
  name?: string
  subname?: string
  checkbox?: boolean
  checked?: boolean
  checkDisabled?: boolean
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void
  handleOpenEdit?: (isEdit: boolean) => void
  sensorDropDown?: boolean
  alertRuleSensors?: string[]
  alertruleID?: string
  children?: React.ReactNode
}

const ActionHeader: FC<IActionHeader> = (props) => {
  const classes = useStyles()
  const {
    name,
    subname,
    checkbox,
    checked,
    checkDisabled,
    handleChange,
    handleOpenEdit,
    sensorDropDown,
    alertRuleSensors = [],
    alertruleID,
    children
  } = props

  const handleEditClick = () => handleOpenEdit?.(true)

  return (
    <div className={classes.header}>
      <div className={classes.text}>
        {children ||
          <>
            <div>
              <Typography variant="h6">{name}</Typography>
              {subname && <Typography className={classes.subname}>{subname}</Typography>}
            </div>
            {handleOpenEdit && (
              <IconButton size="small" className={classes.editIcon} onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            )}
          </>}
      </div>

      <div className={classes.action}>
        {checkbox && (
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleChange} className={classes.checkbox} disabled={checkDisabled} />
            }
            label={
              <>
                <div className={classes.checkboxLabel}>
                  {checked ? <NotificationsOffIcon /> : <NotificationsOnIcon />}
                  DO NOT DISTURB
                </div>
              </>

            }
          />
        )}
        {sensorDropDown && alertruleID && <SensorDropDown alertruleID={alertruleID} existingSensors={alertRuleSensors} />}
      </div>
    </div>
  )
}

export default ActionHeader
