import { useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import { useGlobalData } from '../../utils/hooks/global-data'
import { ClientSiteDialog } from './ClientSiteDialog'
import { useListClients } from '../../utils/hooks/clients'
import { useFetchSites } from '../../utils/hooks'
import { Client } from '../../API'

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  clientSiteMenu: {
    listStyle: 'none',
    margin: theme.spacing(1),
    padding: 0,
    minWidth: '150px',
    cursor: 'pointer',
    borderRadius: '5px',
    background: theme.palette.grey['100'],

    '&:hover': {
      background: theme.palette.grey['300']
    }
  },
  menuItem: {
    padding: theme.spacing(1)
  },
  icon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.light
  }
}))

function UserMenu() {
  const classes = useStyles()
  const [{ site: siteId, client: clientId }] = useGlobalData()
  const [isClientSiteOpen, setIsClientSiteOpen] = useState(false)
  const clientsRes = useListClients()
  const { keyedSites } = useFetchSites(clientId)

  const clients = (clientsRes.data?.data?.listClients?.items ??
    []) as Client[]
  const client = clients.find((client) => client.id === clientId) || null
  const site = siteId ? keyedSites[siteId] : null

  const handleOpenClientSiteDialog = () => {
    setIsClientSiteOpen(true)
  }

  const handleCloseClientSiteDialog = () => {
    setIsClientSiteOpen(false)
  }

  const handleLogout = () => {
    Auth.signOut()
  }

  return (
    <div className={classes.container}>
      <ul className={classes.clientSiteMenu} onClick={handleOpenClientSiteDialog}>
        <li className={classes.menuItem}>
          Client: {client?.name || 'None'}
        </li>
        <li className={classes.menuItem}>
          Site: {site?.name || 'None'}
        </li>
      </ul>

      <IconButton classes={{ root: classes.icon }} onClick={handleLogout}>
        <MeetingRoomIcon />
      </IconButton>

      {isClientSiteOpen && (
        <ClientSiteDialog handleClose={handleCloseClientSiteDialog} />
      )}
    </div>
  )
}

export { UserMenu }
