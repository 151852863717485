import { useState, useCallback, ChangeEvent, useMemo, useEffect } from 'react'
import { format } from 'date-fns'
import { Dictionary, isEmpty, keyBy } from 'lodash'
import { GridCellValue, GridColDef } from '@mui/x-data-grid'
import { Button, Theme } from '@material-ui/core'
import { ClassNameMap, makeStyles } from '@material-ui/styles'
// Local Helpers
import {
  OmniDevice,
  Device,
  Site,
  UpdateSensorInput,
  SensorGroup,
  SensorGroupSensor,
  Sensor,
  User,
  SensorIssueStatus,
  OmniDevicePingType
} from '../../API'
import {
  useMutateSensor,
  useMutateSensorGroupJoin,
  useFetchSensorIssuesBySensor,
  useFetchUserList,
  useListSensors,
  useFetchSensorReadings,
  getFormattedLabel
} from '../../utils/hooks'
import { SelectChangeEvent } from '../../utils/sharedInterfaces'
// Local Components
import Loading from '../Loading'
import SubHeader from '../SubHeader'
import TableView from '../TableView'
import AlertComp from '../AlertComp'
import AddAlertRuleDialog from './AddAlertRuleDialog'
import AckDialog from './AckDialog'
import { DeviceGraphWrapper } from './DeviceGraphWrapper'
import { useGlobalData } from '../../utils/hooks/global-data'

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    color: 'white'
  }
}))

interface Props {
  site: Site
  omniDeviceData: OmniDevice
  deviceData: Device
  deviceId: string
}

/**
 * This corresponds to a single omni device, with the implicit assumption that there's a 1:1 mapping of OmniDevice : Device
 */
export const DeviceView = (props: Props) => {
  const classes = useStyles()
  const { site, omniDeviceData, deviceData, deviceId } = props

  const [globalData, setGlobalData] = useGlobalData()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [ackDialogSettings, setAckDialogSettings] = useState({
    ackDialogOpen: false,
    id: ''
  })
  const { ackDialogOpen, id: ackSensorIssueID } = ackDialogSettings

  const {
    data,
    isLoading: sensorLoading,
    isError: sensorError
  } = useListSensors({ deviceID: deviceId })
  const sensorData = (data?.items ?? []).filter(
    (sensor) => !!sensor
  ) as Sensor[]
  const keyedSensors = keyBy(sensorData, 'id')
  const selectedSensor = globalData.sensor || sensorData[0]?.id

  useEffect(() => {
    if (
      !sensorLoading &&
      !sensorError &&
      !selectedSensor &&
      !isEmpty(sensorData)
    ) {
      setGlobalData({ sensor: sensorData[0]?.id })
    }
  }, [sensorData, selectedSensor, sensorLoading, sensorError, setGlobalData])

  const {
    issuesData,
    isLoading: issuesLoading,
    isError: issuesError,
    invalidateSensorIssues
  } = useFetchSensorIssuesBySensor({ sensorID: selectedSensor })

  const {
    readingsData: pingData,
    isLoading: pingLoading,
    isError: pingError
  } = useFetchSensorReadings(selectedSensor, OmniDevicePingType.AD_HOC)
  const keyedSensorIssues = keyBy(issuesData, 'id')

  const usersRes = useFetchUserList()
  const users = usersRes.data || []
  const keyedUsers = keyBy(users, 'id')
  const usersLoading = usersRes.isLoading
  const usersError = usersRes.isError

  const handleSensorChange = useCallback(
    (e: ChangeEvent<SelectChangeEvent>) => {
      const {
        target: { value }
      } = e
      setGlobalData({ sensor: value as string })
    },
    [setGlobalData]
  )

  const {
    mutateSensor,
    isLoading: mutateSensorLoading,
    isError: mutateSensorError
  } = useMutateSensor()

  const { mutateSensorGroupJoin, deleteSensorGroupJoin } =
    useMutateSensorGroupJoin()

  const handleAlertRuleChange = async (
    updatedData: Partial<UpdateSensorInput>,
    relationshipsToAdd: SensorGroup[],
    relationshipsToDelete: SensorGroupSensor[]
  ) => {
    try {
      await Promise.all([
        mutateSensor({
          id: selectedSensor,
          ...updatedData,
          _version: keyedSensors[selectedSensor]._version
        }),
        // Create any group relationship that are new in groups
        ...relationshipsToAdd.map((group) =>
          mutateSensorGroupJoin({
            sensorID: selectedSensor,
            sensorgroupID: group.id
          })
        ),
        // Delete any group relationship not in new groups
        ...relationshipsToDelete.map((relationship) =>
          deleteSensorGroupJoin({
            id: relationship.id,
            _version: relationship._version
          })
        )
      ])
      handleToggleDialog()
    } catch (e) {}
  }

  const handleToggleDialog = useCallback(
    () => setDialogOpen((isOpen) => !isOpen),
    []
  )

  const handleAckDialog = useCallback(
    (id?: string) => () =>
      setAckDialogSettings((s) => ({
        ackDialogOpen: !s.ackDialogOpen,
        id: id || ''
      })),
    []
  )

  const renderGraph = () => {
    const sensor = keyedSensors[selectedSensor]
    return (
      <DeviceGraphWrapper
        selectedSensor={sensor}
        handleToggleDialog={handleToggleDialog}
      />
    )
  }

  const columns = useMemo(
    () => renderColumns(keyedSensors, keyedUsers, classes, handleAckDialog),
    [keyedSensors, keyedUsers, classes, handleAckDialog]
  )

  const isLoading = sensorLoading || issuesLoading || usersLoading
  const isError = sensorError || issuesError || usersError

  if (isLoading) {
    return <Loading />
  }

  if (isEmpty(sensorData)) {
    return (
      <AlertComp
        severity="warning"
        message="No sensors are tied to this device"
      />
    )
  }

  return (
    <div>
      <SubHeader
        siteData={site}
        omniDeviceData={omniDeviceData}
        deviceData={deviceData}
        sensors={sensorData}
        sensorValue={selectedSensor || ''}
        onSensorChange={handleSensorChange}
      />

      <TableView
        data={issuesData}
        isError={isError}
        isLoading={isLoading}
        columns={columns}
        shareEnabled={false}
        emptyMessage="No sensor issues found for sensor and dates selected"
        graph={renderGraph()}
        title="Sensor Log"
      />

      <TableView
        data={pingData}
        columns={renderPingColumns(keyedSensors)}
        isError={pingError}
        isLoading={pingLoading}
        shareEnabled={false}
        title="Manual Sensor Pings"
        emptyMessage="No manual pings found for sensor and dates selected"
      />

      <AddAlertRuleDialog
        open={dialogOpen}
        siteID={globalData.site || undefined}
        sensor={keyedSensors[selectedSensor] || {}}
        handleClose={handleToggleDialog}
        onSave={handleAlertRuleChange as any}
        mutateError={mutateSensorError}
        mutateLoading={mutateSensorLoading}
      />

      <AckDialog
        open={ackDialogOpen}
        onClose={handleAckDialog()}
        sensorIssue={keyedSensorIssues[ackSensorIssueID]}
        invalidateSensorIssues={invalidateSensorIssues}
      />
    </div>
  )
}

const formatDateTimeCell = ({ value }: { value: GridCellValue }) => {
  if (value) {
    return format(new Date((value as number) * 1000), 'M/dd/yyyy @hh:mma')
  }
}

const renderColumns = (
  keyedSensors: Dictionary<Sensor>,
  keyedUsers: Dictionary<User>,
  classes: ClassNameMap,
  openAckDialog: (id: string) => () => void
): GridColDef[] => [
  {
    field: 'state',
    headerName: 'State',
    flex: 0.6
  },
  {
    field: 'sensorID',
    headerName: 'Sensor',
    flex: 0.7,
    renderCell({ value }) {
      return keyedSensors[value as string].name
    }
  },
  {
    field: 'userAckID',
    headerName: 'User',
    flex: 0.7,
    renderCell({ id, value, row }) {
      if (row.state === SensorIssueStatus.OPEN && !value) {
        return (
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={openAckDialog(id as string)}
          >
            Acknowledge
          </Button>
        )
      }
      return value ? keyedUsers[value as string]?.Name || 'REDACTED' : ''
    }
  },
  {
    field: 'notes',
    headerName: 'Notes',
    flex: 1
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    flex: 0.9,
    renderCell: formatDateTimeCell
  },
  {
    field: 'ackTime',
    headerName: 'Ack Time',
    flex: 0.9,
    renderCell: formatDateTimeCell
  },
  {
    field: 'lastUpdateTime',
    headerName: 'Last Updated',
    flex: 0.9,
    renderCell: formatDateTimeCell
  }
]

const getReadValue = (readValue: string) => {
  try {
    const readParsed = JSON.parse(readValue)
    const val = readParsed.Value

    if ([true, false].includes(val)) {
      return val ? 1 : 0 // Format booleans into numbers
    }
    return val
  } catch (e) {
    return ''
  }
}

const renderPingColumns = (keyedSensors: Dictionary<Sensor>): GridColDef[] => [
  {
    field: 'sensorID',
    headerName: 'Sensor',
    flex: 1,
    renderCell({ value }) {
      return keyedSensors[value as string].name
    }
  },
  {
    field: 'readTime',
    headerName: 'Ping Time',
    flex: 1,
    renderCell({ value }) {
      return format(new Date((value as number) * 1000), 'M/dd/yyyy @hh:mma')
    }
  },
  {
    field: 'readValue',
    headerName: 'Read Value',
    flex: 1,
    renderCell({ value }) {
      return getReadValue(value as string) + ' ' + getFormattedLabel(value as string)
    }
  }
]
