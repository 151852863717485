import { IFormData } from '../../utils/sharedInterfaces'

export const maintenanceScheduleForm: IFormData[] = [
  {
    name: 'description',
    text: 'Description',
    required: true
  },
  {
    name: 'frequencyInDays',
    text: 'Frequency in Days',
    type: 'number',
    required: true
  }
]

export const maintenanceEventForm: IFormData[] = [
  {
    name: 'company',
    text: 'Company',
    required: true
  },
  {
    name: 'confirmedBy',
    text: 'Maintenance Performed By',
    required: true
  },
  {
    name: 'dateCompleted',
    text: 'Date Completed',
    type: 'date',
    required: true
  },
  {
    name: 'file',
    text: 'Report Document',
    type: 'file',
    required: true
  },
  {
    name: 'notes',
    text: 'Notes',
    required: true
  }
]

export const tableHeaders = [
  {
    label: 'Maintenance Description',
    value: 'description'
  },
  {
    label: 'Frequency',
    value: 'frequencyInDays'
  },
  {
    label: 'Completed',
    value: 'dateCompleted'
  },
  {
    label: 'Next Due',
    value: 'nextDue'
  },
  {
    label: 'Notes',
    value: 'notes'
  },
  {
    label: 'Company',
    value: 'company'
  },
  {
    label: 'Performed By',
    value: 'confirmedBy'
  },
  {
    label: 'Uploaded By',
    value: 'uploadedBy'
  },
  {
    label: 'Report',
    value: 'reportLink'
  },
  {
    label: 'Actions',
    value: ''
  }
]

export const serviceContactForm = [
  {
    name: 'name',
    text: 'Name',
    required: true
  },
  {
    name: 'serviceDescription',
    text: 'Service Description',
    required: true
  },
  {
    name: 'website',
    text: 'Website',
    required: true,
    helperText: 'Must begin with http:// or https://',
    test(value: string) {
      if (!value) return false
      return !/^http(s)?:\/\//.test(value)
    }
  },
  {
    name: 'phone',
    text: 'Phone Number',
    required: true
  },
  {
    name: 'email',
    text: 'Email',
    required: true
  }
]
