import { useQueryClient, useMutation } from 'react-query'
import { MutateUserInput } from '../../../API'
import { resetUserPassword } from '../../../graphql/mutations'
import { makeGraphQlQuery } from '../../graphql'
import { useClient } from '../appData'

export const useResetPassword = () => {
  const queryClient = useQueryClient()
  const clientID = useClient()

  const resetPWDMutation = useMutation(
    (updateData: MutateUserInput) => {
      return makeGraphQlQuery({
        query: resetUserPassword,
        variables: { input: updateData }
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['users', clientID])
    }
  )

  return {
    resetPassword: resetPWDMutation.mutate,
    isLoading: resetPWDMutation.isLoading,
    isError: resetPWDMutation.isError,
    resetMutation: resetPWDMutation.reset
  }
}
