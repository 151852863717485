import { FC, useState, ChangeEvent } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { useFetchAlertRules } from '../../utils/hooks'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  autoComplete: {
    margin: theme.spacing(2, 0),
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  }
}))

interface DialogProps {
  siteId?: string | null
  sensorId: string
  alertRuleId?: string | null
  handleClose: () => void
  onSelect: (sensorId: string, alertRuleId: string | null) => void
}

const UpdateAlertRuleDialog: FC<DialogProps> = (props) => {
  const classes = useStyles()
  const {
    siteId,
    sensorId,
    alertRuleId,
    handleClose: onClose,
    onSelect
  } = props

  const [selectedAlertRuleId, setSelectedAlertRuleId] = useState(alertRuleId || null)
  const {
    alertRules,
    keyedAlertRules,
    isLoading: rulesLoading
  } = useFetchAlertRules(siteId)
  const alertRule = (selectedAlertRuleId && keyedAlertRules[selectedAlertRuleId])
    ? {
        id: keyedAlertRules[selectedAlertRuleId].id,
        name: keyedAlertRules[selectedAlertRuleId].name
      }
    : null

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    onSelect(sensorId, selectedAlertRuleId)
  }

  const handleChange = (_: ChangeEvent<{}>, rule: { id: string, name?: string | null } | null) => {
    setSelectedAlertRuleId(rule?.id || null)
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>Edit Alert Rule</Typography>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          options={alertRules.map((rule) => ({ id: rule.id, name: rule.name }))}
          value={alertRule}
          classes={{ root: classes.autoComplete }}
          getOptionLabel={(option) => option?.name || ''}
          getOptionSelected={(option, value) => value?.id === option?.id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Alert Rule"
              placeholder="Assign alert rule to sensor"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {rulesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSave}
          classes={{ root: classes.save }}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { UpdateAlertRuleDialog }
