import { useQuery } from 'react-query'
import { getAlertRule } from '../../../graphql/queries'
import { AlertRule, GetAlertRuleQuery as QueryType } from '../../../API'
import { api } from '../../gql-client'

export const ALERT_RULE_KEY = 'alertRule'

export const useFetchAlertRule = (alertRuleID?: string) => {
  const alertRuleRes = useQuery(
    [ALERT_RULE_KEY, alertRuleID],
    () => {
      if (!alertRuleID) {
        return
      }
      return api.request<QueryType>({
        query: getAlertRule,
        variables: { id: alertRuleID }
      })
    }
  )

  const alertRule = alertRuleRes?.data?.data?.getAlertRule as AlertRule

  return {
    alertRule
  }
}
