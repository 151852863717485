import { useQuery } from 'react-query'
import { listUsers as query } from '../../../graphql/queries'
import {
  ListUsersQueryVariables as VariablesType,
  ListUsersQuery as QueryType,
  User
} from '../../../API'
import { api } from '../../gql-client'

const USER_LIST_KEY = 'users-list'

type Opts = {
  enabled: boolean
}

function useFetchUserList(
  variables: VariablesType = {},
  opts: Opts = { enabled: true }
) {
  return useQuery(
    [USER_LIST_KEY],
    async () => {
      const res = await api.request<QueryType>({
        query,
        variables
      })

      return (res.data?.listUsers?.items ?? [])
        .filter((user) => !!user && !user._deleted) as User[]
    },
    { enabled: opts.enabled }
  )
}

export { USER_LIST_KEY, useFetchUserList }
