import { useQueryClient, useMutation } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  DeleteSensorGroupMutationVariables as Variables,
  DeleteSensorGroupMutation as Response
} from '../../API'
import { deleteSensorGroup as query } from '../../graphql/mutations'
import { SENSOR_GROUPS_KEY } from '../../utils/hooks'

function useDeleteSensorGroup() {
  const queryClient = useQueryClient()

  return useMutation((variables: Variables) => {
    return api.request<Response>({ query, variables })
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(SENSOR_GROUPS_KEY)
    }
  })
}

export { useDeleteSensorGroup }
