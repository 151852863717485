import { useQueryClient, useMutation } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  UpdateClientMutationVariables as Variables,
  UpdateClientMutation as Response
} from '../../API'
import { updateClient as query } from '../../graphql/mutations'
import { CLIENTS_KEY } from '../../utils/hooks/clients'

function useUpdateClient() {
  const queryClient = useQueryClient()

  return useMutation((variables: Variables) => {
    return api.request<Response>({ query, variables })
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(CLIENTS_KEY)
    }
  })
}

export { useUpdateClient }
