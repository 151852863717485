import { makeGraphQlQuery } from '../../graphql'
import { useMutation, useQueryClient } from 'react-query'
import { UpdateSensorInput } from '../../../API'
import { updateSensor } from '../../../graphql/mutations'

export const useMutateSensor = () => {
  const queryClient = useQueryClient()

  const sensorMutation = useMutation((input: UpdateSensorInput) => {
    return makeGraphQlQuery({
      query: updateSensor,
      variables: {
        input
      }
    })
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries('sensors')
    }
  })

  return {
    mutateSensor: sensorMutation.mutateAsync,
    isLoading: sensorMutation.isLoading,
    isError: sensorMutation.isError
  }
}
