// Custom listSensors query to include sensorgroups for listSensors
// Required as any updates to amplify would overwrite any manual changes made in the queries.ts file

export const listSensorsCustom = /* GraphQL */ `
  query ListSensorsCustom(
    $deviceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sensorByDeviceID(
      deviceID: $deviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        omniSensorId
        sensorType
        deviceID
        alertruleID
        hidden
        sortOrder
        energyEquation
        fullLoadKw
        sensorgroups {
          items {
            id
            sensorgroupID
            sensorgroup {
              name
              id
            }
            _version
            _deleted
          }
          nextToken
        }
        min
        max
        avg
        avgRecent
        lastReading {
          id
          readTime
          readValue
          sensorID
          pingType
          oldSensorID
          newSensorID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
