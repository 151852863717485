import { useMutation, useQueryClient } from 'react-query'
import { makeGraphQlQuery } from '../graphql'
import { OMNI_DEVICES_KEY } from './devices'

export const useMutateOmniDevice = (siteId: string) => {
  const queryClient = useQueryClient()
  const omniDeviceMutation = useMutation(makeGraphQlQuery, {
    onSuccess: () => queryClient.invalidateQueries([OMNI_DEVICES_KEY, siteId])
  })

  return {
    mutateOmniDevice: omniDeviceMutation.mutateAsync,
    resetMutation: omniDeviceMutation.reset,
    isLoading: omniDeviceMutation.isLoading,
    isError: omniDeviceMutation.isError
  }
}
