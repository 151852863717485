import { useGlobalData } from '../global-data'

export const useClient = () => {
  const [{ client }] = useGlobalData()
  return client
}

export const useSite = () => {
  const [{ site }] = useGlobalData()
  return site
}

export const useSensorGroup = () => {
  const [{ sensorGroup }] = useGlobalData()
  return sensorGroup
}
