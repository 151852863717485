import { useQueryClient, useMutation } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  CreateSiteMutationVariables as Variables,
  CreateSiteMutation as Response
} from '../../API'
import { createSite as query } from '../../graphql/mutations'
import { SITES_KEY } from '../../utils/hooks'

function useCreateSite() {
  const queryClient = useQueryClient()

  return useMutation((variables: Variables) => {
    return api.request<Response>({ query, variables })
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(SITES_KEY)
    }
  })
}

export { useCreateSite }
