import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Snackbar,
  Switch,
  Theme,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import { useState, ChangeEvent } from 'react'
import { useMutateOmniDevice } from '../../../utils/hooks'
import { ConnectionType, CreateOmniDeviceInput, OmniDevice, Site } from '../../../API'
import { createOmniDevice, updateOmniDevice } from '../../../graphql/mutations'

const validationSchema = yup.object({
  deviceName: yup.string().required('Device name is required'),
  hostname: yup.string().required('IP Address is required'),
  port: yup.number().required('Port is required'),
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required')
})

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  description: {
    fontSize: 18,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(2, 0),
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  }
}))

interface Props extends DialogProps {
  site?: Site
  omniDevice?: OmniDevice
}

const MutateOmniDeviceDialog = (props: Props) => {
  const { site, omniDevice, ...rest } = props
  const classes = useStyles()
  const { mutateOmniDevice } = useMutateOmniDevice(
    site?.id ?? omniDevice?.id ?? ''
  )
  const [value, setValue] = useState('')

  const formik = useFormik({
    initialValues: {
      deviceName: props.omniDevice?.deviceName ?? '',
      hostname: props.omniDevice?.hostname ?? props.omniDevice?.ipAddress ?? '',
      port: props.omniDevice?.port ?? 0,
      connectionType: props.omniDevice?.connectionType ?? ConnectionType.VCL,
      username: props.omniDevice?.username ?? '',
      password: props.omniDevice?.password ?? ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      const query = omniDevice ? updateOmniDevice : createOmniDevice
      const input: CreateOmniDeviceInput = {
        siteID: site?.id,
        deviceName: values.deviceName,
        hostname: values.hostname,
        port: values.port,
        connectionType: values.connectionType,
        username: values.username,
        password: values.password
      }
      if (omniDevice) {
        input.id = omniDevice.id
        input.siteID = omniDevice.siteID
        input._version = omniDevice._version
      }
      const res = await mutateOmniDevice({
        query,
        variables: { input }
      })
      console.log(res)
      setValue('Successfully mutated omni device')
      if (props.onClose) {
        props.onClose({}, 'escapeKeyDown')
      }
    }
  })

  const handleConnectionTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked ? ConnectionType.VCL : ConnectionType.LEGACY
    formik.setFieldValue('connectionType', value)
  }

  const closeSnackbar = () => setValue('')

  return (
    <>
      <Snackbar open={!!value} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success">
          {value}
        </Alert>
      </Snackbar>
      <Dialog classes={{ paper: classes.dialogPaper }} {...rest}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            disableTypography
            classes={{ root: classes.dialogTitle }}
          >
            <Typography variant="h5" className={classes.title}>
              Create Omni Device
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              classes={{ root: classes.autoComplete }}
              fullWidth
              id="deviceName"
              name="deviceName"
              label="Device Name"
              value={formik.values.deviceName}
              onChange={formik.handleChange}
              error={
                formik.touched.deviceName && Boolean(formik.errors.deviceName)
              }
              helperText={formik.touched.deviceName && formik.errors.deviceName}
            />
            <TextField
              classes={{ root: classes.autoComplete }}
              fullWidth
              id="hostname"
              name="hostname"
              label="Hostname"
              value={formik.values.hostname}
              onChange={formik.handleChange}
              error={
                formik.touched.hostname && Boolean(formik.errors.hostname)
              }
              helperText={formik.touched.hostname && formik.errors.hostname}
            />
            <TextField
              classes={{ root: classes.autoComplete }}
              fullWidth
              id="port"
              name="port"
              label="Port"
              value={formik.values.port}
              onChange={formik.handleChange}
              error={formik.touched.port && Boolean(formik.errors.port)}
              helperText={formik.touched.port && formik.errors.port}
            />
            <FormControlLabel
              label="VCL Connection"
              control={
                <Switch
                  checked={formik.values.connectionType === ConnectionType.VCL}
                  onChange={handleConnectionTypeChange}
                />
              }
            />
            <TextField
              classes={{ root: classes.autoComplete }}
              fullWidth
              id="username"
              name="username"
              label="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              classes={{ root: classes.autoComplete }}
              fullWidth
              id="password"
              name="password"
              label="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            {formik.isSubmitting && <LinearProgress />}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              classes={{ root: classes.save }}
              disabled={formik.isSubmitting}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default MutateOmniDeviceDialog
