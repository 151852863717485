import { IAppData, AppDataTypes } from '../'

export const updateClientID = (clientID: string | undefined): IAppData => ({
  type: AppDataTypes.CLIENT_ID,
  clientID
})

export const updateSiteID = (siteID: string | undefined): IAppData => ({
  type: AppDataTypes.SITE_ID,
  siteID
})

export const updateSensorGroupID = (sensorgroupID: string | undefined): IAppData => ({
  type: AppDataTypes.GROUP_ID,
  sensorgroupID
})

export const updateOmniDeviceID = (omnideviceID: string): IAppData => ({
  type: AppDataTypes.OMNI_ID,
  omnideviceID
})

export const updateDeviceID = (deviceID: string): IAppData => ({
  type: AppDataTypes.DEVICE_ID,
  deviceID
})

export const updateSensorID = (sensorID: string): IAppData => ({
  type: AppDataTypes.SENSOR_ID,
  sensorID
})

export const updateHiddenSensorsShown = (hiddenSensorsShown: boolean): IAppData => ({
  type: AppDataTypes.HIDDEN_SENSORS,
  hiddenSensorsShown
})

export const dispatches = {
  device: updateDeviceID,
  omniDevice: updateOmniDeviceID,
  group: updateSensorGroupID,
  site: updateSiteID,
  client: updateClientID
}
