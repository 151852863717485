import { makeGraphQlQuery } from '../../graphql'
import { useMutation, useQueryClient } from 'react-query'
import { Site } from '../../../API'

export const useMutateServiceContacts = (site: Site) => {
  const queryClient = useQueryClient()

  const serviceContactMutation = useMutation(makeGraphQlQuery, {
    onSuccess: () => queryClient.invalidateQueries(['serviceContacts', site])
  })

  return {
    mutateServiceContact: serviceContactMutation.mutateAsync,
    resetMutation: serviceContactMutation.reset,
    isLoading: serviceContactMutation.isLoading,
    isError: serviceContactMutation.isError
  }
}
