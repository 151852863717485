import { useState, useCallback, useMemo } from 'react'
import { keyBy } from 'lodash'
import cx from 'classnames'
import {
  Button,
  Snackbar,
  TextField
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import formatISO from 'date-fns/formatISO'
import { makeStyles } from '@material-ui/styles'
import MuiPhoneNumber from 'material-ui-phone-number'
import { IFormData } from '../../utils/sharedInterfaces'
import FileUpload from '../../components/FileUpload'
import { aryIannaTimeZones } from '../../components/Timezone/timezones'

const useStyles = makeStyles((theme: any) => ({
  textField: {
    background: 'white',
    marginTop: theme.spacing(4),
    '&:first-of-type': {
      marginTop: 0
    }
  },
  helperText: {
    margin: 0,
    paddingLeft: 14,
    color: 'white',
    backgroundColor: theme.palette.primary.light
  },
  accepted: {
    backgroundColor: 'green'
  },
  error: {
    backgroundColor: 'red'
  },
  removed: {
    backgroundColor: theme.palette.secondary.dark
  },
  snackBarMessage: {
    display: 'flex',
    justifyContent: 'center'
  },
  btnsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  deleteBtn: {
    marginRight: theme.spacing(2)
  },
  saveBtn: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

type ISnackBar = {
  status: 'accepted' | 'error' | 'removed' | ''
  message: string
}

type Props = {
  title: string
  fields: IFormData[]
  data?: Record<string, any> | null
  isLoading: boolean
  isError: boolean
  errorMsg?: string
  numberFields?: string[]
  onSave: (data: any) => void
  onDelete?: () => void
}

function FormFields({
  title,
  fields,
  isLoading,
  isError,
  data = {},
  errorMsg,
  numberFields = [],
  onSave,
  onDelete
}: Props) {
  const classes = useStyles()

  const [formData, setFormData] = useState(
    fields.reduce<any>(
      (m, field) => ({ ...m, [field.name]: data?.[field.name] }),
      {}
    )
  )

  const keyedFields = useMemo(() => keyBy(fields, 'name'), [fields])
  const isSubmitDisabled =
    isLoading ||
    Object.keys(formData).some((field) => {
      const value = formData[field]
      if (!keyedFields[field].required) {
        return false
      }
      return typeof value === 'number'
        ? value < 0
        : !value || keyedFields[field].test?.(value)
    })

  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBar>({
    status: '',
    message: ''
  })

  const handleFileChange = useCallback((name: string, file: File | string) => {
    setFormData((d: any) => ({ ...d, [name]: file }))
    setSnackBarSettings({
      status: file ? 'accepted' : 'removed',
      message: file
        ? `${(file as File).name} successfully added`
        : 'File removed'
    })
  }, [])

  const handleFileError = useCallback((message: string) => {
    setErrorMessage(message)
    if (message) {
      setSnackBarSettings({ status: 'error', message })
    }
  }, [])

  const handlePhoneChange = useCallback((value) => {
    setFormData((d: any) => ({ ...d, phone: value }))
  }, [])

  const handleTimeZoneChange = useCallback((e: any) => {
    const value = e.target.innerText
    setFormData((d: any) => ({ ...d, timezone: value }))
  }, [])

  const handleChange = useCallback(
    (e: any) => {
      const {
        target: { name, value }
      } = e
      let val = value
      if (numberFields.includes(name)) {
        val = Number(val)
      }
      setFormData((d: any) => ({ ...d, [name]: val }))
    },
    [numberFields]
  )

  const determineFieldError = (field: IFormData) => {
    if (!field.required) {
      return false
    }
    const fieldFormValue = formData[field.name]
    return fieldFormValue === '' || field.test?.(fieldFormValue)
  }

  const handleSave = useCallback(
    () =>
      onSave({
        ...formData,
        ...(data?.id
          ? {
              id: data.id,
              _version: data._version // Required for amplify to mutate document correctly
            }
          : {})
      }),
    [onSave, formData, data]
  )

  return (
    <div>
      {isError && (
        <Alert severity="error">
          {!errorMsg
            ? 'There was a problem saving your info. If this persists, please contact support.'
            : errorMsg}
        </Alert>
      )}

      {fields.map((field: IFormData) => {
        if (field.name === 'phone') {
          return (
            <MuiPhoneNumber
              size="small"
              className={classes.textField}
              key={`${title}-form-phone`}
              id={`${title}-form-phone`}
              name="phone"
              label="Phone Number"
              value={formData.phone}
              onChange={handlePhoneChange}
              defaultCountry="us"
              fullWidth
              variant="filled"
              error={!!(field.required && formData[field.name] === '')}
              disabled={isLoading}
              required={Boolean(field.required)}
            />
          )
        }

        if (field.type === 'file') {
          return (
            <FileUpload
              key={`${title}-form-${field.name}`}
              label={field.text}
              name={field.name}
              value={formData[field.name] || ''}
              disabled={isLoading}
              required={field.required ?? false}
              maxSize={1000 * 1000 * 10}
              accept=".doc,.docx,.pdf"
              error={errorMessage}
              handleChange={handleFileChange}
              handleError={handleFileError}
            />
          )
        }

        if (field.type === 'timezone') {
          return (
            <Autocomplete
              key={`${title}-form-${field.name}`}
              classes={{ root: classes.textField }}
              disablePortal
              fullWidth
              value={formData[field.name] ?? field.defaultValue ?? ''}
              onChange={handleTimeZoneChange}
              id="combo-box-demo"
              options={aryIannaTimeZones}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  key={`${title}-form-${field.name}`}
                  id={`${title}-form-${field.name}`}
                  label={`${field.text}`}
                  name={field.name}
                  value={formData[field.name] ?? field.defaultValue ?? ''}
                  fullWidth
                  variant="filled"
                  error={determineFieldError(field)}
                  helperText={field.helperText}
                  disabled={isLoading}
                  required={Boolean(field.required)}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                />
              )}
            />
          )
        }

        const isDateType = field.type === 'date'
        return (
          <TextField
            size="small"
            type={isDateType ? 'datetime-local' : undefined}
            classes={{ root: classes.textField }}
            key={`${title}-form-${field.name}`}
            id={`${title}-form-${field.name}`}
            label={`${field.text}`}
            name={field.name}
            value={formData[field.name] ?? field.defaultValue ?? ''}
            onChange={handleChange}
            fullWidth
            variant="filled"
            error={determineFieldError(field)}
            helperText={field.helperText}
            disabled={isLoading}
            required={Boolean(field.required)}
            InputLabelProps={isDateType ? { shrink: true } : {}}
            inputProps={{
              max: isDateType ? formatISO(new Date()).slice(0, 19) : undefined
            }}
            FormHelperTextProps={{
              className: classes.helperText
            }}
          />
        )
      })}

      <Snackbar
        open={!!snackBarSettings.message}
        message={snackBarSettings.message}
        autoHideDuration={3000}
        onClose={() => setSnackBarSettings({ status: '', message: '' })}
        ContentProps={{
          className: cx(
            classes.snackBarMessage,
            snackBarSettings.status ? classes[snackBarSettings.status] : ''
          )
        }}
      />

      <div className={classes.btnsContainer}>
        {onDelete && data?.id &&
          <div className={classes.deleteBtn}>
            <Button
              color="default"
              variant="contained"
              disabled={isSubmitDisabled}
              onClick={onDelete}
            >
              Delete
            </Button>
          </div>}
        <div className={classes.saveBtn}>
          <Button
            color="secondary"
            variant="contained"
            disabled={isSubmitDisabled}
            onClick={handleSave}
          >
            {`${data?.id ? 'Update' : 'Create'} ${title}`}
          </Button>
        </div>
      </div>
    </div>
  )
}

export { FormFields }
