import { FC } from 'react'
import Proptypes from 'prop-types'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme
} from '@material-ui/core/styles'

const Theme: FC = ({ children }) => {
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: '#2f516b'
      },
      secondary: {
        main: '#3e9ee9'
      },
      info: {
        main: '#002940'
      }
    },
    typography: {
      fontFamily:
        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serrif'
    },
    overrides: {
      MuiTypography: {
        root: {
          color: 'white'
        }
      },
      MuiTabs: {
        root: {
          color: 'white'
        },
        fixed: {
          alignSelf: 'flex-end'
        },
        flexContainer: {
          marginBottom: 12
        }
      },
      MuiSelect: {
        root: {
          color: 'white',
          fontSize: 18,
          fontWeight: 500,
          marginTop: 10,
          marginBottom: 10
        },
        icon: {
          fill: 'white'
        },
        select: {
          '&:focus': {
            backgroundColor: 'inherit'
          }
        }
      },
      MuiBreadcrumbs: {
        root: {
          color: 'white'
        }
      },
      MuiButton: {
        root: {
          color: 'white'
        }
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'inherit',
          '&:hover': {
            backgroundColor: 'inherit'
          },
          '&.Mui-focused': {
            backgroundColor: 'inherit'
          }
        }
      },
      MuiCardHeader: {
        root: {
          backgroundColor: '#002940',
          paddingTop: 8,
          paddingLeft: 12,
          paddingBottom: 4
        },
        title: {
          fontSize: 20,
          fontWeight: 600
        },
        subheader: {
          fontSize: 14,
          color: 'rgba(255, 255, 255, .85)',
          textAlign: 'center'
        }
      },
      MuiGrid: {
        'spacing-xs-3': {
          width: '100%',
          margin: 0
        }
      },
      // @ts-ignore
      MuiAutocomplete: {
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: 'rgba(124, 207, 255, .3)'
          },
          '&[data-focus="true"]': {
            backgroundColor: 'rgba(62, 158, 233, .4)'
          }
        }
      }
    }
  })

  return <MUIThemeProvider theme={muiTheme}>{children}</MUIThemeProvider>
}

Theme.propTypes = {
  children: Proptypes.oneOfType([
    Proptypes.arrayOf(Proptypes.node),
    Proptypes.node
  ])
}

export default Theme
