export const userResetStatus = 'RESET_REQUIRED'

export enum PermissionTypes {
  SUPER_ADMIN = 'Super Admin',
  CLIENT_ADMIN = 'Client Admin',
  CLIENT_USER = 'Client User',
  SITE_ADMIN = 'Site Admin',
  SITE_USER = 'Site User',
  NONE = 'None'
}
