import { useQuery } from 'react-query'
import { sensorByAlertRule as query } from '../../../graphql/queries'
import {
  SensorByAlertRuleQueryVariables as VariablesType,
  SensorByAlertRuleQuery as QueryType
} from '../../../API'
import { api } from '../../gql-client'

const SENSOR_BY_ALERT_RULE = 'devices'

type Opts = {
  enabled: boolean
}

function useFetchSensorsByAlertRule(
  variables: VariablesType = {},
  opts: Opts = { enabled: true }
) {
  return useQuery(
    [SENSOR_BY_ALERT_RULE, variables],
    async () => {
      const res = await api.request<QueryType>({
        query,
        variables
      })

      return res.data?.sensorByAlertRule
    },
    { enabled: opts.enabled }
  )
}

export { SENSOR_BY_ALERT_RULE, useFetchSensorsByAlertRule }
