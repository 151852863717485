import { useQuery } from 'react-query'
import { ListMaintenanceSchedulesQuery, MaintenanceSchedule } from '../../../API'
import { listMaintenanceSchedules } from '../../../graphql/queries'
import { makeGraphQlQuery } from '../../graphql'

export const useFetchMaintenanceSchedules = (siteID: string | null) => {
  const scheduleRes = useQuery<
    unknown,
    unknown,
    { data?: ListMaintenanceSchedulesQuery }
  >(['maintenanceSchedules', siteID], () => {
    if (!siteID) {
      return
    }
    return makeGraphQlQuery({
      query: listMaintenanceSchedules,
      variables: {
        filter: {
          siteID: { eq: siteID }
        }
      }
    })
  })

  const schedules = scheduleRes.data?.data?.listMaintenanceSchedules?.items ?? []

  return {
    schedules: schedules.filter((sched) => !!sched && !sched._deleted) as MaintenanceSchedule[],
    isLoading: scheduleRes.isLoading,
    isError: scheduleRes.isError
  }
}
