import { useQuery } from 'react-query'
import keyBy from 'lodash/keyBy'
import { listAlertRules } from '../../../graphql/queries'
import { ListAlertRulesQuery as QueryType, AlertRule } from '../../../API'
import { api } from '../../gql-client'

export const ALERT_RULE_LIST_KEY = 'alertRules'

export const useFetchAlertRules = (siteID?: string | null) => {
  const alertRulesRes = useQuery([ALERT_RULE_LIST_KEY, siteID], () => {
    if (!siteID) {
      return
    }
    return api.request<QueryType>({
      query: listAlertRules,
      variables: { filter: { siteID: { eq: siteID } } }
    })
  })

  const alertRules = (
    alertRulesRes?.data?.data?.listAlertRules?.items ?? []
  ).filter((rule) => !!rule && !rule._deleted) as AlertRule[]

  return {
    alertRules,
    keyedAlertRules: keyBy(alertRules, 'id'),
    isLoading: alertRulesRes.isLoading,
    isError: alertRulesRes.isError
  }
}
