import { useMutation } from 'react-query'
import { UpdateSensorIssueInput } from '../../../API'
import { updateSensorIssue } from '../../../graphql/mutations'
import { api } from '../../gql-client'

export const useMutateSensorIssue = () => {
  return useMutation(async (input: UpdateSensorIssueInput) =>
    api.request<UpdateSensorIssueInput>({
      query: updateSensorIssue,
      variables: {
        input
      }
    })
  )
}
