import { makeGraphQlQuery } from '../../graphql'
import { useMutation, useQueryClient } from 'react-query'
import { ALERT_RULE_KEY, ALERT_RULE_LIST_KEY } from '.'

export const useMutateAlertRule = (siteID?: string | null, alertRuleID?: string) => {
  const queryClient = useQueryClient()

  const alertRuleMutation = useMutation(makeGraphQlQuery, {
    onSuccess: () => {
      queryClient.invalidateQueries([ALERT_RULE_LIST_KEY, siteID])
      queryClient.invalidateQueries([ALERT_RULE_KEY, alertRuleID])
    }
  })

  return {
    mutateAlertRule: alertRuleMutation.mutateAsync,
    resetAlertRuleMutation: alertRuleMutation.reset,
    isLoading: alertRuleMutation.isLoading,
    isError: alertRuleMutation.isError
  }
}
