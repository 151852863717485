import { useQuery } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  ReportByReportTemplateAndDateQueryVariables as Variables,
  ReportByReportTemplateAndDateQuery as Response
} from '../../API'
import { REPORT_LIST_KEY } from './useFetchReportList'

const query = `
  query ReportByReportTemplateAndDate(
    $reporttemplateID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportByReportTemplateAndDate(
      reporttemplateID: $reporttemplateID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reporttemplateID
        name
        date
      }
      nextToken
      startedAt
    }
  }
`

function useFetchReportsByTemplate(variables: Variables = {}) {
  return useQuery([REPORT_LIST_KEY, variables], () => {
    return api.request<Response>({ query, variables })
  })
}

export { useFetchReportsByTemplate }
