import { Auth } from 'aws-amplify'
import { useQuery } from 'react-query'
import { GetUserQuery as QueryType } from '../../../API'
import { api } from '../../gql-client'

const CURRENT_USER_KEY = 'me'

type Opts = {
  enabled: boolean
}

const query = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      Username
      Name
      Email
      Phone
      IsAdmin
      SmsAlerts
      EmailAlerts
      AccountContactPreference
      ClientID
      SiteID
      UserStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      UserContactPreferences {
        nextToken
        startedAt
        items {
          id
          userID
          siteID
          canSendEmail
          canSendText
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`

function useFetchCurrentUser(opts: Opts = { enabled: true }) {
  // TODO(nwestman): See if there's a way to get around this explicit typecheck
  return useQuery(
    [CURRENT_USER_KEY],
    async () => {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
      const id = currentAuthenticatedUser.username

      const res = await api.request<QueryType>({
        query,
        variables: { id }
      })

      return res.data?.getUser
    },
    { enabled: opts.enabled }
  )
}

export { CURRENT_USER_KEY, useFetchCurrentUser }
