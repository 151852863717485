import { FC, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import useUserInfo from '../../redux/hooks/useUserInfo'
import { useApproveReport } from './useApproveReport'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  textField: {
    color: 'white',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '& .MuiInputBase-root': {
      width: '100%'
    }
  }
}))

interface DialogProps {
  reportId: string,
  onApprove: () => void
  onClose: () => void
}

const ApproveReportDialog: FC<DialogProps> = (props) => {
  const { reportId, onApprove, onClose } = props
  const classes = useStyles()
  const userInfo = useUserInfo()
  const approveReport = useApproveReport()
  const [comment, setComment] = useState('')

  const handleClose = () => {
    onClose()
  }

  const handleApprove = async () => {
    approveReport.mutateAsync({
      id: reportId,
      approver: userInfo.userId,
      approvalComment: comment
    })
    onApprove()
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>Approve Report</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Comment (Optional)"
          multiline
          minRows={4}
          variant="outlined"
          className={classes.textField}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={approveReport.isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleApprove}
          disabled={approveReport.isLoading}
          classes={{ root: classes.save }}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ApproveReportDialog }
