import { IAppData, AppDataTypes } from './interfaces'

export const appData = (state = {
  clientID: '',
  siteID: '',
  sensorgroupID: '',
  omnideviceID: '',
  deviceID: '',
  sensorID: '',
  hiddenSensorsShown: false
}, action: IAppData) => {
  switch (action.type) {
    case AppDataTypes.CLIENT_ID:
      return {
        ...state,
        clientID: action.clientID
      }
    case AppDataTypes.SITE_ID:
      return {
        ...state,
        siteID: action.siteID
      }
    case AppDataTypes.GROUP_ID:
      return {
        ...state,
        sensorgroupID: action.sensorgroupID
      }
    case AppDataTypes.OMNI_ID:
      return {
        ...state,
        omnideviceID: action.omnideviceID
      }
    case AppDataTypes.DEVICE_ID:
      return {
        ...state,
        deviceID: action.deviceID
      }
    case AppDataTypes.SENSOR_ID:
      return {
        ...state,
        sensorID: action.sensorID
      }
    case AppDataTypes.HIDDEN_SENSORS:
      return {
        ...state,
        hiddenSensorsShown: action.hiddenSensorsShown
      }
    default:
      return state
  }
}
