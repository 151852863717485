import { API } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import Observable from 'zen-observable-ts'
import { makeGraphQlQuery } from './graphql'

// Better types for hooks
async function request<QueryResult>(...args: Parameters<typeof API.graphql>) {
  const res = (await makeGraphQlQuery(args[0] as any)) as GraphQLResult<QueryResult>

  return res
}

async function subscription<QueryResult>(
  ...args: Parameters<typeof API.graphql>
) {
  const res = (await API.graphql(...args)) as Observable<{
    value: {
      data: QueryResult
    }
  }>

  return res
}

const api = {
  request,
  subscription
}

export { api }

const filterData = (item: any | null) => !!item

export { filterData }
