import { AlertRule, CreateAlertRuleInput } from '../../API'
import { FormikProps, useFormik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  StandardTextFieldProps,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DEFAULT_ALERT_TEXT_TEMPLATE } from './constants'
import { useMutateAlertRule } from '../../utils/hooks'
import { createAlertRule, updateAlertRule } from '../../graphql/mutations'
import { useCallback } from 'react'
import _ from 'lodash'

const validationSchema = yup.object({
  name: yup.string().required('Device name is required'),
  protocol: yup.string().required('Hostname is required'),
  minValue: yup.number().required('Minimum value required'),
  maxValue: yup
    .number()
    .moreThan(yup.ref('minValue'), 'Must be greater than the minimum value')
    .required('Maximum value is required'),
  alertDelay: yup
    .number()
    .moreThan(-1, 'Alert Delay is required and must be non-negative integer')
    .integer()
    .required('Alert Delay is required and must be non-negative integer'),
  snoozeTime: yup
    .number()
    .moreThan(-1, 'Snooze Time is required and must be non-negative integer')
    .integer()
    .required('Snooze Time is required and must be non-negative integer'),
  alertTextTemplate: yup
    .string()
    .default(DEFAULT_ALERT_TEXT_TEMPLATE)
    .required('Alert Text Template is required')
})
type FormValues = yup.InferType<typeof validationSchema>

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  title: {
    flexGrow: 1
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  textField: {
    margin: theme.spacing(2, 0),
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  }
}))

interface SimpleFieldProps extends StandardTextFieldProps {
  valueName: keyof FormValues
  formik: FormikProps<FormValues>
}
const SimpleField = ({
  valueName,
  label,
  formik,
  ...props
}: SimpleFieldProps) => {
  const classes = useStyles()
  return (
    <TextField
      classes={{ root: classes.textField }}
      fullWidth
      id={valueName}
      name={valueName}
      label={label}
      value={formik.values[valueName]}
      onChange={formik.handleChange}
      error={formik.touched[valueName] && Boolean(formik.errors[valueName])}
      helperText={formik.touched[valueName] && formik.errors[valueName]}
      {...props}
    />
  )
}

const DEFAULT_VALUES = {
  name: '',
  protocol: '',
  minValue: 0,
  maxValue: 0,
  alertDelay: 0,
  snoozeTime: 0,
  alertTextTemplate: DEFAULT_ALERT_TEXT_TEMPLATE
}

interface Props extends DialogProps {
  isEdit: boolean
  siteID?: string | null
  selectedAlertRule: AlertRule | null
}

const AlertForm = ({
  isEdit,
  siteID,
  selectedAlertRule,
  ...dialogProps
}: Props) => {
  const classes = useStyles()
  const { mutateAlertRule } = useMutateAlertRule(siteID, selectedAlertRule?.id)
  const initialValues = isEdit
    ? _.merge({}, DEFAULT_VALUES, selectedAlertRule)
    : DEFAULT_VALUES

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const query =
        selectedAlertRule && isEdit ? updateAlertRule : createAlertRule
      const input: CreateAlertRuleInput = {
        siteID,
        name: values.name,
        protocol: values.protocol,
        minValue: values.minValue,
        maxValue: values.maxValue,
        alertDelay: values.alertDelay,
        snoozeTime: values.snoozeTime,
        alertTextTemplate: values.alertTextTemplate
      }
      if (selectedAlertRule && isEdit) {
        input.id = selectedAlertRule.id
        input.siteID = selectedAlertRule.siteID
        input._version = selectedAlertRule._version
      }
      await mutateAlertRule({
        query,
        variables: { input }
      })
      dialogProps.onClose?.({}, 'escapeKeyDown')
    },
    [selectedAlertRule, isEdit, dialogProps, mutateAlertRule, siteID]
  )
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} {...dialogProps}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
          <Typography variant="h5" className={classes.title}>
            Close Issue and report any notes
          </Typography>
        </DialogTitle>
        <DialogContent>
          <SimpleField formik={formik} valueName="name" label="Name" />
          <SimpleField
            formik={formik}
            valueName="protocol"
            label="Protocol/Description"
          />
          <SimpleField
            formik={formik}
            valueName="minValue"
            label="Min Value"
            type={'number'}
          />
          <SimpleField
            formik={formik}
            valueName="maxValue"
            label="Max Value"
            type={'number'}
          />
          <SimpleField
            formik={formik}
            valueName="alertDelay"
            label="Alert Delay"
            type={'number'}
          />
          <SimpleField
            formik={formik}
            valueName="snoozeTime"
            label="Snooze Time"
            type={'number'}
          />
          <SimpleField
            formik={formik}
            valueName="alertTextTemplate"
            label="Alert Text Template"
          />
          {formik.isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            classes={{ root: classes.save }}
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AlertForm
