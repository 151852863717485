import { useQueryClient, useMutation } from 'react-query'
// import { Auth } from '@aws-amplify/auth'
// import { useDispatch } from 'react-redux'
import { MutateUserInput } from '../../../API'
import { adminUpdateUser } from '../../../graphql/mutations'
// import { updateUserInfo } from '../../../redux'
import { makeGraphQlQuery } from '../../graphql'
import { CURRENT_USER_KEY, USER_LIST_KEY } from '.'

export const useMutateUser = () => {
  // const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    (updateData: MutateUserInput) => {
      return makeGraphQlQuery({
        query: adminUpdateUser,
        variables: { input: updateData }
      })
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(USER_LIST_KEY)
        queryClient.invalidateQueries(CURRENT_USER_KEY)

        // const newUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
        // dispatch(updateUserInfo(newUser))
      }
    }
  )
}
