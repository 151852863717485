
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useFetchHeaderData } from '../utils/hooks'
import Navbar from '../components/Navbar'
import { MaintenanceOverivew } from '../components/Maintenance'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%'
  },
  nav: {
    flex: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.primary.main
  }
}))

const Maintenance = () => {
  const classes = useStyles()

  const {
    siteData,
    dataIsLoading,
    dataError
  } = useFetchHeaderData()

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <Navbar />
      </div>
      <div className={classes.content}>
        <MaintenanceOverivew
          isDataLoading={dataIsLoading}
          isDataError={dataError}
          siteData={siteData}
        />
      </div>
    </div>
  )
}

export default Maintenance
