import { FC, useMemo } from 'react'

import { Client, User, Site, ListClientsQueryVariables } from '../../API'
import { useFetchSites } from '../../utils/hooks'
import OverviewSection from './OverviewSection'
import { PermissionTypes } from './constants'
import { useListClients } from '../../utils/hooks/clients'
import { useStyles } from './User.styles'
import { UserContactPreferencesControl } from './UserContactPreferencesControl'

const getPermissions = (user: User) => {
  const permissions = []
  if (user.SiteID) {
    permissions.push(
      user.IsAdmin ? PermissionTypes.SITE_ADMIN : PermissionTypes.SITE_USER
    )
    return permissions
  }
  if (user.ClientID) {
    permissions.push(
      user.IsAdmin ? PermissionTypes.CLIENT_ADMIN : PermissionTypes.CLIENT_USER
    )
    return permissions
  }
  if (user?.IsAdmin) {
    permissions.push(PermissionTypes.SUPER_ADMIN)
    return permissions
  }
  permissions.push(PermissionTypes.NONE)
  return permissions
}

interface IUserOveriew {
  user: User,
  displayUserContactPreferencesControl?: boolean;
}

export const UserOveriew: FC<IUserOveriew> = (props) => {
  const classes = useStyles()
  const { user, displayUserContactPreferencesControl = false } = props

  const { ClientID = '', SiteID } = user

  const attrs = useMemo(
    () => [
      [
        { Name: 'First Name', Value: user.Name?.split(' ')[0] },
        { Name: 'Last Name', Value: user.Name?.split(' ')[1] }
      ],
      [
        { Name: 'Email', Value: user.Email },
        { Name: 'Phone Number', Value: user.Phone }
      ]
    ],
    [user]
  )

  const {
    sites,
    isLoading: sitesLoading,
    isError: sitesError
  } = useFetchSites(ClientID || '', SiteID || [])

  const clientFilter = ClientID ?? (SiteID && sites[0]?.clientID)
  const variables: ListClientsQueryVariables | undefined = clientFilter
    ? {
        filter: {
          id: {
            eq: clientFilter
          }
        }
      }
    : undefined

  const {
    data: clientsData,
    isLoading: clientsLoading,
    isError: clientsError
  } = useListClients(variables)
  const clients = (clientsData?.data?.listClients?.items ?? []) as Client[]

  const createClientSiteSection = (client: Client, sites: Site[]) => {
    return [
      {
        Name: 'Client',
        Value: client.name || ''
      },
      {
        Name: 'Sites',
        Value: sites
          .filter((site) => site.clientID === client.id)
          .map((site) => site.name || '')
      }
    ]
  }

  const locations = clients.map((client) => createClientSiteSection(client, sites))
  const locationsIsLoading = clientsLoading || sitesLoading
  const locationsIsError = clientsError || sitesError

  return (
    <div className={classes.userBody}>
      <OverviewSection title="Contact" data={attrs} classes={classes} />

      <OverviewSection
        title="Permissions"
        data={getPermissions(user)}
        classes={classes}
      />

      <OverviewSection
        title="Locations"
        data={locations}
        isLoading={locationsIsLoading}
        errorMsg={
          locationsIsError ? 'There was a problem fetching the users sites' : ''
        }
        classes={classes}
      />

      {displayUserContactPreferencesControl && <UserContactPreferencesControl user={user} />}

      <div className={classes.actionsContainer}>{props.children}</div>
    </div>
  )
}
