import { useDispatch } from 'react-redux'
import { updateClientID, updateDeviceID, updateOmniDeviceID, updateSiteID, IAppData } from '../../../redux'
import { useEffect } from 'react'
import { useGetDeviceByID, useGetOmniDeviceByID, useGetSiteByID } from '../'
import { useClient, useSite } from '../appData'
import { useGlobalData } from '../global-data'

export function useUpdateRedux(deviceID: string) {
  const [{ device: reduxDeviceID, omniDevice: reduxOmniID }] = useGlobalData()
  const reduxClientID = useClient()
  const reduxSiteID = useSite()

  const {
    isLoading: deviceIsLoading,
    deviceData,
    omniDeviceID
  } = useGetDeviceByID(deviceID)

  const {
    isLoading: omniDeviceIsLoading,
    omniDeviceData,
    siteID
  } = useGetOmniDeviceByID(omniDeviceID)

  const {
    isLoading: siteIsLoading,
    siteData,
    clientID
  } = useGetSiteByID(siteID)

  useCreateUE(updateDeviceID, deviceID, reduxDeviceID)
  useCreateUE(updateOmniDeviceID, omniDeviceID, reduxOmniID)
  useCreateUE(updateSiteID, siteID, reduxSiteID)
  useCreateUE(updateClientID, clientID, reduxClientID)

  return {
    site: siteData,
    omniDeviceData,
    deviceData,
    isLoading: deviceIsLoading || omniDeviceIsLoading || siteIsLoading
  }
}

const useCreateUE = (updator: (id: string) => Partial<IAppData>, id: string, reduxID?: string | null) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (id && !reduxID) {
      dispatch(updator(id))
    }
  }, [id, reduxID, updator, dispatch])
}
