import { FC } from 'react'
import { Typography } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
// Local components
import Loading from '../Loading'
import AlertComp from '../AlertComp'

type SubData = {
  Name: string
  Value?: string | string[] | null
}
type Data = string | SubData[] | null

interface IOverviewSectionProps {
  title: string
  data?: Data[] | null
  isLoading?: boolean
  errorMsg?: string
  classes: ClassNameMap
}

const OverviewSection: FC<IOverviewSectionProps> = (props) => {
  const { title, data, isLoading, errorMsg, classes } = props

  const renderArray = <T, >(section: T) => {
    if (!Array.isArray(section)) {
      return (
        <Typography variant="h6" className={classes.info}>
          {section}
        </Typography>
      )
    }
    return (
      section.map((subSection) => {
        if (typeof subSection === 'string') {
          return (
            <Typography variant="h6" className={classes.info} key={`subsection-${subSection}`}>
              {subSection}
            </Typography>
          )
        }
        return (
          <div key={`${title}-sub-${subSection.Name}`}>
            <Typography variant="h5" className={classes.subInfo}>
              {subSection.Name}
            </Typography>
            {renderArray<string | string[]>(subSection?.Value)}
          </div>
        )
      })
    )
  }

  const renderBody = () => {
    if (isLoading) {
      return <Loading />
    }

    if (errorMsg) {
      return <AlertComp severity="warning" message={errorMsg} />
    }

    return data?.map((info, i) => (
      <div
        key={`${title}-${i}`}
        className={
          Array.isArray(info) ? classes.contactSubContainer : ''
        }
      >
        {renderArray(info)}
      </div>
    ))
  }

  return (
    <div className={classes.infoContainer}>
      <Typography variant="h5" className={classes.infoTitle}>
        {title}
      </Typography>

      {renderBody()}

      {props.children}
    </div>
  )
}

export default OverviewSection
