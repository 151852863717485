import { FC, useState, ChangeEvent } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  autoComplete: {
    margin: theme.spacing(2, 0),
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  }
}))

interface DialogProps {
  siteId?: string | null
  sensorId: string
  handleClose: () => void
  onSelect: (sensorId: string, equationType: string) => void
}

const TextDialog: FC<DialogProps> = (props) => {
  const classes = useStyles()
  const { sensorId, handleClose: onClose, onSelect } = props

  const [equationType, setEquationType] = useState<any>({
    id: 'decimal',
    name: 'Decimal'
  })

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    onSelect(sensorId, equationType.id)
    handleClose()
  }
  const options = [
    {
      id: 'decimal',
      name: 'Decimal'
    },
    {
      id: 'percent',
      name: 'Percent'
    }
  ]

  const handleChange = (
    _: ChangeEvent<{}>,
    option: { id: string; name?: string | null } | null
  ) => {
    setEquationType(option)
  }

  return (
    <Dialog open onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>
          Edit Equation Type
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          options={options.map((g) => ({ id: g.id, name: g.name }))}
          value={equationType}
          classes={{ root: classes.autoComplete }}
          getOptionLabel={(option) => option?.name || ''}
          getOptionSelected={(option, value) => value?.id === option?.id}
          onChange={handleChange as any}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Energy Equation"
              placeholder="Assign Equation Type"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSave}
          classes={{ root: classes.save }}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { TextDialog }
