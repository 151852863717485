import { API, Auth } from 'aws-amplify'

interface Options {
  query: string
  variables?: object
}

/**
 * Wrapper function to force auth during backend updates
 *
 * TODO(nwestman): Gut this - cognito is the default authorizer now
 *
 * @param options query and variables
 * @returns
 * @deprecated use api.request<>
 */
export const makeGraphQlQuery = async (options: Options) => {
  const authToken = (await Auth.currentSession()).getIdToken().getJwtToken()
  return API.graphql({
    query: options.query,
    variables: options.variables,
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    authToken
  })
}
