/**
 * Get the standard deviation of an array.
 * @param array
 * @returns number
 */
const getStandardDeviation = (array: Array<number>) => {
  const n = array.length
  const mean = array.reduce((a, b) => a + b, 0) / n
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  )
}

/**
 * Gets +- 3 standard deviations from the min.
 *
 * TODO(nwestman): Make this a utility.
 *
 * @param values
 * @return [min, max]
 */
export const getStandardRange = (values: Array<number>) => {
  if (!values || !values.length) {
    return []
  }
  const n = values.length
  const mean = values.reduce((a, b) => a + b) / n
  const std = getStandardDeviation(values)
  return [mean - 3 * std, mean + 3 * std]
}
