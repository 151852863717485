import { useQuery } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  Report as ApiReport,
  GetReportQueryVariables as Variables,
  GetReportQuery as Response
} from '../../API'

const REPORT_KEY = 'report'

export type TReport = ApiReport

const query = `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      reporttemplateID
      name
      date
      data
      approver
      approvedAt
      approvalComment
      approverUser {
        id
        Username
        Name
      }
    }
  }
`

function useFetchReport(variables: Variables | null) {
  return useQuery([REPORT_KEY, variables], () => {
    if (!variables) {
      return
    }

    return api.request<Response>({ query, variables })
  })
}

export { REPORT_KEY, useFetchReport }
