import { SensorReading } from '../../../API'

const labelTypes: { [key: string]: string } = {
  V: 'Voltage',
  A: 'Amp',
  '°C': 'Celsius',
  ms: 'Milliseconds',
  undefined: 'On(1) or Off(0)',
  '°F': 'Fahrenheit',
  h: 'Hours',
  '%': 'Percentage'
}

export const getChartLabel = (sensorReading?: SensorReading) => {
  if (!sensorReading) {
    return ''
  }
  return getFormattedLabel(sensorReading?.readValue as string)
}

export const getFormattedLabel = (readValue: string) => {
  const { Unit, WatchName } = JSON.parse(readValue)
  const label = labelTypes[Unit] || ''
  return Unit ? label : WatchName
}
