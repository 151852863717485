import { graphqlOperation } from 'aws-amplify'
import { useQuery } from 'react-query'
import { bySensorPingTypeAndTime } from '../../../graphql/queries'
import {
  BySensorPingTypeAndTimeQuery,
  SensorReading,
  BySensorPingTypeAndTimeQueryVariables,
  OmniDevicePingType
} from '../../../API'
import { api } from '../../gql-client'
import { useDates } from '../..'

export const getSensorReadings = async (
  variables: BySensorPingTypeAndTimeQueryVariables,
  setPageNumber?: (pageNumber: number) => void
) => {
  if (!variables.sensorID) {
    return
  }

  const res = await api.request<BySensorPingTypeAndTimeQuery>(
    graphqlOperation(bySensorPingTypeAndTime, variables)
  )

  variables.nextToken = res.data?.bySensorPingTypeAndTime?.nextToken
  let pageNumber = 1
  setPageNumber?.(pageNumber)

  while (variables.nextToken) {
    const nextResult = await api.request<BySensorPingTypeAndTimeQuery>(
      graphqlOperation(bySensorPingTypeAndTime, variables)
    )

    res.data?.bySensorPingTypeAndTime?.items.push(
      ...(nextResult.data?.bySensorPingTypeAndTime?.items || [])
    )
    variables.nextToken = nextResult.data?.bySensorPingTypeAndTime?.nextToken
    setPageNumber?.(pageNumber++)
  }

  return res
}

export const useFetchSensorReadings = (
  sensorID: string,
  pingType: OmniDevicePingType = OmniDevicePingType.SCHEDULED
) => {
  const { from, to } = useDates()
  const between = [
    { pingType, readTime: from },
    { pingType, readTime: to }
  ]

  const variables: BySensorPingTypeAndTimeQueryVariables = {
    sensorID,
    pingTypeReadTime: { between },
    limit: 10000
  }

  const sensorReadingRes = useQuery(
    ['sensorReading', variables],
    () => getSensorReadings(variables),
    {
      refetchInterval: 1000 * 60 * 10 // 10 Minutes
    }
  )

  const { isLoading, isError, isFetching, dataUpdatedAt } = sensorReadingRes
  const listSensorReadingResult =
    sensorReadingRes.data?.data?.bySensorPingTypeAndTime
  const readingsData = (listSensorReadingResult?.items ?? []) as SensorReading[]

  return {
    readingsData,
    isLoading,
    isError,
    isFetching,
    dataUpdatedAt
  }
}
