import { OmniDevice } from '../../../API'
import StorageIcon from '@material-ui/icons/Storage'
import SettingsIcon from '@material-ui/icons/SettingsEthernet'
import { usePingOmniDevice } from '../../../utils/hooks/usePingOmniDevice'
import { Button, Grid, makeStyles, Snackbar, Theme } from '@material-ui/core'
import DataCard from '../../../components/DataCard'
import { useState } from 'react'
import { Alert } from '@material-ui/lab'
import MutateOmniDeviceDialog from './MutateOmniDeviceDialog'
import { OmniDevicePingType } from '../../../models'
import { useGetOmniDeviceByID } from '../../../utils/hooks'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: 'white'
  }
}))

interface Props {
  id: string
}

export const OmniDeviceCard = (props: Props) => {
  const classes = useStyles()
  const { mutateAsync } = usePingOmniDevice()
  const [value, setValue] = useState('')
  const closeSnackbar = () => setValue('')
  const [showDialog, setShowDialog] = useState(false)
  const toggleDialog = () => setShowDialog(!showDialog)
  const { id } = props
  const { isLoading, omniDeviceData: omni } = useGetOmniDeviceByID(id)

  if (isLoading) {
    return <div>Loading...</div>
  }

  const renderCardRows = (omni: OmniDevice) => [
    {
      icon: <StorageIcon />,
      text: `Hostname: ${omni.hostname || omni.ipAddress}`
    },
    {
      icon: <SettingsIcon />,
      text: `Port: ${omni.port}`
    }
  ]

  // TODO(nwestman): encapsulate ping into a button (probably)
  const onClick = async () => {
    const res = await mutateAsync({
      id,
      pingType: OmniDevicePingType.AD_HOC
    })
    console.log(res)
    setValue(res?.listDevicesResult || '')
  }

  return (
    <Grid key={omni.id} item>
      <MutateOmniDeviceDialog
        omniDevice={omni}
        open={showDialog}
        onClose={toggleDialog}
      />
      <DataCard
        id={omni.id}
        cardTitle={omni.deviceName || ''}
        rows={renderCardRows(omni)}
        handleToggleForm={toggleDialog}
      >
        <Button
          color="secondary"
          variant="contained"
          onClick={onClick}
          classes={{ root: classes.button }}
        >
          Ping Device Now
        </Button>
      </DataCard>
      <Snackbar open={!!value} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success">
          Obtained a value of {value}
        </Alert>
      </Snackbar>
    </Grid>
  )
}
