import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme: any) => ({
  root: {
    color: theme.palette.secondary.light
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  }
}))

const Loading = () => {
  const classes = useStyles()
  return (
    <div className={classes.loading}>
      <CircularProgress classes={{ root: classes.root }} />
    </div>
  )
}

export default Loading
