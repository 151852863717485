import { FC, useState, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Autocomplete, AutocompleteGetTagProps } from '@material-ui/lab'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  Theme
} from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
// Local Comps
import AlertComp from '../AlertComp'
import { updateAlertRule as query } from '../../graphql/mutations'
import { AlertRule, User } from '../../API'
import { useMutateAlertRule } from '../../utils/hooks'
import { maxUsersAlerted } from './constants'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 800
  }
}))

interface UserDialogProps {
  open: boolean
  handleClose: () => void
  alertRule: AlertRule
  keyedUsers: { [username: string]: User }
  userListError: boolean
}

const UserDialog: FC<UserDialogProps> = (props) => {
  const { open, handleClose, keyedUsers, userListError, alertRule } = props
  const { contactUsers, _version, siteID, id } = alertRule
  const classes = useStyles()
  const [formUsers, setFormUsers] = useState(alertRule?.contactUsers as string[])
  const maxUsersAlertedExceded = formUsers && formUsers?.length > maxUsersAlerted
  useEffect(() => {
    setFormUsers(contactUsers as string[] || [])
  }, [contactUsers])

  const {
    mutateAlertRule,
    resetAlertRuleMutation,
    isLoading,
    isError
  } = useMutateAlertRule(siteID || '', id)

  const handleSubmit = async () => {
    try {
      await mutateAlertRule({
        query,
        variables: {
          input: {
            id,
            _version,
            contactUsers: formUsers
          }
        }
      })
      handleClose()
    } catch (e) {

    }
  }

  const onClose = () => {
    handleClose()
    resetAlertRuleMutation()
    setFormUsers(alertRule.contactUsers as string[] || [])
  }

  const handleUserChange = useCallback(
    (e, value: string[]) => {
      setFormUsers(value)
    },
    [setFormUsers]
  )

  const renderTags = (
    tagValue: string[],
    getTagProps: AutocompleteGetTagProps
  ) => {
    return tagValue.map((option: string, index: number) => {
      const user = keyedUsers[option]
      if (!user) {
        return null
      }
      return <Chip key={user.id} label={user.Name} {...getTagProps({ index })} />
    })
  }

  const renderAlert = () => {
    if (!userListError) {
      return null
    }
    return <AlertComp severity="error" message="There was a problem fetching the users" />
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>Assign Users for Alerts</Typography>
      </DialogTitle>
      {renderAlert()}
      <DialogContent>
        {isError && <AlertComp severity="error" message="There was a problem saving the users" />}
        <Typography variant="h6">
          Please select which users should recieve alerts for this alert rule
        </Typography>
        <Autocomplete
          multiple
          id="users"
          value={formUsers}
          onChange={handleUserChange}
          options={Object.keys(keyedUsers)}
          getOptionLabel={(option) => keyedUsers[option]?.Name || ''}
          renderTags={renderTags}
          renderInput={(params) => (
            <TextField {...params} label="Users" placeholder="Users" />
          )}
        />
        {maxUsersAlertedExceded &&
          <Typography variant="h6">
            {`The number of users for an alert is limited to ${maxUsersAlerted}. Please remove users before saving.`}
          </Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button className={classes.save} onClick={handleSubmit} disabled={isLoading || maxUsersAlertedExceded}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserDialog
