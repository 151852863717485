import { getStandardRange } from '../../utils/dataRange'
import { Slider, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  slider: {
    color: theme.palette.secondary.light,
    marginTop: theme.spacing(5),
    cursor: 'auto',
    '& .MuiSlider-thumbColorSecondary': {
      boxShadow: 'none'
    },
    '& .MuiSlider-markLabel': {
      color: 'white'
    }
  }
}))

interface Props {
  min: number
  max: number
}

const AlertRuleSlider = ({ min, max }: Props) => {
  const classes = useStyles()
  const sliderValues = [min, max]
  const [sliderMin, sliderMax] = getStandardRange(sliderValues)
  const marks = [
    {
      value: min,
      label: `Min: ${min}`
    },
    {
      value: max,
      label: `Max: ${max}`
    }
  ]

  return (
    <Slider
      color="secondary"
      className={classes.slider}
      valueLabelDisplay="on"
      min={sliderMin}
      max={sliderMax}
      marks={marks}
      value={sliderValues}
    />
  )
}
export default AlertRuleSlider
