/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://xrlzydunqfg53lq4pjolevcklm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:c6d9d1fd-72e0-4a5c-82d9-a6ace21e90cf",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_PgH3EoTDt",
    "aws_user_pools_web_client_id": "2udicdj26tndstc8ihv5in7ve6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "crwdashboard5e908ab84b374e338ca1a10e8e78162392949-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
