import useUserInfo from '../../redux/hooks/useUserInfo'
import { Button, makeStyles, Snackbar, Theme } from '@material-ui/core'
import { useCallback, useState } from 'react'
import { Alert } from '@material-ui/lab'
import { dateToFixed } from '../../utils'

import {
  SensorReading,
  BySensorPingTypeAndTimeQueryVariables,
  OmniDevicePingType
} from '../../API'
import { getSensorReadings } from '../../utils/hooks'
import { useDownloadCsv } from '../../utils/hooks/useDownloadCsv'
import { utcToZonedTime } from 'date-fns-tz'
import { formatISO } from 'date-fns'

const getParams = (sensorID: string): BySensorPingTypeAndTimeQueryVariables => {
  const pingType = OmniDevicePingType.SCHEDULED
  const JAN_2020 = 1577918160
  const between = [
    { pingType, readTime: JAN_2020 },
    { pingType, readTime: dateToFixed(new Date().setSeconds(0, 0)) }
  ]

  return {
    sensorID,
    pingTypeReadTime: { between },
    limit: 10000
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  alertBtn: {
    marginBottom: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}))

interface Props {
  sensorID: string
  sensorName: string
  timezone: string | null
}

export const DownloadAllButton = ({
  sensorID,
  sensorName,
  timezone
}: Props) => {
  const classes = useStyles()
  const userInfo = useUserInfo()
  const [snackbarActive, setSnackbarActive] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const downloadCsv = useDownloadCsv()

  const handleDownloadCsv = (data: SensorReading[]) => {
    const csvData = data.map((d) => {
      const { Value, Unit } = JSON.parse(d.readValue ?? '')
      const zonedTime = timezone
        ? utcToZonedTime(d.updatedAt, timezone)
        : d.updatedAt

      return {
        'Read Value': Value,
        'Read Unit': Unit,
        [`Updated At (${timezone || 'local'})`]: formatISO(zonedTime as any),
        'Updated At (UTC)': d.updatedAt
      }
    })

    downloadCsv(csvData, `${sensorName} All Time`)
  }

  const closeSnackbar = useCallback(() => {
    setSnackbarActive(false)
  }, [])

  const handleClick = useCallback(async () => {
    setSnackbarActive(true)

    const variables = getParams(sensorID)
    const res = await getSensorReadings(variables, setPageNumber)
    const items = (res?.data?.bySensorPingTypeAndTime?.items ??
      []) as SensorReading[]

    handleDownloadCsv(items)

    setSnackbarActive(false)
  }, [sensorID])

  if (!userInfo.isSuperAdmin) {
    return null
  }

  return (
    <>
      <Snackbar open={snackbarActive} onClose={closeSnackbar}>
        <Alert>
          Downloaded {pageNumber} pages of data, downloading more...
        </Alert>
      </Snackbar>
      <Button
        variant="contained"
        className={classes.alertBtn}
        onClick={handleClick}
      >
        Download All Data
      </Button>
    </>
  )
}
