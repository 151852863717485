import { FC, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Grid, Theme, Typography } from '@material-ui/core'
import { useFetchDevices } from '../../utils/hooks'
import { CompareSelect } from './CompareSelect'
import AlertComp from '../AlertComp'
import SingleDeviceGraph from '../SingleDeviceGraph'
import Loading from '../Loading'
import TableHeader from '../TableHeader'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(1),
    height: theme.spacing(10),
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sensorNames: {
    marginLeft: theme.spacing(1)
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  formControl: {
    margin: theme.spacing(0, 1),
    width: 250,
    '& .MuiInputLabel-root': {
      color: 'white'
    }
  },
  inputLabel: {
    marginTop: theme.spacing(1)
  },
  menuPaper: {
    maxHeight: 300,
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    '& .MuiListItem-root.Mui-disabled': {
      opacity: 1,
      color: 'white'
    }
  },
  disabled: {
    '& span': {
      opacity: 0.3
    }
  },
  white: {
    color: 'white'
  },
  graphContainer: {
    padding: theme.spacing(2, 3)
  }
}))

export const CompareView: FC = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const initialSensorID = useMemo(() => {
    const params = new URLSearchParams(search)
    const sensorID = params.get('sensorID')
    return sensorID ? [sensorID.replace(/\s/g, '+')] : null
  }, [search])

  const [sensorsCompared, setSensorsCompared] = useState(initialSensorID || [])

  const {
    keyedSensors,
    sensorsData,
    isLoading: sensorsLoading,
    isError
  } = useFetchDevices({}, { ignoreGlobalSensorGroup: true })

  const renderGraphContainer = () => {
    if (sensorsLoading) {
      return <Loading />
    }
    if (isEmpty(sensorsCompared)) {
      return <AlertComp severity="warning" message="Please select sensors to compare" />
    }
    return sensorsCompared.map((id) => keyedSensors[id]
      ? (
        <Grid item key={id} xs={6}>
          <SingleDeviceGraph key={id} sensor={keyedSensors[id]} />
        </Grid>
        )
      : null)
  }

  if (isError) {
    return <AlertComp severity="error" message="There was a problem loading the data" />
  }

  return (
    <div>
      <div className={classes.header}>
        <div>
          <Typography variant="h6">Compare Sensors</Typography>
          <Typography className={classes.sensorNames}>
            {sensorsCompared.map((id) => keyedSensors[id]?.name).join(', ')}
          </Typography>
        </div>
        <CompareSelect
          availableSensors={sensorsData}
          keyedSensors={keyedSensors}
          sensorsCompared={sensorsCompared}
          setCompare={setSensorsCompared}
          isLoading={sensorsLoading}
          classes={classes}
        />
      </div>

      <TableHeader />

      <div className={classes.graphContainer}>
        <Grid container spacing={2}>
          {renderGraphContainer()}
        </Grid>
      </div>
    </div>
  )
}
