import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Navbar from '../components/Navbar'
import Loading from '../components/Loading'
import { Overview, CompareView } from '../components/Dashboard'
import { useFetchHeaderData } from '../utils/hooks'
import AlertComp from '../components/AlertComp'
import { useGlobalData } from '../utils/hooks/global-data'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  nav: {
    flex: 0
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main
  },
  sensorsOfflineContainer: {
    background: theme.palette.error.main,
    animation: `$flash 1000ms ${theme.transitions.easing.easeInOut} infinite`,
    color: 'white',
    fontSize: 30,
    height: '50px',
    textAlign: 'center'
  },
  '@keyframes flash': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.8
    },
    '100%': {
      opacity: 1
    }
  }
}))

const Dashboard = () => {
  const classes = useStyles()
  const { path } = useRouteMatch()
  const [{ client: clientID, site: siteID }, , globalDataToSearchParams] =
    useGlobalData()
  const { siteData, dataIsLoading } = useFetchHeaderData()

  const areSensorReadingsOffline =
    siteData?.sensorsUpdatedAt && !isInPastHour(siteData?.sensorsUpdatedAt)

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <Navbar />
      </div>

      {/* By default show the user the sensors page */}
      <Route exact path={path}>
        <Redirect to={`${path}/sensors?${globalDataToSearchParams()}`} />
      </Route>

      <div className={classes.body}>
        {areSensorReadingsOffline && <SensorsOfflineBanner siteName={siteData.name} />}

        {!siteID && (
          <AlertComp
            severity="warning"
            message={`Please select a ${!clientID ? 'Client' : 'Site'}`}
          />
        )}

        {dataIsLoading && <Loading />}
        {siteData && !dataIsLoading && (
          <>
            <Switch>
              <Route path={`${path}/compare`} component={CompareView} />
              <Route
                exact
                path={`${path}/:tab`}
                render={() => {
                  return <Overview site={siteData} />
                }}
              />
            </Switch>
          </>
        )}
      </div>
    </div>
  )
}

export default Dashboard

const SensorsOfflineBanner = (props: any) => {
  const classes = useStyles()
  const text = props.siteName
    ? `Sensors offline for site ${props.siteName}`
    : 'Sensors offline for this site'
  return (
    <>
      <div className={classes.sensorsOfflineContainer}>{text}</div>
    </>
  )
}

function isInPastHour(timestamp: number) {
  const now = Math.floor(Date.now() / 1000)
  const oneHourAgo = now - 3600
  return timestamp > oneHourAgo
}
