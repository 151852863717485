import { makeGraphQlQuery } from '../../graphql'
import { useMutation } from 'react-query'
import { adminDeleteUser } from '../../../graphql/mutations'

export const useDeleteUser = () => {
  const userDeletion = useMutation((username: string) => {
    return makeGraphQlQuery({
      query: adminDeleteUser,
      variables: { input: { username } }
    })
  })

  return {
    deleteUser: userDeletion.mutateAsync,
    resetDeletion: userDeletion.reset,
    isSuccess: userDeletion.isSuccess,
    isLoading: userDeletion.isLoading,
    isError: userDeletion.isError
  }
}
