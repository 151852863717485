import { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Client, SensorGroup, Site } from '../../API'
import { useFetchSensorGroups, useFetchSites } from '../../utils/hooks'
import { useListClients } from '../../utils/hooks/clients'
import { sensorGroupForm } from '../../utils/FormData'
import ActionHeader from '../../components/ActionHeader'
import { AutoCompleteComp, ChangeFunc } from '../../components/AutoCompleteComp'
import { FormFields } from './FormFields'
import { useCreateSensorGroup } from './useCreateSensorGroup'
import { useUpdateSensorGroup } from './useUpdateSensorGroup'
import { useDeleteSensorGroup } from './useDeleteSensorGroup'

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%'
  },
  content: {
    padding: theme.spacing(2)
  },
  selectContainer: {
    marginBottom: theme.spacing(2)
  }
}))

function SensorGroupManager() {
  const classes = useStyles()
  const [selectedClient, setSelectedClient] = useState<string | null>(null)
  const [selectedSite, setSelectedSite] = useState<string | null>(null)
  const [selectedSensorGroup, setSelectedSensorGroup] = useState<string | null>(null)
  const clientsQuery = useListClients()
  const { sites, keyedSites } = useFetchSites(selectedClient)
  const { sensorGroups, keyedSensorGroups } = useFetchSensorGroups(selectedSite)
  const createMutation = useCreateSensorGroup()
  const updateMutation = useUpdateSensorGroup()
  const deleteMutation = useDeleteSensorGroup()
  const isLoading = createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading
  const clients = (clientsQuery.data?.data?.listClients?.items ??
    []) as Client[]
  const client = clients.find((client) => client.id === selectedClient) || null
  const site = selectedSite ? keyedSites[selectedSite] : null
  const sensorGroup = selectedSensorGroup ? keyedSensorGroups[selectedSensorGroup] : null

  const getRenderedOption = useCallback(
    (test: string, prefix: string) => (option: any) =>
      (
        <div>
          {option[prefix] === test ? <em>{option[prefix]}</em> : option[prefix]}
        </div>
      ),
    []
  )

  const getOptionSelected = useCallback(
    (option: any, value: any) => option?.id === value?.id,
    []
  )

  const handleClientChange: ChangeFunc<Client> = (_, value) => {
    const { id } = value || { id: null, name: null }

    setSelectedClient(id)
    setSelectedSite(null)
  }

  const handleSiteChange: ChangeFunc<Site> = (_, value) => {
    const { id } = value || { id: null, name: null }

    setSelectedSite(id)
  }

  const handleSensorGroupChange: ChangeFunc<Site> = (_, value) => {
    const { id } = value || { id: null, name: null }

    setSelectedSensorGroup(id)
  }

  const handleSave = async (data: Record<string, any>) => {
    const mutation = data.id ? updateMutation : createMutation

    const response = await mutation.mutateAsync({
      // @ts-ignore
      input: {
        ...data,
        siteID: selectedSite
      }
    })

    if (response.data && 'createSensorGroup' in response.data) {
      setSelectedSensorGroup(response.data.createSensorGroup?.id || null)
    }
  }

  const handleDelete = () => {
    if (!selectedSensorGroup || !sensorGroup) {
      return
    }

    deleteMutation.mutateAsync({
      input: {
        id: selectedSensorGroup,
        _version: sensorGroup._version
      }
    })
    setSelectedSensorGroup(null)
  }

  return (
    <div className={classes.container}>
      <ActionHeader name="Sensor Group Manager" />

      <div className={classes.content}>
        <div className={classes.selectContainer}>
          <AutoCompleteComp
            disableClearable={false}
            value={client}
            options={clients}
            getOptionLabel={(option: Client) => option.name || ''}
            getOptionSelected={getOptionSelected}
            handleChange={handleClientChange}
            label="Client"
            renderOption={getRenderedOption('New Client', 'name')}
            checkID={selectedClient}
          />
        </div>
        {selectedClient && (
          <div className={classes.selectContainer}>
            <AutoCompleteComp
              disableClearable={false}
              value={site}
              options={sites}
              getOptionLabel={(option: Site) => option.name || ''}
              getOptionSelected={getOptionSelected}
              handleChange={handleSiteChange}
              label="Site"
              renderOption={getRenderedOption('New Site', 'name')}
              checkID={selectedSite}
            />
          </div>
        )}
        {selectedSite && (
          <div className={classes.selectContainer}>
            <AutoCompleteComp
              disableClearable={false}
              value={sensorGroup}
              options={sensorGroups}
              getOptionLabel={(option: SensorGroup) => option.name || ''}
              getOptionSelected={getOptionSelected}
              handleChange={handleSensorGroupChange}
              label="Sensor Group"
              renderOption={getRenderedOption('New Sensor Group', 'name')}
              checkID={selectedSensorGroup}
            />
          </div>
        )}
        {selectedSite &&
          <FormFields
            key={selectedSensorGroup}
            title="Sensor Group"
            isLoading={isLoading}
            isError={false}
            fields={sensorGroupForm}
            data={sensorGroup}
            onSave={handleSave}
            onDelete={handleDelete}
          />}
      </div>
    </div>
  )
}

export { SensorGroupManager }
