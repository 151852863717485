import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import { useState } from 'react'
import { Site, User, UserContactPreferences } from '../../API'
import { useFetchSites } from '../../utils/hooks'
import { useCreateUserContactPreferences } from '../../utils/hooks/users/useCreateUserContactPreferences'
import { useUpdateUserContactPreferences } from '../../utils/hooks/users/useUpdateUserContactPreferences'
import OverviewSection from './OverviewSection'
import { useStyles } from './User.styles'

interface SitePreferenceProps {
  site: Site
  user: User
  prefs?: UserContactPreferences
}

const SitePreference = ({ site, prefs, user }: SitePreferenceProps) => {
  const classes = useStyles()

  const createUserContactPreferences = useCreateUserContactPreferences()
  const updateUserContactPreferences = useUpdateUserContactPreferences()
  const [email, setEmail] = useState(prefs?.canSendEmail ?? false)
  const [text, setText] = useState(prefs?.canSendText ?? false)

  const persistPreferenceUpdate = async (
    canSendEmail: boolean,
    canSendText: boolean
  ) => {
    if (prefs) {
      await updateUserContactPreferences.mutateAsync({
        input: {
          id: prefs.id,
          siteID: site.id,
          userID: user.id,
          canSendEmail,
          canSendText,
          _version: prefs._version
        }
      })
    } else {
      await createUserContactPreferences.mutateAsync({
        input: {
          siteID: site.id,
          userID: user.id,
          canSendEmail,
          canSendText
        }
      })
    }
  }

  const toggleEmail = async () => {
    setEmail(!email)
    await persistPreferenceUpdate(!email, text)
  }

  const toggleText = async () => {
    setText(!text)
    await persistPreferenceUpdate(email, !text)
  }

  const loading =
    createUserContactPreferences.isLoading ||
    updateUserContactPreferences.isLoading

  return (
    <div key={site.id}>
      <Typography variant="h5" className={classes.subInfo}>
        {site.name}
      </Typography>
      <FormControlLabel
        label="Allow Email Alerts"
        control={
          <Switch checked={email} onChange={toggleEmail} disabled={loading} />
        }
      />
      <FormControlLabel
        label="Allow SMS Alerts"
        control={
          <Switch checked={text} onChange={toggleText} disabled={loading} />
        }
      />
    </div>
  )
}

interface Props {
  user: User
}

export const UserContactPreferencesControl = ({ user }: Props) => {
  const classes = useStyles()

  const { ClientID = '', SiteID, UserContactPreferences } = user

  const { sites, isLoading: sitesLoading } = useFetchSites(
    ClientID || '',
    SiteID || []
  )

  if (sitesLoading) {
    return null
  }

  const renderSitePreference = (site: Site) => {
    const filtered = UserContactPreferences?.items?.filter(
      (prefs) => prefs?.siteID === site.id
    )
    console.log(filtered)
    const prefs = filtered?.[0] ?? undefined
    return <SitePreference site={site} prefs={prefs} user={user} />
  }

  return (
    <OverviewSection title="Contact Preferences" classes={classes}>
      {sites.map(renderSitePreference)}
    </OverviewSection>
  )
}
