import { useState, FC, ChangeEvent, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Loading from '../Loading'
import { ReduxState } from '../../redux'
import { useFetchDevices } from '../../utils/hooks/useFetchDevices'
import { useClient, useSite } from '../../utils/hooks/appData'
import { Sensors, Issues } from './index'
import { Sensor, Site } from '../../API'
import { OmniDevicesTab } from './omni/OmniDevicesTab'
import { SensorsTableView } from './SensorsTableView'

const HIDDEN_SENSORS_VIEW_URL_KEY = 'hiddenSensors'
const TABLE_VIEW_URL_KEY = 'tableView'

const useStyles = makeStyles((theme: any) => ({
  empty: {
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  sensorToggle: {
    marginLeft: theme.spacing(2)
  },
  viewControls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  saveSensorsButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2)
  }
}))

type SensorSortOrder = 'name' | 'date' | 'order'

interface IDashboardViewProps {
  site: Site | null
  tab: string
}

type SensorCompareFn = (a: Sensor, b: Sensor) => number

const SensorCompares: Record<SensorSortOrder, SensorCompareFn> = {
  name: (a: Sensor, b: Sensor) => (a.name || '').localeCompare(b.name || ''),
  date: (a: Sensor, b: Sensor) =>
    (a.createdAt || '').localeCompare(b.createdAt || ''),
  order: (a: Sensor, b: Sensor) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0)
}

const DashboardView: FC<IDashboardViewProps> = (props) => {
  const { site, tab } = props
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const isSuperAdmin = useSelector((state: ReduxState) => state.userInfo.isSuperAdmin)
  const searchParams = new URLSearchParams(location.search)
  const showHiddenSensors = searchParams.get(HIDDEN_SENSORS_VIEW_URL_KEY) === 'true'
  const isTableView = searchParams.get(TABLE_VIEW_URL_KEY) === 'true'
  const clientID = useClient()
  const siteID = useSite()
  const [order, setOrder] = useState<SensorSortOrder>('name')

  const {
    omnisData,
    keyedOmniDevices,
    keyedDevices,
    keyedSensors,
    sensorsData,
    isLoading,
    isFetching,
    isError
  } = useFetchDevices({
    ...(!showHiddenSensors ? { hidden: { ne: true } } : {})
  })

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const nextParams = new URLSearchParams(location.search)
    nextParams.set(HIDDEN_SENSORS_VIEW_URL_KEY, `${e.target.checked}`)
    history.push({ search: nextParams.toString() })
  }

  const handleTableViewChange = (e: ChangeEvent<HTMLInputElement>) => {
    const nextParams = new URLSearchParams(location.search)
    nextParams.set(TABLE_VIEW_URL_KEY, `${e.target.checked}`)
    history.push({ search: nextParams.toString() })
  }

  const handleSortSensorsChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const {
      target: { value }
    } = e
    setOrder(value as SensorSortOrder)
  }

  const sortedSensorsData = useMemo(() => {
    return sensorsData.sort(SensorCompares[order])
  }, [sensorsData, order])

  if (!clientID || !siteID) {
    return (
      <div className={classes.empty}>
        <Typography variant="h6">
          Please select a {!clientID ? 'Client' : 'Site'}.
        </Typography>
      </div>
    )
  }

  if (isLoading || isFetching) {
    return <Loading />
  }

  if (tab === 'omniDevices' && isSuperAdmin) {
    return (
      <OmniDevicesTab
        site={site as Site}
        isError={isError}
        omniDevices={omnisData}
      />
    )
  }

  const renderHiddenSensorToggle = () => {
    if (!isSuperAdmin) {
      return null
    }

    return (
      <FormControlLabel
        label="View Hidden Sensors"
        className={classes.sensorToggle}
        control={
          <Switch checked={showHiddenSensors} onChange={handleSwitchChange} />
        }
      />
    )
  }

  const renderTableViewToggle = () => {
    return (
      <FormControlLabel
        label="Table View"
        className={classes.sensorToggle}
        control={
          <Switch checked={isTableView} onChange={handleTableViewChange} />
        }
      />
    )
  }

  const renderCardViewSortToggle = () => {
    return (
      <FormControl size="small">
        <InputLabel id="sort-order-label">Sort Order</InputLabel>
        <Select
          labelId="sort-order-label"
          id="sensor-sort-order"
          value={order}
          onChange={handleSortSensorsChange}
        >
          <MenuItem dense value={'name'}>Sensor Name</MenuItem>
          <MenuItem dense value={'order'}>Sort Order</MenuItem>
          <MenuItem dense value={'date'}>Date Created</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const renderTab = () => {
    if (isEmpty(sensorsData) || isError) {
      return (
        <div className={classes.empty}>
          <Typography variant="h6">
            No Sensors found matching selected parameters
          </Typography>
        </div>
      )
    }

    if (tab === 'sensors' && !isTableView) {
      return (
        <div>
          <div className={classes.viewControls}>
            {renderHiddenSensorToggle()}
            {renderTableViewToggle()}
            {renderCardViewSortToggle()}
          </div>
          <Sensors
            sensorsData={sortedSensorsData}
            keyedOmniDevices={keyedOmniDevices}
            keyedDevices={keyedDevices}
          />
        </div>
      )
    } else if (tab === 'sensors' && isTableView) {
      return (
        <div>
          <div className={classes.viewControls}>
            {renderHiddenSensorToggle()}
            {renderTableViewToggle()}
          </div>
          <SensorsTableView
            sensorsData={sensorsData}
            keyedDevices={keyedDevices}
          />
        </div>
      )
    } else {
      return (
        <Issues site={site} sensors={sensorsData} keyedSensors={keyedSensors} />
      )
    }
  }

  return renderTab()
}

export default DashboardView
