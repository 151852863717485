import { batch, useSelector } from 'react-redux'
import { dispatches, ReduxState } from '../redux'
import { dispatchKey } from './sharedInterfaces'

export const getResets = (key: string) => {
  const resets = Object.keys(dispatches).slice(0, 4)
  const clearIndex = resets.indexOf(key)
  if (clearIndex >= 0) {
    resets.splice(clearIndex, resets.length - clearIndex)
  }
  return resets.reduce((m, reset) => ({ ...m, [reset]: null }), {})
}

export const clearStore = (
  dispatchKey: dispatchKey,
  dataId: string,
  dispatch: any = () => {}
) => {
  const dispatchTypes = Object.keys(dispatches) as dispatchKey[]
  const keyIndex = dispatchTypes.indexOf(dispatchKey)
  const batchedCalls = dispatchTypes.slice(0, keyIndex + 1)

  batch(() => {
    batchedCalls.forEach((key, ind) => {
      ind === batchedCalls.length - 1
        ? dispatch(dispatches[key](dataId))
        : dispatch(dispatches[key](''))
    })
  })
}

export const dateToFixed = (date: number) => Number((date / 1000).toFixed())

export const useDates = () => {
  const dates = useSelector((state: ReduxState) => state.dateFilters.dates)
  const fallbackTo = new Date().setSeconds(0, 0)

  return {
    from: dateToFixed(dates.from),
    to: dateToFixed(dates.to || fallbackTo)
  }
}
