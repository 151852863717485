import { Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useCallback } from 'react'
import DeviceGraph from './DeviceGraph'
import { Sensor } from '../../API'

const useStyles = makeStyles((theme: Theme) => ({
  alertBtn: {
    marginBottom: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  editContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(12.5)
  }
}))

interface Props {
  selectedSensor: Sensor
  handleToggleDialog: ReturnType<typeof useCallback>
}

// TODO(nwestman): Delete this class.
export const DeviceGraphWrapper = (props: Props) => {
  const classes = useStyles()
  const { selectedSensor, handleToggleDialog } = props

  return (
    <>
      <div className={classes.editContainer}>
        <Button
          variant="contained"
          className={classes.alertBtn}
          onClick={handleToggleDialog}
        >
          Edit Sensor Configuration
        </Button>
      </div>

      <DeviceGraph sensor={selectedSensor} />
    </>
  )
}
