import { useMemo } from 'react'
import { graphqlOperation } from 'aws-amplify'
import get from 'lodash/get'
import { useQuery } from 'react-query'
import { getSite, getOmniDevice, getDevice } from '../../../graphql/queries'
import { OmniDevice, Site, GetDeviceQuery } from '../../../API'
import { makeGraphQlQuery } from '../../graphql'
import { useSite } from '../appData'
import { useGlobalData } from '../global-data'

export const useFetchHeaderData = () => {
  const siteID = useSite()
  const [{ omniDevice: omnideviceID, device: deviceID }] = useGlobalData()
  const siteRes = useQuery(['site', siteID], (): any => {
    if (siteID) {
      return makeGraphQlQuery({ query: getSite, variables: { id: siteID } })
    }
  })

  const omniDeviceRes = useQuery(['omniDevice', omnideviceID], (): any => {
    if (omnideviceID) {
      return makeGraphQlQuery(
        graphqlOperation(getOmniDevice, { id: omnideviceID })
      )
    }
  })

  const deviceRes = useQuery<unknown, unknown, { data?: GetDeviceQuery }>(['device', deviceID], () => {
    if (deviceID) {
      return makeGraphQlQuery(
        graphqlOperation(getDevice, { id: deviceID })
      )
    }
  })

  const siteData: Site | null = get(siteRes, 'data.data.getSite', null)
  const omniDeviceData: OmniDevice | null = get(omniDeviceRes, 'data.data.getOmniDevice', null)
  const deviceData = deviceRes?.data?.data?.getDevice

  const dataIsLoading = useMemo(
    () => siteRes.isLoading || omniDeviceRes.isLoading || deviceRes.isLoading,
    [siteRes, omniDeviceRes, deviceRes]
  )
  const dataError = useMemo(
    () => siteRes.isError || omniDeviceRes.isError || deviceRes.isError,
    [siteRes, omniDeviceRes, deviceRes]
  )

  return {
    siteID,
    siteData,
    deviceData,
    omniDeviceData,
    dataIsLoading,
    dataError
  }
}
