import { FC, useState, useCallback } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { AutoCompleteComp, ChangeFunc } from '../../components/AutoCompleteComp'
import { useFetchSites } from '../../utils/hooks'
import { useGlobalData } from '../../utils/hooks/global-data'
import { useListClients } from '../../utils/hooks/clients'
import { Client, Site } from '../../API'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  autoComplete: {
    margin: theme.spacing(2, 0),
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  },
  selectContainer: {

  }
}))

type ClientSiteDialogProps = {
  handleClose: () => void
}

const ClientSiteDialog: FC<ClientSiteDialogProps> = (props) => {
  const { handleClose } = props
  const classes = useStyles()
  const [{ client: clientID, site: siteID }, setGlobalData] = useGlobalData()
  const [selectedClient, setSelectedClient] = useState(clientID)
  const [selectedSite, setSelectedSite] = useState(siteID)
  const clientsQuery = useListClients()
  const { sites, keyedSites } = useFetchSites(selectedClient)

  const clients = (clientsQuery.data?.data?.listClients?.items ??
    []) as Client[]
  const client = clients.find((client) => client.id === selectedClient) || null
  const site = selectedSite ? keyedSites[selectedSite] : null

  const handleClientChange: ChangeFunc<Client> = (_, value) => {
    const { id } = value || { id: null, name: null }

    setSelectedClient(id)
    setSelectedSite(null)
  }

  const handleSiteChange: ChangeFunc<Site> = (_, value) => {
    const { id } = value || { id: null, name: null }

    setSelectedSite(id)
  }

  const handleSelect = () => {
    setGlobalData({ client: selectedClient, site: selectedSite, sensorGroup: null })
    handleClose()
  }

  const handleEditDialog = useCallback(
    (selection: string) => () => {},
    []
  )

  const getRenderedOption = useCallback(
    (test: string, prefix: string) => (option: any) =>
      (
        <div>
          {option[prefix] === test ? <em>{option[prefix]}</em> : option[prefix]}
        </div>
      ),
    []
  )

  const getOptionSelected = useCallback(
    (option: any, value: any) => option?.id === value?.id,
    []
  )

  return (
    <Dialog
      open
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>Select Client and Site</Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.selectContainer}>
          <AutoCompleteComp
            value={client}
            options={clients}
            getOptionLabel={(option: Client) => option.name || ''}
            getOptionSelected={getOptionSelected}
            handleChange={handleClientChange}
            label="Client"
            newOption={{ name: 'New Client' }}
            renderOption={getRenderedOption('New Client', 'name')}
            handleClick={handleEditDialog('client')}
            checkID={clientID}
          />
        </div>

        <div className={classes.selectContainer}>
          {selectedClient && (
            <AutoCompleteComp
              disableClearable={false}
              value={site}
              options={sites}
              getOptionLabel={(option: Site) => option.name || ''}
              getOptionSelected={getOptionSelected}
              handleChange={handleSiteChange}
              label="Site"
              newOption={{ name: 'New Site' }}
              renderOption={getRenderedOption('New Site', 'name')}
              handleClick={handleEditDialog('site')}
              checkID={siteID}
            />
          )}
        </div>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSelect}
          classes={{ root: classes.save }}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ClientSiteDialog }
