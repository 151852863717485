import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
// Local Comps
import { useMutateSensorIssue } from '../../utils/hooks'
import { SensorIssue, SensorIssueStatus } from '../../API'
import { ReduxState } from '../../redux'

const validationSchema = yup.object({
  notes: yup.string().required('Notes is required')
})
type FormValues = yup.InferType<typeof validationSchema>

function getAWSTimestamp() {
  return +(Date.now() / 1000).toFixed()
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  title: {
    flexGrow: 1
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  textField: {
    margin: theme.spacing(2, 0),
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  }
}))

interface AckDialogProps extends DialogProps {
  sensorIssue: SensorIssue
  invalidateSensorIssues: () => void
}

const AckDialog: FC<AckDialogProps> = (props) => {
  const classes = useStyles()
  const { sensorIssue, invalidateSensorIssues, ...dialogProps } = props
  const userID = useSelector((state: ReduxState) => state.userInfo.userId)
  const { mutateAsync: closeSensorIssue } = useMutateSensorIssue()

  const formik = useFormik({
    initialValues: {
      notes: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values: FormValues) => {
      await closeSensorIssue({
        id: sensorIssue.id,
        state: SensorIssueStatus.CLOSED,
        notes: values.notes,
        userAckID: userID,
        ackTime: getAWSTimestamp(),
        endTime: getAWSTimestamp(),
        _version: sensorIssue._version
      })
      invalidateSensorIssues()
      if (props.onClose) {
        props.onClose({}, 'escapeKeyDown')
      }
    }
  })

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} {...dialogProps}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          disableTypography
          classes={{ root: classes.dialogTitle }}
        >
          <Typography variant="h5" className={classes.title}>
            Close Issue and report any notes
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            classes={{ root: classes.textField }}
            fullWidth
            id="notes"
            name="notes"
            label="Notes"
            value={formik.values.notes}
            onChange={formik.handleChange}
            error={formik.touched.notes && Boolean(formik.errors.notes)}
            helperText={formik.touched.notes && formik.errors.notes}
          />
          {formik.isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            classes={{ root: classes.save }}
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AckDialog
