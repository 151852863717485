import { DEFAULT_ALERT_TEXT_TEMPLATE } from './constants'
import { AlertRule } from '../../API'
import AlertRecipients from './AlertRecipients'
import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AlertRuleSlider from './AlertRuleSlider'
import { DeleteAlertRuleButton } from './DeleteAlertRuleButton'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  infoContainer: {
    display: 'flex',
    width: '100%',
    '& > *': {
      width: '50%'
    }
  },
  infoBox: {
    border: '1px solid rgba(124, 207, 255, 0.5)',
    borderRadius: '7px',
    boxShadow: '2px 3px 5px 1px rgba(0, 0, 0, 0.2)',
    margin: theme.spacing(1),
    padding: theme.spacing(1, 2)
  },
  helperText: {
    color: 'rgba(255, 255, 255, 0.6)'
  },
  sidePadding: {
    margin: theme.spacing(1)
  },
  subTitle: {
    color: theme.palette.secondary.light,
    fontWeight: 600
  },
  table: {
    '& .MuiTableCell-root': {
      color: 'white',
      fontSize: theme.spacing(2)
    },
    '& .MuiTableCell-head': {
      fontWeight: 600,
      fontSize: theme.spacing(2.5)
    }
  },
  recipientsHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  addUserBtn: {
    color: 'white'
  }
}))

interface Props {
  alertRule: AlertRule
}

export const AlertRulesOverview = ({ alertRule }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.infoContainer}>
        <div>
          <div className={classes.infoBox}>
            <Typography variant="h6" className={classes.subTitle}>
              {`Alert Condition: ${alertRule.minValue} < Value < ${alertRule.maxValue}`}
            </Typography>
            <AlertRuleSlider
              min={Number(alertRule.minValue)}
              max={Number(alertRule.maxValue)}
            />
          </div>
          <div className={classes.infoBox}>
            <Typography variant="h6" className={classes.subTitle}>
              Delete Alert Rule
            </Typography>
            <DeleteAlertRuleButton alertRule={alertRule} />
          </div>
        </div>
        <div>
          <div className={classes.infoBox}>
            <Typography variant="h6" className={classes.subTitle}>
              Alert Snooze Time
            </Typography>
            <Typography>{alertRule.snoozeTime} Minutes</Typography>
            <Typography className={classes.helperText}>
              Amount of time an alert can be silenced without resolution.
            </Typography>
          </div>
          <div className={classes.infoBox}>
            <Typography variant="h6" className={classes.subTitle}>
              Initial Alert Delay
            </Typography>
            <Typography>{alertRule.alertDelay} Minutes</Typography>
            <Typography className={classes.helperText}>
              Amount of time that a condition has been met before the first
              alert is sent.
            </Typography>
          </div>
          <div className={classes.infoBox}>
            <Typography variant="h6" className={classes.subTitle}>
              Alert Template
            </Typography>
            <Typography>
              {alertRule.alertTextTemplate ?? DEFAULT_ALERT_TEXT_TEMPLATE}
            </Typography>
            <Typography className={classes.helperText}>
              The template of the alert text message to be sent to users if
              there's an issue.
              <br />
              Available template variables are &#123;&#123;alertName&#125;&#125;
              and &#123;&#123;sensorName&#125;&#125;.
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.sidePadding}>
        <Typography variant="h6" className={classes.subTitle}>
          Alert Protocol
        </Typography>
        <Typography className={classes.sidePadding}>
          {alertRule.protocol}
        </Typography>
      </div>

      <AlertRecipients alertRule={alertRule} classes={classes} />
    </div>
  )
}
