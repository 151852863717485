import { useQuery } from 'react-query'
import keyBy from 'lodash/keyBy'
import { makeGraphQlQuery } from '../../graphql'
import { listSensorGroups } from '../../../graphql/queries'
import { ListSensorGroupsQuery, SensorGroup } from '../../../API'

export const SENSOR_GROUPS_KEY = 'sensorGroups'

export const useFetchSensorGroups = (siteID?: string | null) => {
  const sensorGroupsRes = useQuery<unknown, unknown, { data?: ListSensorGroupsQuery }>([SENSOR_GROUPS_KEY, siteID], () => {
    if (!siteID) { return }
    return makeGraphQlQuery(
      {
        query: listSensorGroups,
        variables: { filter: { siteID: { eq: siteID } } }
      }
    )
  })

  const sensorGroups = (sensorGroupsRes?.data?.data?.listSensorGroups?.items ?? [])
    .filter((group) => !!group && !group._deleted) as SensorGroup[]

  return {
    sensorGroups,
    keyedSensorGroups: keyBy(sensorGroups, 'id'),
    isLoading: sensorGroupsRes.isLoading,
    isError: sensorGroupsRes.isError
  }
}
