import { useQuery } from 'react-query'
import { getDevice } from '../../../graphql/queries'
import { GetDeviceQuery, Device } from '../../../API'
import { api } from '../../../utils/gql-client'

export const useGetDeviceByID = (deviceID: string) => {
  const deviceDataRes = useQuery(['devices', deviceID], async () => {
    const res = await api.request<GetDeviceQuery>({
      query: getDevice,
      variables: { id: deviceID }
    })
    return res.data?.getDevice
  })
  const deviceData = (deviceDataRes.data || {}) as Device
  const omniDeviceID = deviceData.omnideviceID ?? ''

  return {
    isLoading: deviceDataRes.isLoading,
    omniDeviceID,
    deviceData
  }
}
