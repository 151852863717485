import { AlertRule } from '../../API'
import { useDeleteAlertRule } from '../../utils/hooks/alertRules/useDeleteAlertRule'
import { Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import { useState } from 'react'
import DeleteDialog from '../DeleteDialog'

const useStyles = makeStyles((theme: Theme) => ({
  resetBtn: {
    marginTop: theme.spacing(1.5),
    color: 'white',
    fontSize: 15
  },
  deleteBtn: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#ba000d'
    }
  }
}))

interface Props {
  alertRule: AlertRule
}

export const DeleteAlertRuleButton = ({ alertRule }: Props) => {
  const classes = useStyles()
  const [deleteOpen, setDeleteOpen] = useState(false)

  const {
    mutateAsync,
    reset,
    isSuccess,
    isLoading,
    isError
  } = useDeleteAlertRule(alertRule)

  const openDeleteDialog = () => {
    reset()
    setDeleteOpen(true)
  }

  const closeDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const renderDialog = () => {
    if (!deleteOpen) {
      return null
    }
    return (
      <DeleteDialog
        text={`Are you sure you want to delete "${alertRule?.name}"? This is a permanent action and can't be undone.`}
        title={`Delete Alert Rule — ${alertRule?.name}`}
        successText="Alert Rule has been successfully deleted"
        errorText="There was a problem deleting this alert rule"
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        onClose={closeDeleteDialog}
        onConfirm={mutateAsync}
      />
    )
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={openDeleteDialog}
        className={cx(classes.resetBtn, classes.deleteBtn)}
      >
        Delete Alert Rule
      </Button>
      {renderDialog()}
    </>
  )
}
