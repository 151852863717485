import papa from 'papaparse'

function useDownloadCsv() {
  const downloadCsv = (data: Array<Record<string, any>>, fileName: string) => {
    const csv = papa.unparse(data)
    const blob = new Blob([csv])
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = `${fileName}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return downloadCsv
}

export { useDownloadCsv }
