import { useQueryClient, useMutation } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  UpdateReportMutationVariables as Variables,
  UpdateReportTemplateMutation as Response
} from '../../API'
import { REPORT_LIST_KEY } from './useFetchReportList'
import { REPORT_KEY } from './useFetchReport'

type ApprovalInput = Pick<Variables['input'], 'id' | 'approver' | 'approvalComment'>

const query = `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      reporttemplateID
      name
      date
      approvedAt
    }
  }
`

function useApproveReport() {
  const queryClient = useQueryClient()

  return useMutation((input: ApprovalInput) => {
    const variables: Variables = {
      input: {
        ...input,
        approvedAt: new Date().getTime()
      }
    }
    return api.request<Response>({ query, variables })
  }, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(REPORT_KEY),
        queryClient.invalidateQueries(REPORT_LIST_KEY)
      ])
    }
  })
}

export { useApproveReport }
