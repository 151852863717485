import { Link } from 'react-router-dom'
import { Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AlertComp from '../components/AlertComp'

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const NotFound = () => {
  const classes = useStyles()

  return (
    <div className={classes.body}>
      <AlertComp severity="warning" message="This page either doesn't exist or you do not have sufficient privileges to view this page." />
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="secondary" style={{ color: 'white' }}>Go Home</Button>
      </Link>
    </div>
  )
}

export default NotFound
