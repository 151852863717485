import { makeGraphQlQuery } from '../../graphql'
import { useMutation, useQueryClient } from 'react-query'
import { CreateSensorGroupSensorInput, DeleteSensorGroupSensorInput } from '../../../API'
import { createSensorGroupSensor, deleteSensorGroupSensor } from '../../../graphql/mutations'

export const useMutateSensorGroupJoin = () => {
  const queryClient = useQueryClient()

  const sensorGroupJoinMutation = useMutation((input: CreateSensorGroupSensorInput) => {
    return makeGraphQlQuery({
      query: createSensorGroupSensor,
      variables: {
        input
      }
    })
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries('sensors')
    }
  })

  const sensorGroupJoinDeletion = useMutation((input: DeleteSensorGroupSensorInput) => {
    return makeGraphQlQuery({
      query: deleteSensorGroupSensor,
      variables: {
        input
      }
    })
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries('sensors')
    }
  })

  return {
    mutateSensorGroupJoin: sensorGroupJoinMutation.mutateAsync,
    deleteSensorGroupJoin: sensorGroupJoinDeletion.mutateAsync,
    isLoading: sensorGroupJoinMutation.isLoading || sensorGroupJoinDeletion.isLoading,
    isError: sensorGroupJoinMutation.isError || sensorGroupJoinDeletion.isError
  }
}
