import { FC, useState, useCallback, ChangeEvent } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Auth } from '@aws-amplify/auth'

interface IConfirmSignIn {
  parentClasses: any
  userData: any
}

const inputs: any[] = []

const ConfirmSignIn: FC<IConfirmSignIn> = (props) => {
  const { parentClasses, userData } = props

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [code, setCode] = useState<string[]>([])

  const handleConfirmSignIn = useCallback(async () => {
    setError('')
    setIsLoading(true)
    try {
      const confirmRes = await Auth.confirmSignIn(userData, code.join(''))
      if (isEmpty(confirmRes.signInUserSession) || !confirmRes.Session) {
        throw new Error('There was an issue logging in. If this persists, please contact support')
      }
    } catch (e: any) {
      setCode([])
      setError(e.message || e)
    }
    setIsLoading(false)
  }, [userData, code])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id }
    } = e
    const i = Number(id)
    const codeCopy = code.slice()
    codeCopy[i] = value
    setCode(codeCopy)
    if (value !== '' && i !== 5) {
      inputs[i + 1].focus() // Set focus for next textfield if inputted number
    } else if (value === '' && i !== 0) {
      inputs[i - 1].focus() // Set focus for previous textfield if deleted number
    }
  }

  const submitDisabled = isLoading || !code || code.join('').length !== 6

  return (
    <Card className={parentClasses.card}>
      <CardHeader className={parentClasses.header} title="Two Factor Authentication" />
      <CardContent className={parentClasses.header}>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        {/* {resentCode && (
          <Alert severity="success">A new code has been resent successfully</Alert>
        )} */}
        <Typography variant="h5">ENTER THE 6 DIGIT CODE</Typography>
        {new Array(6).fill('').map((_, ind) => (
          <TextField
            key={`code-${ind + 1}`}
            required
            id={`${ind}`}
            autoFocus={ind === 0}
            size="small"
            value={code[ind] || ''}
            disabled={isLoading}
            onChange={handleChange}
            classes={{ root: parentClasses.codeInput }}
            inputProps={{
              maxLength: 1,
              ref: (ref: HTMLInputElement) => { inputs[ind] = ref }
            }}
          />
        ))}
      </CardContent>
      <CardActions classes={{ root: parentClasses.actions }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleConfirmSignIn}
          disabled={submitDisabled}
          classes={{ root: parentClasses.button }}
        >
          Confirm Code
        </Button>
      </CardActions>
    </Card>
  )
}

export default ConfirmSignIn
