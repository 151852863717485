import { FC } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { isEmpty } from 'lodash'
import { useDownloadCsv } from '../utils/hooks/useDownloadCsv'
import AlertComp from './AlertComp'
import TableHeader from './TableHeader'
import TableContent from './TableContent'
import Loading from './Loading'

const useStyles = makeStyles((theme: any) => ({
  table: {
    color: 'white',
    border: 'none',
    margin: theme.spacing(3),
    fontSize: theme.spacing(2),
    '& .MuiDataGrid-columnsContainer': {
      fontSize: theme.spacing(3)
    },
    '& .MuiTablePagination-root': {
      color: 'white'
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'transparent'
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'underline',
    color: 'white'
  }
}))

interface ITableView {
  data: any[]
  isLoading: boolean
  isError: boolean
  columns: GridColDef[]
  csvFileName?: string
  showHeader?: boolean
  emptyMessage?: string
  shareEnabled?: boolean
  graph?: JSX.Element
  title?: string
}

const TableView: FC<ITableView> = (props) => {
  const classes = useStyles()
  const {
    data,
    isLoading,
    isError,
    columns,
    csvFileName,
    showHeader = true,
    emptyMessage,
    shareEnabled = true,
    title
  } = props
  const downloadCsv = useDownloadCsv()

  const handleDownloadCsv = () => {
    const csvData = data.map((row) => Object.values(columns)
      .reduce((acc, column) => ({
        ...acc,
        [column.headerName as string]: row[column.field]
      }), {}))

    downloadCsv(csvData, csvFileName || 'download')
  }

  if (isError) {
    return <AlertComp severity="error" />
  }
  if (isLoading) {
    return <Loading />
  }

  return (
    <div>
      {showHeader && (
        <TableHeader
          shareEnabled={shareEnabled}
          hasData={!isEmpty(data)}
          onDownloadCsv={handleDownloadCsv}
        />
      )}

      {/** TODO(nwestman): Remove graph && title from this, it's only used in device view. */}

      {props.graph}

      {title && (
        <div className={classes.title}>
          <Typography variant="h4">{title}</Typography>
        </div>
      )}

      <TableContent
        data={data}
        isLoading={isLoading}
        columns={columns}
        classes={classes}
        emptyMessage={emptyMessage}
      />
    </div>
  )
}

export default TableView
