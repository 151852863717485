import { useQueryClient, useMutation } from 'react-query'
import { api } from '../../utils/gql-client'
import {
  UpdateSiteMutationVariables as Variables,
  UpdateSiteMutation as Response
} from '../../API'
import { updateSite as query } from '../../graphql/mutations'
import { SITES_KEY } from '../../utils/hooks'

function useUpdateSite() {
  const queryClient = useQueryClient()

  return useMutation((variables: Variables) => {
    return api.request<Response>({ query, variables })
  }, {
    onSuccess: () => {
      return queryClient.invalidateQueries(SITES_KEY)
    }
  })
}

export { useUpdateSite }
