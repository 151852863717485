import { FC, useCallback, useState } from 'react'
import { Button, Grid, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import isEmpty from 'lodash/isEmpty'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import ComputerIcon from '@material-ui/icons/Computer'
import StoreIcon from '@material-ui/icons/Store'
// Local Utils
import { Site, ServiceContact, UpdateServiceContactInput } from '../../API'
import { createServiceContact, updateServiceContact } from '../../graphql/mutations'
import { useFetchServiceContacts, useMutateServiceContacts } from '../../utils/hooks'
import { serviceContactForm } from './constants'
// Local Comps
import Loading from '../Loading'
import NewForm from '../NewForm'
import AlertComp from '../AlertComp'
import DataCard from '../DataCard'

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    display: 'flex',
    height: '100%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(3, 3)
  },
  button: {
    color: 'white'
  }
}))

interface IServiceContactsProps {
  site: Site
}

const ServiceContacts: FC<IServiceContactsProps> = (props) => {
  console.log('gooooo')
  const classes = useStyles()
  const { site } = props
  const [formSettings, setFormSettings] = useState({ open: false, contactID: '' })

  const {
    mutateServiceContact,
    resetMutation,
    isLoading: mutateIsLoading,
    isError: mutateIsError
  } = useMutateServiceContacts(site)

  const {
    serviceContacts,
    keyedContacts,
    isLoading,
    isError
  } = useFetchServiceContacts(site)

  const handleToggleForm = useCallback((contactID: string = '') => () => {
    setFormSettings((settings) => ({
      open: !settings.open,
      contactID
    }))
    resetMutation()
  }, [resetMutation])

  const handleSave = useCallback(async (data: UpdateServiceContactInput) => {
    let query: typeof updateServiceContact | typeof createServiceContact
    if (data.id) {
      query = updateServiceContact
    } else {
      query = createServiceContact
      data.siteID = site.id
    }
    await mutateServiceContact({
      query,
      variables: {
        input: data
      }
    })
    handleToggleForm()()
  }, [mutateServiceContact, handleToggleForm, site])

  const formatRows = (contact: ServiceContact) => ([
    {
      icon: <StoreIcon />,
      text: contact.name
    },
    {
      icon: <PhoneIcon />,
      text: contact.phone
    },
    {
      icon: <EmailIcon />,
      text: contact.email
    },
    {
      icon: <ComputerIcon />,
      text: contact.website
    }
  ])

  const renderContactCard = (contact: ServiceContact) => (
    <Grid key={`contact-${contact.id}`} item>
      <DataCard
        id={contact.id}
        cardTitle={contact.serviceDescription || ''}
        rows={formatRows(contact)}
        handleToggleForm={handleToggleForm(contact.id)}
      />
    </Grid>
  )

  const renderFormDialog = () => {
    if (!formSettings.open) { return null }
    return (
      <NewForm
        title={`${formSettings.contactID ? 'Edit' : 'Create'} Contact`}
        fields={serviceContactForm}
        data={keyedContacts[formSettings.contactID]}
        handleClose={handleToggleForm()}
        onSave={handleSave}
        isLoading={mutateIsLoading}
        isError={mutateIsError}
      />
    )
  }

  const renderBody = () => {
    if (isEmpty(serviceContacts)) {
      return (
        <AlertComp
          severity="warning"
          message={`No Service Contacts were found for the site ${site.name}.`}
        />
      )
    }
    return (
      <Grid container spacing={3} justifyContent="center">
        {serviceContacts.map(renderContactCard)}
      </Grid>
    )
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    )
  }

  if (isError) {
    return (
      <AlertComp
        severity="error"
        message={`There was a problem fetching the service contacts for the site ${site.name}. If this persists, please contact support.`}
      />
    )
  }

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleToggleForm()}
          classes={{ root: classes.button }}
        >
          Add Contact
        </Button>
      </div>
      {renderBody()}
      {renderFormDialog()}
    </div>
  )
}

export default ServiceContacts
