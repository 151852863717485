import { useState } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import SignIn from './SignIn'
import ConfirmSignIn from './ConfirmSignIn'
import SignUp from './SignUp'
import ResetPassword from './ResetPassword'
import crwHorizontal from '../../assets/crw-horizontal.png'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    background: 'linear-gradient(110.46deg, #2F516B -6.29%, #002940 107.05%)'
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh'
  },
  card: {
    width: 670,
    background: '#2F516B'
  },
  header: {
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 0
    },
    '& .MuiFormHelperText-root:not(.Mui-error)': {
      color: theme.palette.common.white
    },
    margin: theme.spacing(2, 0)
  },
  actions: {
    justifyContent: 'space-evenly'
  },
  button: {
    color: 'white',
    width: theme.spacing(25),
    marginBottom: theme.spacing(2),
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    }
  },
  codeInput: {
    width: theme.spacing(3),
    margin: theme.spacing(3, 2),
    '& .MuiInputBase-input': {
      textAlign: 'center',
      fontSize: 20,
      color: 'white'
    },
    '& .MuiInput-underline:before': {
      borderColor: 'white'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: 'white'
    }
  },
  pic: {
    position: 'absolute',
    padding: theme.spacing(2)
  }
}))

const Authentication = () => {
  const [userData, setUserData] = useState({})
  const classes = useStyles()

  const defaultCompProps = {
    handleUserChange: setUserData,
    parentClasses: classes
  }

  return (
    <div className={classes.background}>
      <div className={classes.pic}>
        <img src={crwHorizontal} alt="CRW" />
      </div>

      <div className={classes.cardContainer}>
        <BrowserRouter>
          <Switch>
            <Redirect exact from="/" to="signIn" />
            <Route
              path="/signIn"
              render={() => <SignIn {...defaultCompProps} />}
            />
            <Route
              path="/confirmSignIn"
              render={() => (
                <ConfirmSignIn userData={userData} {...defaultCompProps} />
              )}
            />
            <Route
              path="/forgotPassword"
              render={() => (
                <ResetPassword {...defaultCompProps} userData={userData} />
              )}
            />
            <Route
              path="/signUp"
              render={() => (
                <SignUp {...defaultCompProps} userData={userData} />
              )}
            />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  )
}

export default Authentication
