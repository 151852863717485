import { FC } from 'react'
import { Alert } from '@material-ui/lab'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  empty: {
    margin: theme.spacing(2, 10),
    justifyContent: 'center'
  }
}))

interface IAlertComp {
  severity: 'error' | 'info' | 'success' | 'warning'
  message?: string
}

const AlertComp: FC<IAlertComp> = (props) => {
  const classes = useStyles()
  const {
    severity,
    message = 'There was a problem fetching your data. If this persists, please contact support.'
  } = props

  return (
    <Alert severity={severity} classes={{ root: classes.empty }}>{message}</Alert>
  )
}

export default AlertComp
