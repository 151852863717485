import { makeGraphQlQuery } from '../../graphql'
import { useMutation, useQueryClient } from 'react-query'
import { MaintenanceSchedule } from '../../../API'

export const useMutateMaintenanceEvent = (schedules: MaintenanceSchedule[]) => {
  const queryClient = useQueryClient()

  const eventMutation = useMutation(makeGraphQlQuery, {
    onSuccess: () => queryClient.invalidateQueries(['maintenanceEvents', schedules])
  })

  return {
    createEventMutate: eventMutation.mutateAsync,
    resetEventMutation: eventMutation.reset,
    isLoading: eventMutation.isLoading,
    isError: eventMutation.isError
  }
}
