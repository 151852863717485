import { isEmpty } from 'lodash'
import { useQueries, useQuery, UseQueryResult } from 'react-query'
import { listSensorsCustom as query } from '../../../graphql/customQueries'
import {
  SensorByDeviceIDQueryVariables as VariablesType,
  SensorByDeviceIDQuery as QueryType,
  Device,
  Sensor,
  ModelSensorFilterInput
} from '../../../API'
import { api } from '../../gql-client'

const SENSORS_KEY = 'sensors'
const ALL_SENSORS_KEY = 'all-sensors'

type Opts = {
  enabled: boolean
}

function useListSensors(
  variables: VariablesType = {},
  opts: Opts = { enabled: true }
) {
  variables = { ...variables, limit: 1000 }
  return useQuery(
    [SENSORS_KEY, variables],
    async () => {
      const res = await api.request<QueryType>({
        query,
        variables
      })

      return res.data?.sensorByDeviceID
    },
    { enabled: opts.enabled }
  )
}

const reduceQueries = (
  queries: UseQueryResult[],
  property: keyof UseQueryResult
) => queries.reduce((prev, next) => prev || !!next[property], false)

function useListAllSensors(devices: Device[], filter: ModelSensorFilterInput) {
  const queries = useQueries(
    devices.map((device) => {
      const variables : VariablesType = {
        deviceID: device.id,
        filter: isEmpty(filter) ? undefined : filter,
        limit: 1000
      }
      return {
        queryKey: [ALL_SENSORS_KEY, variables],
        queryFn: () => {
          if (!variables.deviceID) {
            return
          }
          return api.request<QueryType>({
            query,
            variables
          })
        }
      }
    })
  )

  const sensors = queries.reduce((prev, next) => {
    const sensors = (next?.data?.data?.sensorByDeviceID?.items ??
      []) as Sensor[]
    return prev.concat(sensors)
  }, [] as Sensor[])

  return {
    sensors,
    isLoading: reduceQueries(queries, 'isLoading'),
    isError: reduceQueries(queries, 'isError'),
    isFetching: reduceQueries(queries, 'isFetching')
  }
}

export { SENSORS_KEY, useListSensors, useListAllSensors }
