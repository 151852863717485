import { Link } from 'react-router-dom'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { Line } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  FormControlLabel,
  Switch,
  Theme,
  Typography
} from '@material-ui/core'
import { Sensor, SensorReading } from '../../API'
import {
  useFetchSensorReadings,
  useGetSiteByID,
  useGraph
} from '../../utils/hooks'
import { useGlobalData } from '../../utils/hooks/global-data'
import { SetOffsetButton } from './SetOffsetButton'
import DeviceGraphShareButton from './DeviceGraphShareButton'
import { Loading } from 'aws-amplify-react'
import _ from 'lodash'
import { useState } from 'react'
import { useSite } from '../../utils/hooks/appData'
import { DownloadAllButton } from './DownloadAllButton'

const useStyles = makeStyles((theme: Theme) => ({
  graph: {
    width: '100%',
    flex: '1'
  },
  chartContainer: {
    margin: theme.spacing(1, 3),
    display: 'flex',
    maxWidth: '100%',
    flexDirection: 'column'
  },
  lastReading: {
    marginLeft: theme.spacing(2),
    minWidth: '10em',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      // This targets all direct children
      margin: theme.spacing(1)
    }
  },
  readTitle: {
    color: theme.palette.secondary.light,
    fontWeight: 700,
    marginBottom: theme.spacing(1)
  },
  readTime: {
    color: 'rgba(255, 255, 255, .65)',
    margin: theme.spacing(2, 0)
  },
  alertBtn: {
    marginBottom: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    },
    minWidth: '130px'
  }
}))

interface Props {
  sensor: Sensor
}

const formatSensorReading = (reading?: SensorReading) => {
  if (!reading) {
    return null
  }

  const { Value, Unit, WatchName } = JSON.parse(reading.readValue as string)
  const time = format(parseISO(reading.updatedAt), 'h:mma M/d/yyyy')
  let readValue = `${Value} ${Unit || WatchName}`
  if ([true, false].includes(Value)) {
    readValue = Value ? 'On' : 'Off'
  }

  return {
    readValue,
    time
  }
}

// TODO(nwestman): De-dupe with SingleDeviceGraph
const DeviceGraph = ({ sensor }: Props) => {
  const alertruleID = sensor.alertruleID ?? ''
  const sensorID = sensor.id
  const classes = useStyles()
  const [, , globalDataToSearchParams] = useGlobalData()
  const [showMovingAverage, setShowMovingAverage] = useState(false)
  const siteId = useSite()
  const { siteData } = useGetSiteByID(siteId)

  const toggleShowMovingAverage = () => {
    setShowMovingAverage(!showMovingAverage)
  }

  const { readingsData: data, isLoading } = useFetchSensorReadings(sensorID)

  const { lineData, options } = useGraph(data, alertruleID, sensor, {
    showMovingAverage
  })
  const lastestReading = formatSensorReading(data[data.length - 1])

  const renderGraph = () => {
    if (isLoading) {
      // @ts-ignore Looks like a conflict between @types/react in dependencies
      return <Loading />
    }
    if (_.isEmpty(data)) {
      return null
    }
    return (
      // @ts-ignore Looks like a conflict between @types/react in dependencies
      <Line id="data-chart" data={lineData} options={options} height={450} />
    )
  }

  return (
    <div className={classes.chartContainer}>
      <div className={classes.graph}>{renderGraph()}</div>
      <div className={classes.lastReading}>
        <Button variant="contained" className={classes.alertBtn}>
          <Link
            to={`/dashboard/compare?${globalDataToSearchParams({ sensorID })}`}
          >
            Compare
          </Link>
        </Button>
        <Typography variant="h6" className={classes.readTitle}>
          Last Reading
        </Typography>
        <Typography variant="h6" style={{ whiteSpace: 'nowrap' }}>
          {lastestReading?.readValue}
        </Typography>
        <Typography variant="h6" className={classes.readTime}>
          {lastestReading?.time}
        </Typography>

        <DeviceGraphShareButton
          data={data}
          sensorID={sensorID}
          sensorName={sensor.name || ''}
          timezone={siteData.timezone || null}
        />

        <SetOffsetButton sensorID={sensorID} />

        <DownloadAllButton
          sensorID={sensorID}
          sensorName={sensor.name || ''}
          timezone={siteData.timezone || null}
        />

        <FormControlLabel
          label="Average"
          control={
            <Switch
              checked={showMovingAverage}
              onChange={toggleShowMovingAverage}
            />
          }
        />
      </div>
    </div>
  )
}

export default DeviceGraph
