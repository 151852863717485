import React, { useState, useCallback, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import get from 'lodash/get'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import { clearStore } from '../../utils'
import { sensorGroupForm } from '../../utils/FormData'
import { makeGraphQlQuery } from '../../utils/graphql'
import { useFetchSensorGroups } from '../../utils/hooks'
import { useGlobalData } from '../../utils/hooks/global-data'
import { dispatchKey } from '../../utils/sharedInterfaces'
import {
  createSensorGroup,
  updateSensorGroup
} from '../../graphql/mutations'
import { SensorGroup } from '../../API'
import { AutoCompleteComp, ChangeFunc } from '../AutoCompleteComp'
import NewForm from '../NewForm'

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(1.5)
  },
  selector: {
    flex: 'none',
    minWidth: '300px',
    marginRight: theme.spacing(1.5)
  },
  editIcon: {
    color: 'white'
  }
}))

type Props = {
  selectors: Array<'sensorGroup'>
}

function GlobalDataSelection(props: Props) {
  const { selectors } = props
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [activeForm, setActiveForm] = useState('')
  const [editSettings, setEditSettings] = useState({
    editOpen: false,
    editSelection: ''
  })

  const { editOpen, editSelection } = editSettings

  const [{
    client: clientID,
    site: siteID,
    sensorGroup: sensorgroupID
  }, setGlobalData] = useGlobalData()

  const resetData = useCallback(
    async (updatedKey: string, dataRes: object) => {
      const dataId = get(dataRes, 'id', '')
      let localUpdate: dispatchKey = 'omniDevice'
      /* Check what was created and invalidate that list
       * Clear out the dependent fields
       * Set that field to the new ID
       */
      if (updatedKey.includes('Client')) {
        await queryClient.invalidateQueries('clients')
        localUpdate = 'client'
      } else if (updatedKey.includes('Site')) {
        await queryClient.invalidateQueries(['sites', clientID])
        localUpdate = 'site'
      } else if (updatedKey.includes('Group')) {
        await queryClient.invalidateQueries(['sensorGroups', siteID])
        localUpdate = 'group'
      } else {
        await queryClient.invalidateQueries(['omniDevices', sensorgroupID])
      }
      clearStore(localUpdate, dataId)
    },
    [queryClient, clientID, siteID, sensorgroupID]
  )

  const { sensorGroups, keyedSensorGroups } = useFetchSensorGroups(siteID)
  const sensorGroup = sensorgroupID
    ? keyedSensorGroups[sensorgroupID]
    : undefined

  const dataMutation = useMutation(makeGraphQlQuery, {
    onSuccess: async (dataRes: any) => {
      const dataKey = Object.keys(dataRes.data)[0]
      const data = get(dataRes, `data.${dataKey}`, {})
      await resetData(dataKey, data)
      setActiveForm('')
      if (editOpen) {
        setEditSettings({
          editOpen: false,
          editSelection: ''
        })
      }
    }
  })

  const { isLoading, isError } = dataMutation

  const handleGroupChange: ChangeFunc<SensorGroup> = (_, value) => {
    const { id, name } = value || { id: null, name: null }

    if (name === 'New Group') {
      setActiveForm(name)
      return
    }

    setGlobalData({ sensorGroup: id })
  }

  const handleClose = useCallback(() => setActiveForm(''), [])

  const handleSave = useCallback(
    (query: any, relationship: { [key: string]: string | null | undefined } = {}) =>
      async (data: object) => {
        await dataMutation.mutateAsync({
          query,
          variables: {
            input: {
              ...data,
              ...relationship
            }
          }
        })
      },
    [dataMutation]
  )

  const handleEditDialog = useCallback(
    (selection: string) => () => {
      setEditSettings((settings) => ({
        editOpen: !settings.editOpen,
        editSelection: settings.editSelection ? '' : selection
      }))
      setActiveForm(selection ? `Edit ${selection}` : '')
    },
    []
  )

  const getRenderedOption = useCallback(
    (test: string, prefix: string) => (option: any) =>
      (
        <div>
          {option[prefix] === test ? <em>{option[prefix]}</em> : option[prefix]}
        </div>
      ),
    []
  )

  const getOptionSelected = useCallback(
    (option: any, value: any) => option?.id === value?.id,
    []
  )

  const newFormDefaultProps = useMemo(
    () => ({
      title: activeForm,
      isLoading,
      isError,
      handleClose
    }),
    [activeForm, isLoading, isError, handleClose]
  )

  const renderCreateForm = () => {
    if (activeForm === 'New Group') {
      return (
        <NewForm
          {...newFormDefaultProps}
          fields={sensorGroupForm}
          onSave={handleSave(createSensorGroup, { siteID })}
        />
      )
    }
  }

  const renderEditForm = () => {
    if (!editOpen) {
      return null
    }

    if (editSelection === 'group') {
      return (
        <NewForm
          {...newFormDefaultProps}
          handleClose={handleEditDialog('')}
          fields={sensorGroupForm}
          data={sensorGroup}
          onSave={handleSave(updateSensorGroup)}
        />
      )
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.selector}>
        {selectors.includes('sensorGroup') && (
          <AutoCompleteComp
            disableClearable={false}
            value={sensorGroup || null}
            options={sensorGroups}
            getOptionLabel={(option: SensorGroup) => option.name || ''}
            getOptionSelected={getOptionSelected}
            handleChange={handleGroupChange}
            label="Sensor Group"
            newOption={{ name: 'New Group' }}
            renderOption={getRenderedOption('New Group', 'name')}
            handleClick={handleEditDialog('group')}
            Icon={EditIcon}
            checkID={sensorgroupID}
          />
        )}
      </div>

      {renderCreateForm()}

      {renderEditForm()}
    </div>
  )
}

export { GlobalDataSelection }
