import { makeGraphQlQuery } from '../../graphql'
import { useMutation, useQueryClient } from 'react-query'
import { Site } from '../../../API'

export const useMutateMaintenanceSchedule = (site: Site) => {
  const queryClient = useQueryClient()

  const scheduleMutation = useMutation(makeGraphQlQuery, {
    onSuccess: () => queryClient.invalidateQueries(['maintenanceSchedules', site.id])
  })

  return {
    mutateSchedule: scheduleMutation.mutateAsync,
    resetMutation: scheduleMutation.reset,
    isLoading: scheduleMutation.isLoading,
    isError: scheduleMutation.isError
  }
}
