import { FC, ChangeEvent, useState, useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import {
  Breadcrumbs,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper
} from '@material-ui/core'
import NavBar from '../../components/Navbar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/styles'
import ActionHeader from '../../components/ActionHeader'
import Loading from '../../components/Loading'
import { ModelSortDirection, Report as TReport } from '../../API'
import { useFetchUserList } from '../../utils/hooks/users'
import { useGlobalData } from '../../utils/hooks/global-data'
import { convert24Hourto12Hour } from '../../utils/format-time'
import { isTruthy } from '../../utils/is-truthy'
import { ApproveReportDialog } from './ApproveReportDialog'
import { useApproveReport } from './useApproveReport'
import { useFetchReportsByTemplate } from './useFetchReportsByTemplate'
import { useFetchTemplate, TReportTemplate } from './useFetchTemplate'
import { useFetchReport } from './useFetchReport'
import { formatApprovalData, useDownloadCsv } from './useDownloadCsv'

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.primary.main
  },
  nav: {
    flex: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  },
  toolbarControls: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '& > *': {
      marginLeft: theme.spacing(4)
    }
  },
  link: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  tableContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    paddingTop: 0
  },
  dateScrubber: {
    display: 'flex',
    alignItems: 'center'
  }
}))

function useReportApprover(report: TReport | null) {
  const approverRes = useFetchUserList()
  const approver = approverRes?.data?.find((user) => report?.approver === user.id) || null

  return approver
}

type ApprovalStatusProps = {
  report: TReport | null
}
const ApprovalStatus: FC<ApprovalStatusProps> = ({ report }) => {
  const approveReport = useApproveReport()
  const approver = useReportApprover(report)
  const [isApproveReportDialogOpen, setIsApproveReportDialogOpen] = useState(false)

  const handleOpenApproveDialog = () => {
    setIsApproveReportDialogOpen(true)
  }

  const handleCloseApproveDialog = () => {
    setIsApproveReportDialogOpen(false)
  }

  if (!report) {
    return null
  } else if (report.approver) {
    return (
      <Typography>
        {formatApprovalData(approver?.Name || 'Unknown', report.approvedAt || null)}
      </Typography>
    )
  } else {
    return (
      <>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleOpenApproveDialog}
          disabled={approveReport.isLoading}
        >
          Approve
        </Button>
        {isApproveReportDialogOpen &&
          <ApproveReportDialog
            reportId={report.id}
            onApprove={handleCloseApproveDialog}
            onClose={handleCloseApproveDialog}
          />}
      </>
    )
  }
}

type ReportTableRowProps = {
  data: Record<string, any>
}
const ReportTableRow: FC<ReportTableRowProps> = ({ data }) => {
  const [start, end] = Object.values(data)

  if (!start?.readValue || !end?.readValue) {
    return (
      <TableRow>
        <TableCell component="th" scope="row">
          Err
        </TableCell>
        <TableCell>Err</TableCell>
        <TableCell>Err</TableCell>
      </TableRow>
    )
  }
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {start.readValue.WatchName}
      </TableCell>
      <TableCell>{start.readValue.Value.toString()}</TableCell>
      <TableCell>{end.readValue.Value.toString()}</TableCell>
    </TableRow>
  )
}

type ReportTableProps = {
  template: TReportTemplate,
  report: TReport | null
}
const ReportTable: FC<ReportTableProps> = ({ template, report }) => {
  // @ts-ignore
  const data: Record<string, Record<string, any>> = JSON.parse(report?.data || '{}')

  if (!report) {
    return (
      <div>
        Please select a report.
      </div>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{convert24Hourto12Hour(template.times?.[0] || null)}</TableCell>
            <TableCell>{convert24Hourto12Hour(template.times?.[1] || null)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data).map((key, idx) => (
            <ReportTableRow key={idx} data={data[key]} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Report: FC = () => {
  const classes = useStyles()
  const [, , globalDataToSearchParams] = useGlobalData()
  const { reportTemplateId } = useParams<{ reportTemplateId: string }>()
  const {
    data: templateData,
    isLoading: isLoadingTemplate
  } = useFetchTemplate({ id: reportTemplateId })
  const {
    data: reportList,
    isLoading: isLoadingReportList
  } = useFetchReportsByTemplate({ reporttemplateID: reportTemplateId, sortDirection: ModelSortDirection.DESC })
  const reports = useMemo(() => reportList?.data?.reportByReportTemplateAndDate?.items
    .filter(isTruthy)
    .sort((a, b) => {
      const aDate = a.date || ''
      const bDate = b.date || ''

      if (aDate < bDate) {
        return 1
      } else if (aDate > bDate) {
        return -1
      }

      return 0
    }) || null, [reportList])
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null)
  const selectedReportIndex = reports?.findIndex((report) => report.id === selectedReportId) ?? null
  const {
    data: reportRes,
    isLoading: isLoadingReport
  } = useFetchReport(selectedReportId ? { id: selectedReportId } : null)
  const template = templateData?.data?.getReportTemplate || null
  const report = reportRes?.data?.getReport || null
  const approver = useReportApprover(report)
  const handleDownloadCsv = useDownloadCsv(template, report, approver)
  const isLoading = isLoadingTemplate || isLoadingReportList || isLoadingReport

  const handleDateChange = (e: ChangeEvent<{ value: unknown }>) => {
    setSelectedReportId(e.target.value as string)
  }

  const handleScrubDate = (position: number) => {
    if (selectedReportIndex === null) {
      return
    }

    const nextIndex = selectedReportIndex + position
    const nextReportId = reports?.find((_r, idx) => idx === nextIndex)?.id

    if (nextReportId) {
      setSelectedReportId(nextReportId)
    }
  }

  useEffect(() => {
    setSelectedReportId(reports?.[0]?.id || null)
  }, [reports])

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <NavBar />
      </div>

      <div className={classes.content}>
        <ActionHeader>
          <Breadcrumbs>
            <Typography variant="h6">
              <Link
                className={classes.link}
                to={`/reports?${globalDataToSearchParams()}`}
              >
                Report Templates
              </Link>
            </Typography>
            <Typography variant="h6">
              {template?.name}
            </Typography>
          </Breadcrumbs>
        </ActionHeader>
        <div className={classes.toolbar}>
          <div className={classes.toolbarControls}>
            <ApprovalStatus report={report} />
            {report &&
              <Button
                color="secondary"
                variant="contained"
                onClick={handleDownloadCsv}
              >
                Download CSV
              </Button>}
            {reports &&
              <div className={classes.dateScrubber}>
                <IconButton
                  size="small"
                  color="secondary"
                  disabled={selectedReportIndex === null || selectedReportIndex === 0}
                  onClick={() => { handleScrubDate(-1) }}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <FormControl>
                  <Select
                    value={selectedReportId || ''}
                    onChange={handleDateChange}
                  >
                    {reports.map((r) => r.date && (
                      <MenuItem key={r.id} value={r.id}>
                        {format(new Date(r.date), 'M/d/yyyy')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton
                  size="small"
                  color="secondary"
                  disabled={selectedReportIndex === null || selectedReportIndex >= reports.length - 1}
                  onClick={() => { handleScrubDate(1) }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </div>}
          </div>
        </div>
        <div className={classes.tableContainer}>
          {(isLoading || !template)
            ? <Loading />
            : <ReportTable template={template} report={report} />}
        </div>
      </div>
    </div>
  )
}

export { Report }
