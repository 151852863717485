import { FC, useState, useMemo, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import AlertComp from '../AlertComp'
import { AlertRule, Sensor, SensorGroup, SensorGroupSensor, UpdateSensorInput } from '../../API'
import { useFetchAlertRules, useFetchSensorGroups } from '../../utils/hooks'
import { ReduxState } from '../../redux'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  description: {
    fontSize: 18,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  input: {
    width: '100%'
  },
  autoComplete: {
    margin: theme.spacing(2, 0),
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  }
}))

interface DialogProps {
  siteID?: string
  sensor: Sensor
  handleClose: () => void
  onSave: (
    data: Partial<UpdateSensorInput>,
    relationshipsToAdd: (SensorGroup | undefined)[],
    relationshipsToDelete: (SensorGroupSensor | null)[]
  ) => void
  mutateError: boolean
  mutateLoading: boolean
  open: boolean
}

const AddAlertRuleDialog: FC<DialogProps> = (props) => {
  const classes = useStyles()
  const isSuperAdmin = useSelector((state: ReduxState) => state.userInfo.isSuperAdmin)
  const {
    handleClose: onClose,
    onSave,
    siteID,
    mutateLoading,
    mutateError,
    open,
    sensor: {
      name,
      hidden,
      alertruleID,
      omniSensorId,
      sensorgroups: sensorgroupsRelationship
    }
  } = props

  const { existingSensorGroups, sensorgroupsRelationshipItems } = useMemo(() => {
    const sensorgroupsRelationshipItems = sensorgroupsRelationship?.items || []
    const existingSensorGroups = sensorgroupsRelationshipItems
      .filter((group) => !group?._deleted)
      .map((groupJoin) => groupJoin?.sensorgroup) || []
    return {
      existingSensorGroups,
      sensorgroupsRelationshipItems
    }
  }, [sensorgroupsRelationship])

  const [displayName, setDisplayName] = useState(name || '')
  const [selectedRule, setSelectedRule] = useState(alertruleID || '')
  const [selectedGroups, setSelectedGroups] = useState(existingSensorGroups)
  const [sensorHidden, setSensorHidden] = useState(!!hidden)

  const {
    alertRules,
    keyedAlertRules,
    isLoading: rulesLoading,
    isError: rulesError
  } = useFetchAlertRules(siteID)

  const {
    sensorGroups: allSensorGroups,
    isLoading: groupsLoading
  } = useFetchSensorGroups(siteID)

  const handleClose = () => {
    setSensorHidden(!!hidden)
    setSelectedGroups(existingSensorGroups)
    setSelectedRule(alertruleID || '')
    onClose()
  }

  const handleSave = () => {
    onSave(
      {
        name: displayName,
        alertruleID: selectedRule || null,
        hidden: sensorHidden
      },
      selectedGroups.filter((group) => !existingSensorGroups.includes(group)),
      sensorgroupsRelationshipItems
        .filter((relationship) => !selectedGroups.includes(relationship?.sensorgroup) && !relationship?._deleted)
    )
  }

  const handleDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = e
    setDisplayName(value)
  }

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target: { checked } } = e
    setSensorHidden(checked)
  }

  const handleMultipleChange = (_: ChangeEvent<{}>, groups: SensorGroup[]) => {
    setSelectedGroups(groups)
  }

  const handleChange = (_: ChangeEvent<{}>, rule: AlertRule | null) => {
    setSelectedRule(rule?.id || '')
  }

  const renderHiddenToggle = () => {
    if (!isSuperAdmin) return null

    return (
      <FormControlLabel
        label="Hidden"
        control={
          <Switch
            checked={sensorHidden}
            onChange={handleSwitchChange}
          />
        }
      />
    )
  }

  const isLoading = useMemo(() => mutateLoading || rulesLoading, [mutateLoading, rulesLoading])
  const isError = useMemo(() => mutateError || rulesError, [mutateError, rulesError])
  const saveIsDisabled = useMemo(() => isLoading, [isLoading]) // TODO: Save always available?

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>Edit Sensor Configuration</Typography>
      </DialogTitle>
      <DialogContent>
        {isError && (
          <AlertComp
            severity="error"
            message="There was a problem saving your configuration."
          />
        )}
        <TextField
          label="OmniSensor ID"
          name="OmniSensor ID"
          type="text"
          value={omniSensorId ?? 'Not Saved'}
          classes={{ root: classes.input }}
          disabled
        />

        <TextField
          label="Display Name"
          name="Display Name"
          type="text"
          value={displayName}
          onChange={handleDisplayNameChange}
          classes={{ root: classes.input }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Autocomplete
          fullWidth
          multiple
          disabled={mutateLoading || groupsLoading}
          options={allSensorGroups}
          value={selectedGroups}
          classes={{ root: classes.autoComplete }}
          getOptionLabel={(option) => option?.name || ''}
          getOptionSelected={(option, value) => value?.id === option?.id}
          onChange={handleMultipleChange as any}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sensor Groups"
              placeholder="Assign sensor to a group"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {groupsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          fullWidth
          disabled={mutateLoading || rulesLoading}
          options={alertRules}
          value={keyedAlertRules[selectedRule] || null}
          classes={{ root: classes.autoComplete }}
          getOptionLabel={(option) => option?.name || ''}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Alert Rules"
              placeholder="Select an Alert Rule"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {rulesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        {renderHiddenToggle()}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSave}
          disabled={saveIsDisabled}
          classes={{ root: classes.save }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddAlertRuleDialog
