import { useQuery } from 'react-query'
import { getOmniDevice } from '../../../graphql/queries'
import { GetOmniDeviceQuery, OmniDevice } from '../../../API'
import { api } from '../../gql-client'
import { OMNI_DEVICES_KEY } from './useListOmniDevices'

export const useGetOmniDeviceByID = (omniDeviceID: string) => {
  const omniDeviceRes = useQuery(
    [OMNI_DEVICES_KEY, omniDeviceID],
    async () => {
      const res = await api.request<GetOmniDeviceQuery>({
        query: getOmniDevice,
        variables: { id: omniDeviceID }
      })
      return res.data?.getOmniDevice
    },
    {
      enabled: !!omniDeviceID
    }
  )
  const omniDeviceData = (omniDeviceRes.data || {}) as OmniDevice
  const siteID = omniDeviceData.siteID ?? ''

  return {
    isLoading: omniDeviceRes.isLoading,
    siteID,
    omniDeviceData
  }
}
