import { format, setHours, setMinutes } from 'date-fns'

function convert24Hourto12Hour(time: string | null = '') {
  if (!time) { return '' }

  const [hour, minute] = time.split(':')
  const date = setHours(setMinutes(new Date(), parseInt(minute, 10)), parseInt(hour, 10))

  return format(date, 'h:mm aa')
}

export { convert24Hourto12Hour }
