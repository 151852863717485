import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from '@material-ui/core'
import AlertComp from './AlertComp'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '45vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  dialogText: {
    margin: theme.spacing(2, 0),
    color: 'white'
  },
  button: {
    color: 'white'
  }
}))

interface Props {
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  text: string
  errorText?: string
  successText: string
  title: string
}

const DeleteDialog = ({
  text,
  title,
  successText,
  errorText,
  isSuccess,
  isLoading,
  isError,
  onClose,
  onConfirm
}: Props) => {
  const classes = useStyles()

  return (
    <Dialog open onClose={onClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>{title}</DialogTitle>
      <DialogContent>
        {isError && (
          <AlertComp
            severity="error"
            message={
              errorText ||
              'There was an issue deleting this item. If this persits, please contact support.'
            }
          />
        )}
        <Typography className={classes.dialogText}>
          {isSuccess ? successText : text}
        </Typography>
      </DialogContent>
      <DialogActions>
        {!isSuccess && (
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
        )}
        <Button
          variant="contained"
          disabled={isLoading}
          color="secondary"
          classes={{ root: classes.button }}
          onClick={isSuccess ? onClose : onConfirm}
        >
          {isSuccess ? 'Close' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
