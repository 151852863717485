import { IDates } from '../../utils/sharedInterfaces'

export interface IDateFilters {
  type: string
  dates: IDates
}

export const dateFilters = (state = {

  dates: {
    from: new Date().setDate(new Date().getDate() - 1),
    to: undefined
  }
}, action: IDateFilters) => {
  switch (action.type) {
    case 'update_dates':
      return {
        ...state,
        dates: action.dates
      }
    default:
      return state
  }
}
