import { OmniDevice, Sensor, ModelSensorFilterInput } from '../../API'
import { keyBy } from 'lodash'
import { useListOmniDevices } from './devices/useListOmniDevices'
import { useListDevices } from './devices/useListDevices'
import { useListAllSensors } from './devices/useListSensors'
import { filterData } from '../gql-client'
import { useSite } from './appData'
import { useGlobalData } from './global-data'

type FetchDevicesOpts = {
  ignoreGlobalSensorGroup?: boolean
}

export const useFetchDevices = (
  sensorFilters: ModelSensorFilterInput = {},
  opts: FetchDevicesOpts = {}
) => {
  const [{ sensorGroup: sensorgroupID }] = useGlobalData()
  const siteID = useSite()

  const omniDevicesRes = useListOmniDevices({ siteID }, { enabled: !!siteID })

  const omnisData = (omniDevicesRes.data?.items ?? []).filter(
    filterData
  ) as OmniDevice[]
  const keyedOmniDevices = keyBy(omnisData, 'id')

  const devicesRes = useListDevices(omnisData)

  const devicesData = devicesRes.devices
  const keyedDevices = keyBy(devicesData, 'id')

  const sensorsRes = useListAllSensors(devicesData, sensorFilters)

  const filterSensor = (sensor: Sensor) => {
    if (!sensorgroupID || opts.ignoreGlobalSensorGroup) {
      return true
    }
    return sensor.sensorgroups?.items
      .filter((relationship) => !relationship?._deleted)
      .map((relationship) => relationship?.sensorgroupID)
      .includes(sensorgroupID)
  }

  const sensorsData = (sensorsRes.sensors ?? []).filter(
    (sensor) =>
      !!sensor && !!sensor?.name && !sensor._deleted && filterSensor(sensor)
  ) as Sensor[]
  // For some reason, a sensor has been created as the watchpage itself. It has no name or sensorsReadings connected to it, with an id the same as the device ID. Not sure if that is intentional or not

  return {
    omnisData,
    keyedOmniDevices,
    devicesData,
    keyedDevices,
    sensorsData,
    keyedSensors: keyBy(sensorsData, 'id'),
    isLoading:
      omniDevicesRes.isLoading || devicesRes.isLoading || sensorsRes.isLoading,
    isError: omniDevicesRes.isError || devicesRes.isError || sensorsRes.isError,
    isFetching:
      omniDevicesRes.isFetching ||
      devicesRes.isFetching ||
      sensorsRes.isFetching
  }
}
