import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { useGlobalData } from '../../utils/hooks/global-data'
import NavBar from '../../components/Navbar'
import { ClientManager } from './ClientManager'
import { SiteManager } from './SiteManager'
import { SensorGroupManager } from './SensorGroupManager'
import { ExportDataManager } from './ExportDataManager'

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.primary.main
  },
  nav: {
    flex: 0
  },
  content: {
    display: 'flex',
    flex: 1
  }
}))

function Manage() {
  const classes = useStyles()
  const { path } = useRouteMatch()
  const [, , globalDataToSearchParams] = useGlobalData()

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <NavBar />
      </div>
      <div className={classes.content}>
        {/* By default show the user the client page */}
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/clients?${globalDataToSearchParams()}`} />
          </Route>
          <Route path={`${path}/clients`} component={ClientManager} />
          <Route path={`${path}/sites`} component={SiteManager} />
          <Route path={`${path}/sensorGroups`} component={SensorGroupManager} />
          <Route path={`${path}/exportData`} component={ExportDataManager} />
        </Switch>
      </div>
    </div>
  )
}

export { Manage }
