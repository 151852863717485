import format from 'date-fns/format'
import isEmpty from 'lodash/isEmpty'
import {
  Card,
  CardHeader,
  CardContent,
  Theme,
  Typography
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import {
  ONGOING_ISSUES_FILTER,
  useFetchSensorIssuesBySensor
} from '../../utils/hooks'
import {
  Device,
  OmniDevice,
  Sensor,
  SensorReading
} from '../../API'
import Loading from '../Loading'
import AlertComp from '../AlertComp'
import SensorContent from './SensorContent'
import { useGlobalData } from '../../utils/hooks/global-data'

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    minWidth: theme.spacing(34),
    maxWidth: theme.spacing(34),
    paddingBottom: 0,
    cursor: 'pointer'
  },
  headerRoot: {
    background: theme.palette.secondary.main
  },
  headerAlarm: {
    background: theme.palette.error.main,
    animation: `$flash 1000ms ${theme.transitions.easing.easeInOut} infinite`
  },
  '@keyframes flash': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.8
    },
    '100%': {
      opacity: 1
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(16.5),
    '&:last-child': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  sensorReading: {
    paddingTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(0, 0.5)
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: 14
  },
  sensorContent: {
    display: 'flex',
    marginTop: theme.spacing(1.5)
  },
  sensorValue: {
    color: 'black',
    fontSize: 24,
    fontWeight: 600
  },
  smallValue: {
    fontSize: 18,
    padding: theme.spacing(0, 1)
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'underline',
    paddingTop: theme.spacing(1)
  },
  lastDayDetails: {
    display: 'flex',
    paddingBottom: theme.spacing(1)
  }
}))

interface Props {
  omniDevice: OmniDevice
  device: Device
  sensor: Sensor
}

const SensorCard = ({ omniDevice, device, sensor }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const [, , globalDataToSearchParams] = useGlobalData()

  const { issuesData, isLoading, isError } = useFetchSensorIssuesBySensor({
    sensorID: sensor.id,
    filter: ONGOING_ISSUES_FILTER
  })
  const hasOngoingIssues = !isEmpty(issuesData)

  const min = sensor.min || 0
  const max = sensor.max || 0
  const avg = sensor.avg || 0
  const lastReading = sensor.lastReading as SensorReading

  const handleDeviceView = () => {
    history.push(
      `/dashboard/device/${device.id}?${globalDataToSearchParams(
        {},
        {
          omniDevice: omniDevice.id,
          device: device.id,
          sensor: sensor?.id
        }
      )}`
    )
  }

  if (!sensor || !sensor.name) {
    return null
  }

  const determineContent = () => {
    if (isLoading) {
      return <Loading />
    }

    if (isError) {
      return (
        <AlertComp
          severity="warning"
          message="There was a problem fetching the latest reading"
        />
      )
    }

    if (!lastReading) {
      return 'No Readings exist for this sensor.'
    }

    return (
      <SensorContent
        key={`sensor-${sensor.id}`}
        lastReading={lastReading}
        min={min}
        max={max}
        avg={avg}
        classes={classes}
      />
    )
  }

  return (
    <Card classes={{ root: classes.cardRoot }} onClick={handleDeviceView}>
      <CardHeader
        classes={{
          root: hasOngoingIssues ? classes.headerAlarm : classes.headerRoot
        }}
        title={
          <div>
            <Typography variant="h6">{sensor?.name}</Typography>
            <Typography>{device.name}</Typography>
          </div>
        }
        subheader={`Last Updated: ${format(
          new Date(lastReading?.updatedAt ?? new Date()),
          'h:mm aa M/d/yyyy'
        )}`}
      />
      <CardContent className={classes.content}>
        {determineContent()}
      </CardContent>
    </Card>
  )
}

export default SensorCard
