
import { FC, ChangeEvent, MouseEvent, ReactNode } from 'react'
import { IconButton, TextField, Theme } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    display: 'flex',
    alignItems: 'flex-end',

    '&:hover $editIconContainer': {
      display: 'block'
    }
  },
  editIconContainer: {
    display: 'none'
  },
  select: {
    width: '100%',
    color: 'white',
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18,
      fontWeight: 600
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  },
  editIcon: {
    color: 'white'
  }
}))

export type ChangeFunc<T> = (e: ChangeEvent<{}>, value: T | null) => void

interface IAutoCompleteProps {
  handleMouseEnter?: (e: MouseEvent) => void
  handleMouseLeave?: () => void
  value?: object | null
  options: object[]
  getOptionLabel: (param: any) => string
  handleChange: ChangeFunc<any>
  label: string
  renderOption?: (option: object) => ReactNode
  getOptionSelected?: (option: object, value: object) => boolean
  newOption?: {
    deviceName?: string
    name?: string
  }
  handleClick?: () => void
  Icon?: any,
  checkID?: string | null | undefined
  fullWidth?: boolean
  disabled?: boolean
  className?: any
  disableClearable?: boolean
}

const AutoCompleteComp: FC<IAutoCompleteProps> = (props) => {
  const classes = useStyles()
  const {
    value,
    options,
    getOptionLabel,
    handleChange,
    label,
    renderOption,
    getOptionSelected,
    newOption,
    handleClick,
    Icon,
    checkID,
    fullWidth,
    disabled,
    className,
    disableClearable = true
  } = props

  const optionToAdd = newOption ? [newOption] : []

  return (
    <div
      className={classes.selectContainer}
      id={`${checkID}-select`}
    >
      <Autocomplete
        fullWidth={fullWidth}
        disabled={disabled}
        className={className}
        disableClearable={disableClearable}
        id={`${label}-autocomplete`}
        classes={{ root: classes.select }}
        value={value}
        options={[...optionToAdd, ...options]}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={handleChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={label}
            placeholder={`Select a ${label}`}
            InputLabelProps={{ shrink: true }}
          />
        )}
        renderOption={renderOption}
      />
      {Icon && (
        <div className={classes.editIconContainer}>
          <IconButton size="small" onClick={handleClick}>
            <Icon className={classes.editIcon} />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export { AutoCompleteComp }
