import { useQuery } from 'react-query'
import { ListClientsQuery, ListClientsQueryVariables } from '../../../API'
import { listClients } from '../../../graphql/queries'
import { api } from '../../gql-client'

export const CLIENTS_KEY = 'clients'

export const useListClients = (variables?: ListClientsQueryVariables) =>
  useQuery([CLIENTS_KEY, variables], () => {
    return api.request<ListClientsQuery>({
      query: listClients,
      variables
    })
  })
