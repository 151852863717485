import { FC, useEffect } from 'react'
import {
  ONGOING_ISSUES_FILTER,
  useFetchSensorIssuesBySensor
} from '../../utils/hooks'
import { Sensor } from '../../API'

interface ISensorCardProps {
  sensor: Sensor
  onLoad: (sensorID: string, readings: any, issues: any) => void
}

const SensorDataFetcher: FC<ISensorCardProps> = (props) => {
  const { sensor, onLoad } = props

  const { isLoading, dataUpdatedAt, issuesData } = useFetchSensorIssuesBySensor(
    {
      sensorID: sensor.id,
      filter: ONGOING_ISSUES_FILTER
    }
  )

  useEffect(() => {
    if (isLoading) {
      return
    }

    onLoad(sensor.id, sensor, issuesData)
    // Because the hooks return new data objects each time they're called we need
    // to rely on their dataUpdatedAt as a proxy for new data.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensor.id, isLoading, dataUpdatedAt, onLoad])

  return null
}

export { SensorDataFetcher }
