import { FC } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme: any) => ({
  cardRoot: {
    minWidth: theme.spacing(34)
  },
  headerRoot: {
    background: theme.palette.secondary.main,
    '& .MuiCardHeader-title': {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  content: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0)
  },
  infoRow: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& > *': {
      color: theme.palette.primary.dark,
      marginLeft: theme.spacing(2)
    }
  },
  editIcon: {
    color: 'white',
    paddingTop: 6
  }
}))

interface RowData {
  icon: JSX.Element
  text?: string | number | null
}

interface ICardProps {
  id: string
  rows: RowData[]
  cardTitle: string
  handleToggleForm: () => void
}

const DataCard: FC<ICardProps> = (props) => {
  const { id, cardTitle, rows, handleToggleForm } = props
  const classes = useStyles()

  const renderRow = (row: RowData) => (
    <div key={`${id}-${row.text}`} className={classes.infoRow}>
      {row.icon}
      <Typography>{row.text}</Typography>
    </div>
  )

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{ root: classes.headerRoot }}
        title={cardTitle}
        action={
          <IconButton
            size="small"
            onClick={handleToggleForm}
            className={classes.editIcon}
          >
            <EditIcon />
          </IconButton>
        }
      />
      <CardContent classes={{ root: classes.content }}>
        {rows.map(renderRow)}
        <div className={classes.infoRow}>
          {props.children}
        </div>
      </CardContent>
    </Card>
  )
}

export default DataCard
