import { FC, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { useCreateTemplate } from './useCreateTemplate'
import { useFetchSensorGroups, useFetchSites } from '../../utils/hooks'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  textField: {
    color: 'white',
    display: 'block',
    marginBottom: theme.spacing(2),

    '& .MuiInputBase-root': {
      width: '100%'
    }
  }
}))

interface DialogProps {
  onCreate: () => void
  onClose: () => void
}

const CreateReportTemplateDialog: FC<DialogProps> = (props) => {
  const { onCreate, onClose } = props
  const [name, setName] = useState('')
  const [siteId, setSiteId] = useState<string | undefined>(undefined)
  const [sensorGroupId, setSensorGroupId] = useState<string | null>(null)
  const [timeA, setTimeA] = useState('07:30')
  const [timeB, setTimeB] = useState('19:30')
  const createReportTemplate = useCreateTemplate()
  const { sites } = useFetchSites(null)
  const { sensorGroups } = useFetchSensorGroups(siteId)
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  const handleCreate = async () => {
    await createReportTemplate.mutateAsync({
      input: {
        name,
        siteID: siteId,
        sensorgroupID: sensorGroupId || undefined,
        times: [timeA, timeB],
        isActive: true
      }
    })
    onCreate()
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>Create Report Template</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          placeholder="My Report"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={classes.textField}
        />
        <FormControl className={classes.formControl}>
          <InputLabel>Site</InputLabel>
          <Select
            value={siteId}
            onChange={(e) => setSiteId(e.target.value as string)}
          >
            {sites?.map((site) => (
              <MenuItem key={site.id} value={site.id}>
                {site.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel>Sensor Group</InputLabel>
          <Select
            value={sensorGroupId}
            onChange={(e) => setSensorGroupId(e.target.value as string)}
          >
            {sensorGroups?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Time A"
          type="time"
          value={timeA}
          onChange={(e) => setTimeA(e.target.value)}
          className={classes.textField}
          inputProps={{
            step: 300 // 5 min
          }}
        />
        <TextField
          label="Time B"
          type="time"
          value={timeB}
          onChange={(e) => setTimeB(e.target.value)}
          className={classes.textField}
          inputProps={{
            step: 300 // 5 min
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={createReportTemplate.isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleCreate}
          disabled={createReportTemplate.isLoading}
          classes={{ root: classes.save }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { CreateReportTemplateDialog }
