import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  Snackbar,
  Theme,
  Typography
} from '@material-ui/core'
import { usePingSensor } from '../../utils/hooks'
import { makeStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import { useState } from 'react'

const validationSchema = yup.object({
  value: yup.string().required('Value is required')
})
type FormValues = yup.InferType<typeof validationSchema>

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  description: {
    fontSize: 18,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(2, 0),
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  }
}))

interface Props extends DialogProps {
  sensorID: string
}

const SetSensorDialog = (props: Props) => {
  const classes = useStyles()
  const { mutateAsync: setSensorData } = usePingSensor()
  const [value, setValue] = useState('')

  const formik = useFormik({
    initialValues: {
      value: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values: FormValues) => {
      const res = await setSensorData({
        id: props.sensorID,
        value: values.value
      })
      console.log(res)
      setValue(res?.value || '')
      if (props.onClose) {
        props.onClose({}, 'escapeKeyDown')
      }
    }
  })

  const closeSnackbar = () => setValue('')

  return (
    <>
      <Snackbar open={!!value} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success">
          Obtained a value of {value}
        </Alert>
      </Snackbar>
      <Dialog classes={{ paper: classes.dialogPaper }} {...props}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            disableTypography
            classes={{ root: classes.dialogTitle }}
          >
            <Typography variant="h5" className={classes.title}>
              Set Sensor Value
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              classes={{ root: classes.autoComplete }}
              fullWidth
              id="value"
              name="value"
              label="Value"
              value={formik.values.value}
              onChange={formik.handleChange}
              error={formik.touched.value && Boolean(formik.errors.value)}
              helperText={formik.touched.value && formik.errors.value}
            />
            {formik.isSubmitting && <LinearProgress />}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              classes={{ root: classes.save }}
              disabled={formik.isSubmitting}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default SetSensorDialog
