import React from 'react'
import { Route, useRouteMatch, Redirect } from 'react-router-dom'
// Local utils
import { Site } from '../../API'
import {
  useFetchMaintenanceSchedules,
  useFetchMaintenanceEvents
} from '../../utils/hooks'
import { useGlobalData } from '../../utils/hooks/global-data'
import AlertComp from '../../components/AlertComp'
import Loading from '../../components/Loading'
import { GlobalDataSelection } from '../GlobalDataSelection'
// Local components
import Logs from './Logs'
import Schedule from './Schedule'
import SubHeader from '../SubHeader'
import ServiceContacts from './ServiceContacts'
import EnergyUsage from './EnergyUsage'

interface IMaintenanceOverivewProps {
  isDataLoading: boolean,
  isDataError: boolean,
  siteData: Site | null
}

export const MaintenanceOverivew = (props: IMaintenanceOverivewProps) => {
  const { isDataLoading, isDataError, siteData } = props
  const { path } = useRouteMatch()
  const [, , globalDataToSearchParams] = useGlobalData()

  const {
    schedules,
    isLoading: schedulesLoading,
    isError: schedulesError
  } = useFetchMaintenanceSchedules(siteData?.id || null)

  const {
    events,
    keyedEvents,
    isLoading: eventsLoading,
    isError: eventsError
  } = useFetchMaintenanceEvents(schedules)

  const isLoading = schedulesLoading || eventsLoading
  const isError = schedulesError || eventsError

  const renderNotices = () => {
    if (siteData) {
      return
    } else if (isDataLoading) {
      return <Loading />
    } else if (isDataError) {
      return (
        <AlertComp
          severity="error"
          message="There was a problem fetching your information. If this persits, please contact support."
        />
      )
    }

    return (
      <AlertComp severity="warning" message="Please select a Site" />
    )
  }

  return (
    <div>
      <SubHeader siteData={siteData} />

      <GlobalDataSelection selectors={['sensorGroup']} />

      {renderNotices()}

      {/* By default show the user the schedule page */}
      <Route exact path={path}>
        <Redirect to={`${path}/schedule?${globalDataToSearchParams()}`} />
      </Route>

      <Route exact path={`${path}/schedule`}>
        {siteData && (
          <Schedule
            isLoading={isLoading}
            isError={isError}
            site={siteData}
            schedules={schedules}
            keyedEvents={keyedEvents}
          />
        )}
      </Route>

      <Route exact path={`${path}/logs`}>
        {siteData && (
          <Logs
            isLoading={isLoading}
            isError={isError}
            site={siteData}
            schedules={schedules}
            events={events}
          />
        )}
      </Route>

      <Route exact path={`${path}/contacts`}>
        {siteData && (
          <ServiceContacts site={siteData} />
        )}
      </Route>
      <Route exact path={`${path}/energy-usage`}>
        {siteData && (
          <EnergyUsage site={siteData} />
        )}
      </Route>
    </div>
  )
}
