import { useMemo, FC } from 'react'
import { Checkbox, ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'

interface CheckboxOptionProps extends MenuItemProps {
  isIncluded: boolean
  maxSelected: boolean
  value: string
  text?: string | null
  classes: ClassNameMap
}

export const CheckboxOption: FC<CheckboxOptionProps> = (props) => {
  const { isIncluded, maxSelected, value, text, classes, onClick } = props

  const disabled = useMemo(() => {
    return maxSelected && !isIncluded
  }, [maxSelected, isIncluded])

  return (
    <MenuItem onClick={onClick} value={value} disabled={disabled}>
      <ListItemIcon>
        <Checkbox checked={isIncluded} disabled={disabled} className={classes.white} />
      </ListItemIcon>
      <ListItemText primary={text} className={disabled ? classes.disabled : ''} />
    </MenuItem>
  )
}
