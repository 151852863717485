import { FC, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { ClassNameMap } from '@material-ui/styles'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import Loading from './Loading'
import AlertComp from './AlertComp'

interface ITableContent {
  data: any
  isLoading: boolean
  columns: GridColDef[]
  classes: ClassNameMap
  emptyMessage?: string
}

const TableContent: FC<ITableContent> = (props) => {
  const { data, columns, isLoading, classes, emptyMessage } = props
  const [sortModel, setSortModel] = useState([{
    field: columns[columns.length - 1].field,
    sort: 'desc' as GridSortDirection
  }])

  if (!data || isLoading) {
    return <Loading />
  }

  if (isEmpty(data)) {
    return <AlertComp severity="warning" message={emptyMessage} />
  }

  return (
    <DataGrid
      autoHeight
      disableSelectionOnClick
      rows={data}
      columns={columns}
      pageSize={20}
      rowsPerPageOptions={[20]}
      className={classes.table}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
    />
  )
}

export default TableContent
