import { FC, useState } from 'react'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import { ClassNameMap } from '@material-ui/styles'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
// Local Comps
import { useFetchUserList } from '../../utils/hooks'
import { recipientHeaders } from './constants'
import Loading from '../Loading'
import AlertComp from '../AlertComp'
import UserDialog from './UserDialog'
import { AlertRule, User } from '../../API'

interface IAlertRecipientsProps {
  alertRule: AlertRule
  classes: ClassNameMap
}

const AlertRecipients: FC<IAlertRecipientsProps> = (props) => {
  const { classes, alertRule } = props
  const { contactUsers } = alertRule
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const usersListQuery = useFetchUserList()

  const userData = (usersListQuery.data) as User[]
  const isError = usersListQuery.isError
  const isLoading = usersListQuery.isLoading

  const keyedUsers = keyBy(userData, 'Username')

  const handleUserDialog = () => setUserDialogOpen((o) => !o)

  const renderTableHeaders = (header: string) => <TableCell key={header}>{header}</TableCell>

  const renderUserRow = (username: string) => {
    const user = keyedUsers[username]
    return (
      <TableRow key={`alertRule-user-${user?.Username}`}>
        <TableCell>{user?.Name?.split(' ')[0] || '—'}</TableCell>
        <TableCell>{user?.Name?.split(/\s+/)[1] || '—'}</TableCell>
        <TableCell>{user?.Phone || '—'}</TableCell>
        <TableCell>{user?.Email || '-'}</TableCell>
      </TableRow>
    )
  }

  const renderTableRows = () => {
    if (isLoading) {
      return <Loading />
    }
    if (isError || !userData || isEmpty(userData)) {
      return <AlertComp severity="error" message="There was a problem fetching the contact users for this Alert Rule" />
    }
    if (!contactUsers || isEmpty(contactUsers)) {
      return <AlertComp severity="warning" message="No Users assigned to this Alert Rule" />
    }
    return (
      <Table classes={{ root: classes.table }}>
        <TableHead>
          <TableRow>{recipientHeaders.map(renderTableHeaders)}</TableRow>
        </TableHead>
        <TableBody>{(contactUsers as string[]).map(renderUserRow)}</TableBody>
      </Table>
    )
  }

  return (
    <div className={classes.sidePadding}>
      <div className={classes.recipientsHeader}>
        <Typography variant="h6" className={classes.subTitle}>Alert Recipients</Typography>
        <Button
          onClick={handleUserDialog}
          variant="contained"
          color="secondary"
          className={classes.addUserBtn}
        >
          Edit Users
        </Button>
      </div>

      {renderTableRows()}

      <UserDialog
        alertRule={alertRule}
        open={userDialogOpen}
        handleClose={handleUserDialog}
        keyedUsers={keyedUsers}
        userListError={isError}
      />
    </div>
  )
}

export default AlertRecipients
