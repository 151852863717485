import { format } from 'date-fns'

import {
  GetReportTemplateQuery as TemplateResponse,
  GetReportQuery as ReportResponse,
  User
} from '../../API'
import { convert24Hourto12Hour } from '../../utils/format-time'
import { useDownloadCsv as useDownloadCsvFile } from '../../utils/hooks/useDownloadCsv'
import { useFetchSensorList } from './useFetchSensorList'

function formatApprovalData(approver: string, approvedAt: number | null) {
  const approvedAtDate = new Date(approvedAt || 0)
  const approvedAtFormat = `${format(approvedAtDate, "M/d/yyyy 'at' HH:mm")}`

  return `Approved by ${approver} on ${approvedAtFormat}`
}

function useDownloadCsv(
  template: TemplateResponse['getReportTemplate'] = null,
  report: ReportResponse['getReport'] = null,
  approver: User | null
) {
  const parsedReportData: Record<string, Record<string, any>> = JSON.parse(report?.data || '{}')
  const sensorIds = Object.values(parsedReportData).map((row: Record<string, any>) => {
    const [start] = Object.values(row)
    return start?.sensorID
  })
  const sensorList = useFetchSensorList(sensorIds) || []
  const downloadCsv = useDownloadCsvFile()

  const download = () => {
    const startLabel = convert24Hourto12Hour(template?.times?.[0] || null)
    const endLabel = convert24Hourto12Hour(template?.times?.[1] || null)

    if (!parsedReportData) {
      return
    }

    const data = Object.values(parsedReportData).map((row: Record<string, any>) => {
      const [start, end] = Object.values(row)
      const sensor = sensorList.sensors.find((s) => s?.id === start.sensorID)

      return {
        Name: sensor?.name,
        'Omni Sensor ID': sensor?.omniSensorId || 'N/A',
        [startLabel]: start.readValue.Value,
        [endLabel]: end.readValue.Value,
        Report: template?.name,
        Approved: report?.approver
          ? formatApprovalData(
            approver?.Name || 'Unknown',
            report.approvedAt || null
          )
          : 'Not approved',
        Comment: report?.approvalComment || ''
      }
    })

    downloadCsv(data, `Report ${template?.name} ${report?.date}`)
  }

  return download
}

export { formatApprovalData, useDownloadCsv }
