import { Grid } from '@material-ui/core'
import SensorCard from './SensorCard'
import { Sensor, Device, OmniDevice } from '../../API'

interface Props {
  sensorsData: Sensor[]
  keyedOmniDevices: { [omniId: string]: OmniDevice }
  keyedDevices: { [deviceID: string]: Device }
}

export const Sensors = ({
  sensorsData,
  keyedOmniDevices,
  keyedDevices
}: Props) => {
  const renderCard = (sensor: Sensor) => {
    const device = keyedDevices[sensor?.deviceID as string]
    const omniDevice = keyedOmniDevices[device?.omnideviceID as string]

    return (
      <Grid key={`sensor-${sensor?.id}`} item>
        <SensorCard omniDevice={omniDevice} device={device} sensor={sensor} />
      </Grid>
    )
  }

  return (
    <div>
      <Grid container spacing={3} justifyContent="center">
        {sensorsData.map(renderCard)}
      </Grid>
    </div>
  )
}
