import { useEffect } from 'react'
import LogRocket from 'logrocket'
import { updateUserInfo, ReduxState, updateSiteID } from './redux'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
// Amplfiy components
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
// Local components
import Layout from './components/Layout'
import Dashboard from './views/Dashboard'
import Device from './views/Device'
import { Users } from './views/Users'
import { ReportList } from './views/ReportList'
import { Report } from './views/Report'
import Authentication from './components/Authentication'
import Maintenance from './views/Maintenance'
import { Alerts } from './views/Alerts'
import NotFound from './views/NotFound'
import Account from './views/Account'
import { Manage } from './views/Manage'
import { useListClients } from './utils/hooks/clients'
import { useGlobalData } from './utils/hooks/global-data'

Amplify.configure(awsconfig)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5 // 5m
    }
  }
})

const SetupGlobalData = () => {
  const [, setGlobalData] = useGlobalData()
  const clients = useListClients().data?.data?.listClients?.items
  const clientId = clients && clients.length === 1 ? clients[0]?.id : undefined

  useEffect(() => {
    if (clientId) {
      setGlobalData({ client: clientId, site: null, sensorGroup: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  return null
}

const App = (props: any) => {
  const { authData, authState } = props
  const dispatch = useDispatch()
  const user = useSelector((state: ReduxState) => state.userInfo)
  const { sites, isAdmin } = user

  useEffect(() => {
    if (!authData || !user?.userId || authState !== 'signedIn') {
      return
    }

    LogRocket.identify(user.userId, {
      name: user.username,
      email: authData.attributes?.email,
      isAdmin: user.isAdmin,
      isSuperAdmin: user.isSuperAdmin
    })
  }, [authState, authData, user])

  // Determine authState for log in and log off
  useEffect(() => {
    dispatch(updateUserInfo(authData))
  }, [dispatch, authData])

  useEffect(() => {
    if (sites) {
      const initialSite = sites[0]
      dispatch(updateSiteID(initialSite))
    }
  }, [sites, dispatch])

  if (authState === 'loading') {
    return <div />
  }

  if (authState !== 'signedIn' || !user) {
    return <Authentication />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SetupGlobalData />
        <Layout>
          <Switch>
            <Redirect exact from="/" to="dashboard" />
            {/** TODO(nwestman): Solve this in a more scalable way. */}
            <Redirect from="/signIn" to="/dashboard" />
            <Redirect from="/confirmSignIn" to="/dashboard" />
            <Redirect from="/forgotPassword" to="/dashboard" />
            <Redirect from="/signUp" to="/dashboard" />
            <Route path="/dashboard/device/*" component={Device} />
            <Route path="/dashboard" component={Dashboard} />
            {isAdmin && <Route exact path="/users" component={Users} />}
            {isAdmin && (
              <Route exact path="/users/:username" component={Users} />
            )}
            <Route exact path="/alertRules" component={Alerts} />
            <Route exact path="/alertRules/:alertRuleID" component={Alerts} />
            <Route exact path="/reports" component={ReportList} />
            <Route exact path="/reports/:reportTemplateId" component={Report} />
            <Route path="/maintenance" component={Maintenance} />
            <Route exact path="/account" component={Account} />
            {isAdmin && <Route path="/manage" component={Manage} />}
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
