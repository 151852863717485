// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SensorIssueStatus = {
  "ONGOING": "ONGOING",
  "OPEN": "OPEN",
  "RESOLVED": "RESOLVED",
  "CLOSED": "CLOSED",
  "INSPEC": "INSPEC",
  "CALIBRATION": "CALIBRATION",
  "UNKNOWN": "UNKNOWN"
};

const SensorType = {
  "THERMOMETER": "THERMOMETER"
};

const ConnectionType = {
  "UNKNOWN": "UNKNOWN",
  "LEGACY": "LEGACY",
  "VCL": "VCL"
};

const OmniDevicePingType = {
  "UNKNOWN": "UNKNOWN",
  "SCHEDULED": "SCHEDULED",
  "AD_HOC": "AD_HOC",
  "SHADOW": "SHADOW"
};

const UserAccountContactPreference = {
  "DEFAULT": "DEFAULT",
  "DO_NOT_DISTURB": "DO_NOT_DISTURB"
};

const UserStatus = {
  "UNCONFIRMED": "UNCONFIRMED",
  "CONFIRMED": "CONFIRMED",
  "ARCHIVED": "ARCHIVED",
  "COMPROMISED": "COMPROMISED",
  "UNKNOWN": "UNKNOWN",
  "RESET_REQUIRED": "RESET_REQUIRED",
  "FORCE_CHANGE_PASSWORD": "FORCE_CHANGE_PASSWORD"
};

const { SensorGroup, ReportTemplate, Report, ServiceContact, MaintenanceSchedule, MaintenanceEvent, SensorIssue, SensorIssueSummary, SensorIssueContactAttempt, AlertRule, Device, OmniDeviceStatus, Client, SensorReading, Sensor, Site, OmniDevice, User, UserContactPreferences, SensorGroupSensor, PingOmniDeviceResult, PingSensorResult, MergeSensorResult, GenerateReportOutput, AdminUser } = initSchema(schema);

export {
  SensorGroup,
  ReportTemplate,
  Report,
  ServiceContact,
  MaintenanceSchedule,
  MaintenanceEvent,
  SensorIssue,
  SensorIssueSummary,
  SensorIssueContactAttempt,
  AlertRule,
  Device,
  OmniDeviceStatus,
  Client,
  SensorReading,
  Sensor,
  Site,
  OmniDevice,
  User,
  UserContactPreferences,
  SensorGroupSensor,
  SensorIssueStatus,
  SensorType,
  ConnectionType,
  OmniDevicePingType,
  UserAccountContactPreference,
  UserStatus,
  PingOmniDeviceResult,
  PingSensorResult,
  MergeSensorResult,
  GenerateReportOutput,
  AdminUser
};