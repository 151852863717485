import { FC, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.primary.light,
    minWidth: '35vw'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.dark
  },
  backButton: {
    flex: 1
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: 'white'
  },
  save: {
    fontWeight: 600,
    color: 'white'
  },
  autoComplete: {
    margin: theme.spacing(2, 0),
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white'
    },
    '& .MuiInput-root': {
      color: 'white',
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiInput-underline:hover::before': {
      borderColor: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInput-underline:after': {
      borderColor: 'rgba(255, 255, 255, 0.9)'
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      fontSize: 19
    }
  }
}))

interface DialogProps {
  sensorId: string
  handleClose: () => void
  onSelect: (sensorId: string, value: number) => void
}

const NumberDialog: FC<DialogProps> = (props) => {
  const classes = useStyles()
  const { sensorId, handleClose: onClose, onSelect } = props

  const [numVal, setNumVal] = useState<number>(0)

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    onSelect(sensorId, numVal)
    handleClose()
  }

  const handleChange = (data: { target: { value: any } }) => {
    setNumVal(parseFloat(data.target.value))
  }

  return (
    <Dialog open onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <div className={classes.backButton}>
          <IconButton
            size="small"
            onClick={handleClose}
            classes={{ root: classes.icon }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>
          Edit Full Load Kw
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Energy Equation"
          type="number"
          placeholder="Load Kw"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSave}
          classes={{ root: classes.save }}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { NumberDialog }
