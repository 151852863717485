import { useMutation } from 'react-query'
import { PingSensorInput, PingSensorResult } from '../../../API'
import { pingSensor } from '../../../graphql/mutations'
import { api } from '../../gql-client'

export const usePingSensor = () => {
  return useMutation(async (input: PingSensorInput) => {
    const res = await api.request<PingSensorResult>({
      query: pingSensor,
      variables: {
        input
      }
    })
    return (res.data as any)?.pingSensor as PingSensorResult
  })
}
