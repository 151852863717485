import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import Navbar from '../components/Navbar'
import Loading from '../components/Loading'
import { useUpdateRedux } from '../utils/hooks'
import { DeviceView } from '../components/DeviceView'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%'
  },
  nav: {
    flex: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main
  }
}))

const Device = () => {
  const classes = useStyles()
  const { 0: deviceId } = useParams<{ 0: string }>()

  // TODO(twaltze): is this broken? Is this just a batch of queries?
  const { site, omniDeviceData, deviceData, isLoading } = useUpdateRedux(deviceId)

  const renderBody = () => {
    if (isLoading) {
      return <Loading />
    }

    return (
      <DeviceView
        site={site}
        omniDeviceData={omniDeviceData}
        deviceData={deviceData}
        deviceId={deviceId}
      />
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <Navbar />
      </div>

      <div className={classes.content}>
        {renderBody()}
      </div>
    </div>
  )
}

export default Device
