/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const adminCreateUser = /* GraphQL */ `mutation AdminCreateUser($input: MutateUserInput) {
  adminCreateUser(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminCreateUserMutationVariables,
  APITypes.AdminCreateUserMutation
>;
export const adminUpdateUser = /* GraphQL */ `mutation AdminUpdateUser($input: MutateUserInput) {
  adminUpdateUser(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminUpdateUserMutationVariables,
  APITypes.AdminUpdateUserMutation
>;
export const adminDeleteUser = /* GraphQL */ `mutation AdminDeleteUser($input: MutateUserInput) {
  adminDeleteUser(input: $input)
}
` as GeneratedMutation<
  APITypes.AdminDeleteUserMutationVariables,
  APITypes.AdminDeleteUserMutation
>;
export const resetUserPassword = /* GraphQL */ `mutation ResetUserPassword($input: MutateUserInput) {
  resetUserPassword(input: $input)
}
` as GeneratedMutation<
  APITypes.ResetUserPasswordMutationVariables,
  APITypes.ResetUserPasswordMutation
>;
export const pingSensor = /* GraphQL */ `mutation PingSensor($input: PingSensorInput) {
  pingSensor(input: $input) {
    statusCode
    sensorID
    value
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PingSensorMutationVariables,
  APITypes.PingSensorMutation
>;
export const mergeSensors = /* GraphQL */ `mutation MergeSensors($input: MergeSensorInput) {
  mergeSensors(input: $input) {
    statusCode
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MergeSensorsMutationVariables,
  APITypes.MergeSensorsMutation
>;
export const generateReport = /* GraphQL */ `mutation GenerateReport($input: GenerateReportInput) {
  generateReport(input: $input) {
    statusCode
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateReportMutationVariables,
  APITypes.GenerateReportMutation
>;
export const createSensorGroup = /* GraphQL */ `mutation CreateSensorGroup(
  $input: CreateSensorGroupInput!
  $condition: ModelSensorGroupConditionInput
) {
  createSensorGroup(input: $input, condition: $condition) {
    id
    name
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSensorGroupMutationVariables,
  APITypes.CreateSensorGroupMutation
>;
export const updateSensorGroup = /* GraphQL */ `mutation UpdateSensorGroup(
  $input: UpdateSensorGroupInput!
  $condition: ModelSensorGroupConditionInput
) {
  updateSensorGroup(input: $input, condition: $condition) {
    id
    name
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSensorGroupMutationVariables,
  APITypes.UpdateSensorGroupMutation
>;
export const deleteSensorGroup = /* GraphQL */ `mutation DeleteSensorGroup(
  $input: DeleteSensorGroupInput!
  $condition: ModelSensorGroupConditionInput
) {
  deleteSensorGroup(input: $input, condition: $condition) {
    id
    name
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSensorGroupMutationVariables,
  APITypes.DeleteSensorGroupMutation
>;
export const createReportTemplate = /* GraphQL */ `mutation CreateReportTemplate(
  $input: CreateReportTemplateInput!
  $condition: ModelReportTemplateConditionInput
) {
  createReportTemplate(input: $input, condition: $condition) {
    id
    sensorgroupID
    name
    times
    isActive
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    reports {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportTemplateMutationVariables,
  APITypes.CreateReportTemplateMutation
>;
export const updateReportTemplate = /* GraphQL */ `mutation UpdateReportTemplate(
  $input: UpdateReportTemplateInput!
  $condition: ModelReportTemplateConditionInput
) {
  updateReportTemplate(input: $input, condition: $condition) {
    id
    sensorgroupID
    name
    times
    isActive
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    reports {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportTemplateMutationVariables,
  APITypes.UpdateReportTemplateMutation
>;
export const deleteReportTemplate = /* GraphQL */ `mutation DeleteReportTemplate(
  $input: DeleteReportTemplateInput!
  $condition: ModelReportTemplateConditionInput
) {
  deleteReportTemplate(input: $input, condition: $condition) {
    id
    sensorgroupID
    name
    times
    isActive
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    reports {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportTemplateMutationVariables,
  APITypes.DeleteReportTemplateMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    id
    reporttemplateID
    name
    date
    data
    approver
    approvedAt
    approvalComment
    userID
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    approverUser {
      id
      Username
      Name
      Email
      Phone
      EmailAlerts
      SmsAlerts
      IsAdmin
      ClientID
      SiteID
      AccountContactPreference
      UserStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    id
    reporttemplateID
    name
    date
    data
    approver
    approvedAt
    approvalComment
    userID
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    approverUser {
      id
      Username
      Name
      Email
      Phone
      EmailAlerts
      SmsAlerts
      IsAdmin
      ClientID
      SiteID
      AccountContactPreference
      UserStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const deleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    id
    reporttemplateID
    name
    date
    data
    approver
    approvedAt
    approvalComment
    userID
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    approverUser {
      id
      Username
      Name
      Email
      Phone
      EmailAlerts
      SmsAlerts
      IsAdmin
      ClientID
      SiteID
      AccountContactPreference
      UserStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const createServiceContact = /* GraphQL */ `mutation CreateServiceContact(
  $input: CreateServiceContactInput!
  $condition: ModelServiceContactConditionInput
) {
  createServiceContact(input: $input, condition: $condition) {
    id
    name
    serviceDescription
    phone
    website
    email
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceContactMutationVariables,
  APITypes.CreateServiceContactMutation
>;
export const updateServiceContact = /* GraphQL */ `mutation UpdateServiceContact(
  $input: UpdateServiceContactInput!
  $condition: ModelServiceContactConditionInput
) {
  updateServiceContact(input: $input, condition: $condition) {
    id
    name
    serviceDescription
    phone
    website
    email
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceContactMutationVariables,
  APITypes.UpdateServiceContactMutation
>;
export const deleteServiceContact = /* GraphQL */ `mutation DeleteServiceContact(
  $input: DeleteServiceContactInput!
  $condition: ModelServiceContactConditionInput
) {
  deleteServiceContact(input: $input, condition: $condition) {
    id
    name
    serviceDescription
    phone
    website
    email
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServiceContactMutationVariables,
  APITypes.DeleteServiceContactMutation
>;
export const createMaintenanceSchedule = /* GraphQL */ `mutation CreateMaintenanceSchedule(
  $input: CreateMaintenanceScheduleInput!
  $condition: ModelMaintenanceScheduleConditionInput
) {
  createMaintenanceSchedule(input: $input, condition: $condition) {
    id
    description
    frequencyInDays
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    MaintenanceEvents {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMaintenanceScheduleMutationVariables,
  APITypes.CreateMaintenanceScheduleMutation
>;
export const updateMaintenanceSchedule = /* GraphQL */ `mutation UpdateMaintenanceSchedule(
  $input: UpdateMaintenanceScheduleInput!
  $condition: ModelMaintenanceScheduleConditionInput
) {
  updateMaintenanceSchedule(input: $input, condition: $condition) {
    id
    description
    frequencyInDays
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    MaintenanceEvents {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMaintenanceScheduleMutationVariables,
  APITypes.UpdateMaintenanceScheduleMutation
>;
export const deleteMaintenanceSchedule = /* GraphQL */ `mutation DeleteMaintenanceSchedule(
  $input: DeleteMaintenanceScheduleInput!
  $condition: ModelMaintenanceScheduleConditionInput
) {
  deleteMaintenanceSchedule(input: $input, condition: $condition) {
    id
    description
    frequencyInDays
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    MaintenanceEvents {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMaintenanceScheduleMutationVariables,
  APITypes.DeleteMaintenanceScheduleMutation
>;
export const createMaintenanceEvent = /* GraphQL */ `mutation CreateMaintenanceEvent(
  $input: CreateMaintenanceEventInput!
  $condition: ModelMaintenanceEventConditionInput
) {
  createMaintenanceEvent(input: $input, condition: $condition) {
    id
    confirmedBy
    reportLink
    notes
    dateCompleted
    maintenancescheduleID
    uploadedBy
    company
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMaintenanceEventMutationVariables,
  APITypes.CreateMaintenanceEventMutation
>;
export const updateMaintenanceEvent = /* GraphQL */ `mutation UpdateMaintenanceEvent(
  $input: UpdateMaintenanceEventInput!
  $condition: ModelMaintenanceEventConditionInput
) {
  updateMaintenanceEvent(input: $input, condition: $condition) {
    id
    confirmedBy
    reportLink
    notes
    dateCompleted
    maintenancescheduleID
    uploadedBy
    company
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMaintenanceEventMutationVariables,
  APITypes.UpdateMaintenanceEventMutation
>;
export const deleteMaintenanceEvent = /* GraphQL */ `mutation DeleteMaintenanceEvent(
  $input: DeleteMaintenanceEventInput!
  $condition: ModelMaintenanceEventConditionInput
) {
  deleteMaintenanceEvent(input: $input, condition: $condition) {
    id
    confirmedBy
    reportLink
    notes
    dateCompleted
    maintenancescheduleID
    uploadedBy
    company
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMaintenanceEventMutationVariables,
  APITypes.DeleteMaintenanceEventMutation
>;
export const createSensorIssue = /* GraphQL */ `mutation CreateSensorIssue(
  $input: CreateSensorIssueInput!
  $condition: ModelSensorIssueConditionInput
) {
  createSensorIssue(input: $input, condition: $condition) {
    id
    state
    startTime
    lastUpdateTime
    endTime
    notes
    sensorID
    userAckID
    ackTime
    ackEvent
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorIssueContactAttempts {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSensorIssueMutationVariables,
  APITypes.CreateSensorIssueMutation
>;
export const updateSensorIssue = /* GraphQL */ `mutation UpdateSensorIssue(
  $input: UpdateSensorIssueInput!
  $condition: ModelSensorIssueConditionInput
) {
  updateSensorIssue(input: $input, condition: $condition) {
    id
    state
    startTime
    lastUpdateTime
    endTime
    notes
    sensorID
    userAckID
    ackTime
    ackEvent
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorIssueContactAttempts {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSensorIssueMutationVariables,
  APITypes.UpdateSensorIssueMutation
>;
export const deleteSensorIssue = /* GraphQL */ `mutation DeleteSensorIssue(
  $input: DeleteSensorIssueInput!
  $condition: ModelSensorIssueConditionInput
) {
  deleteSensorIssue(input: $input, condition: $condition) {
    id
    state
    startTime
    lastUpdateTime
    endTime
    notes
    sensorID
    userAckID
    ackTime
    ackEvent
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorIssueContactAttempts {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSensorIssueMutationVariables,
  APITypes.DeleteSensorIssueMutation
>;
export const createSensorIssueSummary = /* GraphQL */ `mutation CreateSensorIssueSummary(
  $input: CreateSensorIssueSummaryInput!
  $condition: ModelSensorIssueSummaryConditionInput
) {
  createSensorIssueSummary(input: $input, condition: $condition) {
    id
    siteID
    date
    issues {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSensorIssueSummaryMutationVariables,
  APITypes.CreateSensorIssueSummaryMutation
>;
export const updateSensorIssueSummary = /* GraphQL */ `mutation UpdateSensorIssueSummary(
  $input: UpdateSensorIssueSummaryInput!
  $condition: ModelSensorIssueSummaryConditionInput
) {
  updateSensorIssueSummary(input: $input, condition: $condition) {
    id
    siteID
    date
    issues {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSensorIssueSummaryMutationVariables,
  APITypes.UpdateSensorIssueSummaryMutation
>;
export const deleteSensorIssueSummary = /* GraphQL */ `mutation DeleteSensorIssueSummary(
  $input: DeleteSensorIssueSummaryInput!
  $condition: ModelSensorIssueSummaryConditionInput
) {
  deleteSensorIssueSummary(input: $input, condition: $condition) {
    id
    siteID
    date
    issues {
      id
      state
      startTime
      lastUpdateTime
      endTime
      notes
      sensorID
      userAckID
      ackTime
      ackEvent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSensorIssueSummaryMutationVariables,
  APITypes.DeleteSensorIssueSummaryMutation
>;
export const createSensorIssueContactAttempt = /* GraphQL */ `mutation CreateSensorIssueContactAttempt(
  $input: CreateSensorIssueContactAttemptInput!
  $condition: ModelSensorIssueContactAttemptConditionInput
) {
  createSensorIssueContactAttempt(input: $input, condition: $condition) {
    id
    sensorIssueID
    serviceContactID
    email
    phoneNumber
    attemptNumber
    attemptTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSensorIssueContactAttemptMutationVariables,
  APITypes.CreateSensorIssueContactAttemptMutation
>;
export const updateSensorIssueContactAttempt = /* GraphQL */ `mutation UpdateSensorIssueContactAttempt(
  $input: UpdateSensorIssueContactAttemptInput!
  $condition: ModelSensorIssueContactAttemptConditionInput
) {
  updateSensorIssueContactAttempt(input: $input, condition: $condition) {
    id
    sensorIssueID
    serviceContactID
    email
    phoneNumber
    attemptNumber
    attemptTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSensorIssueContactAttemptMutationVariables,
  APITypes.UpdateSensorIssueContactAttemptMutation
>;
export const deleteSensorIssueContactAttempt = /* GraphQL */ `mutation DeleteSensorIssueContactAttempt(
  $input: DeleteSensorIssueContactAttemptInput!
  $condition: ModelSensorIssueContactAttemptConditionInput
) {
  deleteSensorIssueContactAttempt(input: $input, condition: $condition) {
    id
    sensorIssueID
    serviceContactID
    email
    phoneNumber
    attemptNumber
    attemptTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSensorIssueContactAttemptMutationVariables,
  APITypes.DeleteSensorIssueContactAttemptMutation
>;
export const createAlertRule = /* GraphQL */ `mutation CreateAlertRule(
  $input: CreateAlertRuleInput!
  $condition: ModelAlertRuleConditionInput
) {
  createAlertRule(input: $input, condition: $condition) {
    id
    name
    minValue
    maxValue
    alertDelay
    alertAttempts
    threshold
    snoozeTime
    siteID
    protocol
    alertTextTemplate
    contactUsers
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAlertRuleMutationVariables,
  APITypes.CreateAlertRuleMutation
>;
export const updateAlertRule = /* GraphQL */ `mutation UpdateAlertRule(
  $input: UpdateAlertRuleInput!
  $condition: ModelAlertRuleConditionInput
) {
  updateAlertRule(input: $input, condition: $condition) {
    id
    name
    minValue
    maxValue
    alertDelay
    alertAttempts
    threshold
    snoozeTime
    siteID
    protocol
    alertTextTemplate
    contactUsers
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAlertRuleMutationVariables,
  APITypes.UpdateAlertRuleMutation
>;
export const deleteAlertRule = /* GraphQL */ `mutation DeleteAlertRule(
  $input: DeleteAlertRuleInput!
  $condition: ModelAlertRuleConditionInput
) {
  deleteAlertRule(input: $input, condition: $condition) {
    id
    name
    minValue
    maxValue
    alertDelay
    alertAttempts
    threshold
    snoozeTime
    siteID
    protocol
    alertTextTemplate
    contactUsers
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAlertRuleMutationVariables,
  APITypes.DeleteAlertRuleMutation
>;
export const createDevice = /* GraphQL */ `mutation CreateDevice(
  $input: CreateDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  createDevice(input: $input, condition: $condition) {
    id
    name
    omnideviceID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceMutationVariables,
  APITypes.CreateDeviceMutation
>;
export const updateDevice = /* GraphQL */ `mutation UpdateDevice(
  $input: UpdateDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  updateDevice(input: $input, condition: $condition) {
    id
    name
    omnideviceID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceMutationVariables,
  APITypes.UpdateDeviceMutation
>;
export const deleteDevice = /* GraphQL */ `mutation DeleteDevice(
  $input: DeleteDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  deleteDevice(input: $input, condition: $condition) {
    id
    name
    omnideviceID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Sensors {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceMutationVariables,
  APITypes.DeleteDeviceMutation
>;
export const createOmniDeviceStatus = /* GraphQL */ `mutation CreateOmniDeviceStatus(
  $input: CreateOmniDeviceStatusInput!
  $condition: ModelOmniDeviceStatusConditionInput
) {
  createOmniDeviceStatus(input: $input, condition: $condition) {
    id
    omnideviceID
    timestamp
    listDevicesResult
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOmniDeviceStatusMutationVariables,
  APITypes.CreateOmniDeviceStatusMutation
>;
export const updateOmniDeviceStatus = /* GraphQL */ `mutation UpdateOmniDeviceStatus(
  $input: UpdateOmniDeviceStatusInput!
  $condition: ModelOmniDeviceStatusConditionInput
) {
  updateOmniDeviceStatus(input: $input, condition: $condition) {
    id
    omnideviceID
    timestamp
    listDevicesResult
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOmniDeviceStatusMutationVariables,
  APITypes.UpdateOmniDeviceStatusMutation
>;
export const deleteOmniDeviceStatus = /* GraphQL */ `mutation DeleteOmniDeviceStatus(
  $input: DeleteOmniDeviceStatusInput!
  $condition: ModelOmniDeviceStatusConditionInput
) {
  deleteOmniDeviceStatus(input: $input, condition: $condition) {
    id
    omnideviceID
    timestamp
    listDevicesResult
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOmniDeviceStatusMutationVariables,
  APITypes.DeleteOmniDeviceStatusMutation
>;
export const createClient = /* GraphQL */ `mutation CreateClient(
  $input: CreateClientInput!
  $condition: ModelClientConditionInput
) {
  createClient(input: $input, condition: $condition) {
    id
    name
    address
    email
    website
    phone
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Site {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClientMutationVariables,
  APITypes.CreateClientMutation
>;
export const updateClient = /* GraphQL */ `mutation UpdateClient(
  $input: UpdateClientInput!
  $condition: ModelClientConditionInput
) {
  updateClient(input: $input, condition: $condition) {
    id
    name
    address
    email
    website
    phone
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Site {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientMutationVariables,
  APITypes.UpdateClientMutation
>;
export const deleteClient = /* GraphQL */ `mutation DeleteClient(
  $input: DeleteClientInput!
  $condition: ModelClientConditionInput
) {
  deleteClient(input: $input, condition: $condition) {
    id
    name
    address
    email
    website
    phone
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Site {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientMutationVariables,
  APITypes.DeleteClientMutation
>;
export const createSensorReading = /* GraphQL */ `mutation CreateSensorReading(
  $input: CreateSensorReadingInput!
  $condition: ModelSensorReadingConditionInput
) {
  createSensorReading(input: $input, condition: $condition) {
    id
    readTime
    readValue
    sensorID
    pingType
    oldSensorID
    newSensorID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSensorReadingMutationVariables,
  APITypes.CreateSensorReadingMutation
>;
export const updateSensorReading = /* GraphQL */ `mutation UpdateSensorReading(
  $input: UpdateSensorReadingInput!
  $condition: ModelSensorReadingConditionInput
) {
  updateSensorReading(input: $input, condition: $condition) {
    id
    readTime
    readValue
    sensorID
    pingType
    oldSensorID
    newSensorID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSensorReadingMutationVariables,
  APITypes.UpdateSensorReadingMutation
>;
export const deleteSensorReading = /* GraphQL */ `mutation DeleteSensorReading(
  $input: DeleteSensorReadingInput!
  $condition: ModelSensorReadingConditionInput
) {
  deleteSensorReading(input: $input, condition: $condition) {
    id
    readTime
    readValue
    sensorID
    pingType
    oldSensorID
    newSensorID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSensorReadingMutationVariables,
  APITypes.DeleteSensorReadingMutation
>;
export const createSensor = /* GraphQL */ `mutation CreateSensor(
  $input: CreateSensorInput!
  $condition: ModelSensorConditionInput
) {
  createSensor(input: $input, condition: $condition) {
    id
    name
    omniSensorId
    sensorType
    deviceID
    alertruleID
    hidden
    sortOrder
    energyEquation
    fullLoadKw
    min
    max
    avg
    avgRecent
    lastReading {
      id
      readTime
      readValue
      sensorID
      pingType
      oldSensorID
      newSensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorIssues {
      nextToken
      startedAt
      __typename
    }
    SensorReadings {
      nextToken
      startedAt
      __typename
    }
    sensorgroups {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSensorMutationVariables,
  APITypes.CreateSensorMutation
>;
export const updateSensor = /* GraphQL */ `mutation UpdateSensor(
  $input: UpdateSensorInput!
  $condition: ModelSensorConditionInput
) {
  updateSensor(input: $input, condition: $condition) {
    id
    name
    omniSensorId
    sensorType
    deviceID
    alertruleID
    hidden
    sortOrder
    energyEquation
    fullLoadKw
    min
    max
    avg
    avgRecent
    lastReading {
      id
      readTime
      readValue
      sensorID
      pingType
      oldSensorID
      newSensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorIssues {
      nextToken
      startedAt
      __typename
    }
    SensorReadings {
      nextToken
      startedAt
      __typename
    }
    sensorgroups {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSensorMutationVariables,
  APITypes.UpdateSensorMutation
>;
export const deleteSensor = /* GraphQL */ `mutation DeleteSensor(
  $input: DeleteSensorInput!
  $condition: ModelSensorConditionInput
) {
  deleteSensor(input: $input, condition: $condition) {
    id
    name
    omniSensorId
    sensorType
    deviceID
    alertruleID
    hidden
    sortOrder
    energyEquation
    fullLoadKw
    min
    max
    avg
    avgRecent
    lastReading {
      id
      readTime
      readValue
      sensorID
      pingType
      oldSensorID
      newSensorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorIssues {
      nextToken
      startedAt
      __typename
    }
    SensorReadings {
      nextToken
      startedAt
      __typename
    }
    sensorgroups {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSensorMutationVariables,
  APITypes.DeleteSensorMutation
>;
export const createSite = /* GraphQL */ `mutation CreateSite(
  $input: CreateSiteInput!
  $condition: ModelSiteConditionInput
) {
  createSite(input: $input, condition: $condition) {
    id
    name
    timezone
    sensorsUpdatedAt
    lastOfflineNotification
    clientID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorGroups {
      nextToken
      startedAt
      __typename
    }
    ReportTemplates {
      nextToken
      startedAt
      __typename
    }
    ServiceContacts {
      nextToken
      startedAt
      __typename
    }
    MaintenanceSchedules {
      nextToken
      startedAt
      __typename
    }
    AlertRules {
      nextToken
      startedAt
      __typename
    }
    Devices {
      nextToken
      startedAt
      __typename
    }
    UserContactPreferences {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSiteMutationVariables,
  APITypes.CreateSiteMutation
>;
export const updateSite = /* GraphQL */ `mutation UpdateSite(
  $input: UpdateSiteInput!
  $condition: ModelSiteConditionInput
) {
  updateSite(input: $input, condition: $condition) {
    id
    name
    timezone
    sensorsUpdatedAt
    lastOfflineNotification
    clientID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorGroups {
      nextToken
      startedAt
      __typename
    }
    ReportTemplates {
      nextToken
      startedAt
      __typename
    }
    ServiceContacts {
      nextToken
      startedAt
      __typename
    }
    MaintenanceSchedules {
      nextToken
      startedAt
      __typename
    }
    AlertRules {
      nextToken
      startedAt
      __typename
    }
    Devices {
      nextToken
      startedAt
      __typename
    }
    UserContactPreferences {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSiteMutationVariables,
  APITypes.UpdateSiteMutation
>;
export const deleteSite = /* GraphQL */ `mutation DeleteSite(
  $input: DeleteSiteInput!
  $condition: ModelSiteConditionInput
) {
  deleteSite(input: $input, condition: $condition) {
    id
    name
    timezone
    sensorsUpdatedAt
    lastOfflineNotification
    clientID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    SensorGroups {
      nextToken
      startedAt
      __typename
    }
    ReportTemplates {
      nextToken
      startedAt
      __typename
    }
    ServiceContacts {
      nextToken
      startedAt
      __typename
    }
    MaintenanceSchedules {
      nextToken
      startedAt
      __typename
    }
    AlertRules {
      nextToken
      startedAt
      __typename
    }
    Devices {
      nextToken
      startedAt
      __typename
    }
    UserContactPreferences {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSiteMutationVariables,
  APITypes.DeleteSiteMutation
>;
export const createOmniDevice = /* GraphQL */ `mutation CreateOmniDevice(
  $input: CreateOmniDeviceInput!
  $condition: ModelOmniDeviceConditionInput
) {
  createOmniDevice(input: $input, condition: $condition) {
    id
    deviceName
    note
    connectionType
    ipAddress
    hostname
    port
    username
    password
    devicegroupID
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Devices {
      nextToken
      startedAt
      __typename
    }
    OmniDeviceStatuses {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOmniDeviceMutationVariables,
  APITypes.CreateOmniDeviceMutation
>;
export const updateOmniDevice = /* GraphQL */ `mutation UpdateOmniDevice(
  $input: UpdateOmniDeviceInput!
  $condition: ModelOmniDeviceConditionInput
) {
  updateOmniDevice(input: $input, condition: $condition) {
    id
    deviceName
    note
    connectionType
    ipAddress
    hostname
    port
    username
    password
    devicegroupID
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Devices {
      nextToken
      startedAt
      __typename
    }
    OmniDeviceStatuses {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOmniDeviceMutationVariables,
  APITypes.UpdateOmniDeviceMutation
>;
export const deleteOmniDevice = /* GraphQL */ `mutation DeleteOmniDevice(
  $input: DeleteOmniDeviceInput!
  $condition: ModelOmniDeviceConditionInput
) {
  deleteOmniDevice(input: $input, condition: $condition) {
    id
    deviceName
    note
    connectionType
    ipAddress
    hostname
    port
    username
    password
    devicegroupID
    siteID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Devices {
      nextToken
      startedAt
      __typename
    }
    OmniDeviceStatuses {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOmniDeviceMutationVariables,
  APITypes.DeleteOmniDeviceMutation
>;
export const pingOmniDevice = /* GraphQL */ `mutation PingOmniDevice($input: PingOmniDeviceInput) {
  pingOmniDevice(input: $input) {
    statusCode
    omnideviceID
    message
    listDevicesResult
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PingOmniDeviceMutationVariables,
  APITypes.PingOmniDeviceMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    Username
    Name
    Email
    Phone
    EmailAlerts
    SmsAlerts
    IsAdmin
    ClientID
    SiteID
    AccountContactPreference
    UserStatus
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    UserContactPreferences {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    Username
    Name
    Email
    Phone
    EmailAlerts
    SmsAlerts
    IsAdmin
    ClientID
    SiteID
    AccountContactPreference
    UserStatus
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    UserContactPreferences {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    Username
    Name
    Email
    Phone
    EmailAlerts
    SmsAlerts
    IsAdmin
    ClientID
    SiteID
    AccountContactPreference
    UserStatus
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    UserContactPreferences {
      nextToken
      startedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createUserContactPreferences = /* GraphQL */ `mutation CreateUserContactPreferences(
  $input: CreateUserContactPreferencesInput!
  $condition: ModelUserContactPreferencesConditionInput
) {
  createUserContactPreferences(input: $input, condition: $condition) {
    id
    userID
    siteID
    canSendEmail
    canSendText
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserContactPreferencesMutationVariables,
  APITypes.CreateUserContactPreferencesMutation
>;
export const updateUserContactPreferences = /* GraphQL */ `mutation UpdateUserContactPreferences(
  $input: UpdateUserContactPreferencesInput!
  $condition: ModelUserContactPreferencesConditionInput
) {
  updateUserContactPreferences(input: $input, condition: $condition) {
    id
    userID
    siteID
    canSendEmail
    canSendText
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserContactPreferencesMutationVariables,
  APITypes.UpdateUserContactPreferencesMutation
>;
export const deleteUserContactPreferences = /* GraphQL */ `mutation DeleteUserContactPreferences(
  $input: DeleteUserContactPreferencesInput!
  $condition: ModelUserContactPreferencesConditionInput
) {
  deleteUserContactPreferences(input: $input, condition: $condition) {
    id
    userID
    siteID
    canSendEmail
    canSendText
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserContactPreferencesMutationVariables,
  APITypes.DeleteUserContactPreferencesMutation
>;
export const createSensorGroupSensor = /* GraphQL */ `mutation CreateSensorGroupSensor(
  $input: CreateSensorGroupSensorInput!
  $condition: ModelSensorGroupSensorConditionInput
) {
  createSensorGroupSensor(input: $input, condition: $condition) {
    id
    sensorgroupID
    sensorID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    sensorgroup {
      id
      name
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    sensor {
      id
      name
      omniSensorId
      sensorType
      deviceID
      alertruleID
      hidden
      sortOrder
      energyEquation
      fullLoadKw
      min
      max
      avg
      avgRecent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSensorGroupSensorMutationVariables,
  APITypes.CreateSensorGroupSensorMutation
>;
export const updateSensorGroupSensor = /* GraphQL */ `mutation UpdateSensorGroupSensor(
  $input: UpdateSensorGroupSensorInput!
  $condition: ModelSensorGroupSensorConditionInput
) {
  updateSensorGroupSensor(input: $input, condition: $condition) {
    id
    sensorgroupID
    sensorID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    sensorgroup {
      id
      name
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    sensor {
      id
      name
      omniSensorId
      sensorType
      deviceID
      alertruleID
      hidden
      sortOrder
      energyEquation
      fullLoadKw
      min
      max
      avg
      avgRecent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSensorGroupSensorMutationVariables,
  APITypes.UpdateSensorGroupSensorMutation
>;
export const deleteSensorGroupSensor = /* GraphQL */ `mutation DeleteSensorGroupSensor(
  $input: DeleteSensorGroupSensorInput!
  $condition: ModelSensorGroupSensorConditionInput
) {
  deleteSensorGroupSensor(input: $input, condition: $condition) {
    id
    sensorgroupID
    sensorID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    sensorgroup {
      id
      name
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    sensor {
      id
      name
      omniSensorId
      sensorType
      deviceID
      alertruleID
      hidden
      sortOrder
      energyEquation
      fullLoadKw
      min
      max
      avg
      avgRecent
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSensorGroupSensorMutationVariables,
  APITypes.DeleteSensorGroupSensorMutation
>;
