import { useQuery } from 'react-query'
import { omniDeviceBySiteID as query } from '../../../graphql/queries'
import {
  OmniDeviceBySiteIDQueryVariables as VariablesType,
  OmniDeviceBySiteIDQuery as QueryType
} from '../../../API'
import { api } from '../../gql-client'

const OMNI_DEVICES_KEY = 'omni-devices'

type Opts = {
  enabled: boolean
}

function useListOmniDevices(
  variables: VariablesType = {},
  opts: Opts = { enabled: true }
) {
  return useQuery(
    [OMNI_DEVICES_KEY, variables],
    async () => {
      const res = await api.request<QueryType>({
        query,
        variables
      })

      return res.data?.omniDeviceBySiteID
    },
    { enabled: opts.enabled }
  )
}

export { OMNI_DEVICES_KEY, useListOmniDevices }
