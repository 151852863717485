import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  userBody: {
    margin: theme.spacing(3, 5),
    width: '60%'
  },
  infoContainer: {
    border: '1px solid rgba(124, 207, 255, 0.5)',
    borderRadius: '7px',
    boxShadow: '2px 3px 5px 1px rgba(0, 0, 0, 0.2)',
    margin: theme.spacing(2, 0, 3, 0),
    padding: theme.spacing(1, 3)
  },
  infoTitle: {
    textDecoration: 'underline',
    textUnderlineOffset: '1px',
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
  contactSubContainer: {
    width: '100%',
    display: 'flex',
    // Remove this rule, it's not performant or scalable. Give sub-components the class we know they deserve
    '& > div': {
      flexGrow: 1,
      flexBasis: '1px'
    }
  },
  subInfo: {
    color: theme.palette.secondary.light,
    fontWeight: 600
  },
  info: {
    marginLeft: theme.spacing(1),
    overflowWrap: 'anywhere'
  },
  resetBtn: {
    marginTop: theme.spacing(1.5),
    color: 'white',
    fontSize: 15
  },
  deleteBtn: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#ba000d'
    }
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))
